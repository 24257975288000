import React, { FC, useCallback, useState } from "react";
import {
  DescriptionBlock,
  DragNDropBlock,
  DragNDropZone,
  FolderIcon,
  InstructionItem,
  InstructionItemNumber,
  TemplateLink,
  UploadButtons,
  UploadControlBlock,
  UploadFormWrapper,
  UploadInstructionList,
  UploadStatus,
  UploadText,
  UploadTitle,
  UploadZoneTitle,
} from "./styles";
import { IUploadFormProps } from "./props";
import { Button } from "../../../../components";
import { ButtonStylePreset } from "../../../../utils/types";
import { message, Upload } from "antd";
import { KeyriError } from "../../../../utils/keyri-error";
import { readFile } from "../../../../utils/csv-helper";
import moment from "moment";
import { fingerprintListTableValidator } from "../../../../utils/validators";
import { fingerprintListsAPI } from "../../../../utils/api";
import { defaultErrorHandler } from "../../../../utils/helpers";
import { useStore } from "../../../../utils/hooks";
import { API_URL } from "../../../../utils/constants";

const { Dragger } = Upload;

export const UploadForm: FC<IUploadFormProps> = ({ recordsHandler, showControlBlock = true }) => {
  const {
    services: { currentService },
  } = useStore();

  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);
  const [records, setRecords] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(async () => {
    try {
      if (!currentService) throw new KeyriError(998);
      setLoading(true);
      await fingerprintListsAPI.createNewRecords({ serviceId: currentService.id, records, override: true });
      message.success("Records successfully added.");
    } catch (err) {
      defaultErrorHandler(err);
    } finally {
      setRecords([]);
      setTotalRecordsCount(0);
      setLoading(false);
    }
  }, [records]);

  const defaultFileHandler = useCallback(
    async ({ file }) => {
      try {
        if (!currentService) throw new KeyriError(998);
        const result = await readFile(file);
        if (["Value", "Outcome", "Expiry Date", "List Type"].some((value) => !result?.columns.includes(value)))
          throw new KeyriError({ code: 999, message: "CSV should include next columns: 'Value', 'Outcome', 'Expiry Date', 'List Type'" });
        const records = result.data.map((record) => ({
          value: record.Value,
          expired_date: moment(record["Expiry Date"]).valueOf(),
          notes: record.Notes,
          outcome: record.Outcome,
          list_type: record["List Type"],
        }));
        const errors = fingerprintListTableValidator({ records });
        if (Object.keys(errors).length) {
          const errorMessage = Object.keys(errors)
            .map((index: string) => `${index}. ${Object.values(errors[index as any]).join(", ")}`)
            .join("\n");
          throw new KeyriError({ code: 999, message: errorMessage });
        }
        setRecords(records);
        setTotalRecordsCount(records.length);
        if (recordsHandler) recordsHandler(records);
      } catch (err) {
        defaultErrorHandler(err);
      }
    },
    [currentService]
  );

  const handleCancelButton = useCallback(() => {
    setRecords([]);
    setTotalRecordsCount(0);
  }, []);

  return (
    <UploadFormWrapper>
      <UploadTitle>Upload CSV</UploadTitle>
      <DescriptionBlock>
        <UploadInstructionList>
          <InstructionItem>
            <InstructionItemNumber>1</InstructionItemNumber>
            <span>
              Download Keyri’s CSV template <TemplateLink href={`${API_URL}/service-list/template`}>here</TemplateLink>.
            </span>
          </InstructionItem>
          <InstructionItem>
            <InstructionItemNumber>2</InstructionItemNumber>Input relevant data from each list field.
          </InstructionItem>
          <InstructionItem>
            <InstructionItemNumber>3</InstructionItemNumber>Upload populated CSV file to the link provided.
          </InstructionItem>
        </UploadInstructionList>
      </DescriptionBlock>
      <DragNDropBlock>
        <DragNDrop fileHandler={defaultFileHandler} />
      </DragNDropBlock>
      {showControlBlock && (
        <UploadControlBlock>
          <UploadButtons>
            <Button disabled={!records.length || isLoading} width={66} onClick={handleSubmit} preset={ButtonStylePreset.BaseButtonWithBorder} title="Save" />
            <Button
              disabled={!records.length || isLoading}
              width={78}
              onClick={handleCancelButton}
              preset={ButtonStylePreset.BaseButtonWithBorder}
              title="Cancel"
            />
          </UploadButtons>
          <UploadStatus>Total New Fields: {totalRecordsCount}</UploadStatus>
        </UploadControlBlock>
      )}
    </UploadFormWrapper>
  );
};

const DragNDrop: FC<any> = ({ fileHandler }) => {
  return (
    <Dragger name="fingerprint-list" listType="text" showUploadList={false} accept={".csv"} maxCount={1} beforeUpload={() => false} onChange={fileHandler}>
      {
        <DragNDropZone>
          <FolderIcon />
          <UploadZoneTitle>Drag file here or</UploadZoneTitle>
          <UploadText>Upload file</UploadText>
        </DragNDropZone>
      }
    </Dragger>
  );
};
