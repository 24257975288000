import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { FONTS } from "../../assets";
import { DIMENSIONS } from "../../utils/constants";

export const AdminMenuWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  height: max-content;
  min-width: 260px;
  width: 260px;
  margin: 0 42px 50px 0;
  padding: 24px;

  position: sticky;
  top: 10px;
  z-index: 10;

  display: flex;
  flex-direction: column;
  gap: 8px;

  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.04);
  border-radius: 10px;

  @media (max-width: ${DIMENSIONS.LIST_BLOCK}px) {
    min-width: 65px;
    width: 65px;
    padding: 10px;
    margin-right: 22px;
  }
`;

export const TraillingLine = styled.div`
  margin: 12px 0;

  border: 1px solid ${COLORS.GALLERY};
`;

export const MenuSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SectionHeader = styled.div`
  margin: 12px;

  font-family: "Miriam Libre";
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.GRAY};

  @media (max-width: ${DIMENSIONS.LIST_BLOCK}px) {
    display: none;
  }
`;

export const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const SectionExternalItem = styled.a`
  padding: 0 0 0 14px;
  height: 45px;
  width: 100%;

  display: flex;
  align-items: center;
  gap: 10px;

  font-family: ${FONTS.RUBIK};
  color: ${COLORS.SCORPION};

  border: 1px solid transparent;
  background: transparent;
  border-radius: 6px;

  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${COLORS.MEDIUM_PURPLE};
    border: 1px solid ${COLORS.MEDIUM_PURPLE};
    background-color: ${COLORS.WHITE_LILAC};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  }
`;

export const SectionItemIconContainer = styled.div`
  font-family: ${FONTS.RUBIK};
  font-size: 11px;

  @media (max-width: ${DIMENSIONS.LIST_BLOCK}px) {
    font-size: 14px;
  }
`;

export const SectionItemText = styled.div`
  font-family: ${FONTS.RUBIK};
  font-size: 14px;
  line-height: 17px;

  @media (max-width: ${DIMENSIONS.LIST_BLOCK}px) {
    display: none;
  }
`;

export const HiddenMenuElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${DIMENSIONS.LIST_BLOCK}px) {
    display: none;
  }
`;
