import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, MailVerification, QRCode } from "../../components";
import { authAPI } from "../../utils/api";
import { DIMENSIONS, env, KEYRI_LINKS, Routes, WIDGET_LOGIN_URL } from "../../utils/constants";
import { useStore, useTabTitle } from "../../utils/hooks";
import {
  AdditionalBlock,
  ChangeRoute,
  ExternalLink,
  FormContentWrapper,
  FormFieldWrapper,
  FormWrapper,
  InfoWarnIcon,
  InputImage,
  Logo,
  MainWrapper,
  WarnAlert,
} from "../../styles";
import ILoginScreenProps from "./props";
import { IMAGES } from "../../assets";
import { ButtonStylePreset, FingerprintLogEvents, ILoginForm } from "../../utils/types";
import { COLORS } from "../../utils/colors";
import { DefaultPasswordLogin, FormHeader, Line, LoginTypeContainer, QrCodeWrapper, QrContainer, QrLogin } from "./styles";
import { useFormik } from "formik";
import { loginFormValidator } from "../../utils/validators";
import { defaultErrorHandler, generateQueryString, parseQuery } from "../../utils/helpers";
import { Input } from "antd";
import { fingerprintHelper } from "../../utils/fingerprint-helper";
import { KeyriError } from "../../utils/keyri-error";

export enum LoginSteps {
  LoginScreen = "loginScreen",
  SentEmailScreen = "sentEmailScreen",
}

type LoginHandlerEmailPayload = {
  email: string;
};

type LoginHandlerV3Payload = {
  deviceId?: string;
  payload?: string;
  email?: string;
  timestamp_nonce?: string;
  signature?: string;
};

export const LoginScreen: FC<ILoginScreenProps> = ({ authCallback }) => {
  useTabTitle("Login");
  const {
    user: { confirmUserAccount },
  } = useStore();
  const [stage, setStage] = useState<LoginSteps>(LoginSteps.LoginScreen);
  const navigate = useNavigate();

  const {
    values: { email },
    handleChange,
    errors,
    handleSubmit,
    handleBlur,
    touched,
    setErrors,
  } = useFormik<ILoginForm>({
    onSubmit: (payload) => {
      loginHandler(payload as LoginHandlerEmailPayload);
    },
    initialValues: {
      email: "",
    },
    validate: loginFormValidator,
  });

  const location = useLocation();
  const params = generateQueryString(
    ["dev", "test"].includes(env)
      ? {
          Origin: `${env}-app.keyri.com`,
          Environment: "dev",
          Custom: JSON.stringify({ browserType: "browser", type: "login" }),
          qsd: "true",
        }
      : { Custom: JSON.stringify({ browserType: "browser", type: "login" }), qsd: "true" }
  );

  const loginLink = `${WIDGET_LOGIN_URL}?${params}`;

  const loginHandler = useCallback(
    async (data: LoginHandlerEmailPayload | LoginHandlerV3Payload, src?: string) => {
      try {
        if ((data as LoginHandlerV3Payload).payload) {
          if (src !== loginLink) return;
          const payloadObject = JSON.parse((data as LoginHandlerV3Payload).payload as string);
          const { email } = payloadObject;
          fingerprintHelper.createEvent(FingerprintLogEvents.Login, email).catch((err) => console.error(err));
          await authAPI.login(data);
          await authCallback();
          navigate(Routes.Welcome);
        } else if ((data as LoginHandlerV3Payload).signature) {
          if (src !== loginLink) return;
          const { email } = data;
          fingerprintHelper.createEvent(FingerprintLogEvents.Login, email as string).catch((err) => console.error(err));
          await authAPI.login({ payload: JSON.stringify(data) });
          await authCallback();
          navigate(Routes.Welcome);
        } else {
          await authAPI.loginByEmail({ email: data.email?.toLowerCase() });
          setStage(LoginSteps.SentEmailScreen);
        }
      } catch (err: unknown | KeyriError) {
        if (err instanceof KeyriError && err.code === 1008) {
          setErrors({
            email: `It doesn’t look like there’s an account associated with this email. Sign up to continue.`,
          });
        } else {
          defaultErrorHandler(err);
        }
      }
    },
    [navigate, setErrors]
  );

  useEffect(() => {
    try {
      if (parseQuery(location.search).token) {
        confirmUserAccount(parseQuery(location.search).token?.toString()).catch((err) => {
          navigate(Routes.Login);
          defaultErrorHandler(err);
        });
      }
    } catch (err: unknown | KeyriError) {
      navigate(Routes.Login);
      defaultErrorHandler(err);
    }
  }, [location.search, confirmUserAccount]);

  const LoginMain = useCallback(() => {
    switch (stage) {
      case LoginSteps.LoginScreen: {
        return (
          <LoginScreenWithQR
            email={email}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            loginHandler={loginHandler}
            loginLink={loginLink}
            touched={touched}
            errors={errors}
          />
        );
      }

      case LoginSteps.SentEmailScreen: {
        return <MailVerification type={"login"} />;
      }
    }
  }, [stage, email, handleBlur, handleChange, handleSubmit, loginLink, touched, errors, loginHandler]);

  return (
    <MainWrapper>
      <FormContentWrapper>
        <a href="https://keyri.com">
          <Logo src={IMAGES.NEW_LOGO} alt="Keyri logo" />
        </a>
        {LoginMain()}
        <AdditionalBlock color={COLORS.MOON_RAKER}>
          By clicking Log In, or scanning the QR code, you agree to Keyri’s
          <ExternalLink href={KEYRI_LINKS.Terms} target="_blank">
            {" "}
            Terms of Service
          </ExternalLink>
          . <br /> You may receive email notifications from us and can opt out at any time.
        </AdditionalBlock>
      </FormContentWrapper>
    </MainWrapper>
  );
};

const LoginScreenWithQR: FC<any> = ({ email, handleChange, handleBlur, handleSubmit, loginHandler, loginLink, touched, errors }) => {
  return (
    <FormWrapper>
      <LoginTypeContainer>
        <DefaultPasswordLogin>
          <FormHeader align="left">Welcome back!</FormHeader>

          {touched.email && errors.email && errors.email && <WarnAlert message={errors.email} type="info" icon={<InfoWarnIcon />} showIcon maxWidth={350} />}

          <FormFieldWrapper minWidth={350}>
            <Input
              name="email"
              type="email"
              value={email}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Email Address"
              prefix={<InputImage src={IMAGES.EMAIL} alt="Email Address field" />}
            />
          </FormFieldWrapper>

          <Button preset={ButtonStylePreset.LightPurpleButton} onClick={handleSubmit} title={"Log In"} />
          <AdditionalBlock color={COLORS.GRAY} fontSize="14px">
            Don’t have an account?
            <ChangeRoute to={Routes.SignUp}> Sign up</ChangeRoute>
          </AdditionalBlock>
        </DefaultPasswordLogin>
        <Line />
        <QrLogin>
          <FormHeader align="left">Log in with QR code</FormHeader>
          <QrContainer>
            <QrCodeWrapper dimension={DIMENSIONS.QR_CODE_DIMENSION}>
              <QRCode action={loginHandler} dimension={DIMENSIONS.QR_CODE_DIMENSION} src={loginLink} />
            </QrCodeWrapper>
          </QrContainer>
        </QrLogin>
      </LoginTypeContainer>
    </FormWrapper>
  );
};
