import React, { FC, MouseEventHandler, useCallback, useEffect } from "react";
import { message } from "antd";
import { useFormik } from "formik";
import Icon from "@ant-design/icons";

import { IAccountAccessPerDeviceProps } from "./props";
import { ICONS } from "assets";
import { Input, KeyriSwitch } from "components";
import { defaultErrorHandler } from "utils/helpers";
import { accAccessPerDeviceFormValidator } from "utils/validators";
import { FingerprintRulesList, IAccountAccessPerDeviceForm, InputStylePreset } from "utils/types";
import { successParamChangeMessage } from "../../index";
import { KeyriError } from "utils/keyri-error";
import { NoWrappedBlock } from "../../styles";
import {
  CancelPackageButton,
  FieldWrapper,
  IconStyle,
  ParameterTitle,
  ParameterTitleContainer,
  ParameterValues,
  RiskFormDefault,
  RiskParameter,
  SavePackageButton,
} from "styles";

export const AccountAccessPerDevice: FC<IAccountAccessPerDeviceProps> = ({ disabled = false, initialValue, ruleStatus, handleEnableSwitch, onSubmit }) => {
  const {
    values: { denyAccAccess },
    handleChange,
    errors,
    handleSubmit,
    handleBlur,
    touched,
    resetForm,
  } = useFormik<IAccountAccessPerDeviceForm>({
    onSubmit: async () => {
      try {
        await onSubmit(denyAccAccess);
        message.success(successParamChangeMessage);
      } catch (err: unknown | KeyriError) {
        resetForm();
        defaultErrorHandler(err);
      }
    },
    initialValues: {
      denyAccAccess: initialValue,
    },
    validate: accAccessPerDeviceFormValidator,
  });

  useEffect(() => {
    errors.denyAccAccess && message.error(errors.denyAccAccess);
  }, [errors.denyAccAccess]);

  const cancelValueChanges = useCallback(() => {
    resetForm({
      values: {
        denyAccAccess: initialValue,
      },
    });
  }, [resetForm]);

  return (
    <RiskParameter data-name="account-access-per-device">
      <ParameterTitleContainer maxWidth={250} minWidth={250}>
        <Icon component={ICONS.AVATAR} style={IconStyle} />
        <ParameterTitle>Account Access per Device</ParameterTitle>
      </ParameterTitleContainer>

      <NoWrappedBlock>
        <ParameterValues>
          <RiskFormDefault onSubmit={handleSubmit}>
            <FieldWrapper>
              <Input
                disabled={disabled || !ruleStatus}
                onBlur={handleBlur}
                name="denyAccAccess"
                type="number"
                value={denyAccAccess.toString()}
                onChange={handleChange}
                error={touched.denyAccAccess && errors.denyAccAccess ? errors.denyAccAccess : ""}
                preset={InputStylePreset.NarrowPaddedForPlaceholder}
                maxWidth={80}
                min={0}
                max={1000}
              />
            </FieldWrapper>

            {denyAccAccess !== initialValue ? (
              <>
                <SavePackageButton
                  data-status={!Object.keys(errors).length ? "active" : "disabled"}
                  disabled={!!errors?.denyAccAccess}
                  data-name={"account-access-per-device"}
                  onClick={handleSubmit as unknown as MouseEventHandler}
                />
                <CancelPackageButton data-name={"reset-account-access-per-device"} onClick={cancelValueChanges} />
              </>
            ) : undefined}
          </RiskFormDefault>
        </ParameterValues>
        <KeyriSwitch disabled={disabled} checked={ruleStatus} onChange={handleEnableSwitch(FingerprintRulesList.AccountAccessPerDevice)} />
      </NoWrappedBlock>
    </RiskParameter>
  );
};
