import styled from "styled-components";
import { DIMENSIONS } from "./utils/constants";
import { FONTS } from "./assets";
import { COLORS } from "./utils/colors";
import { Alert, Input } from "antd";
import {CheckOutlined, CloseOutlined, InfoCircleFilled} from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Search } = Input;

export interface IMaxWidthProps {
  maxWidth?: number;
}

export interface IMinWidthProps {
  minWidth?: number;
}

export interface IMaxDimensionsProps {
  maxWidth?: number;
  maxHeight?: number;
}

export interface IAlignProps {
  align?: string;
}

export type IAdditionalBlockProps = {
  color?: string;
  fontSize?: string;
};

export interface IBlockType {
  type?: "small" | "regular";
}

export interface IIndexProps {
  color: string;
}

export interface IFlexAlignProps {
  align?: string;
}

interface IGapProps {
  gap?: number;
}

interface ISearchBlockProps {
  height?: number;
}

interface IWarnAlertProps {
  maxWidth?: number;
}

const BaseAlertStyles = `
  border-radius: 4px !important;
  margin: 0px auto 12px auto;
  width: 100%;
`;

export const GeneralBlockShadow = `
  background-color: ${COLORS.WHITE};
  border-radius: 20px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.04);
  width: 100%;
`;

export const SearchBlock = styled(Search)<ISearchBlockProps>`
  height: ${({height}) => height ? height : 35}px !important;

  & > .ant-input-wrapper {
    height: ${({height}) => height ? height - 2 : 33}px !important;

    & > .ant-input-search,
    & > .ant-input,
    & > .ant-input-group-addon,
    & .ant-input-search,
    & .ant-btn {
      height: ${({height}) => height ? height - 2 : 33}px !important;
      font-size: 14px !important;
    }
  }
`;

export const WarnAlert = styled(Alert)<IWarnAlertProps>`
  max-width: ${({ maxWidth }) => (!!maxWidth ? `${maxWidth}px` : "100%")};
  background-color: ${COLORS.WHITE_LILAC} !important;
  border-color: ${COLORS.MOON_RAKER} !important;
  ${BaseAlertStyles}
`;

export const BillingWarnALert = styled(Alert)<IWarnAlertProps>`
  ${BaseAlertStyles};
  max-width: ${({ maxWidth }) => (!!maxWidth ? `${maxWidth}px` : "100%")};
  background-color: ${COLORS.BRIDAL_HEATH} !important;
  border-color: #fed18d !important;
`;

export const InfoWarnIcon = styled(InfoCircleFilled)`
  color: ${COLORS.MEDIUM_PURPLE} !important;
`;

export const InfoBillingWarnIcon = styled(InfoCircleFilled)`
  color: #fdb13f !important;
`;

export const Form = `
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  width: 100%;
  max-width: 430px;
  
  @media (max-width: ${DIMENSIONS.BUTTON_WITH_SMALL_DIMENSION}px) {
    width: 100%;
  }
`;

export const RiskManagementSubTitle = styled.div<{ color?: string }>`
  font-family: ${FONTS.RUBIK};
  font-weight: 400;
  font-size: 14px;
  color: ${({ color }) => (color ? color : COLORS.MINE_SHAFT)};
  height: 40px;
  display: flex;
  align-items: flex-end;
`;

export const ParametersList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 24px;

  li:first-child {
    margin-top: 24px;
  }

  font-family: ${FONTS.RUBIK};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${COLORS.SCORPION};
`;

export const DevicesParamsAccessBlock = styled.div`
  margin-top: 42px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 1 0;
`;

export const FieldTitle = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.SCORPION};
  user-select: none;
`;

export const RiskParameter = styled.li<{ wrapFrom?: number }>`
  font-family: ${FONTS.RUBIK};
  position: relative;
  display: flex;
  justify-content: start;
  flex-direction: row;
  gap: 12px 30px;
  align-items: center;

  &[data-name="straight-line-distance"] {
    align-items: flex-end;
  }

  @media (max-width: ${({ wrapFrom }) => wrapFrom ?? DIMENSIONS.MAX_TABLET_WIDTH}px) {
    flex-wrap: wrap;
  }
`;

export const ParameterValues = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: 50%;
  max-width: 276px;
  width: 100%;
`;

export const HiddenInput = styled.input`
  display: none;
`;

const inputStyles = `
  width: 276px;
`;

export const CheckboxLabel = styled.label`
  ${inputStyles};
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${COLORS.GRAY};
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: ${COLORS.GRAY};

  input[type="checkbox"]:checked + & {
    border-color: ${COLORS.MEDIUM_PURPLE};
    color: ${COLORS.MEDIUM_PURPLE};
  }

  input[type="checkbox"]:disabled + & {
    border-color: ${COLORS.LIGHT_PURPLE};
    color: ${COLORS.LIGHT_PURPLE};
  }
`;

export const RiskFormDefault = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 16px;
`;

export const RiskForm = styled.form`
  ${inputStyles};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 16px;
`;

export const EditButtons = `
  box-sizing: border-box !important;
  
  padding: 9px;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 4px;
  border: 1px solid ${COLORS.MEDIUM_PURPLE};
  color: ${COLORS.MEDIUM_PURPLE};
  cursor: pointer;
`;

const DisabledStyles = `
  cursor: not-allowed !important;
  border-color: ${COLORS.RADICAL_RED};
  color: ${COLORS.RADICAL_RED};
`;

export const SavePackageButton = styled(CheckOutlined)`
  ${EditButtons};
  ${({ disabled }) => (disabled ? DisabledStyles : "")}
`;

export const CancelPackageButton = styled(CloseOutlined)`
  ${EditButtons};
`;

export const ParameterTitleContainer = styled.div<{ wrap?: "wrap" | "nowrap"; minWidth?: number; maxWidth?: number }>`
  display: flex;
  gap: 12px;
  align-items: center;
  align-self: flex-end;
  flex-wrap: ${({ wrap }) => (wrap ? wrap : "nowrap")};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}px` : "")};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : "")};

  flex: 50%;
`;

export const IconStyle = {
  fontSize: "13px",
  lineHeight: "16px",
  color: `${COLORS.MEDIUM_PURPLE}`,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const RiskInputLabel = styled.label`
  position: relative;
  width: 100%;

  &::before {
    content: "Km";
    position: absolute;
    top: 6px;
    right: 12px;
  }
`;

export const MainContentWrapper = styled.div`
  flex: 1 0 auto;
`;

export const MainWrapper = styled.div`
  padding: 120px 60px 10px 60px;
  max-width: ${DIMENSIONS.CONTENT_MAX_WIDTH}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: ${FONTS.RUBIK};

  @media (max-width: ${DIMENSIONS.WHITEPAPER_FORM}px) {
    padding: 120px 30px 10px 30px;
  }
`;

export const FormContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 42px;
`;

export const DefaultForm = styled.form`
  ${Form}

  &:last-child {
    margin-bottom: 36px;
  }
`;

export const FormFieldWrapper = styled.div<IMaxWidthProps & IMinWidthProps>`
  display: flex;
  flex-direction: column;
  min-width: ${({minWidth}) => !!minWidth ? minWidth : 280}px;
  max-width: ${({minWidth}) => !!minWidth ? minWidth : 380}px;
  flex-shrink: 1;
  flex-grow: 1;
  gap: 10px;
`;

export const Logo = styled.img`
  height: 51px;
`;

export const AdditionalBlock = styled.div<IAdditionalBlockProps>`
  max-width: 500px;

  font-family: ${FONTS.RUBIK};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : "12px")};
  text-align: center;
  color: ${({ color }) => color};
`;

export const FormWrapper = styled.div<IMaxDimensionsProps>`
  padding: 40px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  background-color: ${COLORS.WHITE};
  border-radius: 20px;
  box-shadow: 0px 100px 80px rgba(108, 73, 172, 0.07), 0px 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198),
    0px 22.3363px 17.869px rgba(108, 73, 172, 0.0417275), 0px 12.5216px 10.0172px rgba(108, 73, 172, 0.035),
    0px 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725), 0px 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);

  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : "")}
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}px;` : "")};

  @media (max-width: ${DIMENSIONS.WHITEPAPER_FORM}px) {
    padding: 30px;
  }
`;

export const FormHeader = styled.h2<IAlignProps>`
  margin: 0;

  font-family: ${FONTS.RUBIK};
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;

  text-align: ${({ align }) => (align ? align : "center")};
  color: ${COLORS.SCORPION};
`;

export const InputLabel = styled.label`
  position: relative;
`;

export const InputImage = styled.img`
  height: 15px;
  width: 15px;
  margin-right: 3px;
`;

export const RequiredFieldInfo = styled.div`
  padding: 2px 0;
  font-family: ${FONTS.RUBIK};
  font-size: 12px;
  color: ${COLORS.MEDIUM_PURPLE};
`;

export const TitleMessage = styled.div`
  margin: 0 0 24px;

  font-family: ${FONTS.RUBIK};
  font-size: 20px;
  line-height: 26px;
  text-align: center;
`;

export const MenuItemContainer = styled.div<IMaxWidthProps>`
  width: 100%;
  margin: 0 0 42px;
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : "")}
`;

export const MenuItemContent = styled.div<IGapProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : 30)}px;
`;

export const BlockContainer = styled.div`
  font-family: ${FONTS.RUBIK};
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const BlockTitle = styled.div`
  font-family: ${FONTS.RUBIK};
  font-size: 20px;
  line-height: 26px;
  text-align: left;
  color: ${COLORS.SCORPION};
  
  a {
    color: ${COLORS.SCORPION};
  }
`;

export const BlockContent = styled.div<IBlockType>`
  padding: ${({ type }) => (type === "small" ? 32 : 60)}px;
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 36px;

  background-color: ${COLORS.WHITE};
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.04);
  border-radius: 20px;

  @media (max-width: ${DIMENSIONS.LIST_BLOCK}px) {
    padding: ${({ type }) => (type === "small" ? 16 : 30)}px;
  }
`;

export const ExternalLink = styled.a`
  font-family: ${FONTS.RUBIK};
  font-size: inherit;
  line-height: 22px;

  color: ${COLORS.MEDIUM_PURPLE};
  text-decoration: none;
`;

export const ChangeRoute = styled(Link)`
  font-family: ${FONTS.RUBIK};
  font-size: inherit;
  line-height: 22px;

  color: ${COLORS.MEDIUM_PURPLE};
  text-decoration: none;
`;

export const GoBackButton = styled.button`
  font-family: ${FONTS.RUBIK};
  font-size: inherit;
  line-height: 22px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  color: ${COLORS.MEDIUM_PURPLE};
  text-decoration: none;
`;

/* LIST WITH COLORED NUMBER */

export const StepList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Index = styled.div<IIndexProps>`
  min-width: 32px;
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${COLORS.WHITE};
  background-color: ${({ color }) => color};
  border-radius: 4px;
`;

export const Step = styled.div`
  display: flex;
  gap: 10%;
  width: 100%;

  @media (max-width: ${DIMENSIONS.L_BREAKPOINT_WIDTH}px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const StepExplanation = styled.li<IFlexAlignProps>`
  display: flex;
  align-items: ${({ align }) => (align ? align : "center")};
  gap: 16px;

  font-size: 15px;
  line-height: 23px;

  flex: 45%;
`;

export const StepAction = styled.div`
  flex: 45%;
`;

export const StepInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const StepText = styled.div`
  color: ${COLORS.SCORPION};
`;

export const StepDescription = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.GRAY};
`;

export const LabelsInput = `
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  
  position: relative;
  
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.ALTO};
  border-radius: 4px;
`;

export const ParameterTitle = styled.div<{maxWidth?: number}>`
  line-height: 32px;
  user-select: none;
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px` : "")};
`;

export const ParametersListBlock = styled.div`
  padding: 60px;
  max-width: 750px;

  background-color: ${COLORS.WHITE};
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.04);
  border-radius: 20px;

  @media (max-width: ${DIMENSIONS.LIST_BLOCK}px) {
    padding: 30px;
  }
`;

/* ------------------------ */
