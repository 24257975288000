import { FC } from "react";

import IButtonProps from "./props";
import { ButtonContainer, ButtonText, StyledLink } from "./styles";

export const Button: FC<IButtonProps> = ({ title, to, preset, applyAddedStyles, disabled, onClick, ...restProps }) => {
  return to ? (
    <StyledLink to={to}>
      <ButtonContainer preset={preset} onClick={!disabled ? onClick : undefined} {...restProps}>
        <ButtonText preset={preset}>{title}</ButtonText>
      </ButtonContainer>
    </StyledLink>
  ) : (
    <ButtonContainer data-type={title} preset={preset} disabled={disabled} {...restProps} onClick={!disabled ? onClick : undefined}>
      <ButtonText preset={preset} disabled={disabled} applyAddedStyles={applyAddedStyles}>
        {title}
      </ButtonText>
    </ButtonContainer>
  );
};
