import React, { FC, useMemo } from "react";
import { DeviceProperty, MainBlock } from "./styles";
import { ICONS } from "assets";
import Icon from "@ant-design/icons";
import { IDeviceInfoProps } from "./props";
import { format } from "date-fns";
import { IconStyle } from "styles";

export const DeviceInfo: FC<IDeviceInfoProps> = ({ userData }) => {
  const lastActivity = useMemo(() => {
    return userData?.lastLogDate ? format(new Date(userData.lastLogDate), "yyyy-MM-dd") : "No logs";
  }, [userData]);

  const firstActivity = useMemo(() => {
    return userData?.firstLogDate ? format(new Date(userData.firstLogDate), "yyyy-MM-dd") : "No logs";
  }, [userData]);

  return (
    <MainBlock>
      <DeviceProperty>
        <Icon component={ICONS.USER_VERTICAL_CARD} style={IconStyle} />
        User ID: {userData?.userId}
      </DeviceProperty>
      <DeviceProperty>
        <Icon component={ICONS.USER_VERTICAL_CARD} style={IconStyle} />
        Device count: {userData?.devicesCount}
      </DeviceProperty>
      <DeviceProperty>
        <Icon component={ICONS.USER_VERTICAL_CARD} style={IconStyle} />
        Last Activity: {lastActivity}
      </DeviceProperty>
      <DeviceProperty>
        <Icon component={ICONS.USER_VERTICAL_CARD} style={IconStyle} />
        First Activity: {firstActivity}
      </DeviceProperty>
    </MainBlock>
  );
};
