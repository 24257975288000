import React, { createContext } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RootStore } from "./stores";
import "./assets/fonts/stylesheet.css";
import "antd/lib/input/style/index.css";
import "antd/lib/table/style/index.css";
import "antd/lib/avatar/style/index.css";
import "antd/lib/button/style/index.css";
import "antd/lib/dropdown/style/index.css";
import "antd/lib/menu/style/index.css";
import "antd/lib/message/style/index.css";
import "antd/lib/space/style/index.css";
import "antd/lib/switch/style/index.css";
import "antd/lib/tooltip/style/index.css";
import "antd/lib/pagination/style/index.css";
import "antd/lib/spin/style/index.css";
import "antd/lib/modal/style/index.css";
import "antd/lib/date-picker/style/index.css";
import "antd/lib/input-number/style/index.css";
import "antd/lib/radio/style/index.css";
import "antd/lib/style/css";
import "antd/lib/select/style/index.css";
import "antd/lib/alert/style/index.css";
import "antd/lib/checkbox/style/index.css";
import "antd/lib/tag/style/index.css";
import "react-credit-cards/es/styles-compiled.css";
import { message } from "antd";
import * as Sentry from "@sentry/react";
import { useLocation } from "react-router";
import { createRoutesFromChildren, matchRoutes, useNavigationType } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary";

Sentry.init({
  dsn: "https://278ba5ba86b743e088933b5dea79c195@o409645.ingest.sentry.io/4504174883962880",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(React.useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
    }),
  ],
  release: process.env.REACT_APP_RELEASE,
  environment: process.env.REACT_APP_BRANCH === "dev" ? "development" : "test",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

export const store = RootStore.create({} as any);

export const StoreContext = createContext(store);

message.config({ maxCount: 1 });

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <StoreContext.Provider value={store}>
        <App />
      </StoreContext.Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
