import React, { FC, useCallback, useMemo, useState } from "react";
import { IServiceKeysCredentialsProps } from "./props";
import { useStore, useTabTitle } from "utils/hooks";
import { MenuItemContainer, MenuItemContent } from "styles";
import { AppDetails, DeleteAppModal, KeysNCredentials, VerifyDomain } from "components";
import { DeleteAppBlock, DeleteAppButton } from "./styles";
import { DeleteOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { defaultErrorHandler } from "utils/helpers";
import { KeyriError } from "utils/keyri-error";
import { Role } from "@denver23/keyri-shared";

const ServiceKeysCredentialsComponent: FC<IServiceKeysCredentialsProps> = ({ setIsFetch }) => {
  useTabTitle("Keys & Credentials");
  const {
    services,
    services: { currentService },
    user,
  } = useStore();

  const [isDeleteAppModalVisible, setIsModalVisible] = useState<boolean>(false);

  const canDelete = useMemo<boolean>(() => {
    return currentService?.members?.find((member) => member.id === user.id)?.UserService?.role === Role.Owner;
  }, [user, currentService]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpdateServiceData = useCallback(
    (parameter) => {
      return async (value: string) => {
        if (!!currentService) {
          try {
            await services.updateCurrentService({
              [parameter]: value,
            });
          } catch (err: unknown | KeyriError) {
            defaultErrorHandler(err);
          }
        }
      };
    },
    [currentService, services]
  );

  return (
    <>
      <MenuItemContainer>
        <MenuItemContent gap={42}>
          {!services.currentService?.isDomainApproved && services.currentService?.domainName && <VerifyDomain />}
          <KeysNCredentials />
          <AppDetails updateService={handleUpdateServiceData} />
          <DeleteAppBlock visible={canDelete}>
            <DeleteAppButton onClick={showModal}>
              <DeleteOutlined /> Delete Application
            </DeleteAppButton>
          </DeleteAppBlock>
        </MenuItemContent>
      </MenuItemContainer>
      <DeleteAppModal visible={isDeleteAppModalVisible} onCancel={handleCancel} />
    </>
  );
};

export const ServiceKeysCredentials = observer(ServiceKeysCredentialsComponent);
