import styled from "styled-components";
import { COLORS } from "utils/colors";
import { DIMENSIONS } from "utils/constants";

export const AppListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 50%;

  @media (max-width: ${DIMENSIONS.MAX_FLEX_ROW_WIDTH}px) {
    flex-direction: column;
    gap: 56px;
  }
`;

export const AppListBlock = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;

  &:last-child {
    padding: 0 0 0 56px;
    border-style: solid;
    border-width: 0 0 0 1px;
    border-color: ${COLORS.GALLERY};

    @media (max-width: ${DIMENSIONS.MAX_FLEX_ROW_WIDTH}px) {
      padding: 56px 0 0 0;
      border-width: 1px 0 0 0;
    }
  }
`;

export const AppListTitle = styled.div`
  font-size: 18px;
  color: ${COLORS.SCORPION};

  display: flex;
  gap: 9px;
  align-items: center;
`;

export const PropertyName = styled.div<{ fontSize?: number }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 14)}px;
  color: ${COLORS.SCORPION};
  text-align: left;
  margin-bottom: 6px;

  display: flex;
  gap: 8px;
  align-items: center;
`;

export const AppDetailsIcon = {
  width: "18px",
  height: "18px",
  color: `${COLORS.MEDIUM_PURPLE}`,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
