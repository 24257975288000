import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { ISize } from "./types";
import { Devices, DIMENSIONS, DimensionTypes, Routes } from "./constants";
import { analyticsAPI, clearAxiosConfig, loadAxiosConfig } from "./api";
import { StoreContext } from "../index";
import { matchRoutes, useLocation } from "react-router-dom";
import { KeyriError } from "./keyri-error";
import { message } from "antd";

export const useCurrentPath = (): { name: string; path: string } | undefined => {
  const location = useLocation();
  const routes = Object.keys(Routes).map((routeName) => ({
    name: routeName,
    path: (Routes as any)[routeName],
  }));
  const result = matchRoutes(routes, location);

  return result && (result[0]?.route as any);
};

export const useIsSmallerDimension = (mediaValue: DimensionTypes) => {
  const { width }: ISize = useWindowSize();
  return !!width && width < DIMENSIONS[mediaValue];
};

export const useWindowSize = (): ISize => {
  const [windowSize, setWindowSize] = useState<ISize>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};

export const useTabTitle = (title: string): void => {
  useEffect(() => {
    document.title = `Keyri Passwordless Authentication | ${title}`;
  }, [title]);
};

export const useIsLargerYOffset = (YOffset: number): boolean => {
  const [isLargerOffset, setIsLargerOffset] = useState(false);

  useEffect(() => {
    const getCurrentOffsetStatus = () => setIsLargerOffset(window.pageYOffset >= YOffset);

    window.addEventListener("scroll", () => getCurrentOffsetStatus());

    return () => {
      window.removeEventListener("scroll", () => getCurrentOffsetStatus());
    };
  }, [YOffset]);
  return isLargerOffset;
};

export const useScrollToStart = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export const useDeviceOS = (): Devices => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/android/i.test(userAgent)) {
    return Devices.Android;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return Devices.IOS;
  }
  return Devices.Unknown;
};

export const useAuthenticatedUser = () => {
  const [error, setError] = useState<null | string>(null);
  const [isFetch, setIsFetch] = useState(loadAxiosConfig());

  const { user } = useStore();

  const logout = () => {
    clearAxiosConfig();
    user.setLoggedIn(false);
  };

  const AuthMe = useCallback(async () => {
    const isAuth = loadAxiosConfig();
    if (isAuth) {
      if (user.isLoggedIn) return;
      setIsFetch(true);
      try {
        await user.loadMe();
        user.setLoggedIn(true);
      } catch (err: any | KeyriError) {
        setError(err.message);
      } finally {
        setIsFetch(false);
      }
    } else {
      user.setLoggedIn(false);
      user.resetUserData();
    }
  }, [user, setError, setIsFetch, loadAxiosConfig]);

  useEffect(() => {
    AuthMe();
  }, [user.id, AuthMe]);

  return {
    error,
    setIsFetch,
    isFetch,
    logout,
    AuthMe,
  };
};

export const useUserServices = () => {
  const {
    user: { isLoggedIn },
    services: { loadUserServices },
  } = useStore();
  const controller = new AbortController();

  useEffect(() => {
    if (isLoggedIn) {
      loadUserServices(controller.signal);
    } else {
      controller.abort();
    }
  }, [isLoggedIn]);
};

export function useStore() {
  return useContext(StoreContext);
}

export function useAmberfloRequest({
  requests,
  currentService,
  periodQuery,
}: {
  requests: Array<{ body: any; callback: (item: any) => void }>;
  currentService: any;
  periodQuery: { from: number; to?: number };
}) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [tokenError, setTokenError] = useState<string | null>(null);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [amberfloToken, setToken] = useState<string | null>(null);

  const getAmberfloData = useCallback(
    async (initialToken?: string) => {
      let usedToken = amberfloToken;
      if (initialToken) {
        setToken(initialToken);
        usedToken = initialToken;
      }
      if (!usedToken) return;
      const { data } = await analyticsAPI.getAmberfloAnalytics(
        requests.map((request) => request.body),
        usedToken
      );
      if (Array.isArray(data)) data.forEach((item: any, index: number) => requests[index].callback(item));
      setLoading(false);
    },
    [amberfloToken, requests]
  );

  useEffect(() => {
    if (currentService && !tokenError) {
      setLoading(true);
      if (!amberfloToken) {
        analyticsAPI
          .getAmberfloToken(currentService.id)
          .then(async ({ data }) => {
            await getAmberfloData(data.data.sessionToken);
          })
          .catch((err: any | KeyriError) => {
            setTokenError(err.message);
          })
          .finally(() => setLoading(false));
      } else {
        getAmberfloData()
          .catch((err: any | KeyriError) => {
            message.error(err.message);
            setRequestError(err.message);
          })
          .finally(() => setLoading(false));
      }
    }
  }, [currentService?.id, periodQuery]);

  return { isLoading, tokenError, requestError };
}

export const useChangableField = (initialMode: boolean = false) => {
  const [nameInput, setNameInput] = useState<string | null>(null);
  const [editMode, setEditMode] = useState<boolean>(initialMode);
  const [firstCall, setFirstCall] = useState(true);
  const inputElement = useRef<any>(null);

  function switchEditMode(initialValue: string) {
    return () => {
      if (!editMode) {
        setNameInput(initialValue);
      }
      setEditMode(!editMode);
    };
  }

  function onInput(e: any) {
    setNameInput(e?.target?.value);
  }

  useEffect(() => {
    if (!firstCall && editMode && inputElement.current && typeof inputElement.current.focus === "function") {
      inputElement.current.focus();
    }
    if (firstCall) setFirstCall(false);
  }, [editMode, firstCall]);

  return {
    nameInput,
    setNameInput,
    switchEditMode,
    editMode,
    onInput,
    setEditMode,
    reference: inputElement,
  };
};

export function useIsMounted() {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return () => isMountedRef.current;
}
