import styled from "styled-components";
import { Radio } from "antd";
import { FONTS } from "../../../../assets";
import { COLORS } from "../../../../utils/colors";

export const RiskForm = styled.form<{ width?: string }>`
  ${({ width }) => `width: ${width === "auto" ? "auto" : "276px"}`};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

export const RadioGroup = styled(Radio.Group)`
  display: flex;
  justify-content: center;

  .ant-radio-button-wrapper-disabled {
    color: rgba(0, 0, 0, 0.25);
  }
`;

export const RadioButton = styled(Radio.Button)<{ width?: string }>`
  ${({ width }) => `width: ${width ? width : "92px"}`};
  font-family: ${FONTS.RUBIK};
  font-size: 14px;
  color: ${COLORS.SCORPION};

  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background-color: ${COLORS.WHITE} !important;
`;
