import React, { FC } from "react";
import { LoaderWrapper } from "./styles";
import { Rings } from "react-loader-spinner";

export const DefaultLoader: FC<any> = () => {
  return (
    <LoaderWrapper>
      <Rings color="#330E7A" />
    </LoaderWrapper>
  );
};
