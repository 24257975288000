import { FC, useMemo } from "react";
import { BlockContent, BlockTitle, FormFieldWrapper, InfoWarnIcon, MenuItemContainer, MenuItemContent, WarnAlert } from "styles";
import { ApplicationInfo, CloseLink, Line } from "../../styles";
import { Input, message } from "antd";
import Icon, { GlobalOutlined } from "@ant-design/icons";
import { ICONS } from "assets";
import { Billing, ButtonWithIcon, IPlanTypes } from "components";
import { useFormik } from "formik";
import { INewAuthApplicationForm, ServiceType, SwitchingLogic } from "utils/types";
import { generateRoute, Routes } from "utils/constants";
import { KeyriError } from "utils/keyri-error";
import { defaultErrorHandler, filterPlans } from "utils/helpers";
import { createAuthServiceFormValidator } from "utils/validators";
import { useStore } from "utils/hooks";
import { useNavigate } from "react-router-dom";
import { IAddAuthApplicationProps } from "./props";

export const AddAuthService: FC<IAddAuthApplicationProps> = ({ setIsFetch, closeLink, onBillingChange, cardData }) => {
  const {
    services: { createNewService },
    billing: { plans, addServiceCard, changeServicePlan },
  } = useStore();

  const navigate = useNavigate();

  const {
    values: { name, domainName, plan },
    handleChange,
    errors,
    handleSubmit,
    handleBlur,
    touched,
    isValid,
  } = useFormik<INewAuthApplicationForm>({
    onSubmit: async ({ plan }) => {
      try {
        /* Checking existing plan before continue create service */
        const selectedPlan = plans.find((filter) => filter.id === plan);
        if (!selectedPlan) return message.error("Plan not found");

        /* Firstly create service with free plan before check valid credit card from Stripe */
        const tempFreePlan = plans.find((plan) => plan.name === "Developer");
        if (!tempFreePlan) return message.error("Error through creating new service. Please try again later.");

        //Creating subDomain
        let subDomainName = domainName;
        if (subDomainName.indexOf("http://") === -1 && subDomainName.indexOf("https://") === -1) {
          subDomainName = `http://${subDomainName}`;
        }
        subDomainName = new URL(subDomainName).hostname.replace("www.", "").split(".")[0];
        subDomainName = `${subDomainName}.onekey.to`;

        const payload = {
          name,
          domainName,
          subDomainName: subDomainName.slice(0, -10),
          planId: tempFreePlan.id,
          type: ServiceType.Authentication,
        };
        const service = await createNewService(payload);

        /* Added billing info to new service */
        let card;
        if (selectedPlan.requiredCard) {
          const { cardNumber, cardExpiry, cardCVC, cardErrors, country, zip } = cardData;
          if (cardErrors.length || !country || !zip) return message.error("Credit card is invalid.");
          const [exp_month, exp_year] = cardExpiry.split(" / ").map((value) => +value);
          const cardRequest = {
            cardNumber: cardNumber.replaceAll(" ", ""),
            exp_month,
            exp_year,
            cvc: cardCVC,
            country: cardData.country,
            zip: cardData.zip,
          };
          setIsFetch(true);
          const attachedCard = await addServiceCard({
            ...cardRequest,
            serviceId: service.id,
          });
          card = attachedCard.id;
          setIsFetch(false);
        }

        /* attach new plan to service */
        if (tempFreePlan.id !== plan)
          await changeServicePlan(service.id, {
            planId: plan,
            cardId: card,
            switchLogic: SwitchingLogic.Immediately,
          });
        navigate(generateRoute(Routes.AuthenticationDashboard, { serviceId: service.id }));
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      }
    },
    initialValues: {
      name: "",
      domainName: "",
      plan: filterPlans(plans, IPlanTypes.AuthFraud).find((plan) => plan.name === "Developer").id,
    },
    validate: createAuthServiceFormValidator,
  });

  const submitButtonStatus: boolean = useMemo(() => {
    const { cardErrors, country, zip } = cardData;
    let selectedPlan = plans.find((planObject) => planObject.id === plan);

    const isSelectedPlanRequiredCard = !!selectedPlan?.requiredCard;
    const isCardDataEmpty = !!cardErrors.length || !country || !zip;
    const isFieldsTouched = !!Object.keys(touched).length;

    return (isCardDataEmpty && isSelectedPlanRequiredCard) || !isFieldsTouched || !isValid || !plan;
  }, [isValid, touched, plan, cardData, plans]);

  return (
    <MenuItemContainer maxWidth={1320}>
      <MenuItemContent>
        <BlockTitle>Add Your Application</BlockTitle>
        <BlockContent>
          <ApplicationInfo>
            <FormFieldWrapper>
              <Input
                name="name"
                type="text"
                value={name}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Application Name"
                prefix={<Icon component={ICONS.ADD_APPLICATION} />}
              />
              {touched.name && errors.name && errors.name && <WarnAlert message={errors.name} type="info" icon={<InfoWarnIcon />} showIcon maxWidth={380} />}
            </FormFieldWrapper>
            <FormFieldWrapper>
              <Input
                name="domainName"
                type="text"
                value={domainName}
                placeholder="Site Domain"
                onBlur={handleBlur}
                onChange={handleChange}
                prefix={<GlobalOutlined />}
              />
              {touched?.domainName && errors?.domainName && (
                <WarnAlert message={errors?.domainName} type="info" icon={<InfoWarnIcon />} showIcon maxWidth={380} />
              )}
            </FormFieldWrapper>
          </ApplicationInfo>
          <Billing defaultPlanTypes={IPlanTypes.AuthFraud} selectedPlan={plan} onChange={onBillingChange} handleChange={handleChange} handleBlur={handleBlur} />
          <Line />
          <ButtonWithIcon
            style={{ padding: "13px", height: "auto" }}
            onClick={handleSubmit}
            icon={<Icon component={ICONS.PLUS} />}
            text="Add Application"
            maxWidth={936}
            type={"submit"}
            centerButton
            disabled={submitButtonStatus}
          />
          <CloseLink to={closeLink}>Cancel</CloseLink>
        </BlockContent>
      </MenuItemContent>
    </MenuItemContainer>
  );
};
