import React, { FC, FormEvent, useCallback } from "react";
import Icon from "@ant-design/icons";
import { message } from "antd";
import { IRadioOptionsProps } from "./props";
import { defaultErrorHandler } from "utils/helpers";
import { successParamChangeMessage } from "../../index";
import { KeyriError } from "utils/keyri-error";
import { KeyriSwitch } from "components";
import { NoWrappedBlock } from "../../styles";
import { IconStyle, ParameterTitle, ParameterTitleContainer, ParameterValues, RiskParameter } from "styles";
import { RiskRadioButtons } from "../RiskRadioButtons";

export const RadioOptions: FC<IRadioOptionsProps> = ({ disabled = false, options, onRadioChange, handleEnableSwitch }) => {
  const handleRadioChange = useCallback((parameter: string) => {
    return async (event: FormEvent<HTMLFormElement>) => {
      try {
        await onRadioChange(parameter, (event.target as any).value);
        message.success(successParamChangeMessage);
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      }
    };
  }, []);

  return (
    <>
      {options.map((option) => (
        <RiskParameter key={option.parameter} data-name={option.parameter}>
          <ParameterTitleContainer minWidth={250} maxWidth={250}>
            <Icon component={option.icon} style={IconStyle} />
            <ParameterTitle>{option.title}</ParameterTitle>
          </ParameterTitleContainer>

          <NoWrappedBlock>
            <ParameterValues>
              <RiskRadioButtons
                buttonsList={option.buttonsList}
                handler={handleRadioChange(option.parameter)}
                disabled={disabled || !option.enabled}
                defaultValue={option.enabled ? option.defaultValue : ""}
              />
            </ParameterValues>
            <KeyriSwitch
              disabled={disabled || !handleEnableSwitch}
              checked={option.enabled}
              onChange={handleEnableSwitch ? handleEnableSwitch(option.parameter) : () => {}}
            />
          </NoWrappedBlock>
        </RiskParameter>
      ))}
    </>
  );
};
