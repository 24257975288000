import { MeterAggregations, MeterApiNames, TimeIntervals } from "./constants";

interface AmberflowRequestParams {
  meterApiType: MeterApiNames;
  periodQuery: { from: number; to?: number };
  groupBy?: string;
  timeGroupingInterval?: TimeIntervals;
}

export const amberfloRequests = {
  getGroupingDataRequest: ({ meterApiType, periodQuery, groupBy, timeGroupingInterval = TimeIntervals.Day }: AmberflowRequestParams) => ({
    aggregation: MeterAggregations.Sum,
    meterApiName: meterApiType,
    timeGroupingInterval: timeGroupingInterval,
    groupBy,
    timeRange: {
      startTimeInSeconds: periodQuery.from,
      endTimeInSeconds: periodQuery.to,
    },
  }),
};
