import styled from "styled-components";
import { COLORS } from "../../../../utils/colors";

export const DarkTableText = styled.span`
  color: ${COLORS.GRAY};
`;

export const LightTableText = styled.span`
  color: ${COLORS.SILVER};
`;
