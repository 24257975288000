import React, { FC, FormEvent, useCallback } from "react";
import Icon from "@ant-design/icons";
import { message } from "antd";

import { ICheckboxGroupProps } from "./props";
import { defaultErrorHandler } from "utils/helpers";
import { AllowedParametersValues } from "utils/types";
import { successParamChangeMessage } from "../../index";
import { KeyriError } from "utils/keyri-error";
import { KeyriSwitch } from "components";
import { NoWrappedBlock } from "../../styles";
import { CheckboxLabel, HiddenInput, IconStyle, ParameterTitle, ParameterTitleContainer, ParameterValues, RiskForm, RiskParameter } from "styles";

export const CheckboxGroup: FC<ICheckboxGroupProps> = ({ options, onCheckboxChange, disabled, handleEnableSwitch }) => {
  const handleCheckboxChange = useCallback((parameter: string) => {
    return async (event: FormEvent<HTMLFormElement>) => {
      try {
        const value = (event.target as any).checked ? AllowedParametersValues.Allow : AllowedParametersValues.Deny;
        await onCheckboxChange(parameter, value);
        message.success(successParamChangeMessage);
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      }
    };
  }, []);

  return (
    <>
      {options.map((option) => (
        <RiskParameter key={option.parameter} data-name={option.parameter}>
          <ParameterTitleContainer minWidth={250} maxWidth={250}>
            <Icon component={option.icon} style={IconStyle} />
            <ParameterTitle>{option.title}</ParameterTitle>
          </ParameterTitleContainer>
          <NoWrappedBlock>
            <ParameterValues>
              <RiskForm onChange={handleCheckboxChange(option.parameter)}>
                <HiddenInput disabled={disabled} checked={option.defaultValue === AllowedParametersValues.Allow} id={option.parameter} type="checkbox" />
                <CheckboxLabel data-status={!disabled} htmlFor={option.parameter}>
                  {option.defaultValue === AllowedParametersValues.Allow ? "Allow" : "Deny"}
                </CheckboxLabel>
              </RiskForm>
            </ParameterValues>
            <KeyriSwitch disabled={disabled} checked={option.enabled} onChange={handleEnableSwitch(option.parameter)} />
          </NoWrappedBlock>
        </RiskParameter>
      ))}
    </>
  );
};
