import { FC, useCallback, useEffect } from "react";
import { Modal } from "antd";
import { IMAGES } from "../../assets";
import { Container, CrossButton, Headline, HeadlineImage, HeadlineInfo, ModalContent, QrCode, QrContainer, StepListTitle } from "./styles";
import { COLORS } from "../../utils/colors";
import { QRCode } from "../QRCode";
import { DIMENSIONS, env, LocalStorage, WIDGET_ATTACH_ACCOUNT } from "../../utils/constants";
import { refreshTokens, userAPI } from "../../utils/api";
import { IAddPasslessLogin } from "./props";
import { Index, StepExplanation, StepList, StepText } from "../../styles";
import { useStore } from "../../utils/hooks";
import { defaultErrorHandler, generateQueryString } from "../../utils/helpers";

export interface IAttachAccountV3Payload {
  payload?: string;
  deviceId?: string;
  publicKey?: string;
}

export const AddPasslessLogin: FC<IAddPasslessLogin> = ({ handleModal, isModalVisible }) => {
  const {
    user: { email },
  } = useStore();
  const params: string = generateQueryString(
    env === "dev"
      ? {
          Origin: "dev-app.keyri.com",
          Environment: "dev",
          Custom: JSON.stringify({ browserType: "browser", email, type: "register" }),
          qsd: "true",
        }
      : {
          Custom: JSON.stringify({ browserType: "browser", email, type: "register" }),
          qsd: "true",
        }
  );

  const attachLink = `${WIDGET_ATTACH_ACCOUNT}?${params}`;

  const attachHandler = useCallback(
    async (data: IAttachAccountV3Payload, src: string) => {
      try {
        if (src !== attachLink) return;
        if (!data) return;
        if (data.publicKey) {
          await userAPI.attachKeyriAccount({ payload: JSON.stringify(data) });
        } else {
          await userAPI.attachKeyriAccount(data);
        }
        handleModal();
      } catch (err) {
        defaultErrorHandler(err);
      }
    },
    [handleModal, attachLink]
  );

  useEffect(() => {
    if (isModalVisible) {
      refreshTokens(`${localStorage.getItem(LocalStorage.RefreshToken)}`);
    }
  }, [isModalVisible]);

  return (
    <Modal style={{ minWidth: "400px" }} data-item={"passless-login"} visible={isModalVisible} closable={false} footer={null} width={986}>
      <Headline>
        <HeadlineInfo>
          <HeadlineImage src={IMAGES.MODAL_PHONE} />
          Connect Your Account To The Keyri App
        </HeadlineInfo>
        <CrossButton onClick={handleModal} />
      </Headline>

      <Container>
        <ModalContent>
          <StepListTitle>This will allow you to log into Keyri without a password by using just a QR code</StepListTitle>
          <StepList>
            <StepExplanation>
              <Index color={COLORS.MEDIUM_PURPLE}>1</Index>
              <StepText>Scan this QR code to Download the Keyri app 👉</StepText>
            </StepExplanation>
            <StepExplanation>
              <Index color={COLORS.ROBINS_EGG_BLUE}>2</Index>
              <StepText>Re-scan the QR code using the Keyri app to link this account to it 🤝</StepText>
            </StepExplanation>
            <StepExplanation>
              <Index color={COLORS.CERULEAN}>3</Index>
              <StepText>Now you can log into Keyri by scanning the QR code on the Login Page 🙌</StepText>
            </StepExplanation>
          </StepList>
        </ModalContent>
        <QrContainer>
          <QrCode dimension={DIMENSIONS.QR_CODE_DIMENSION}>
            <QRCode dimension={DIMENSIONS.QR_CODE_DIMENSION} action={attachHandler} src={attachLink} />
          </QrCode>
        </QrContainer>
      </Container>
    </Modal>
  );
};
