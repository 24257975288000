import { FC, useCallback } from "react";
import { IMAGES } from "../../assets";
import { Action, ActionImage, Item, MainWrapper } from "./styles";
import { Routes } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { IWelcomeScreenProps } from "./props";
import { useTabTitle } from "../../utils/hooks";
import { ServiceType } from "../../utils/types";

export const WelcomeScreen: FC<IWelcomeScreenProps> = () => {
  useTabTitle("Welcome");
  const navigate = useNavigate();

  const handleClick = useCallback(
    (serviceType: ServiceType) => navigate({ pathname: Routes.AddApplication, search: `?serviceType=${serviceType}` }),
    [navigate]
  );

  return (
    <MainWrapper>
      <Item onClick={() => handleClick(ServiceType.Authentication)}>
        <Action>Add Your Authentication or Fraud Prevention Application</Action>
        <ActionImage src={IMAGES.WELCOME_PLUS} alt="Add Your Authentication or Fraud Prevention Application" />
      </Item>

      <Item onClick={() => handleClick(ServiceType.EchoGuard)}>
        <Action>Add Your Keyri EchoGuard / Reverse SMS Application</Action>
        <ActionImage src={IMAGES.WELCOME_PLUS} alt="Add Your Keyri EchoGuard / Reverse SMS Application" />
      </Item>
    </MainWrapper>
  );
};
