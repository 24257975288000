import React, { ErrorInfo } from "react";
import { Alert, message } from "antd";
import { Routes } from "../../utils/constants";
import { matchRoutes } from "react-router-dom";
import { clearAxiosConfig } from "../../utils/api";

class ErrorBoundary extends React.Component<any> {
  state: { hasError: boolean; showErrorMessage: boolean };
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, showErrorMessage: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const routes = Object.keys(Routes).map((routeName) => ({
      name: routeName,
      path: (Routes as any)[routeName],
    }));
    const matchedRoutes = matchRoutes(routes, window.location.pathname);
    const result = Array.isArray(matchedRoutes) && matchedRoutes.length > 0 ? matchedRoutes[0].route : null;

    if (!result || ["AuthenticationDashboard", "EchoDashboard"].includes((result as any).name)) {
      clearAxiosConfig();
    }
    if (["Login", "Home"].includes((result as any).name)) return this.setState({ showErrorMessage: true });
    window.location.href = "/";
    message.error(error.message);
  }

  render() {
    if (this.state.showErrorMessage) {
      return <Alert message="Error" description="Something went wrong." type="error" showIcon />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
