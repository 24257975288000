import React, { FC, MouseEventHandler, useCallback } from "react";
import Icon from "@ant-design/icons";
import { message } from "antd";
import { useFormik } from "formik";

import { IWarnSettingProps } from "./props";
import { ICONS } from "assets";
import { Input, KeyriSwitch } from "components";
import { FingerprintRulesList, InputStylePreset, IWarnSettingForm } from "utils/types";
import { defaultErrorHandler } from "utils/helpers";
import { warnValidator } from "utils/validators";
import { successParamChangeMessage } from "../../index";
import { KeyriError } from "utils/keyri-error";
import { CancelPackageButton, IconStyle, ParameterTitle, ParameterTitleContainer, RiskParameter, SavePackageButton } from "styles";

export const WarnSetting: FC<IWarnSettingProps> = ({
  disabled = false,
  initialValues: { eventsCount, interval },
  ruleStatus,
  handleEnableSwitch,
  onSubmit,
}) => {
  const {
    values: { events, seconds },
    handleChange,
    errors,
    handleSubmit,
    handleBlur,
    touched,
    resetForm,
  } = useFormik<IWarnSettingForm>({
    onSubmit: async () => {
      try {
        // something is being ...
        await onSubmit(events, seconds);
        message.success(successParamChangeMessage);
      } catch (err: unknown | KeyriError) {
        resetForm();
        defaultErrorHandler(err);
      }
    },
    initialValues: {
      events: eventsCount,
      seconds: interval,
    },
    validate: warnValidator,
  });

  const cancelDistanceChanges = useCallback(() => {
    resetForm({
      values: {
        events: eventsCount,
        seconds: interval,
      },
    });
  }, [resetForm]);

  return (
    <RiskParameter data-name="warn-settings">
      <ParameterTitleContainer wrap={"wrap"}>
        <Icon component={ICONS.WARN} style={IconStyle} />
        <ParameterTitle>Warn after</ParameterTitle>
        <Input
          disabled={disabled || !ruleStatus}
          onBlur={handleBlur}
          name="events"
          placeholder="3"
          type="number"
          value={events.toString()}
          onChange={handleChange}
          error={touched.events && errors.events ? errors.events : ""}
          preset={InputStylePreset.InlineInput}
          maxWidth={50}
          min={0}
          max={1000}
        />
        <ParameterTitle>event attempts within</ParameterTitle>
        <Input
          disabled={disabled || !ruleStatus}
          onBlur={handleBlur}
          name="seconds"
          placeholder="3"
          type="number"
          value={seconds.toString()}
          onChange={handleChange}
          error={touched.seconds && errors.seconds ? errors.seconds : ""}
          preset={InputStylePreset.InlineInput}
          maxWidth={50}
          min={0}
          max={1000}
        />
        <ParameterTitle>seconds</ParameterTitle>

        {(events !== eventsCount || seconds !== interval) && (
          <>
            <SavePackageButton
              data-status={!Object.keys(errors).length ? "active" : "disabled"}
              disabled={!!errors?.events || !!errors?.seconds}
              data-name={"warn-after-events"}
              onClick={handleSubmit as any as MouseEventHandler<HTMLSpanElement>}
            />
            <CancelPackageButton data-name={"reset-warn-after-events"} onClick={cancelDistanceChanges} />
          </>
        )}
      </ParameterTitleContainer>
      <KeyriSwitch disabled={disabled} checked={ruleStatus} onChange={handleEnableSwitch(FingerprintRulesList.SignalAfterAttempts)} />
    </RiskParameter>
  );
};
