import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { DIMENSIONS } from "../../utils/constants";

export const IconStyle = {
  fontSize: "13px",
  lineHeight: "16px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  padding: "8px",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: `${COLORS.ROBINS_EGG_BLUE}`,
  borderRadius: "6px",
};

export const KeyList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
`;

const keyTitleWidth = 180;
const keyItemGap = 24;

export const KeyItem = styled.li`
  display: flex;
  gap: ${keyItemGap}px;
  max-width: 100%;

  @media (max-width: ${DIMENSIONS.MAX_FLEX_ROW_WIDTH}px) {
    flex-wrap: wrap;
  }
  @media (min-width: ${DIMENSIONS.MAX_FLEX_ROW_WIDTH}px) {
    & > button {
      max-width: calc(100% - ${keyTitleWidth}px);
    }
  }
`;

export const KeyTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  font-size: 13px;
  color: ${COLORS.TURQUOISE};
  min-width: calc(${keyTitleWidth}px - ${keyItemGap}px);
`;

export const KeyName = styled.div`
  font-size: 14px;
  color: ${COLORS.SCORPION};
  white-space: nowrap;
`;
