import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { FONTS } from "../../assets";
import { InputStylePreset } from "../../utils/types";

interface IFormInputProps {
  fieldError?: string;
  preset?: InputStylePreset;
  maxWidth?: number;
  type?: string;
  bgImage?: string;
  minWidth?: number;
  placeholderColor?: string;
}

const Base = `
  width: 100%;
  border-radius: 25px;
  margin-bottom: 20px;
  padding: 14px 25px;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  color: ${COLORS.DARK_GRAY} !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  &::placeholder {
    color: #999999;
    font-size: 17px;
  }
`;

export const FormInput = styled.input.attrs(({ disabled }) => ({ disabled }))<IFormInputProps>`
  ${Base}
  ${({ width }) => (width ? `width: ${width}px;` : "")}
  ${({ preset = InputStylePreset.BaseInput, fieldError, bgImage, maxWidth, placeholderColor }) => {
    switch (preset) {
      case InputStylePreset.BaseInput:
        return `
          background-color: ${COLORS.WHITE};
          border: ${fieldError ? `1px solid ${COLORS.RADICAL_RED}` : `1px solid ${COLORS.BLACK}`};
        `;
      case InputStylePreset.DangerInput:
        return `
          padding: 16px 20px 16px 46px;
          margin: 0;
          
          font-size: 16px;
          line-height: 19px;
          
          background-size: 15px 15px;
          border-radius: 8px;
          border: 1px solid ${COLORS.CARNATION};
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);  
          
          &::placeholder {
            color: ${placeholderColor ? placeholderColor : COLORS.GRAY};
          }
          
          &:focus {
            border: 1px solid ${COLORS.CARNATION};
            box-shadow: 0px 0px 6px rgba(140, 91, 220, 0.4);
            border-radius: 8px;
          }
        `;
      case InputStylePreset.NarrowPaddedForPlaceholder:
        return `
          padding: ${maxWidth && maxWidth < 55 ? "5px" : "5px 34px 5px 12px"};
          margin: 0;
          
          min-width: 80px;
          font-size: 14px;
          line-height: 22px;
          color: ${COLORS.SCORPION};
          
          border: ${fieldError ? `1px solid ${COLORS.RADICAL_RED}` : `1px solid ${COLORS.ALTO}`};
          border-radius: 4px;
          
          &::placeholder {
            color: ${placeholderColor ? placeholderColor : COLORS.SILVER};
            text-align: center;
            font-size: 14px;
          }
          
          &:focus {
            border: ${fieldError ? `1px solid ${COLORS.RADICAL_RED}` : `1px solid ${COLORS.MEDIUM_PURPLE}`};
            border-radius: 4px;
          }
        `;

      case InputStylePreset.InlineInput:
        return `
        padding: 5px 3px;
        margin: 0;
        
        min-width: 40px;
        font-size: 14px;
        text-align: center;
        line-height: 22px;
        color: ${COLORS.SCORPION};
        
        border: ${fieldError ? `1px solid ${COLORS.RADICAL_RED}` : `1px solid ${COLORS.ALTO}`};
        border-radius: 4px;
        
        &::placeholder {
          color: ${placeholderColor ? placeholderColor : COLORS.SCORPION};
          font-size: 14px;
        }
        
        &:focus {
          border: ${fieldError ? `1px solid ${COLORS.RADICAL_RED}` : `1px solid ${COLORS.MEDIUM_PURPLE}`};
          border-radius: 4px;
        }
        `;

      default:
        return ``;
    }
  }}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : "")}
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}px;` : "")}
  font-family: ${FONTS.RUBIK};
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`;
