// @ts-ignore
import { Input, message } from "antd";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStore, useTabTitle } from "../../utils/hooks";
import { Routes } from "../../utils/constants";
import { IAddApplicationProps } from "./props";
import { parseQuery } from "../../utils/helpers";
import { AddAuthService, AddEchoService } from "./screenComponents";
import { ICardData, ServiceType } from "../../utils/types";

export const AddApplicationScreen: FC<IAddApplicationProps> = ({ setIsFetch }) => {
  useTabTitle("Add Your Application");
  const {
    services: { servicesList },
  } = useStore();
  const location = useLocation();
  const [cardData, setCardData] = useState<ICardData>({
    cardNumber: "",
    cardExpiry: "",
    cardCVC: "",
    cardErrors: ["cardNumber"],
    country: "",
    zip: "",
  });
  const serviceType = useMemo<ServiceType>(() => {
    const { serviceType } = parseQuery(location.search);
    return serviceType ? (serviceType as ServiceType) : ServiceType.Authentication;
  }, [location.search]);

  const closeLink = useMemo<Routes>(() => (servicesList.length ? Routes.AuthenticationDashboard : Routes.Welcome), [servicesList]);

  const onBillingChange = useCallback((data: ICardData) => {
    setCardData(data);
  }, []);

  return serviceType === "auth" ? (
    <AddAuthService cardData={cardData} onBillingChange={onBillingChange} closeLink={closeLink} setIsFetch={setIsFetch} />
  ) : (
    <AddEchoService cardData={cardData} onBillingChange={onBillingChange} closeLink={closeLink} setIsFetch={setIsFetch} />
  );
};
