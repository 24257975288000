import styled from 'styled-components';
import { FONTS } from '../../assets';
import { COLORS } from '../../utils/colors';

export const StyledRadioButton = styled.div`
  width: 120px;
  background-color: rgba(119, 126, 176, 0.2);
  font-size: 16px;
  font-weight: 500;
  font-family: ${FONTS.RUBIK};
  color: ${COLORS.PERSIAN_INDIGO};
  text-align: center;
  padding: 0 20px;
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid rgba(119, 126, 176, 0.2);
  margin-right: 20px;
  cursor: pointer;
  user-select: none;
`;

export const HiddenInput = styled.input`
  display: none;
  
  &:checked+${StyledRadioButton} {
    background-color: ${COLORS.BLACK_GRAY};
    color: ${COLORS.WHITE};
  }
`;
