import styled, { keyframes } from "styled-components";
import { COLORS } from '../../utils/colors';
import { FONTS } from '../../assets';

interface QRCodeWrapperProps {
  show?: boolean;
  dimension: number;
}

const smooth = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const QRCodeWrapper = styled.div<QRCodeWrapperProps>`
  animation: ${smooth} ${({ show = true }) => (show ? 2 : 0)}s ease-in forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({dimension}) => !!dimension ? dimension : 194}px;
  height: ${({dimension}) => !!dimension ? dimension : 194}px;
`;

export const TipText = styled.div`
  color: ${COLORS.PERSIAN_INDIGO};
  font-family: ${FONTS.FUTURA_MEDIUM_C};
  font-size: 1rem;
  width: 160px;
  text-align: center;
`;

export const Iframe = styled.iframe<QRCodeWrapperProps>`
  border: none;
  width: ${({dimension}) => !!dimension ? dimension : 194}px;
  height: ${({dimension}) => !!dimension ? dimension : 194}px;
`;
