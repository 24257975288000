import React, { FC, useCallback, useMemo } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { message, Table, Tooltip } from "antd";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useParams } from "react-router";
import { Button, ButtonWithIcon, DefaultLoader, Input, KeyriSelect } from "components";
import { LoaderBackground } from "components/DefaultLoader/styles";
import { SelectSizesEnum } from "components/Select/styles";
import { ControlBlock, CustomTables } from "./styles";
import { ICustomRuleConditionsTableProps, ICustomRuleMainParamsTable, ICustomRuleNoCodeProps, ICustomRuleSummaryProps } from "./props";
import { KeyriError } from "utils/keyri-error";
import { useStore } from "utils/hooks";
import { outcomeValues } from "utils/tables-settings";
import { ButtonStylePreset, ButtonWithIconStylePreset, IChangeFingerprintRuleValue, INewCustomRuleForm, InputStylePreset, OutcomeValues } from "utils/types";
import { buildNoCodeRuleCondition, convertRuleConditionToFormFields, defaultErrorHandler, initConditionsData, initConditionValue } from "utils/helpers";
import { createCustomRuleFormValidator } from "utils/validators";
import { generateRoute, Routes } from "utils/constants";
import { COLORS } from "utils/colors";
import { FieldWrapper } from "styles";
import { getNewRuleColumns } from "screens/FingerprintListsScreen/tables";

export const successParamChangeMessage = "Service rule was added. Please allow up to 30 minutes for the system to update.";

export const CustomRuleNoCode: FC<ICustomRuleNoCodeProps> = ({ canEdit }) => {
  const {
    services: { changeFingerprintParameters, currentService },
  } = useStore();

  const { ruleId } = useParams();
  const navigate = useNavigate();
  const rule = useMemo(() => {
    if (!ruleId) return;
    if (!currentService) return;
    return currentService.fingerprintRules?.rules?.find((rule) => rule.rule === ruleId);
  }, [currentService, ruleId]);

  const formik = useFormik<INewCustomRuleForm>({
    onSubmit: async ({ ruleName, outcome, notes, conditions, operator }) => {
      try {
        const newRule: IChangeFingerprintRuleValue = {
          rule: ruleName,
          signal: ruleName,
          outcome,
          notes,
          conditions: {
            [`${operator ?? "$and"}`]: conditions.map(buildNoCodeRuleCondition),
          },
        };
        if (rule) newRule.oldRule = rule.rule;
        await changeFingerprintParameters([newRule]);
        message.success(successParamChangeMessage);
        navigate(generateRoute(Routes.FingerprintRiskManagement, { serviceId: currentService?.id ?? "" }));
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      }
    },
    initialValues: {
      ruleName: rule?.rule ?? "",
      outcome: rule?.outcome ?? OutcomeValues.Allow,
      notes: rule?.notes ?? "",
      conditions: rule?.conditions?.$and?.map(convertRuleConditionToFormFields) ?? initConditionsData,
      operator: null,
    },
    validate: createCustomRuleFormValidator,
  });

  const handleSelectChange = useCallback(
    (field: string) => {
      return (value: string) => formik.setFieldValue(field, value, true);
    },
    [formik.setFieldValue]
  );
  return (
    <>
      <FormikProvider value={formik}>
        <CustomTables>
          <CustomRuleMainParamsTable
            canEdit={canEdit}
            values={formik.values}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            errors={formik.errors}
            touched={formik.touched}
            handleSelectChange={handleSelectChange}
          />
          <CustomRuleConditionsTable canEdit={canEdit} handleSelectChange={handleSelectChange} operator={formik.values.operator} />
        </CustomTables>
      </FormikProvider>
    </>
  );
};

const CustomRuleMainParamsTable: FC<ICustomRuleMainParamsTable> = ({ canEdit, values, handleChange, handleBlur, errors, touched, handleSelectChange }) => {
  return (
    <Table
      className={"custom-rule-upper-table"}
      loading={{
        spinning: false,
        indicator: (
          <LoaderBackground>
            <DefaultLoader />
          </LoaderBackground>
        ),
        wrapperClassName: "tableSpinner",
      }}
      scroll={{
        x: "max-content",
      }}
      pagination={false}
      columns={[
        {
          title: "Add New Custom Rule",
          dataIndex: "paramName",
          key: "paramName",
          width: 180,
          render: (value: string) => <>{value}</>,
        },
        {
          title: "",
          dataIndex: "input",
          key: "input",
          render: (value: string) => <>{value}</>,
        },
      ]}
      dataSource={[
        {
          paramName: "Rule Name",
          input: (
            <FieldWrapper>
              <Tooltip color={COLORS.CARNATION} title={touched.ruleName && errors.ruleName} key={`error.ruleName`}>
                <Input
                  disabled={!canEdit}
                  onBlur={handleBlur}
                  name="ruleName"
                  value={values.ruleName}
                  width={333}
                  placeholder={"NAME"}
                  onChange={handleChange}
                  error={touched.ruleName && errors.ruleName ? (errors.ruleName as string) : ""}
                  preset={InputStylePreset.NarrowPaddedForPlaceholder}
                  maxWidth={333}
                />
              </Tooltip>
            </FieldWrapper>
          ),
        },
        {
          paramName: "Outcome",
          input: (
            <KeyriSelect
              maxWidth={148}
              size={SelectSizesEnum.Small}
              onBlur={handleBlur}
              onChange={handleSelectChange("outcome")}
              name={`outcome`}
              value={values.outcome}
              options={outcomeValues}
              disabled={!canEdit}
            />
          ),
        },
        {
          paramName: "Notes",
          input: (
            <FieldWrapper>
              <Input
                disabled={!canEdit}
                onBlur={handleBlur}
                name="notes"
                value={values.notes}
                width={333}
                placeholder={"NOTES"}
                onChange={handleChange}
                error={touched.notes && errors.notes ? (errors.notes as string) : ""}
                preset={InputStylePreset.NarrowPaddedForPlaceholder}
                maxWidth={333}
              />
            </FieldWrapper>
          ),
        },
      ]}
      sticky={false}
    />
  );
};

const CustomRuleConditionsTable: FC<ICustomRuleConditionsTableProps> = ({ canEdit, handleSelectChange, operator }) => (
  <FieldArray
    name={"conditions"}
    render={({
      form: {
        setFieldValue,
        errors,
        touched,
        values: { conditions },
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldTouched,
        ...formHelpers
      },
      pop,
      push,
      remove,
      ...arrayHelpers
    }) => (
      <Table
        className={"custom-rule-lower-table"}
        loading={{
          spinning: false,
          indicator: (
            <LoaderBackground>
              <DefaultLoader />
            </LoaderBackground>
          ),
          wrapperClassName: "tableSpinner",
        }}
        scroll={{
          x: "max-content",
        }}
        pagination={false}
        columns={getNewRuleColumns({ canEdit, remove, handleChange, handleBlur, errors, touched, handleSelectChange, setFieldTouched, conditions })}
        dataSource={conditions.map((condition: any) => ({ ...condition, operator }))}
        sticky={false}
        summary={() => <CustomRuleSummary canEdit={canEdit} errors={errors} addCondition={push} onSubmit={handleSubmit} />}
      />
    )}
  />
);

const CustomRuleSummary: FC<ICustomRuleSummaryProps> = ({ canEdit, onSubmit, addCondition, errors }) => {
  const {
    services: { currentService },
  } = useStore();
  const handleNewCondition = useCallback(() => {
    addCondition(initConditionValue);
  }, [addCondition]);
  const navigate = useNavigate();

  const handleCancelClick = useCallback(() => {
    navigate(generateRoute(Routes.FingerprintRiskManagement, { serviceId: currentService?.id ? currentService.id : "" }));
  }, [navigate]);

  return (
    <>
      <ControlBlock height={48}>
        <ButtonWithIcon
          disabled={!canEdit}
          style={{ padding: "0px 8px" }}
          text="Add Another"
          icon={<PlusOutlined />}
          maxWidth={130}
          height={26}
          preset={ButtonWithIconStylePreset.Small}
          onClick={handleNewCondition}
        />
      </ControlBlock>
      <ControlBlock>
        <Button
          width={66}
          onClick={onSubmit}
          preset={ButtonStylePreset.BaseButtonWithBorder}
          title="Save"
          disabled={!!Object.keys(errors).length || !canEdit}
        />
        <Button width={78} onClick={handleCancelClick} preset={ButtonStylePreset.BaseButtonWithBorder} title="Cancel" />
      </ControlBlock>
    </>
  );
};
