import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FingerprintModes } from "../../types";
import { DeviceInfo } from "../DeviceInfo";
import {
  DetailItem,
  DetailsList,
  DetailTitle,
  DetailValue,
  DetailValuesList,
  ErrorMsg,
  EventDetailsBlock,
  EventDetailsWrapper,
  EventDetailTitle,
  EventInfoBlock,
  ShortValue,
} from "./styles";
import { useParams } from "react-router";
import { IUserData } from "../../props";
import { getBrowserByUserAgent, getOSbyUserAgent, getOSWithVersionByUserAgent } from "utils/device";
import { useIsMounted, useStore, useTabTitle } from "utils/hooks";
import { fingerprintAPI } from "utils/api";
import { KeyriError } from "utils/keyri-error";
import { IEventDetailsResponse, IGetFingerprintDataRequest } from "utils/types";
import { defaultErrorHandler, formatLocation, getLocationString, parseQuery, toTitleCase } from "utils/helpers";
import { FingerprintSignalsTitles } from "utils/constants";
import { format } from "date-fns";

export const EventDetails: FC<any> = () => {
  useTabTitle("Fingerprinting Event");
  const {
    services: { currentService },
  } = useStore();
  const isMounted = useIsMounted();

  // Get all initializing params from query string or set default
  const location = useLocation();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const currentQueryParams = useMemo<{ mode: FingerprintModes }>(() => {
    const params = parseQuery(location.search);
    return {
      mode: params.mode ? (params.mode as FingerprintModes) : FingerprintModes.Events,
    };
  }, [location.search]);

  const [localSearchInputField, setSearchInputField] = useState<string>("");
  const [mode, setMode] = useState<FingerprintModes>(currentQueryParams.mode);
  const [eventInfo, setEventInfo] = useState<IEventDetailsResponse | null>(null);
  const [userData, setUserData] = useState<IUserData | null>(null);
  const [isFetch, setIsFetch] = useState<boolean>(false);
  const searchPlaceholder = useMemo(() => {
    switch (mode) {
      case FingerprintModes.Events:
        return "Search by Event ID";
      case FingerprintModes.Users:
        return "Search by User ID";
      case FingerprintModes.Devices:
        return "Search by Device ID";
    }
  }, [mode]);
  const renderData = useMemo(() => {
    if (!eventInfo) return null;
    const deviceParams = typeof eventInfo.device.deviceParams === "string" ? JSON.parse(eventInfo.device.deviceParams) : eventInfo.device.deviceParams;
    return {
      eventDetails: [
        { title: "Event Type", value: toTitleCase(eventInfo.event.event) },
        { title: "Timestamp", value: format(new Date(eventInfo.event.createdAt), "yyyy-MM-dd HH:mm:ss") },
        { title: "Device ID", value: eventInfo.device.fingerprint },
        { title: "User ID", value: eventInfo.user.userId },
        {
          title: "Risk Signals",
          value: eventInfo.event.signals.map((signal) => (FingerprintSignalsTitles[signal] ? FingerprintSignalsTitles[signal] : signal)),
        },
        {
          title: "Risk Determination",
          value: toTitleCase(eventInfo.event.riskStatus),
        },
      ],
      deviceId: [
        { title: "Associated User IDs", value: eventInfo.device.fingerprintUsers, short: true },
        { title: "Last Activity", value: new Date(eventInfo.device.lastEvent.createdAt).toISOString().split("T")?.[0] },
        { title: "First Activity", value: new Date(eventInfo.device.firstEvent.createdAt).toISOString().split("T")?.[0] },
        { title: "Locations", value: formatLocation(eventInfo.device.fingerprintLocations) },
        {
          title: "Cumulative Risk Signals",
          value: eventInfo.device.fingerprintSignals.map((signal) => (FingerprintSignalsTitles[signal] ? FingerprintSignalsTitles[signal] : signal)),
        },
      ],
      userId: [
        { title: "Associated Device IDs", value: eventInfo.user.userDevices, short: true },
        { title: "Last Activity", value: new Date(eventInfo.user.lastEvent.createdAt).toISOString().split("T")?.[0] },
        { title: "First Activity", value: new Date(eventInfo.user.firstEvent.createdAt).toISOString().split("T")?.[0] },
        { title: "Locations", value: formatLocation(eventInfo.user.userLocations) },
        {
          title: "Cumulative Risk Signals",
          value: eventInfo.user.userSignals.map((signal) => (FingerprintSignalsTitles[signal] ? FingerprintSignalsTitles[signal] : signal)),
        },
      ],
      systemDetails: [
        // { title: "Device ID", value: eventInfo.device.fingerprint },
        { title: "Device Type", value: getOSbyUserAgent(deviceParams.userAgent ? deviceParams.userAgent : "Unknown").platform },
        { title: "Operating System", value: getOSWithVersionByUserAgent(deviceParams.userAgent ? deviceParams.userAgent : "Unknown").name },
        { title: "Browser", value: getBrowserByUserAgent(deviceParams.userAgent ? deviceParams.userAgent : "Unknown browser") },
        { title: "User Agent", value: deviceParams.userAgent ? deviceParams.userAgent : "Not Available" },
      ],
      ipDetails: [
        { title: "Geolocation", value: "Not Available" },
        { title: "IP", value: eventInfo.ip.value },
        {
          title: "IP Location",
          value: getLocationString(eventInfo.ip.location),
        },
        { title: "ISP", value: eventInfo.ip.isp_data?.asn_name ? eventInfo.ip.isp_data?.asn_name : "Not Available" },
        {
          title: "Cumulative Risk Signals",
          value: eventInfo.ip.ipSignals.filter((signal) => !!FingerprintSignalsTitles[signal]).map((signal) => FingerprintSignalsTitles[signal]),
        },
      ],
    };
  }, [eventInfo]);

  const loadEventDetails = useCallback(async () => {
    try {
      setIsFetch(true);
      if (!currentService) return;
      const {
        data: { data },
      } = await fingerprintAPI.getEventDetails({ serviceId: currentService.id, eventId: `${eventId}` });
      setEventInfo(data);
      setIsFetch(false);
      return data;
    } catch (err: unknown | KeyriError) {
      setIsFetch(false);
      defaultErrorHandler(err);
    }
  }, [currentService, eventId]);

  const loadUserData = useCallback(
    async (params: Omit<IGetFingerprintDataRequest, "skip" | "periodQuery" | "eventTypes" | "riskFilters" | "signalFilters" | "limit">) => {
      try {
        if (!isMounted) return;
        setIsFetch(true);
        if (!params.userId) throw new KeyriError(997);
        const {
          data: { result, data, error },
        } = await fingerprintAPI.getUserData(params);
        if (!result && error) throw new KeyriError(error);
        if (!isMounted) return;
        setUserData({
          userId: data.userId,
          devicesCount: data.devicesCount,
          firstLogDate: data.firstLog?.updatedAt ? data.firstLog.updatedAt : undefined,
          lastLogDate: data.lastLog?.updatedAt ? data.lastLog.updatedAt : undefined,
        });
        setIsFetch(false);
        return true;
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
        if (!isMounted) return;
        setUserData({
          userId: params.userId as string,
          devicesCount: 0,
          firstLogDate: undefined,
          lastLogDate: undefined,
        });
        setIsFetch(false);
      }
    },
    [setUserData]
  );

  const renderEventDetails = useCallback((params: Array<{ title: string; value: Array<string> | string; short?: boolean }>, key?: string) => {
    return params.map(({ title, value, short }, index) => (
      <DetailItem key={`${key}-${title}`}>
        {/*<Icon component={index ? ICONS.AVATAR : ICONS.USER_CARD} style={IconStyle} />*/}
        <DetailTitle>{title}</DetailTitle>
        <DetailValuesList>
          {Array.isArray(value) ? (
            value.length ? (
              value.map((elem) => (short ? <ShortValue>{elem}</ShortValue> : <DetailValue>{elem}</DetailValue>))
            ) : (
              <DetailValue>No data</DetailValue>
            )
          ) : short ? (
            <ShortValue>{value}</ShortValue>
          ) : (
            <DetailValue>{value}</DetailValue>
          )}
        </DetailValuesList>
      </DetailItem>
    ));
  }, []);

  useEffect(() => {
    if (eventId === ":eventId") return;
    loadEventDetails().then((data) => {
      if (!currentService || !data) return;
      const params: Omit<IGetFingerprintDataRequest, "skip" | "periodQuery" | "eventTypes" | "riskFilters" | "signalFilters" | "limit"> = {
        serviceId: currentService.id,
        userId: data.user.userId,
      };
      if (!isMounted) return;
      loadUserData(params);
    });
  }, [currentService, loadEventDetails, eventId]);

  return (
    <>
      <DeviceInfo userData={userData} />
      <EventDetailsWrapper>
        {!isFetch && !renderData ? (
          <ErrorMsg>Event wasn't found</ErrorMsg>
        ) : (
          <EventDetailsBlock>
            <EventInfoBlock>
              <EventDetailTitle>Event Details</EventDetailTitle>
              <DetailsList>{renderData && renderEventDetails(renderData.eventDetails, "eventDetails")}</DetailsList>
            </EventInfoBlock>
            <EventInfoBlock>
              <EventDetailTitle>Device Details</EventDetailTitle>
              <DetailsList>{renderData && renderEventDetails(renderData.deviceId, "deviceId")}</DetailsList>
            </EventInfoBlock>
            <EventInfoBlock>
              <EventDetailTitle>User Details</EventDetailTitle>
              <DetailsList>{renderData && renderEventDetails(renderData.userId, "userId")}</DetailsList>
            </EventInfoBlock>
            <EventInfoBlock>
              <EventDetailTitle>System & Browser Details</EventDetailTitle>
              <DetailsList>{renderData && renderEventDetails(renderData.systemDetails, "systemDetails")}</DetailsList>
            </EventInfoBlock>
            <EventInfoBlock>
              <EventDetailTitle>Location & IP Details</EventDetailTitle>
              <DetailsList>{renderData && renderEventDetails(renderData.ipDetails, "ipDetails")}</DetailsList>
            </EventInfoBlock>
          </EventDetailsBlock>
        )}
      </EventDetailsWrapper>
    </>
  );
};
