import { types, flow, SnapshotOut } from "mobx-state-tree";
import { subscriptionAPI } from "../utils/api";
import { ICardInitializingData, IChangeServicePlanData } from "../utils/types";
import { defaultErrorHandler } from "../utils/helpers";
import { KeyriError } from "../utils/keyri-error";

export const defaultPlans = [
  "Fraud Prevention - Developer",
  "Fraud Prevention - Scaling",
  "Fraud Prevention - Enterprise",
  "Developer",
  "Scaling",
  "Enterprise",
];

const Plan = types.model({
  isDefault: types.boolean,
  interval: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  amberfloDescription: types.maybeNull(types.string),
  name: types.string,
  order: types.number,
  planId: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  productId: types.maybeNull(types.string),
  id: types.string,
  requiredCard: types.boolean,
  permissions: types.array(types.string),
  customPermissions: types.array(types.string),
});

const Subscription = types.model({
  customerId: types.maybe(types.string),
  endTimeInSeconds: types.maybe(types.maybeNull(types.number)),
  startTimeInSeconds: types.maybe(types.maybeNull(types.number)),
  productId: types.maybe(types.string),
  productPlanId: types.maybe(types.string),
  serviceId: types.string,
  userId: types.string,
});

const LatestInvoice = types.model({
  invoiceUri: types.string,
  invoiceKey: types.model({
    accountId: types.string,
    customerId: types.string,
    productId: types.string,
    productPlanId: types.string,
    year: types.number,
    month: types.number,
    day: types.number,
  }),
  planName: types.string,
  invoiceStartTimeInSeconds: types.number,
  invoiceEndTimeInSeconds: types.number,
  gracePeriodInHours: types.number,
  totalBill: types.model({
    itemPrice: types.number,
    fixPrice: types.number,
    prepaid: types.number,
    totalDiscount: types.number,
    totalPriceBeforeDiscount: types.number,
    totalPriceBeforePrepaid: types.number,
    discountableFixPrice: types.number,
    discountableBasePrice: types.number,
    prepaidableFixPrice: types.number,
    prepayableNotDiscountableFees: types.number,
    totalPrepayablePrice: types.number,
    startTimeInSeconds: types.number,
    endTimeInSeconds: types.number,
    totalPrice: types.number,
  }),
  invoicePriceStatus: types.string,
  paymentStatus: types.string,
  paymentCurrencyInfo: types.model({
    basePlanCurrency: types.string,
    paymentCurrency: types.string,
    exchangeRate: types.number,
  }),
});

const PlansArray = types.array(Plan);

const CreditCard = types.model({
  isActive: types.boolean,
  userId: types.string,
  serviceId: types.string,
  cardBrand: types.string,
  cardLast4: types.string,
  cardExpire: types.string,
  country: types.string,
  zip: types.string,
  id: types.string,
});

export interface IPlanMX extends SnapshotOut<typeof Plan> {}

const BillingStore = types
  .model("BillingStore", {
    plans: PlansArray,
    currentServicePlan: types.maybe(Plan),
    latestInvoice: types.maybe(LatestInvoice),
    currentServiceSubscription: types.maybe(Subscription),
    currentServiceCard: types.maybe(CreditCard),
    isSubscriptionFetch: types.boolean,
    isPlansFetch: types.boolean,
    isCardFetch: types.boolean,
  })
  .actions((self) => ({
    getCurrentServicePlan: flow(function* (serviceId: string) {
      try {
        self.isSubscriptionFetch = true;
        const {
          data: {
            data: { activePlan, activeSubscription, latestInvoice },
          },
        } = yield subscriptionAPI.getServiceSubscription(serviceId);
        self.latestInvoice = latestInvoice && latestInvoice.invoiceUri && LatestInvoice.create(latestInvoice as any);
        self.currentServicePlan = activePlan && activePlan.id ? Plan.create(activePlan) : Plan.create(undefined);
        self.currentServiceSubscription = activeSubscription ? Subscription.create(activeSubscription as any) : Subscription.create(undefined);
      } catch (err: unknown | KeyriError) {
        self.latestInvoice = LatestInvoice.create(undefined);
        self.currentServicePlan = Plan.create(undefined);
        self.currentServiceSubscription = Subscription.create(undefined);
        defaultErrorHandler(err);
      } finally {
        self.isSubscriptionFetch = false;
      }
    }),
    getPlans: flow(function* () {
      try {
        self.isPlansFetch = true;
        const {
          data: {
            data: { plans },
          },
        } = yield subscriptionAPI.getPlans();
        self.plans = PlansArray.create(plans);
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      } finally {
        self.isPlansFetch = false;
      }
    }),
    clearCurrentServicePlan: () => {
      self.currentServicePlan = undefined;
      self.currentServicePlan = undefined;
      self.currentServiceCard = undefined;
      self.currentServiceSubscription = undefined;
    },
    changeServicePlan: flow(function* (serviceId: string, servicePlanData: IChangeServicePlanData) {
      try {
        self.isSubscriptionFetch = true;
        const {
          data: {
            data: { activeSubscription, plan },
          },
        } = yield subscriptionAPI.changeServicePlan(serviceId, servicePlanData);

        self.currentServicePlan = plan && plan.id ? Plan.create(plan) : Plan.create(undefined);
        self.currentServiceSubscription = activeSubscription ? Subscription.create(activeSubscription) : Subscription.create(undefined);
        return activeSubscription;
      } catch (err: unknown | KeyriError) {
        throw err;
      } finally {
        self.isSubscriptionFetch = false;
      }
    }),
    loadServiceCard: flow(function* (serviceId: string) {
      try {
        self.isCardFetch = true;
        const {
          data: {
            data: { card },
          },
        } = yield subscriptionAPI.getServiceCreditCard(serviceId);

        if (!card) {
          self.currentServiceCard = undefined;
        } else {
          self.currentServiceCard = CreditCard.create(card);
        }
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      } finally {
        self.isCardFetch = false;
      }
    }),
    addServiceCard: flow(function* (cardData: ICardInitializingData) {
      try {
        self.isCardFetch = true;
        const {
          data: {
            data: { card },
          },
        } = yield subscriptionAPI.addServiceCard(cardData);

        self.currentServiceCard = CreditCard.create(card);
        self.isCardFetch = false;
        return card;
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      } finally {
        self.isCardFetch = false;
      }
    }),
  }));

export default BillingStore;
