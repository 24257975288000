import { types } from "mobx-state-tree";
import UserStore from "./UserStore";
import ServicesStore, { ServicesStatusesEnum } from "./ServicesStore";
import BillingStore from "./BillingStore";
import FingerprintListStore from "./FingerprintListStore";
import { ListType } from "../screens/FingerprintListsScreen/types";

export const RootStore = types
  .model("Root", {
    user: types.optional(UserStore, { isFetch: false }),
    services: types.optional(ServicesStore, { servicesStatus: ServicesStatusesEnum.NotLoaded, settingsChanged: false }),
    billing: types.optional(BillingStore, { isSubscriptionFetch: false, isCardFetch: false, isPlansFetch: false }),
    fingerprintList: types.optional(FingerprintListStore, {
      analyticsListsData: { country: undefined, user_id: undefined, ip: undefined, imei: undefined, device_id: undefined },
      listType: ListType.Main,
      searchField: "",
      outcome: undefined,
      page: 1,
      recordsCount: 0,
      records: [],
      isRecordsLoading: false,
    }),
  })
  .actions((self) => ({
    clearStore: function () {
      self.user = UserStore.create({ isLoggedIn: false, isSuperAdmin: false, isFetch: false });
      self.services = ServicesStore.create({ servicesList: [], servicesStatus: ServicesStatusesEnum.NotLoaded, settingsChanged: false });
      self.billing = BillingStore.create({ isSubscriptionFetch: false, isCardFetch: false, isPlansFetch: false });
      self.fingerprintList = FingerprintListStore.create({
        analyticsListsData: {},
        listType: ListType.Main,
        searchField: "",
        outcome: [],
        page: 1,
        recordsCount: 0,
        records: [],
        isRecordsLoading: false,
      });
    },
  }));
