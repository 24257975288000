import React, { FC } from "react";
import { ICheckboxProps } from "./props";
import { KeyriCheckbox } from "./styles";
import { COLORS } from "../../utils/colors";

export const Checkbox: FC<ICheckboxProps> = ({ defaultChecked, disabled = false, onChange, color = COLORS.MINE_SHAFT, children }) => {
  return (
    <KeyriCheckbox color={color} defaultChecked={defaultChecked} disabled={disabled} onChange={onChange}>
      {children}
    </KeyriCheckbox>
  );
};
