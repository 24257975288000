import styled from "styled-components";
import { FONTS } from "../../assets";
import { COLORS } from "../../utils/colors";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 81px;
  flex-grow: 1;

  width: 100%;
`;

export const Item = styled.div`
  width: 420px;
  height: 292px;
  padding: 60px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.ALTO};
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  cursor: pointer;
`;

export const Action = styled.div`
  font-family: ${FONTS.RUBIK};
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  color: ${COLORS.SCORPION};
`;

export const ActionImage = styled.img`
  height: 84px;
  max-width: 63px;
`;
