import React, { FC } from "react";
import { Multiselect } from "components";
import { IOutcomeFilterProps } from "./props";
import { outcomeValues } from "utils/tables-settings";
import { OutcomeValues } from "utils/types";
import { MultiselectModes } from "components/Multiselect/props";

export const OutcomeFilter: FC<IOutcomeFilterProps> = ({ outcome, setOutcome }) => {
  return (
    <Multiselect<OutcomeValues>
      rightPadding={90}
      mode={MultiselectModes.Multiple}
      key={"Outcome"}
      defaultValues={outcome}
      style={{ width: "30%", minWidth: "330px" }}
      onChange={(values: Array<OutcomeValues> | string | undefined) => {
        setOutcome(typeof values === "object" ? values : []);
      }}
      options={outcomeValues}
      filterPlaceholder={"Outcome"}
    />
  );
};
