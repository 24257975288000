import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { BlockTitle, MenuItemContainer, MenuItemContent, SearchBlock } from "../../styles";
import { useStore, useTabTitle } from "../../utils/hooks";
import { SearchResultsCount } from "./styles";
import { periodToNumberQuery, TimeQueryFormat } from "../../utils/time-periods";
import { UploadOutlined } from "@ant-design/icons/lib";
import { DatePicker, Table } from "antd";
import { API_URL, DIMENSIONS, generateRoute, LogTypes, Routes, TimePeriodsList } from "../../utils/constants";
import { ButtonWithIconStylePreset, ILogsQueryParams, IPeriod, ISessionRecord } from "../../utils/types";
import { useLocation, useNavigate } from "react-router-dom";
import { analyticsAPI } from "../../utils/api";
import { FlexGroup, RadioButton, RadioGroup, SelectDeviceContainer, SubFlexGroup } from "../ActivityReportScreen/styles";
import moment from "moment";
import { ButtonWithIcon, DefaultLoader, LinkWithIcon, RadioButtons } from "../../components";
import { ArrowRightOutlined, UnlockOutlined } from "@ant-design/icons";
import { defaultErrorHandler, generateQueryString, parseQuery } from "../../utils/helpers";
import { ILogsScreen } from "./props";
import { observer } from "mobx-react-lite";
import { COLORS } from "../../utils/colors";
import { LoaderBackground } from "../../components/DefaultLoader/styles";
import { logsEventsColumns } from "../../utils/tables-settings";
import { ServicesStatusesEnum } from "../../stores/ServicesStore";

const { RangePicker } = DatePicker;

interface ICurrentQueryParams {
  selectedPeriod: TimePeriodsList;
  periodQuery: { from: number; to?: number };
  page: number;
  type: LogTypes;
  searchQuery: undefined | string | string[];
}

export const LogsScreenComponent: FC<ILogsScreen> = () => {
  useTabTitle("Logs");
  const {
    services: { currentService, servicesStatus },
    billing: { currentServicePlan },
  } = useStore();

  const navigate = useNavigate();

  // Get all initializing params from query string or set default
  const location = useLocation();
  const currentQueryParams = useMemo<ICurrentQueryParams>(() => {
    const params = parseQuery(location.search);
    return {
      selectedPeriod: params.selectedPeriod ? (params.selectedPeriod as TimePeriodsList) : TimePeriodsList.Last7Days,
      periodQuery:
        params.selectedPeriod === TimePeriodsList.Custom
          ? { from: +params.from, to: +params.to }
          : periodToNumberQuery(params.selectedPeriod ? params.selectedPeriod as string : TimePeriodsList.Last7Days, TimeQueryFormat.Milliseconds),
      page: params.page ? +params.page : 1,
      type: params.type ? (params.type as LogTypes) : LogTypes.All,
      searchQuery: params.searchQuery ? params.searchQuery : undefined,
    };
  }, [location.search]);

  // Set all state variables
  const [isSessionsLoading, setSessionsLoading] = useState<boolean>(false);
  const [sessions, setSessions] = useState<any[]>([]);
  const [sessionsCount, setSessionsCount] = useState<number>(0);
  const [page, setPage] = useState<number>(currentQueryParams.page);
  const [selectedPeriod, setSelectedPeriod] = useState<TimePeriodsList>(currentQueryParams.selectedPeriod);
  const [periodQuery, setPeriodQuery] = useState<IPeriod>(currentQueryParams.periodQuery);
  const [logsType, setLogsType] = useState<LogTypes>(currentQueryParams.type);
  const [searchQuery, setSearchQuery] = useState(currentQueryParams.searchQuery);
  const [permissions, setPermissions] = useState<Array<string>>([...(currentServicePlan?.permissions ?? []), ...(currentServicePlan?.customPermissions ?? [])]);

  // Handlers for all events
  const handlePeriod = useCallback((field: TimePeriodsList) => {
    return (value: any) => {
      setPage(1);
      setSelectedPeriod(field);
      switch (field) {
        case TimePeriodsList.Custom: {
          setPeriodQuery({
            from: value[0].valueOf(),
            to: value[1].valueOf(),
          });
          break;
        }
        default:
          setPeriodQuery(periodToNumberQuery(field, TimeQueryFormat.Milliseconds));
      }
    };
  }, []);
  const handleLogs = useCallback((event) => {
    const type: LogTypes = event.target.value;
    setLogsType(type);
  }, []);
  const onPageChange = useCallback((page: number) => {
    setPage(page);
  }, []);

  // Memoized Data, which used in JSX
  const exportCSVLink = useMemo<string>(() => {
    const token = localStorage.getItem("token");
    if (!currentService || !token) return "";

    const params: Record<string, string> = {
      logsType,
      token,
      from: periodQuery.from?.toString(),
    };
    if (periodQuery.to) params.to = periodQuery.to?.toString();
    const queryParams = generateQueryString(params);
    return `${API_URL}/analytics/${currentService?.id}/export?${queryParams}`;
  }, [currentService, logsType, periodQuery]);

  const navigateQuery = useCallback(
    (queryParams) => {
      const searchParams = generateQueryString(queryParams);
      navigate({ search: searchParams });
    },
    [navigate]
  );

  const loadFilteredLogsList = useCallback(() => {
    if (!currentService) return;
    const params: ILogsQueryParams = {
      page,
      type: logsType,
      selectedPeriod,
    };
    if (searchQuery) params.searchQuery = searchQuery.toString();
    if (selectedPeriod === TimePeriodsList.Custom) params.from = +periodQuery.from * 1000;
    if (selectedPeriod === TimePeriodsList.Custom && periodQuery.to) params.to = +periodQuery.to * 1000;
    navigateQuery(params);

    setSessionsLoading(true);
    analyticsAPI
      .getServiceSessions({
        serviceId: currentService.id,
        skip: (page - 1) * DIMENSIONS.DEFAULT_SESSIONS_PER_PAGE,
        period: periodQuery,
        logsType,
        searchQuery: !!searchQuery ? searchQuery.toString() : undefined,
      })
      .then(
        ({
          data: {
            data: { analyticsRecords, analyticsCount },
          },
        }) => {
          setSessionsCount(analyticsCount || 0);
          setSessions(
            analyticsRecords.map((session: ISessionRecord) => ({
              key: session.id,
              time: session.createdAt,
              userId: session.userId,
              distance: session.riskStraightLineDistance,
              device: session.device,
              riskBrowserAnonymousProxy: session.riskBrowserAnonymousProxy,
              riskBrowserKnownAttackerIP: session.riskBrowserKnownAttackerIP,
              riskBrowserTorIP: session.riskBrowserTorIP,
              riskCountryDifferential: session.riskCountryDifferential,
              type: session.type,
              accepted: session.accepted,
            }))
          );
        }
      )
      .catch((err) => defaultErrorHandler(err))
      .finally(() => setSessionsLoading(false));
  }, [currentService, selectedPeriod, periodQuery, page, logsType, searchQuery, navigateQuery]);

  useEffect(() => {
    loadFilteredLogsList();
  }, [currentService, selectedPeriod, periodQuery, page, logsType, loadFilteredLogsList]);

  useEffect(() => {
    setPage(1);
    handlePeriod(TimePeriodsList.Last7Days);
    setLogsType(LogTypes.All);
    const permissions = currentServicePlan?.permissions ? currentServicePlan.permissions : [];
    const customPermissions = currentServicePlan?.customPermissions ? currentServicePlan.customPermissions : [];
    setPermissions([...permissions, ...customPermissions]);
  }, [currentService, currentServicePlan, handlePeriod]);

  return (
    <MenuItemContainer>
      <MenuItemContent>
        <BlockTitle>Logs</BlockTitle>
        <FlexGroup>
          <SubFlexGroup>
            <RadioGroup value={selectedPeriod}>
              <RadioButton value={TimePeriodsList.Last7Days} onClick={handlePeriod(TimePeriodsList.Last7Days)}>
                1W
              </RadioButton>
              <RadioButton value={TimePeriodsList.Last30Days} onClick={handlePeriod(TimePeriodsList.Last30Days)}>
                1M
              </RadioButton>
              <RadioButton value={TimePeriodsList.Last365Days} onClick={handlePeriod(TimePeriodsList.Last365Days)}>
                1Y
              </RadioButton>
              <RadioButton value={TimePeriodsList.Custom}>Custom</RadioButton>
            </RadioGroup>
            <RangePicker
              style={{ maxWidth: "260px" }}
              picker="date"
              format="MMM D, YYYY"
              value={[moment(periodQuery.from), moment(periodQuery?.to ? periodQuery.to : Date.now())]}
              separator={<ArrowRightOutlined style={{ color: COLORS.SILVER }} />}
              disabledDate={(currentData) => currentData.isAfter(moment(Date.now()))}
              onChange={handlePeriod(TimePeriodsList.Custom)}
            />
          </SubFlexGroup>
          <SelectDeviceContainer>
            <RadioButtons
              handler={handleLogs}
              defaultValue={logsType}
              list={[
                { value: LogTypes.All, title: "All" },
                { value: LogTypes.Success, title: "Success" },
                { value: LogTypes.Fail, title: "Fail" },
              ]}
            />
          </SelectDeviceContainer>
        </FlexGroup>

        <FlexGroup>
          <SearchBlock
            placeholder="Search by User ID"
            style={{ maxWidth: 505 }}
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            onSearch={loadFilteredLogsList}
          />
          <SubFlexGroup>
            <SearchResultsCount>
              {sessionsCount.toLocaleString()}
              {sessionsCount === 1 ? " Result" : " Results"}
            </SearchResultsCount>
            <LinkWithIcon text="Export" icon={<UploadOutlined />} maxWidth={100} preset={ButtonWithIconStylePreset.Small} href={exportCSVLink} />

            {!permissions.includes("Risk Analytics") && servicesStatus === ServicesStatusesEnum.Fetched && !!sessionsCount && (
              <ButtonWithIcon
                text="Unlock Risk Data"
                icon={<UnlockOutlined />}
                preset={ButtonWithIconStylePreset.GreenSmall}
                to={`${generateRoute(Routes.KeysAndCredentials, { serviceId: currentService?.id || "" })}#billing`}
              />
            )}
          </SubFlexGroup>
        </FlexGroup>

        <Table
          loading={{
            spinning: isSessionsLoading,
            indicator: (
              <LoaderBackground>
                <DefaultLoader />
              </LoaderBackground>
            ),
            wrapperClassName: "tableSpinner",
          }}
          scroll={{
            x: "max-content",
          }}
          pagination={{
            current: page,
            onChange: onPageChange,
            position: ["bottomCenter"],
            pageSize: DIMENSIONS.DEFAULT_SESSIONS_PER_PAGE,
            total: sessionsCount,
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
          columns={logsEventsColumns(permissions.includes("Risk Analytics"))}
          dataSource={sessions}
        />
      </MenuItemContent>
    </MenuItemContainer>
  );
};

export const LogsScreen = observer(LogsScreenComponent);
