import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FlexGroup, SubFlexGroup } from "screens/ActivityReportScreen/styles";
import { LinkWithIcon, Multiselect, RadioButtons } from "components";
import { API_URL, DIMENSIONS, FingerprintEventsTitles, FingerprintRiskTitles, FingerprintSignalsTitles, TimePeriodsList } from "utils/constants";
import moment from "moment/moment";
import { ArrowRightOutlined, DownloadOutlined } from "@ant-design/icons";
import { COLORS } from "utils/colors";
import { MultiselectWrapper, SearchResultsCount } from "../../styles";
import {
  ButtonWithIconStylePreset,
  FingerprintLogEvents,
  FingerprintRiskParams,
  FingerprintSignals,
  ICurrentQueryParams,
  IFingerprintDevice,
  IFingerprintEvent,
  IFingerprintQueryParams,
  IGetFingerprintDataRequest,
  IPeriod,
} from "utils/types";
import { MultiselectModes } from "components/Multiselect/props";
import { EventsTable } from "../EventsTable";
import { UsersTable } from "../UsersTable";
import { DevicesTable } from "../DevicesTable";
import { useIsMounted, useStore, useTabTitle } from "utils/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { defaultErrorHandler, generateQueryString, getLocationString, parseQuery, parseQueryArray } from "utils/helpers";
import { periodToNumberQuery, TimeQueryFormat } from "utils/time-periods";
import { IUserData } from "../../props";
import { fingerprintAPI } from "utils/api";
import { getDeviceFromUserAgent } from "utils/device";
import { getSignalColor } from "utils/fingerprint-helper";
import { DatePicker } from "antd";
import { KeyriError } from "utils/keyri-error";
import { format } from "date-fns";
import { FingerprintModes, ITableDevice, ITableEvent } from "../../types";

const { RangePicker } = DatePicker;

export const MainLogsScreen: FC<any> = () => {
  useTabTitle("Fingerprinting Logs");
  const {
    services: { currentService },
    billing: { currentServicePlan },
    user: { email },
  } = useStore();

  const isMounted = useIsMounted();
  const navigate = useNavigate();

  // Get all initializing params from query string or set default
  const location = useLocation();
  const currentQueryParams = useMemo<ICurrentQueryParams>(() => {
    const params = parseQuery(location.search);
    return {
      selectedPeriod: params.selectedPeriod ? (params.selectedPeriod as TimePeriodsList) : TimePeriodsList.Last7Days,
      periodQuery:
        params.selectedPeriod === TimePeriodsList.Custom
          ? { from: +params.from, to: +params.to }
          : periodToNumberQuery(params.selectedPeriod ? params.selectedPeriod.toString() : TimePeriodsList.Last7Days, TimeQueryFormat.Milliseconds),
      page: params.page ? +params.page : 1,
      eventTypes: Object.values(FingerprintLogEvents).filter((value) => parseQueryArray(params.eventTypes?.toString()).includes(value)),
      riskFilters: Object.values(FingerprintRiskParams).filter((value) => parseQueryArray(params.riskFilters?.toString()).includes(value)),
      signalFilters: Object.values(FingerprintSignals).filter((value) => parseQueryArray(params.signalFilters?.toString()).includes(value)),
      mode: params.mode ? (params.mode as FingerprintModes) : FingerprintModes.Events,
      searchUserField: params.searchUserField ? params.searchUserField : "",
    };
  }, [location.search]);

  // Set all state variables
  const [isDataLoading, setDataLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<FingerprintModes>(currentQueryParams.mode);
  const [searchUserField, setSearchUserField] = useState<string>(currentQueryParams.searchUserField?.toString());
  const [tableData, setTableData] = useState<Array<ITableEvent | ITableDevice>>([]);
  const [elementsCount, setElementsCount] = useState<number>(0);
  const [page, setPage] = useState<number>(currentQueryParams.page);
  const [selectedPeriod, setSelectedPeriod] = useState<TimePeriodsList>(currentQueryParams.selectedPeriod);
  const [periodQuery, setPeriodQuery] = useState<IPeriod>(currentQueryParams.periodQuery);
  const [eventTypes, setEventTypes] = useState<Array<FingerprintLogEvents>>(currentQueryParams.eventTypes);
  const [riskFilters, setRiskFilters] = useState<Array<FingerprintRiskParams>>(currentQueryParams.riskFilters);
  const [signalFilters, setSignalFilters] = useState<Array<FingerprintSignals>>(currentQueryParams.signalFilters);
  const [userData, setUserData] = useState<IUserData | null>(null);

  // Memoized Data, which used in JSX
  const exportCSVLink = useMemo<string>(() => {
    const token = localStorage.getItem("token");
    if (!currentService || !token) return "";

    const params: Record<string, string> = {
      token,
      periodQuery: JSON.stringify(periodQuery),
      limit: DIMENSIONS.DEFAULT_EVENTS_PER_PAGE.toString(),
      skip: ((page - 1) * DIMENSIONS.DEFAULT_EVENTS_PER_PAGE).toString(),
    };
    if (eventTypes.length) params.eventTypes = JSON.stringify(eventTypes);
    if (riskFilters.length) params.resultFilters = JSON.stringify(riskFilters);
    if (signalFilters.length) params.signalFilters = JSON.stringify(signalFilters);
    const queryParams = generateQueryString(params);
    switch (mode) {
      case FingerprintModes.Events:
        return `${API_URL}/fingerprint/${currentService?.id}/export/events?${queryParams}`;
      case FingerprintModes.Users:
        return `${API_URL}/fingerprint/${currentService?.id}/export/${searchUserField ? searchUserField : email}/events?${queryParams}`;
      case FingerprintModes.Devices:
        return `${API_URL}/fingerprint/${currentService?.id}/export/${searchUserField ? searchUserField : email}/devices?${queryParams}`;
      default:
        return "";
    }
    return `${API_URL}/fingerprint/${currentService?.id}/export/${mode.toLowerCase()}?${queryParams}`;
  }, [mode, currentService, eventTypes, periodQuery, riskFilters, signalFilters]);

  const onPageChange = useCallback((page: number) => {
    setPage(page);
    window.scrollTo(0, 0);
  }, []);

  const setTable = useCallback(
    (mode: FingerprintModes) => {
      switch (mode) {
        case FingerprintModes.Events:
          return (
            <EventsTable
              events={tableData as Array<ITableEvent>}
              eventsCount={elementsCount}
              isEventsLoading={isDataLoading}
              page={page}
              onPageChange={onPageChange}
            />
          );
        case FingerprintModes.Users:
          return (
            <UsersTable
              searchQueryValue={searchUserField}
              onSearch={setSearchUserField}
              events={tableData as Array<ITableEvent>}
              userData={userData}
              eventsCount={elementsCount}
              isEventsLoading={isDataLoading}
              page={page}
              onPageChange={onPageChange}
            />
          );
        case FingerprintModes.Devices:
          return (
            <DevicesTable
              searchQueryValue={searchUserField}
              onSearch={setSearchUserField}
              devices={tableData as Array<ITableDevice>}
              eventsCount={elementsCount}
              userData={userData}
              isEventsLoading={isDataLoading}
              page={page}
              onPageChange={onPageChange}
            />
          );
        default:
          return <></>;
      }
    },
    [tableData, elementsCount, isDataLoading, page, onPageChange, mode]
  );

  // Handlers for all events
  const handlePeriod = useCallback((field: TimePeriodsList) => {
    return (value: any) => {
      setPage(1);
      setSelectedPeriod(field);
      switch (field) {
        case TimePeriodsList.Custom: {
          setPeriodQuery({
            from: value[0].valueOf(),
            to: value[1].valueOf(),
          });
          break;
        }
        default:
          setPeriodQuery(periodToNumberQuery(field, TimeQueryFormat.Milliseconds));
      }
    };
  }, []);

  const navigateQuery = useCallback(
    (queryParams) => {
      const searchParams = generateQueryString(queryParams);
      navigate({ search: searchParams });
    },
    [navigate]
  );

  const loadFilteredEventList = useCallback(
    async (params: IGetFingerprintDataRequest) => {
      const {
        data: {
          data: { count, events },
        },
      } = await fingerprintAPI.getServiceEvents(params);
      if (!isMounted) return;
      setElementsCount(count);
      const mappedEvents = events.map((event: IFingerprintEvent) => {
        const deviceParams =
          typeof event.fingerprint?.deviceParams === "string" ? JSON.parse(event.fingerprint?.deviceParams) : event.fingerprint?.deviceParams;
        const deviceType = ["iOS", "Android"].includes(deviceParams?.platform) ? "Mobile" : "Web";
        const client =
          deviceType === "Mobile"
            ? deviceParams?.platform
            : getDeviceFromUserAgent(deviceParams?.userAgent ? deviceParams?.userAgent : "", { platform: false });

        return {
          time: event.createdAt,
          userId: event.userId,
          deviceId: event.fingerprint.fingerprint,
          event: FingerprintEventsTitles[event.event],
          result: event.result,
          location: [`Near ${getLocationString(event.location)}`, `IP: ${event.ip}`],
          deviceType,
          client,
          risk: event.riskStatus,
          signals: event.signals.map((signal) => ({
            title: FingerprintSignalsTitles[signal] ? FingerprintSignalsTitles[signal] : signal,
            name: signal,
            color: getSignalColor(signal, currentService),
          })),
          key: event.id,
          id: event.id,
        };
      });

      setTableData(mappedEvents);
    },
    [currentService, selectedPeriod, periodQuery, page, eventTypes, riskFilters, signalFilters, navigateQuery, mode]
  );

  const loadUserData = useCallback(
    async (params: IGetFingerprintDataRequest) => {
      try {
        const {
          data: { result, data, error },
        } = await fingerprintAPI.getUserData(params);
        if (!isMounted) return;
        if (!result && error) throw new KeyriError(error);
        setUserData({
          userId: data.userId,
          devicesCount: data.devicesCount,
          firstLogDate: data.firstLog?.updatedAt ? data.firstLog.updatedAt : undefined,
          lastLogDate: data.lastLog?.updatedAt ? data.lastLog.updatedAt : undefined,
        });
        return true;
      } catch (err) {
        if (!isMounted) return;
        setUserData({
          userId: params.userId as string,
          devicesCount: 0,
          firstLogDate: undefined,
          lastLogDate: undefined,
        });
      }
    },
    [setUserData]
  );

  const loadUserEventList = useCallback(
    async (params: IGetFingerprintDataRequest) => {
      const loadUserEvents = async () => {
        try {
          const {
            data: {
              data: { count, events },
            },
          } = await fingerprintAPI.getUserEvents(params);
          if (!isMounted) return;
          setElementsCount(count);
          const mappedEvents = events.map((event: IFingerprintEvent) => {
            const deviceParams = JSON.parse(event.fingerprint?.deviceParams);
            const deviceType = ["iOS", "Android"].includes(deviceParams?.platform) ? "Mobile" : "Web";
            const client =
              deviceType === "Mobile"
                ? deviceParams?.platform
                : getDeviceFromUserAgent(deviceParams?.userAgent ? deviceParams?.userAgent : "", { platform: false });
            return {
              time: event.createdAt,
              deviceId: event.fingerprint.fingerprint,
              event: FingerprintEventsTitles[event.event],
              result: event.result,
              location: [
                `Near ${event.location.city}, ${event.location.countryCode !== "US" ? event.location.countryCode : event.location.regionCode}`,
                `IP: ${event.ip}`,
              ],
              deviceType,
              client,
              risk: event.riskStatus,
              signals: event.signals.map((signal) => ({
                title: FingerprintSignalsTitles[signal] ? FingerprintSignalsTitles[signal] : signal,
                name: signal,
                color: getSignalColor(signal, currentService),
              })),
              key: event.id,
              id: event.id,
              userId: event.userId,
            };
          });
          setTableData(mappedEvents);
        } catch (err) {
          throw err;
        }
      };
      return Promise.all([loadUserEvents(), loadUserData(params)]);
    },
    [currentService, selectedPeriod, periodQuery, page, eventTypes, riskFilters, signalFilters, navigateQuery, mode]
  );

  const loadDevicesList = useCallback(
    async (params: IGetFingerprintDataRequest) => {
      const loadUserDevices = async () => {
        try {
          const {
            data: { data },
          } = await fingerprintAPI.getUserDevices(params);
          if (!isMounted) return;
          const result = data.devices
            .map((device: IFingerprintDevice & { firstLog: IFingerprintEvent }) => {
              const deviceParams = (typeof device.deviceParams === "string" ? JSON.parse(device.deviceParams) : device.deviceParams) ?? {};
              const deviceType = ["iOS", "Android"].includes(deviceParams?.platform) ? "Mobile" : "Web";
              const client =
                deviceType === "Mobile"
                  ? deviceParams?.platform
                  : getDeviceFromUserAgent(deviceParams?.userAgent ? deviceParams?.userAgent : "", { platform: false });
              const lastEvent = device.events[device.events.length - 1];
              const firstEvent = device.firstLog;
              const activity = [
                `Last: ${format(new Date(lastEvent.updatedAt), "uuuu-MM-dd HH:mm:ss")}`,
                `First: ${format(new Date(firstEvent.updatedAt), "uuuu-MM-dd HH:mm:ss")}`,
              ];
              return {
                deviceId: device.fingerprint,
                lastLocation: [
                  `Near ${lastEvent.location.city}, ${
                    lastEvent.location.countryCode !== "US" ? lastEvent.location.countryCode : lastEvent.location.regionCode
                  }`,
                  `IP: ${lastEvent.ip}`,
                ],
                deviceType,
                client,
                activity,
                lastActivity: lastEvent.updatedAt,
                signals: lastEvent?.signals
                  ? lastEvent.signals.map((signal) => ({
                      title: FingerprintSignalsTitles[signal] ? FingerprintSignalsTitles[signal] : signal,
                      name: signal,
                      color: getSignalColor(signal, currentService),
                    }))
                  : [],
              };
            })
            .sort((first, second) => new Date(second.lastActivity).getTime() - new Date(first.lastActivity).getTime());
          setTableData(result);
          setElementsCount(data.devicesCount);

          return true;
        } catch (err) {
          throw err;
        }
      };
      return Promise.all([loadUserDevices(), loadUserData(params)]);
    },
    [currentService, selectedPeriod, periodQuery, page, eventTypes, riskFilters, signalFilters, navigateQuery, mode]
  );

  const loadFilteredDataList = useCallback(async () => {
    try {
      if (!currentService) return;
      const params: IFingerprintQueryParams = {
        page,
        eventTypes,
        riskFilters,
        signalFilters,
        selectedPeriod,
        mode,
        searchUserField,
      };
      if (selectedPeriod === TimePeriodsList.Custom) params.from = +periodQuery.from * 1000;
      if (selectedPeriod === TimePeriodsList.Custom && periodQuery.to) params.to = +periodQuery.to * 1000;
      navigateQuery(params);

      setDataLoading(true);

      const requestParams: IGetFingerprintDataRequest = {
        serviceId: currentService.id,
        limit: DIMENSIONS.DEFAULT_EVENTS_PER_PAGE,
        skip: (page - 1) * DIMENSIONS.DEFAULT_EVENTS_PER_PAGE,
        eventTypes,
        riskFilters,
        signalFilters,
        periodQuery,
      };

      let userId = searchUserField;
      if (!searchUserField) {
        const {
          data: {
            data: { events },
          },
        } = await fingerprintAPI.getServiceEvents({
          ...requestParams,
          limit: 1,
          skip: 0,
          eventTypes: [
            FingerprintLogEvents.Access,
            FingerprintLogEvents.Purchase,
            FingerprintLogEvents.Deposit,
            FingerprintLogEvents.Withdrawal,
            FingerprintLogEvents.Signup,
            FingerprintLogEvents.Login,
            FingerprintLogEvents.PasswordReset,
            FingerprintLogEvents.AttachNewDevice,
            FingerprintLogEvents.ProfileUpdate,
          ],
        });
        if (events.length && !!events[0].userId) {
          userId = events[0].userId;
        } else {
          userId = email ?? "";
        }
      }

      requestParams.userId = userId;

      const loadData = async () => {
        switch (mode) {
          case FingerprintModes.Events:
            return loadFilteredEventList(requestParams);
            break;
          case FingerprintModes.Users:
            return loadUserEventList(requestParams);
            break;
          case FingerprintModes.Devices:
            return loadDevicesList({ ...requestParams, limit: DIMENSIONS.DEFAULT_DEVICES_PER_PAGE });
            break;
          default:
            console.error("Mode is incorrect");
            return true;
        }
      };

      await loadData();
    } catch (err: unknown | KeyriError) {
      defaultErrorHandler(err);
    } finally {
      setDataLoading(false);
    }
  }, [currentService, selectedPeriod, periodQuery, page, eventTypes, riskFilters, signalFilters, navigateQuery, mode, searchUserField]);

  useEffect(() => {
    loadFilteredDataList();
  }, [currentService, selectedPeriod, periodQuery, page, eventTypes, riskFilters, signalFilters, loadFilteredDataList, mode, searchUserField]);

  useEffect(() => {
    setPage(1);
    handlePeriod(TimePeriodsList.Last7Days);
  }, [currentService, currentServicePlan, handlePeriod]);

  return (
    <>
      <FlexGroup>
        <SubFlexGroup>
          <RadioButtons
            defaultValue={selectedPeriod}
            list={[
              { value: TimePeriodsList.Last7Days, title: "1W", handler: handlePeriod(TimePeriodsList.Last7Days) },
              { value: TimePeriodsList.Last30Days, title: "1M", handler: handlePeriod(TimePeriodsList.Last30Days) },
              { value: TimePeriodsList.Last365Days, title: "1Y", handler: handlePeriod(TimePeriodsList.Last365Days) },
              { value: TimePeriodsList.Custom, title: "Custom" },
            ]}
          />
          <RangePicker
            style={{ maxWidth: "260px" }}
            picker="date"
            format="MMM D, YYYY"
            value={[moment(periodQuery.from), moment(periodQuery?.to ? periodQuery.to : Date.now())]}
            separator={<ArrowRightOutlined style={{ color: COLORS.SILVER }} />}
            disabledDate={(currentData) => currentData.isAfter(moment(Date.now()))}
            onChange={handlePeriod(TimePeriodsList.Custom)}
          />
        </SubFlexGroup>
        <SubFlexGroup>
          <RadioButtons
            defaultValue={mode}
            handler={(event) => {
              setTableData([]);
              setElementsCount(0);
              setMode(event.target.value);
              setPage(1);
            }}
            list={[
              { value: FingerprintModes.Events, title: FingerprintModes.Events },
              { value: FingerprintModes.Users, title: FingerprintModes.Users },
              { value: FingerprintModes.Devices, title: FingerprintModes.Devices },
            ]}
          />
        </SubFlexGroup>
      </FlexGroup>

      <SubFlexGroup>
        <MultiselectWrapper>
          <Multiselect<FingerprintLogEvents>
            mode={MultiselectModes.Multiple}
            key={"Event"}
            defaultValues={eventTypes}
            style={{ width: "30%", minWidth: "330px" }}
            onChange={(values: Array<FingerprintLogEvents> | string | undefined) => {
              setEventTypes(typeof values === "object" ? values : []);
            }}
            options={Object.keys(FingerprintLogEvents)
              .filter((key) => !/^Test/.test(key))
              .map((value) => ({
                label: FingerprintEventsTitles[FingerprintLogEvents[value as keyof typeof FingerprintLogEvents]],
                value: FingerprintLogEvents[value as keyof typeof FingerprintLogEvents],
                key: FingerprintLogEvents[value as keyof typeof FingerprintLogEvents],
              }))}
            filterPlaceholder={"Event"}
          />
          <Multiselect<FingerprintRiskParams>
            mode={MultiselectModes.Multiple}
            key={"Risk"}
            defaultValues={riskFilters}
            style={{ width: "30%", minWidth: "330px" }}
            onChange={(values: Array<FingerprintRiskParams> | string | undefined) => {
              setRiskFilters(typeof values === "object" ? values : []);
            }}
            options={Object.keys(FingerprintRiskParams).map((value) => ({
              label: FingerprintRiskTitles[FingerprintRiskParams[value as keyof typeof FingerprintRiskParams]],
              value: FingerprintRiskParams[value as keyof typeof FingerprintRiskParams],
              key: FingerprintRiskParams[value as keyof typeof FingerprintRiskParams],
            }))}
            filterPlaceholder={"Risk"}
          />
          <Multiselect<FingerprintSignals>
            mode={MultiselectModes.Multiple}
            key={"Signal"}
            defaultValues={signalFilters}
            style={{ width: "30%", minWidth: "330px" }}
            onChange={(values: Array<FingerprintSignals> | string | undefined) => {
              setSignalFilters(typeof values === "object" ? values : []);
            }}
            options={Object.keys(FingerprintSignals).map((value) => ({
              label: FingerprintSignalsTitles[FingerprintSignals[value as keyof typeof FingerprintSignals]],
              value: FingerprintSignals[value as keyof typeof FingerprintSignals],
              key: FingerprintSignals[value as keyof typeof FingerprintSignals],
            }))}
            filterPlaceholder={"Signal"}
          />
        </MultiselectWrapper>
        <SubFlexGroup>
          <SearchResultsCount>
            {elementsCount?.toLocaleString()}
            {elementsCount === 1 ? " Result" : " Results"}
          </SearchResultsCount>
          <LinkWithIcon text="Export" icon={<DownloadOutlined />} maxWidth={100} preset={ButtonWithIconStylePreset.Small} href={exportCSVLink} />
        </SubFlexGroup>
      </SubFlexGroup>
      {setTable(mode)}
    </>
  );
};
