import { InvoiceDetails } from "@amberflo/uikit/components/organisms/InvoiceDetails";
import { IPlanBillingScreenProps } from "./props";
import { SetupBilling } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { KeyriError } from "utils/keyri-error";
import { defaultErrorHandler } from "utils/helpers";
import { useStore, useTabTitle } from "utils/hooks";
import {AmberfloProvider, InvoicesList} from "@amberflo/uikit";
import { analyticsAPI } from "../../utils/api";
import { AmberfloWidgetStyles } from "./styles";
import { MenuItemContainer } from "../../styles";

export const PlanBillingScreen = ({ setIsFetch }: IPlanBillingScreenProps) => {
  useTabTitle("Plan & Billing");
  const {
    services,
    services: { currentService },
  } = useStore();
  const [amberfloToken, setAmberfloToken] = useState<string | null>(null);

  const handleUpdateServiceData = useCallback(
    (parameter) => {
      return async (value: string) => {
        if (!currentService || amberfloToken) return;
        try {
          await services.updateCurrentService({
            [parameter]: value,
          });
        } catch (err: unknown | KeyriError) {
          defaultErrorHandler(err);
        }
      };
    },
    [currentService, services]
  );

  useEffect(() => {
    if (!currentService) return;
    const fetchAmberfloToken = async () => {
      try {
        const {
          data: {
            data: { sessionToken },
          },
        } = await analyticsAPI.getAmberfloToken(currentService.id);
        setAmberfloToken(sessionToken);
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      }
    };

    fetchAmberfloToken();
  }, []);

  return (
    <MenuItemContainer>
      <SetupBilling updateService={handleUpdateServiceData} setIsFetch={setIsFetch} />
      {amberfloToken && (
        <AmberfloWidgetStyles>
          <AmberfloProvider session={amberfloToken}>
            <InvoiceDetails />
            <InvoicesList hasExportAction={false}/>
          </AmberfloProvider>
        </AmberfloWidgetStyles>
      )}
    </MenuItemContainer>
  );
};
