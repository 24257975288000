import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { FONTS } from "../../assets";
import { IAlignProps } from "../../styles";
import { DIMENSIONS } from "../../utils/constants";

export const FormHeader = styled.h2<IAlignProps>`
  margin: 0;

  font-family: ${FONTS.RUBIK};
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;

  text-align: ${({ align }) => (align ? align : "center")};
  color: ${COLORS.SCORPION};
`;

export const LoginTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 44px;

  @media (max-width: ${DIMENSIONS.L_BREAKPOINT_WIDTH}px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const DefaultPasswordLogin = styled.div`
  flex-basis: 350px;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const QrLogin = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const QrContainer = styled.div`
  width: 220px;
  height: 220px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: conic-gradient(from 45deg at 50% 50%, #767676 0deg, #39d2d5 180deg, #8c5bdc 360deg);

  border-radius: 13px;
`;

export const QrCodeWrapper = styled.div<{ dimension: number }>`
  padding: 10px;

  width: ${({dimension}) => !!dimension ? dimension : 214}px;
  height: ${({dimension}) => !!dimension ? dimension : 214}px;

  background-color: ${COLORS.WHITE};
  border-radius: 10px;
`;

export const Line = styled.div`
  height: 270px;

  border: 1px solid ${COLORS.GALLERY};

  @media (max-width: ${DIMENSIONS.L_BREAKPOINT_WIDTH}px) {
    display: none;
  }
`;
