import styled from "styled-components";
import { FONTS } from "../../assets";
import { COLORS } from "../../utils/colors";
import { Link } from "react-router-dom";

export const Message = styled.div`
  font-family: ${FONTS.RUBIK};
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.MINE_SHAFT};
`;

export const UpgradePlanButton = styled(Link)`
  color: ${COLORS.KUMERA};
  background-color: inherit;
  border: none;
  cursor: pointer;
`;
