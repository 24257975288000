import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { FONTS } from "../../assets";

export const SubBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

export const SubBlockTitle = styled.div`
  font-family: ${FONTS.RUBIK};
  margin: 0 0 6px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  color: ${COLORS.SCORPION};
`;

export const Plans = styled.fieldset`
  padding-top: 20px;
  padding-bottom: 38px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
`;

export const PlanInput = styled.input`
  display: none;
  position: fixed;
  width: 0;
`;

export const PlanLabel = styled.label`
  font-family: ${FONTS.RUBIK};
  flex: 1 1 280px;
  width: 376px;
  min-height: 250px;
  max-width: 376px;
  overflow: hidden;

  box-shadow: 0 3px 2px 0 rgba(108, 73, 172, 0.02), 0 7px 5px 0 rgba(108, 73, 172, 0.03), 0 13px 10px 0 rgba(108, 73, 172, 0.04),
    0 12px 12px 0 rgba(108, 73, 172, 0.04), 0 10px 10px 0 rgba(108, 73, 172, 0.05), 0 30px 20px 0 rgba(108, 73, 172, 0.07);
  border-radius: 20px;
  color: ${COLORS.SCORPION};
  cursor: pointer;
  user-select: none;

  input[type="radio"]:checked + & {
    border: 1px solid ${COLORS.MEDIUM_PURPLE};
    box-shadow: 0px 0px 6px rgba(140, 91, 220, 0.4);
    border-radius: 20px;
  }
`;
