import styled from "styled-components";
import { COLORS } from "../../utils/colors";

const height = 34;
export const AutoCompleteWrapper = styled.div<{ width?: number; fieldError?: string }>`
  width: ${({ width }) => (width ? width : 250)}px;
  height: ${height}px;
  border: ${({ fieldError }) => (fieldError ? `1px solid ${COLORS.RADICAL_RED}` : `1px solid ${COLORS.ALTO}`)};
  border-radius: 4px;
  background-color: ${COLORS.WHITE} !important;

  &:focus-within {
    border: ${({ fieldError }) => (fieldError ? `1px solid ${COLORS.RADICAL_RED}` : `1px solid ${COLORS.MEDIUM_PURPLE}`)};
  }

  .ant-select-selection-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-select,
  .ant-select-selector {
    width: 100%;
    height: ${height - 2}px !important;
    border: none !important;
    box-shadow: none !important;
  }
`;
