import React, { FC, useCallback, useEffect, useState } from "react";
import { DefaultLoader, KeyriSelect } from "components";
import { LoaderBackground } from "components/DefaultLoader/styles";
import { DIMENSIONS } from "utils/constants";
import { message, Table } from "antd";
import { TableTitle } from "../TableTitle";
import { useStore, useTabTitle } from "utils/hooks";
import { defaultErrorHandler } from "utils/helpers";
import { fingerprintListsAPI } from "utils/api";
import { observer } from "mobx-react-lite";
import { ListType } from "../../types";
import { IKeyriSelectProps } from "components/Select/props";
import { countriesList } from "utils/countries-list";
import { OutcomeValues } from "utils/types";
import { OutcomeFilter } from "../OutcomeFilter";
import { UploadForm } from "../UploadForm";
import { IRecord } from "../UploadForm/props";
import { baseListsColumns } from "../../tables";

const CountryTable: FC<{}> = () => {
  useTabTitle("Fingerprint Lists - Countries");

  const {
    services: { currentService },
    fingerprintList: { isRecordsLoading, recordsCount, searchField, outcome, page, records, resetRecords, setPage, loadDataList, setLoadingStatus, setOutcome },
  } = useStore();

  const [showUploadPopup, setShowUploadPopup] = useState<boolean>(false);
  const [uploadedRecords, setUploadedRecords] = useState<Array<IRecord> | undefined>(undefined);

  const handleUploadRecords = useCallback((records: Array<IRecord>) => {
    setUploadedRecords(records);
    setShowUploadPopup(false);
  }, []);

  const switchUploadPopup = useCallback(() => {
    setShowUploadPopup(!showUploadPopup);
  }, [showUploadPopup]);

  const fullNameRecords = [...records].map((record) => {
    const fullCountryName = countriesList.find((elem) => elem.code === record.value);
    return { ...record, value: fullCountryName ? fullCountryName.country : record.value, key: record.id };
  });

  const onPageChange = useCallback((page: number) => {
    setPage(page);
    window.scrollTo(0, 0);
  }, []);

  const changeOutcome = useCallback(
    async (recordId: string, newOutcomeValue: OutcomeValues) => {
      try {
        if (!currentService) return;
        setLoadingStatus(true);
        await fingerprintListsAPI.changeServiceRecords({ serviceId: currentService.id, recordId, outcome: newOutcomeValue });

        await loadDataList(currentService.id);
        message.success("Rule was updated");
      } catch (err) {
        defaultErrorHandler(err);
      }
    },
    [currentService, page, outcome, searchField]
  );

  const deleteRecord = useCallback(
    async (recordId: string) => {
      try {
        if (!currentService) return;
        setLoadingStatus(true);
        await fingerprintListsAPI.deleteServiceRecord({ serviceId: currentService.id, recordId });
        await loadDataList(currentService.id);
        message.success("Rule successfully deleted");
      } catch (err) {
        defaultErrorHandler(err);
      }
    },
    [currentService, page, outcome, searchField]
  );

  useEffect(() => {
    return () => resetRecords();
  }, []);

  return (
    <>
      <OutcomeFilter outcome={outcome as Array<OutcomeValues>} setOutcome={setOutcome} />
      {showUploadPopup && <UploadForm showControlBlock={false} recordsHandler={handleUploadRecords} />}
      <Table
        className={"table-with-upper-block table-without-shadow table-with-border "}
        scroll={{ x: "max-content" }}
        loading={{
          spinning: isRecordsLoading,
          indicator: (
            <LoaderBackground>
              <DefaultLoader />
            </LoaderBackground>
          ),
          wrapperClassName: "tableSpinner",
        }}
        pagination={{
          current: page,
          onChange: onPageChange,
          position: ["bottomCenter"],
          pageSize: DIMENSIONS.DEFAULT_SESSIONS_PER_PAGE,
          total: recordsCount,
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
        columns={baseListsColumns({ deleteFunction: deleteRecord, changeOutcome })}
        dataSource={[...fullNameRecords]}
        sticky={true}
        summary={() => {
          return (
            <TableTitle
              uploadedRecords={uploadedRecords}
              handleUploadButton={switchUploadPopup}
              valueField={CountriesSelector}
              list_type={ListType.Country}
              inputPlaceholder={"COUNTRY_VALUE"}
            />
          );
        }}
      />
    </>
  );
};

const CountriesSelector: FC<IKeyriSelectProps> = (props) => (
  <KeyriSelect
    dataName={"country-selector"}
    {...props}
    options={countriesList
      .map((country) => ({ value: country.code, label: country.country }))
      .sort((firstCountry, secondCountry) => {
        if (firstCountry.label < secondCountry.label) return -1;
        if (firstCountry.label > secondCountry.label) return 1;
        return 0;
      })}
  />
);

export const CountryTableWrapper = observer(CountryTable);
