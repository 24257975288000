import { format } from "date-fns";
import { FingerprintSignalsColors, LogsTableRiskStatuses, LogsTableStatuses, LogsTableStatusesNames } from "./constants";
import { Space, Tooltip } from "antd";
import { ColoredTableItem, CroppedColumn } from "../screens/FingerprintLogsScreen/styles";
import React from "react";
import { COLORS } from "./colors";
import { DarkTableText, LightTableText } from "../screens/FingerprintLogsScreen/screenComponents/DevicesTable/styles";
import { FingerprintRiskParams, OutcomeValues } from "./types";
import { DeniedAccessTableItem } from "../screens/LogsScreen/styles";
import { getDeviceFromUserAgent } from "./device";
import { sortOutcomeValues } from "./helpers";
import { ITableSignal } from "../screens/FingerprintLogsScreen/types";

export const logsEventsColumns = (isAccessReceived: boolean) => [
  {
    title: "Timestamp",
    dataIndex: "time",
    key: "time",
    width: 180,
    render: (text: string, record: any, index: number) => format(new Date(text), "uuuu-MM-dd HH:mm:ss"),
  },
  {
    title: "User ID",
    dataIndex: "userId",
    key: "userId",
    width: 118,
    render: (value: string) => {
      return <CroppedColumn colorValue={COLORS.MINE_SHAFT}>{value}</CroppedColumn>;
    },
  },
  {
    title: "Result",
    dataIndex: "accepted",
    key: "accepted",
    width: 68,
    render: (value: boolean) => {
      return (
        <Space size="middle">
          {value ? <ColoredTableItem colorValue={"green"}>Success</ColoredTableItem> : <ColoredTableItem colorValue={"red"}>Fail</ColoredTableItem>}
        </Space>
      );
    },
  },
  {
    title: "Type",
    key: "type",
    dataIndex: "type",
    width: 68,
    render: (value: LogsTableStatusesNames) => {
      return (
        <Space size="middle">
          <ColoredTableItem colorValue={"green"}>{value}</ColoredTableItem>
        </Space>
      );
    },
  },
  {
    title: "Distance",
    key: "distance",
    dataIndex: "distance",
    width: 68,
    render: (value?: LogsTableStatusesNames) => {
      return <Space size="middle">{isAccessReceived ? typeof value === "number" ? `${value} km` : "Unknown" : <DeniedAccessTableItem />}</Space>;
    },
  },
  {
    title: "Device",
    key: "device",
    dataIndex: "device",
    width: 136,
    render: (value: string) => {
      return <Space size="middle">{isAccessReceived ? getDeviceFromUserAgent(value) : <DeniedAccessTableItem />}</Space>;
    },
  },
  {
    title: "Browser Anonymous Proxy",
    key: "riskBrowserAnonymousProxy",
    dataIndex: "riskBrowserAnonymousProxy",
    width: 68,
    render: (value?: LogsTableStatusesNames) => {
      const { text, colorStyle } = LogsTableStatuses[!value ? LogsTableStatusesNames.Accept : LogsTableStatusesNames.Reject];
      return <Space size="middle">{isAccessReceived ? <ColoredTableItem colorValue={colorStyle}>{text}</ColoredTableItem> : <DeniedAccessTableItem />}</Space>;
    },
  },
  {
    title: "Browser Known Attacker IP",
    key: "riskBrowserKnownAttackerIP",
    dataIndex: "riskBrowserKnownAttackerIP",
    width: 68,
    render: (value?: LogsTableStatusesNames) => {
      const { text, colorStyle } = LogsTableStatuses[!value ? LogsTableStatusesNames.Accept : LogsTableStatusesNames.Reject];

      return <Space size="middle">{isAccessReceived ? <ColoredTableItem colorValue={colorStyle}>{text}</ColoredTableItem> : <DeniedAccessTableItem />}</Space>;
    },
  },
  {
    title: "Browser Tor IP",
    key: "riskBrowserTorIP",
    dataIndex: "riskBrowserTorIP",
    width: 68,
    render: (value?: LogsTableStatusesNames) => {
      const { text, colorStyle } = LogsTableStatuses[!value ? LogsTableStatusesNames.Accept : LogsTableStatusesNames.Reject];

      return <Space size="middle">{isAccessReceived ? <ColoredTableItem colorValue={colorStyle}>{text}</ColoredTableItem> : <DeniedAccessTableItem />}</Space>;
    },
  },
  {
    title: "Country Differential",
    key: "riskCountryDifferential",
    dataIndex: "riskCountryDifferential",
    width: 68,
    render: (value?: LogsTableStatusesNames) => {
      const { text, colorStyle } = LogsTableStatuses[!value ? LogsTableStatusesNames.Accept : LogsTableStatusesNames.Reject];

      return <Space size="middle">{isAccessReceived ? <ColoredTableItem colorValue={colorStyle}>{text}</ColoredTableItem> : <DeniedAccessTableItem />}</Space>;
    },
  },
];

export const fingerprintEventsColumns = [
  {
    title: "Timestamp",
    dataIndex: "time",
    key: "time",
    width: 120,
    render: (text: string, record: any, index: number) => {
      const isValidTime = Date.parse(text);
      if (isNaN(isValidTime) === true) return "Unknown";
      return format(new Date(text), "uuuu-MM-dd HH:mm:ss");
    },
  },
  {
    title: "Device ID",
    dataIndex: "deviceId",
    key: "deviceId",
    width: 118,
    render: (value: string) => {
      return (
        <Tooltip title={value}>
          <CroppedColumn>{value}</CroppedColumn>
        </Tooltip>
      );
    },
  },
  {
    title: "User ID",
    dataIndex: "userId",
    key: "userId",
    width: 118,
    render: (value: string) => {
      return (
        <Tooltip title={value}>
          <CroppedColumn colorValue={COLORS.MINE_SHAFT}>{value}</CroppedColumn>
        </Tooltip>
      );
    },
  },
  {
    title: "Event",
    key: "event",
    dataIndex: "event",
    width: 150,
  },
  // {
  //   title: "Initial Result",
  //   key: "result",
  //   dataIndex: "result",
  //   width: 120,
  //   render: (value: FingerprintLogResult) => {
  //     const { text, colorStyle } = LogsTableStatuses[FingerprintsResultStatuses[value] ?? FingerprintsResultStatuses[FingerprintLogResult.Fail]];
  //     return (
  //       <Space size="middle">
  //         <ColoredTableItem colorValue={colorStyle}>{text}</ColoredTableItem>
  //       </Space>
  //     );
  //   },
  // },
  {
    title: "Device Type",
    key: "deviceType",
    dataIndex: "deviceType",
    width: 120,
  },
  {
    title: "Client",
    key: "client",
    dataIndex: "client",
    width: 120,
  },
  {
    title: "Location",
    key: "location",
    dataIndex: "location",
    width: 230,
    render: (value: Array<string>) => {
      return (
        <>
          {value?.[0]}
          <br />
          {value?.[1]}
        </>
      );
    },
  },
  {
    title: "Risk",
    key: "risk",
    dataIndex: "risk",
    width: 68,
    render: (value: FingerprintRiskParams) => {
      const { text, colorStyle } = LogsTableRiskStatuses[value] ?? { text: value, colorStyle: FingerprintSignalsColors.custom };

      return (
        <Space size="middle">
          <ColoredTableItem colorValue={colorStyle}>{text}</ColoredTableItem>
        </Space>
      );
    },
  },
  {
    title: "Signals",
    key: "signals",
    dataIndex: "signals",
    width: 200,
    render: (values: Array<ITableSignal>) => (
      <Space direction={"vertical"} size="middle">
        {values.length ? (
          values.map((value) => <ColoredTableItem colorValue={value.color}>{value.title}</ColoredTableItem>)
        ) : (
          <ColoredTableItem colorValue={"green"}>No Signals</ColoredTableItem>
        )}
      </Space>
    ),
  },
];

export const fingerprintUsersColumns = [
  {
    title: "Timestamp",
    dataIndex: "time",
    key: "time",
    width: 120,
    render: (text: string, record: any, index: number) => {
      const isValidTime = Date.parse(text);
      if (isNaN(isValidTime) === true) return "Unknown";
      return format(new Date(text), "uuuu-MM-dd HH:mm:ss");
    },
  },
  {
    title: "Device ID",
    dataIndex: "deviceId",
    key: "deviceId",
    width: 118,
    render: (value: string) => {
      return (
        <Tooltip title={value}>
          <CroppedColumn>{value}</CroppedColumn>
        </Tooltip>
      );
    },
  },
  {
    title: "Event",
    key: "event",
    dataIndex: "event",
    width: 150,
  },
  // {
  //   title: "Initial Result",
  //   key: "result",
  //   dataIndex: "result",
  //   width: 120,
  //   render: (value: FingerprintLogResult) => {
  //     const { text, colorStyle } = LogsTableStatuses[FingerprintsResultStatuses[value] ?? FingerprintsResultStatuses[FingerprintLogResult.Fail]];
  //     return (
  //       <Space size="middle">
  //         <ColoredTableItem colorValue={colorStyle}>{text}</ColoredTableItem>
  //       </Space>
  //     );
  //   },
  // },
  {
    title: "Device Type",
    key: "deviceType",
    dataIndex: "deviceType",
    width: 120,
  },
  {
    title: "Client",
    key: "client",
    dataIndex: "client",
    width: 120,
  },
  {
    title: "Location",
    key: "location",
    dataIndex: "location",
    width: 200,
    render: (value: Array<string>) => {
      return (
        <>
          {value[0]}
          <br />
          {value[1]}
        </>
      );
    },
  },
  {
    title: "Risk",
    key: "risk",
    dataIndex: "risk",
    width: 68,
    render: (value: FingerprintRiskParams) => {
      const { text, colorStyle } = LogsTableRiskStatuses[value] ?? { text: value, colorStyle: FingerprintSignalsColors.custom };

      return (
        <Space size="middle">
          <ColoredTableItem colorValue={colorStyle}>{text}</ColoredTableItem>
        </Space>
      );
    },
  },
  {
    title: "Signals",
    key: "signals",
    dataIndex: "signals",
    width: 200,
    render: (values: Array<ITableSignal>) => (
      <Space direction={"vertical"} size="middle">
        {values.length ? (
          values.map((value) => <ColoredTableItem colorValue={value.color}>{value.title}</ColoredTableItem>)
        ) : (
          <ColoredTableItem colorValue={"green"}>No Signals</ColoredTableItem>
        )}
      </Space>
    ),
  },
];

export const fingerprintDevicesColumns = [
  {
    title: "Device ID",
    dataIndex: "deviceId",
    key: "deviceId",
    width: 118,
    render: (value: string) => {
      return (
        <Tooltip title={value}>
          <CroppedColumn>{value}</CroppedColumn>
        </Tooltip>
      );
    },
  },
  {
    title: "Device Type",
    key: "deviceType",
    dataIndex: "deviceType",
    width: 120,
  },
  {
    title: "Client",
    key: "client",
    dataIndex: "client",
    width: 160,
  },
  {
    title: "Activity",
    key: "activity",
    dataIndex: "activity",
    width: 240,
    render: (value: Array<string>) => {
      return (
        <>
          <DarkTableText>{value ? value[0] : "Unknown"}</DarkTableText>
          <br />
          <LightTableText>{value ? value[1] : "Unknown"}</LightTableText>
        </>
      );
    },
  },
  {
    title: "Last Location",
    key: "lastLocation",
    dataIndex: "lastLocation",
    width: 200,
    render: (value: Array<string>) => {
      return (
        <>
          {value ? value[0] : "Unknown"}
          <br />
          {value ? value[1] : "Unknown"}
        </>
      );
    },
  },
  {
    title: "Signals",
    key: "signals",
    dataIndex: "signals",
    width: 200,
    render: (values: Array<ITableSignal>) => (
      <Space direction={"vertical"} size="middle">
        {values.length ? (
          values.map((value) => <ColoredTableItem colorValue={value.color}>{value.title}</ColoredTableItem>)
        ) : (
          <ColoredTableItem colorValue={"green"}>No Signals</ColoredTableItem>
        )}
      </Space>
    ),
  },
];

export const listsColumns = [
  {
    title: "Lists Category",
    dataIndex: "lists_category",
    key: "lists_category",
    width: 120,
    render: (value: string) => <CroppedColumn colorValue={COLORS.MINE_SHAFT}>{value}</CroppedColumn>,
  },
  {
    title: "Date Updated",
    dataIndex: "timestamp",
    key: "timestamp",
    width: 120,
    render: (text: string, record: any, index: number) => {
      const isValidTime = Date.parse(text);
      if (isNaN(isValidTime) === true) return "None";
      return format(new Date(text), "uuuu-MM-dd");
    },
  },
  {
    title: "No. of Records",
    key: "number_of_records",
    dataIndex: "number_of_records",
    width: 120,
    render: (value: string | undefined) => {
      return !!value ? value : "None";
    },
  },
  {
    title: "Outcome",
    key: "outcome",
    dataIndex: "outcome",
    width: 120,
    render: (values: Array<OutcomeValues> | undefined) => (
      <Space direction={"horizontal"} size="middle">
        {values?.length ? (
          sortOutcomeValues(values).map((value) => (
            <ColoredTableItem colorValue={LogsTableRiskStatuses[value].colorStyle}>{LogsTableRiskStatuses[value].text}</ColoredTableItem>
          ))
        ) : (
          <ColoredTableItem colorValue={"green"}>No records</ColoredTableItem>
        )}
      </Space>
    ),
  },
  {
    title: "Description",
    key: "description",
    dataIndex: "description",
    width: 200,
  },
];

export const outcomeValues = [
  { value: OutcomeValues.Allow, label: "Allow", key: OutcomeValues.Allow },
  { value: OutcomeValues.Warn, label: "Warn", key: OutcomeValues.Warn },
  { value: OutcomeValues.Deny, label: "Deny", key: OutcomeValues.Deny },
];
