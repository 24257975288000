import { KeyriError } from "./keyri-error";

export class CryptoModule {
  subtle = window.crypto.subtle;
  public async generateKeyPair() {
    if (!this.subtle) throw new KeyriError(996);
    const ECDHKey = await window.crypto.subtle.generateKey({ name: "ECDH", namedCurve: "P-256" }, true, ["deriveKey"]);

    return this.getB64KeysFromKeyPair(ECDHKey);
  }

  private async getB64KeysFromKeyPair(cryptoKey: CryptoKeyPair) {
    const publicKeyB64 = await this.getB64KeyFromCryptoKey(cryptoKey.publicKey, "spki");
    const privateKeyB64 = await this.getB64KeyFromCryptoKey(cryptoKey.privateKey, "pkcs8");

    return { publicKeyB64, privateKeyB64 };
  }

  private async getB64KeyFromCryptoKey(cryptoKey: CryptoKey, format: "spki" | "pkcs8" | "raw") {
    if (!window.crypto.subtle) throw new KeyriError(996);
    const keyData = await window.crypto.subtle.exportKey(format, cryptoKey);
    const keyBytes = new Uint8Array(keyData);
    const keyB64 = btoa(String.fromCharCode.apply(null, keyBytes as any));

    return keyB64;
  }
}
