import { flow, types} from "mobx-state-tree";
import { IGetFingerprintServiceRecordsRequest, OutcomeValues } from "../utils/types";
import { DIMENSIONS } from "../utils/constants";
import { fingerprintListsAPI } from "../utils/api";
import { defaultErrorHandler } from "../utils/helpers";
import { ListType } from "../screens/FingerprintListsScreen/types";
import { getCountryCode } from "../utils/countries-list";

export const UpdatedByModel = types.model({
  id: types.string,
  name: types.string,
  email: types.string,
  emailVerified: types.boolean,
  createdAt: types.string,
  updatedAt: types.string,
});

export const Record = types.model({
  id: types.string,
  serviceId: types.string,
  expired_date: types.maybeNull(types.string),
  value: types.string,
  notes: types.maybeNull(types.string),
  outcome: types.string,
  list_type: types.string,
  updatedBy: types.maybe(UpdatedByModel),
  updaterId: types.maybeNull(types.string),
  createdAt: types.string,
  updatedAt: types.string,
});

const Analytics = types.model({
  count: types.number,
  outcomeValues: types.array(types.string),
  latestUpdate: types.string,
});

const AnalyticsData = types.model({
  country: types.maybe(Analytics),
  imei: types.maybe(Analytics),
  user_id: types.maybe(Analytics),
  device_id: types.maybe(Analytics),
  ip: types.maybe(Analytics),
});

const OutcomeArray = types.array(types.string);

const FingerprintListStore = types
  .model("FingerprintListStore", {
    analyticsListsData: AnalyticsData,
    searchField: types.string,
    outcome: OutcomeArray,
    page: types.number,
    recordsCount: types.number,
    records: types.optional(types.array(Record), []),
    isRecordsLoading: types.boolean,
    listType: types.string,
  })
  .actions((self) => ({
    loadServiceInfo: flow(function* (serviceId: string) {
      try {
        if (self.listType !== ListType.Main) return;
        self.isRecordsLoading = true;
        const {
          data: {
            data: { analyticsData },
          },
        } = yield fingerprintListsAPI.getServiceRecordsInfo(serviceId);
        self.analyticsListsData = analyticsData;
      } catch (err) {
        defaultErrorHandler(err);
      } finally {
        self.isRecordsLoading = false;
      }
    }),
    loadDataList: flow(function* (serviceId: string) {
      try {
        if (self.listType === ListType.Main) return;
        self.isRecordsLoading = true;

        const requestParams: IGetFingerprintServiceRecordsRequest = {
          serviceId,
          limit: DIMENSIONS.DEFAULT_SERVICE_RECORDS_PER_PAGE,
          skip: (self.page - 1) * DIMENSIONS.DEFAULT_SERVICE_RECORDS_PER_PAGE,
        };

        if (self.listType !== ListType.Search) requestParams.listType = self.listType as ListType;

        if (!!self.searchField) {
          requestParams.search = self.listType === ListType.Country ? getCountryCode(self.searchField) : self.searchField;
          requestParams.filter = "value";
        }
        if (!!self.outcome) requestParams.outcome = self.outcome as Array<OutcomeValues>;

        const {
          data: {
            data: { list, count },
          },
        } = yield fingerprintListsAPI.getServiceRecords(requestParams);

        self.recordsCount = count;
        self.records = list;
      } catch (err) {
        defaultErrorHandler(err);
      } finally {
        self.isRecordsLoading = false;
      }
    }),
    resetRecords: () => (self.records = [] as any),
    setLoadingStatus: (value: boolean) => (self.isRecordsLoading = value),
    setSearchField: (value: string) => (self.searchField = value),
    setOutcome: (value: Array<string>) => self.outcome = OutcomeArray.create(value),
    setPage: (value: number) => (self.page = value),
    setListType: (value: ListType) => (self.listType = value),
  }));

export default FingerprintListStore;
