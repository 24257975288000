import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
// @ts-ignore
import CreditCardInput from "react-credit-card-input";
import { IBillingInformationProps } from "./props";
import { Container, CountryContainer, Country, Zip, FieldWrapper } from "./styles";
import { Select } from "antd";
import countriesList from "./countries.json";

interface ICountry {
  name: string;
  code: string;
}

const { Option } = Select;

export const BillingInformation: FC<IBillingInformationProps> = ({ onChange }) => {
  const [cardNumber, setCardNumber] = useState<string>("");
  const [cardExpiry, setCardExpiry] = useState<string>("");
  const [cardCVC, setCardCVC] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [cardErrors, setCardErrors] = useState<Array<string>>(["cardNumber", "cardExpiry", "cardCVC"]);
  const countries: ICountry[] = JSON.parse(JSON.stringify(countriesList));

  const onCardErrorsHandler = useCallback(
    ({ inputName }) => {
      if (!cardErrors.includes(inputName)) setCardErrors([...cardErrors, inputName]);
    },
    [cardErrors]
  );

  const handleInputChange = (callback: (data: string) => void, fieldName: string) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      setCardErrors(cardErrors.filter((item: any) => item !== fieldName));
      callback(e.target.value);
    };
  };

  useEffect(() => {
    onChange({ cardNumber, cardExpiry, cardCVC, cardErrors, country, zip });
  }, [cardNumber, cardExpiry, cardCVC, cardErrors, country, zip, onChange]);

  return (
    <Container>
      <FieldWrapper>
        <CreditCardInput
          onError={onCardErrorsHandler}
          cardNumberInputProps={{
            value: cardNumber,
            onChange: handleInputChange(setCardNumber, "cardNumber"),
          }}
          cardExpiryInputProps={{
            value: cardExpiry,
            onChange: handleInputChange(setCardExpiry, "cardExpiry"),
          }}
          cardCVCInputProps={{
            value: cardCVC,
            onChange: handleInputChange(setCardCVC, "cardCVC"),
          }}
          cardImageClassName="creditCardInputImage"
          inputClassName="creditCardInput"
          fieldClassName="creditCardField"
          containerClassName="creditCardContainer"
        />
      </FieldWrapper>
      <FieldWrapper>
        <CountryContainer>
          <Country>
            <Select
              defaultValue="Country"
              bordered={false}
              dropdownStyle={{ borderRadius: "10px" }}
              className="country-select"
              showSearch
              onChange={(value) => setCountry(value)}
              filterOption={(input, option) => {
                return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase());
              }}
            >
              {countries.map((country) => (
                <Option key={country.code} value={country.code}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Country>
          <Zip name="zip" type="number" placeholder="ZIP Code" autoComplete="off" onChange={(e) => setZip(e.target.value)} />
        </CountryContainer>
      </FieldWrapper>
    </Container>
  );
};
