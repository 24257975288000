import React, { FC, useMemo } from "react";
import { BlockTitle, MenuItemContainer, MenuItemContent } from "../../styles";
import { Link, Route, Routes } from "react-router-dom";
import { ILogsScreen } from "./props";
import { observer } from "mobx-react-lite";
import { InternalLogsLinks } from "./types";
import { EventDetails, MainLogsScreen } from "./screenComponents";
import { generateRoute, Routes as RoutesConstants } from "utils/constants";
import { useLocation } from "react-router";
import { useStore } from "utils/hooks";

export const FingerprintLogsScreenComponent: FC<ILogsScreen> = () => {
  const {
    services: { currentService },
  } = useStore();
  const location = useLocation();
  const subRoute = useMemo(() => {
    switch (true) {
      case location.pathname.includes('details'):
        return " > Event Details";
      default:
        return "";
    }
  }, [location]);

  return (
    <MenuItemContainer>
      <MenuItemContent>
        <BlockTitle>
          {subRoute.length ? <Link to={generateRoute(RoutesConstants.FingerprintLogs, { serviceId: currentService?.id || "" })}>Logs</Link> : "Logs"}
          {subRoute}
        </BlockTitle>
        <Routes>
          <Route path={InternalLogsLinks.MainLogsPage} element={<MainLogsScreen />} />
          <Route path={InternalLogsLinks.EventDetails} element={<EventDetails />} />
        </Routes>
      </MenuItemContent>
    </MenuItemContainer>
  );
};

export const FingerprintLogsScreen = observer(FingerprintLogsScreenComponent);
