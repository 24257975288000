import React, { FC, useCallback, useEffect, useState } from "react";
import { DefaultLoader } from "components";
import { LoaderBackground } from "components/DefaultLoader/styles";
import { DIMENSIONS } from "utils/constants";
import { message, Table } from "antd";
import { TableTitle } from "../TableTitle";
import { useStore, useTabTitle } from "utils/hooks";
import { defaultErrorHandler } from "utils/helpers";
import { fingerprintListsAPI } from "utils/api";
import { observer } from "mobx-react-lite";
import { ListType } from "../../types";
import { OutcomeValues } from "utils/types";
import { OutcomeFilter } from "../OutcomeFilter";
import { UploadForm } from "../UploadForm";
import { IRecord } from "../UploadForm/props";
import { baseListsColumns } from "../../tables";

const DeviceIdTable: FC<{}> = () => {
  useTabTitle("Fingerprint Lists - Device ID");

  const {
    services: { currentService },
    fingerprintList: { isRecordsLoading, recordsCount, searchField, outcome, page, records, resetRecords, setPage, loadDataList, setLoadingStatus, setOutcome },
  } = useStore();

  const [showUploadPopup, setShowUploadPopup] = useState<boolean>(false);
  const [uploadedRecords, setUploadedRecords] = useState<Array<IRecord> | undefined>(undefined);

  const handleUploadRecords = useCallback((records: Array<IRecord>) => {
    setUploadedRecords(records);
    setShowUploadPopup(false);
  }, []);

  const switchUploadPopup = useCallback(() => {
    setShowUploadPopup(!showUploadPopup);
  }, [showUploadPopup]);

  const onPageChange = useCallback((page: number) => {
    setPage(page);
    window.scrollTo(0, 0);
  }, []);

  const changeOutcome = useCallback(
    async (recordId: string, newOutcomeValue: OutcomeValues) => {
      try {
        if (!currentService) return;
        setLoadingStatus(true);
        await fingerprintListsAPI.changeServiceRecords({ serviceId: currentService.id, recordId, outcome: newOutcomeValue });

        await loadDataList(currentService.id);
        message.success("Rule was updated");
      } catch (err) {
        defaultErrorHandler(err);
      }
    },
    [currentService, page, outcome, searchField]
  );

  const deleteRecord = useCallback(
    async (recordId: string) => {
      try {
        if (!currentService) return;
        setLoadingStatus(true);
        await fingerprintListsAPI.deleteServiceRecord({ serviceId: currentService.id, recordId });
        await loadDataList(currentService.id);
        message.success("Rule successfully deleted");
      } catch (err) {
        defaultErrorHandler(err);
      }
    },
    [currentService, page, outcome, searchField]
  );

  useEffect(() => {
    return () => resetRecords();
  }, []);

  return (
    <>
      <OutcomeFilter outcome={outcome as Array<OutcomeValues>} setOutcome={setOutcome} />
      {showUploadPopup && <UploadForm showControlBlock={false} recordsHandler={handleUploadRecords} />}
      <Table
        className={"table-with-upper-block table-without-shadow table-with-border"}
        scroll={{ x: "max-content" }}
        loading={{
          spinning: isRecordsLoading,
          indicator: (
            <LoaderBackground>
              <DefaultLoader />
            </LoaderBackground>
          ),
          wrapperClassName: "tableSpinner",
        }}
        pagination={{
          current: page,
          onChange: onPageChange,
          position: ["bottomCenter"],
          pageSize: DIMENSIONS.DEFAULT_SESSIONS_PER_PAGE,
          total: recordsCount,
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
        columns={baseListsColumns({ deleteFunction: deleteRecord, changeOutcome })}
        dataSource={[...records]}
        sticky={true}
        summary={() => {
          return (
            <TableTitle
              uploadedRecords={uploadedRecords}
              handleUploadButton={switchUploadPopup}
              list_type={ListType.DeviceID}
              inputPlaceholder={"DEVICE_ID_VALUE"}
            />
          );
        }}
      />
    </>
  );
};

export const DeviceIdTableWrapper = observer(DeviceIdTable);
