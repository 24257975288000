import styled from 'styled-components';
import { FONTS } from '../../assets';
import { COLORS } from '../../utils/colors';

interface IDeleteAppBlock {
  visible: boolean;
}

export const DeleteAppBlock = styled.div<IDeleteAppBlock>`
  text-align: center;
  display: ${({visible}) => visible ? 'block' : 'none'};
`;

export const DeleteAppButton = styled.button`
  border: none;
  background: none;
  font-family: ${FONTS.RUBIK};
  color: ${COLORS.GRAY};
  font-size: 15px;
  cursor: pointer;
`;
