import { MeterApiNames, TimePeriodsList } from "./constants";
import { ListType } from "../screens/FingerprintListsScreen/types";
import { FingerprintModes } from "../screens/FingerprintLogsScreen/types";
import { ICustomRuleCondition } from "../screens/FingerprintRiskManagement/types";
import { Operators } from "../screens/FingerprintRiskManagement/screenComponents/CustomRuleNoCode/props";
import { Role } from "@denver23/keyri-shared";

export enum ServiceType {
  Authentication = "auth",
  EchoGuard = "echo",
}
export interface ICurrentQueryParams {
  selectedPeriod: TimePeriodsList;
  periodQuery: { from: number; to?: number };
  page: number;
  eventTypes: Array<FingerprintLogEvents>;
  riskFilters: Array<FingerprintRiskParams>;
  signalFilters: Array<FingerprintSignals>;
  mode: FingerprintModes;
  searchUserField: string | string[];
}

export interface IListsQueryParams {
  page: number;
  outcome?: Array<OutcomeValues>;
  search: string | string[];
}

export enum ButtonStylePreset {
  BaseButton = "BaseButton",
  BaseButtonWithBorder = "BaseButtonWithBorder",
  PurpleButtonWithBorder = "PurpleButtonWithBorder",
  LightPurpleButton = "LightPurpleButton",
  SmallGreyButton = "SmallGreyButton",
}

export interface ISessionsResponse {
  analyticsCount: number;
  analyticsRecords: Array<ISessionRecord>;
}

export interface ISessionRecord {
  createdAt: string;
  device: string;
  id: string;
  updatedAt: string;
  riskBrowserAnonymousProxy?: boolean;
  riskBrowserKnownAttackerIP?: boolean;
  riskBrowserTorIP?: boolean;
  riskCountryDifferential?: boolean;
  riskStraightLineDistance?: number;
  serviceId: string;
  type: string;
  userId: string;
  accepted: boolean;
}

export enum ButtonWithIconStylePreset {
  Default = "Default",
  Dashed = "Dashed",
  Small = "Small",
  GreenSmall = "GreenSmall",
  GreenDashed = "GreenDashed",
  Borderless = "Borderless",
  RedSmall = "RedSmall",
}

export interface IPlan {
  interval: string | null;
  isDefault: boolean;
  description: string | null;
  amberfloDescription: string | null;
  name: string;
  order: number;
  planId: string | null;
  price: number | null;
  productId: string | null;
  id: string;
  requiredCard: boolean;
  permissions: Array<string>;
  customPermissions: Array<string>;
}

export interface IChangeServicePlanData {
  planId: string;
  cardId?: string;
  switchLogic: SwitchingLogic;
}

export enum SwitchingLogic {
  Immediately = "immediately",
  AtPeriodEnd = "atPeriodEnd",
}

export interface ISubscription {
  period_end: number;
  period_start: number;
  plan: IPlan;
  serviceId: string;
  status: string;
  subId: string;
  user: string;
  id: string;
}

export enum InputStylePreset {
  BaseInput = "BaseInput",
  BaseInputGray = "BaseInputGray",
  SmallBaseInput = "SmallBaseInput",
  NarrowPaddedForPlaceholder = "NarrowPaddedForPlaceholder",
  DangerInput = "DangerInput",
  InlineInput = "InlineInput",
}

export interface ISize {
  width: number | undefined;
  height: number | undefined;
}

export interface ILoginForm {
  email: string;
}

export interface IProfileForm {
  email: string;
  name: string;
}
export interface IDeleteAppForm {
  appName: string;
}

export interface ICardInitializingData {
  cardNumber: string;
  exp_month: number;
  exp_year: number;
  cvc: string;
  country: string;
  zip: string;
  serviceId: string;
}

export interface ICreditCard {
  cardId: string;
  userId: string;
  serviceId: string;
  customerId: string;
  cardBrand: string;
  cardLast4: string;
  cardExpire: string;
  country: string;
  zip: string;
  id: string;
}

export interface ICreateServiceForm {
  name: string;
  domainName: string;
}

export interface IEditUserForm {
  name: string;
  email: string;
}

export interface INewAuthApplicationForm {
  name: string;
  domainName: string;
  plan: string;
}

export interface INewEchoApplicationForm {
  name: string;
  plan: string;
}

export interface INewCustomRuleForm {
  ruleName: string;
  outcome: string;
  notes: string;
  conditions: Array<ICustomRuleCondition>;
  operator: Operators | null;
}

export interface IChangeServicePlanForm {
  plan: string;
}

export interface ISignUpForm {
  fullName: string;
  email: string;
  response: string;
}

export interface IRiskDistanceForm {
  warnDistance: string;
  denyDistance: string;
}

export interface IRegistrationsPerDeviceForm {
  denyRegistrationsPerDevices: number;
}

export interface IAccountAccessPerDeviceForm {
  denyAccAccess: number;
}

export interface IFingerprintListTableValues {
  records: Array<IFingerprintListRecord>;
}

export interface IFingerprintListRecord {
  value: string;
  outcome: OutcomeValues;
  expired_date: number | undefined;
  notes: string;
}

export interface IWarnSettingForm {
  events: number;
  seconds: number;
}

export interface IAddRuleForm {
  events: string;
  identifiers: string;
  eventsAmount: number | undefined;
  amountedIdentifiers: string;
  risk: OutcomeValues | undefined;
  ruleName: string;
}

export interface IStackRuleForm {
  firstSignal: string;
  secondSignal: string;
  risk: OutcomeValues | undefined;
}
export interface IPutUserUpdate {
  firstName?: string;
  secondName?: string;
  name?: string;
  email?: string;
  photo?: string;
}

export interface ICardData {
  cardNumber: string;
  cardExpiry: string;
  cardCVC: string;
  cardErrors: string[];
  country: string;
  zip: string;
}

export interface UserService {
  id: string;
  serviceId: string;
  userId: string;
  role: Role;
}

export interface Invite {
  id: string;
  serviceId: string;
  email: string;
  role: Role;
}

export enum FingerprintRulesList {
  RegistrationsPerDevice = "registrations_per_device",
  AccountAccessPerDevice = "account_access_per_device",
  SignalAfterAttempts = "signal_after_attempts",
  NewDeviceDetected = "new_device_detected",
  BotDetected = "bot_detected",
  AppVpnProxy = "app_vpn_proxy",
  AppTorIp = "app_tor_ip",
  AppSuspiciousIp = "app_suspicious_ip",
  ImprobableTravel = "improbable_travel",
  NewIpCountry = "new_ip_country",
  EmulatedDevice = "emulated_device",
  JailbrokenRooted = "jailbroken_rooted",
  SwizzledTempered = "swizzled_tempered",
  DangerousApps = "dangerous_apps",
  Debuggable = "debuggable",
  EstablishedUserDeviceAllow = "established_user_device_allow",
}

export interface IFingerprintRule {
  rule?: string;
  eventType?: string;
  conditions: Record<string, any>;
  outcome: string;
  signal: string;
  enabled?: boolean;
  strength?: number;
}

export interface IFingerprintRules {
  id: string;
  rules: Array<IFingerprintRule>;
  serviceId: string;
  createdAt: string;
  updatedAt: string;
}

export interface IService {
  key: string;
  application: {
    id: string;
    serviceId: string;
    whiteLabelAppKey: string;
    appKey: string;
    widgetKey: string;
    createdAt: string;
    updatedAt: string;
  };
  isValid: boolean;
  id: string;
  name: string;
  logo: string | null;
  createdAt: string;
  updatedAt: string;
  qrCodeType: string;
  qrLogo: string | null;
  UserService?: UserService;
  members?: Array<{
    createdAt: string;
    email: string;
    emailVerified: boolean;
    name: string;
    UserService: UserService;
    updatedAt: string;
    id: string;
  }>;
  invited?: Array<{
    email: string;
    id: string;
    serviceId: string;
    role: Role;
  }>;
  appSettings: {
    androidPackageName?: null | string;
    sha256CertFingerprints?: null | Array<string>;
    androidGooglePlayLink?: null | string;
    iosBundleId?: null | string;
    iosTeamId?: null | string;
    iosAppStoreLink?: null | string;
  } | null;
  subDomainName: string | null;
  domainName: string | null;
  domainVerifiedRecord: string;
  isDomainApproved: boolean;
  serviceRiskParameters: IServiceRiskParameters;
  fingerprintRules: IFingerprintRules;
  type: ServiceType;
}

export interface IUserData {
  id: string;
  name: string;
  email: string;
  userId?: string;
  role: string;
  emailVerified: boolean;
  createdAt: string;
}

export enum IMemberStatus {
  Accepted = "Accepted",
  Invited = "Invited",
}

export interface IServiceMember {
  id: string;
  role: Role;
  name: string;
  email: string;
  status: IMemberStatus;
  edited: boolean;
}

export interface IChangeUserRole {
  serviceId: string;
  role: Role;
}

export interface IGetServiceResponse {
  service: IService;
}

export interface IUpdateServiceFingerprintParametersResponse {
  fingerprintRules: Array<IServiceRule>;
}

export enum AllowedParametersValues {
  Allow = "allow",
  Warn = "warn",
  Deny = "deny",
}

export enum FingerprintRiskParams {
  Allow = "allow",
  Warn = "warn",
  Deny = "deny",
}

export interface IServiceRiskParameters {
  warnDistance: number | null;
  denyDistance: number | null;
  countryDifferential: AllowedParametersValues;
  browserTorIP: AllowedParametersValues;
  browserKnownAttackerIP: AllowedParametersValues;
  browserAnonymousProxy: AllowedParametersValues;
  id: string;
  serviceId: string;
  denyRootedJailbrokenDevice: boolean;
  denyEmulatorDetection: boolean;
  warnNewBrowserDetection: boolean;
}

export interface IPeriod {
  from: number;
  to?: number;
}

export interface ILogsQueryParams {
  page?: number;
  type?: string;
  selectedPeriod: TimePeriodsList;
  from?: number;
  to?: number;
  searchQuery?: string;
  amberfloMeter?: MeterApiNames;
  chartResults?: Array<FingerprintRiskParams>;
  chartEvents?: Array<FingerprintLogEvents>;
}

export interface IFingerprintQueryParams {
  page?: number;
  eventTypes?: Array<string>;
  selectedPeriod: TimePeriodsList;
  from?: number;
  to?: number;
  riskFilters?: Array<string>;
  signalFilters?: Array<string>;
  mode: FingerprintModes;
  searchUserField: string;
}

export interface IGetFingerprintDataRequest {
  userId?: string;
  serviceId: string;
  limit: number;
  skip: number;
  eventTypes: Array<FingerprintLogEvents>;
  riskFilters: Array<FingerprintRiskParams>;
  signalFilters: Array<FingerprintSignals>;
  periodQuery: IPeriod;
}

export interface IGetEventDetailsRequest {
  serviceId: string;
  eventId: string;
}

export interface IGetFingerprintServiceRecordsRequest {
  serviceId: string;
  limit: number;
  skip: number;
  search?: string;
  filter?: string;
  listType?: ListType;
  outcome?: Array<OutcomeValues>;
}

export interface IDeleteServiceRecords {
  serviceId: string;
  recordId: string;
}

export interface IChangeServiceRecords {
  serviceId: string;
  recordId: string;
  outcome: OutcomeValues;
}

export interface IChangeFingerprintRuleValue {
  oldRule?: string;
  rule: string;
  conditions?: Record<string, any>;
  outcome?: OutcomeValues | string;
  signal?: string;
  notes?: string;
  enabled?: boolean;
}

export interface ICreateServiceRecords {
  serviceId: string;
  records: Array<IFingerprintListRecord>;
  override: boolean;
}

export interface ICreateServiceRuleResponse {
  records: Array<IServiceRule>;
}

export interface IGetServiceRulesResponse {
  count: number;
  list: Array<IServiceRule>;
}

export interface IGetServiceRulesResponse {
  analyticsData: Record<
    string,
    {
      count: number;
      outcomeValues: Array<OutcomeValues>;
      latestUpdate: string;
    }
  >;
}

export interface IServiceRule {
  id: string;
  serviceId: string;
  expired_date: string | null;
  value: any;
  notes: string;
  outcome: OutcomeValues;
  list_type: ListType | FingerprintRulesList;
  updatedBy: any;
  updaterId: string;
  createdAt: string;
  updatedAt: string;
}

export interface IUserResponse {
  user: IUserData;
}

export interface IServicesResponse {
  services: IService[];
}

export interface IPlansResponse {
  services: IPlan[];
}

export interface ICreditCardsResponse {
  cards: ICreditCard[];
}

export interface ICreditCardResponse {
  card: ICreditCard;
}

export interface ISubscriptionResponse {
  subscription: ISubscription;
}
export interface IGetSubscriptionResponse {
  activePlan: IPlan;
  activeSubscription: Record<any, any>;
  latestInvoice: Record<any, any>;
}

export interface IFingerprintEventsResponse {
  count: number;
  events: Array<IFingerprintEvent>;
}

export interface IFingerprintUserDataResponse {
  userId: string;
  devicesCount: number;
  firstLog: IFingerprintEvent;
  lastLog: IFingerprintEvent;
}

export interface IFingerprintDevicesResponse {
  userId: string;
  devicesCount: number;
  devices: Array<IFingerprintDevice & { firstLog: IFingerprintEvent }>;
}

export interface IEventDetailsResponse {
  event: IFingerprintEvent;
  device: {
    id: string;
    fingerprint: string;
    tlsFingerprint: string;
    deviceParams: string;
    createdAt: string;
    updatedAt: string;
    firstEvent: IFingerprintEvent;
    lastEvent: IFingerprintEvent;
    fingerprintUsers: Array<string>;
    fingerprintLocations: Array<{ location: string; ip: string }>;
    fingerprintSignals: Array<FingerprintSignals>;
  };
  ip: {
    isp_data: { asn_name: string } | null;
    ipSignals: Array<FingerprintSignals>;
    value: string;
    location: ILocation;
  };
  user: {
    userId: string;
    userDevices: Array<string>;
    firstEvent: IFingerprintEvent;
    lastEvent: IFingerprintEvent;
    userLocations: Array<{ location: string; ip: string }>;
    userSignals: Array<FingerprintSignals>;
  };
}

export interface IFingerprintDevice {
  id: string;
  fingerprint: string;
  tlsFingerprint: string;
  cryptoCookie: string;
  deviceParams: string;
  createdAt: string;
  updatedAt: string;
  events: Array<IFingerprintEvent>;
}

export enum FingerprintLogResult {
  Success = "success",
  Fail = "fail",
  Incomplete = "incomplete",
}

export enum FingerprintSignals {
  CountryListDeny = "deny_country_list",
  CountryListWarn = "warn_country_list",
  CountryListAllow = "allow_country_list",
  IPListDeny = "deny_ip_list",
  IPListWarn = "warn_ip_list",
  IPListAllow = "allow_ip_list",
  UserIDListDeny = "deny_user_id_list",
  UserIDListWarn = "warn_user_id_list",
  UserIDListAllow = "allow_user_id_list",
  DeviceIDListDeny = "deny_device_id_list",
  DeviceIDListWarn = "warn_device_id_list",
  DeviceIDListAllow = "allow_device_id_list",
  IMEIListDeny = "deny_imei_list",
  IMEIListWarn = "warn_imei_list",
  IMEIListAllow = "allow_imei_list",
  MultipleAccountSignupsPerDevice = "multiple_account_signups_per_device",
  NewDevice = "new_device",
  MultipleAccountAccessPerDevice = "multiple_account_access_per_device",
  SuspiciousIP = "suspicious_ip",
  VpnProxy = "vpn_proxy",
  TOR = "tor",
  ImprobableTravel = "improbable_travel",
  NewIPCountry = "new_ip_country",
  MaxEventsPerTimeframe = "max_events_per_timeframe",
  EmulatorDetection = "emulator_detection",
  JailbrokenRooted = "jailbroken_rooted",
  SwizzlingDetected = "swizzling_detected",
  TamperingDetected = "tampering_detected",
  Debuggable = "debuggable",
  DangerousApps = "dangerous_apps",
  MaliciousPackages = "malicious_packages",
  NewCountry = "new_country",
  BotDetection = "bot_detection",
  HeadlessBrowser = "headless_browser",
  EstablishedUserDeviceAllow = "established_user_device_allow",
}

export interface IFingerprintEvent {
  id: string;
  event: FingerprintLogEvents;
  location: ILocation;
  ip: string;
  isp_data: { asn_name: string } | null;
  result: FingerprintLogResult;
  signals: Array<FingerprintSignals>;
  fingerprintId: string;
  applicationId: string;
  userId: string | null;
  createdAt: string;
  updatedAt: string;
  riskParams: string;
  riskStatus: FingerprintRiskParams;
  fingerprint: {
    deviceParams: string;
    createdAt: string;
    fingerprint: string;
  };
}

export interface ILocation {
  region: string;
  regionCode: string;
  regionType: string;
  city: string;
  country: string;
  countryCode: string;
  continent_name: string;
  continent_code: string;
  latitude: number;
  longitude: number;
}

export enum FingerprintLogEvents {
  Access = "access",
  Visits = "visits",
  Login = "login",
  Signup = "signup",
  AttachNewDevice = "attach_new_device",
  ProfileUpdate = "profile_update",
  PasswordReset = "password_reset",
  Withdrawal = "withdrawal",
  Deposit = "deposit",
  Purchase = "purchase",
  TestLogin = "test::login",
  TestAccess = "test::access",
  TestSignup = "test::signup",
}

export enum OutcomeValues {
  Allow = "allow",
  Warn = "warn",
  Deny = "deny",
}
