import { ChartData } from "chart.js";
import { FingerprintLogEvents, FingerprintRiskParams } from "../../utils/types";

export interface EventsState {
  groupedEventsData: ChartData<"bar", number[], string>;
  chartEvents: Array<FingerprintLogEvents>;
  chartResults: Array<FingerprintRiskParams>;
  isChartLoading: boolean;
  eventsChartError: string | null;
}

type EventsAction =
  | { type: "SET_GROUPED_EVENTS"; payload: ChartData<"bar", number[], string> }
  | { type: "SET_CHART_EVENTS"; payload: Array<FingerprintLogEvents> }
  | { type: "SET_CHART_RESULTS"; payload: Array<FingerprintRiskParams> }
  | { type: "SET_IS_CHART_LOADING"; payload: boolean }
  | { type: "SET_CHART_ERROR"; payload: string | null };

export function EventsReducer(state: EventsState, action: EventsAction): EventsState {
  switch (action.type) {
    case "SET_GROUPED_EVENTS":
      return { ...state, groupedEventsData: action.payload };
    case "SET_CHART_EVENTS":
      return { ...state, chartEvents: action.payload };
    case "SET_CHART_RESULTS":
      return { ...state, chartResults: action.payload };
    case "SET_IS_CHART_LOADING":
      return { ...state, isChartLoading: action.payload };
    case "SET_CHART_ERROR":
      return { ...state, eventsChartError: action.payload };
    default:
      return state;
  }
}
