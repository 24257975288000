import React, { FC } from "react";
import { generateRoute, Routes } from "../../utils/constants";
import { Message, UpgradePlanButton } from "./styles";
import { IAlertMessageProps } from "./props";
import { Alert } from "antd";
import { AlertProps } from "antd/lib/alert";

export const AlertMessage: FC<IAlertMessageProps & AlertProps> = ({ currentService, message, ...props }) => {
  return (
    <Alert
      message={
        <Message>
          <span>{message} </span>
          <UpgradePlanButton to={generateRoute(Routes.PlanBilling, { serviceId: currentService?.id })}>Upgrade Now</UpgradePlanButton>
        </Message>
      }
      {...props}
    />
  );
};
