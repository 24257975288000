import React, { FC, useCallback, useMemo, useState } from "react";
import { fingerprintUsersColumns } from "utils/tables-settings";
import { Table } from "antd";
import { LoaderBackground } from "components/DefaultLoader/styles";
import { DefaultLoader } from "components";
import { DIMENSIONS, generateRoute, Routes } from "utils/constants";
import { IUsersTableProps } from "./props";
import { DeviceInfo } from "../DeviceInfo";
import { SearchBlock } from "styles";
import { useNavigate } from "react-router-dom";
import { useStore } from "utils/hooks";

export const UsersTable: FC<IUsersTableProps> = ({ userData, onSearch, searchQueryValue, isEventsLoading, page, onPageChange, eventsCount, events }) => {
  const {
    services: { currentService },
  } = useStore();
  const columns = useMemo(() => {
    return fingerprintUsersColumns;
  }, []);

  const [searchQuery, setSearchQuery] = useState(searchQueryValue);
  const navigate = useNavigate();

  const handleRowDblClick = useCallback(
    (record: any) => {
      navigate(generateRoute(Routes.FingerprintLogDetails, { serviceId: currentService?.id || "", eventId: record.id }));
    },
    [navigate, currentService]
  );

  return (
    <>
      <SearchBlock
        placeholder="Search User ID"
        style={{ maxWidth: 505 }}
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        onSearch={onSearch}
      />
      <DeviceInfo userData={userData} />
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handleRowDblClick(record),
          };
        }}
        scroll={{ x: "max-content" }}
        loading={{
          spinning: isEventsLoading,
          indicator: (
            <LoaderBackground>
              <DefaultLoader />
            </LoaderBackground>
          ),
          wrapperClassName: "tableSpinner",
        }}
        pagination={{
          current: page,
          onChange: onPageChange,
          position: ["bottomCenter"],
          pageSize: DIMENSIONS.DEFAULT_SESSIONS_PER_PAGE,
          total: eventsCount,
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
        columns={columns}
        dataSource={events}
        rowClassName={"selected-row"}
      />
    </>
  );
};
