import { FC } from "react";
import { IAutocompleteProps } from "./props";
import { AutoComplete } from "antd";
import { AutoCompleteWrapper } from "./styles";

export const Autocomplete: FC<IAutocompleteProps> = ({ width, error = undefined, ...props }) => {
  return (
    <AutoCompleteWrapper fieldError={error} width={width}>
      <AutoComplete {...props} />
    </AutoCompleteWrapper>
  );
};
