import React, { FormEvent, MouseEventHandler, useCallback, useMemo } from "react";
import { IQRAuthRulesProps } from "./props";
import Icon from "@ant-design/icons";
import { ICONS } from "assets";
import {
  CancelPackageButton,
  CheckboxLabel,
  DevicesParamsAccessBlock,
  FieldTitle,
  FieldWrapper,
  HiddenInput,
  IconStyle,
  ParametersList,
  ParameterTitle,
  ParameterTitleContainer,
  ParameterValues,
  RiskForm,
  RiskInputLabel,
  RiskManagementSubTitle,
  RiskParameter,
  SavePackageButton,
} from "styles";
import { Input, KeyriSwitch } from "components";
import { InputStylePreset, IRiskDistanceForm } from "utils/types";
import { RadioOptions } from "../../RadioOptions";
import { NoWrappedBlock } from "screens/FingerprintRiskManagement/styles";
import { useStore } from "utils/hooks";
import { useFormik } from "formik";
import { message } from "antd";
import { successParamChangeMessage } from "screens/FingerprintRiskManagement/index";
import { KeyriError } from "utils/keyri-error";
import { defaultErrorHandler } from "utils/helpers";
import { riskDistanceFormValidator } from "utils/validators";
import { StraightLineRuleSwitch } from "./styles";

export const QRAuthRules = ({ canEdit }: IQRAuthRulesProps) => {
  const {
    services,
    services: { currentService },
  } = useStore();

  const riskAuthOptions = useMemo(() => {
    const { currentService } = services;
    return [
      {
        parameter: "countryDifferential",
        title: "Country differential",
        defaultValue: currentService?.serviceRiskParameters?.countryDifferential,
        icon: ICONS.COUNTRY_DIFFERENTIAL,
        enabled: true,
      },
      {
        parameter: "browserTorIP",
        title: "Browser TOR IP",
        defaultValue: currentService?.serviceRiskParameters?.browserTorIP,
        icon: ICONS.TOR_FILLED,
        enabled: true,
      },
      {
        parameter: "browserKnownAttackerIP",
        title: "Browser Known Attacker IP",
        defaultValue: currentService?.serviceRiskParameters?.browserKnownAttackerIP,
        icon: ICONS.KNOWN_ATTACKER,
        enabled: true,
      },
      {
        parameter: "browserAnonymousProxy",
        title: "Browser Anonymous Proxy",
        defaultValue: currentService?.serviceRiskParameters?.browserAnonymousProxy,
        icon: ICONS.ANONYMOUS_FILLED,
        enabled: true,
      },
    ];
  }, [services.currentService]);

  const browserDetectionOption = useMemo(() => {
    const { currentService } = services;
    return {
      parameter: "warnNewBrowserDetection",
      title: "New Browser Detection",
      defaultValue: currentService?.serviceRiskParameters?.warnNewBrowserDetection,
      icon: ICONS.DESKTOP,
      enabled: currentService?.serviceRiskParameters?.warnNewBrowserDetection,
    };
  }, [services.currentService]);

  const {
    values: { warnDistance, denyDistance },
    handleChange,
    errors,
    handleSubmit,
    handleBlur,
    touched,
    resetForm,
  } = useFormik<IRiskDistanceForm>({
    onSubmit: async () => {
      try {
        if (warnDistance > denyDistance) return message.error("Warn distance cannot be greater than deny distance");
        await services.changeRiskParameter({
          warnDistance: +warnDistance,
          denyDistance: +denyDistance,
        });
        message.success(successParamChangeMessage);
      } catch (err: unknown | KeyriError) {
        resetForm();
        defaultErrorHandler(err);
      }
    },
    initialValues: {
      warnDistance: currentService?.serviceRiskParameters?.warnDistance ? currentService.serviceRiskParameters.warnDistance.toString() : "0",
      denyDistance: currentService?.serviceRiskParameters?.denyDistance ? currentService.serviceRiskParameters.denyDistance.toString() : "0",
    },
    validate: riskDistanceFormValidator,
  });

  const cancelDistanceChanges = useCallback(() => {
    resetForm({
      values: {
        warnDistance: currentService?.serviceRiskParameters?.warnDistance ? currentService.serviceRiskParameters.warnDistance.toString() : "0",
        denyDistance: currentService?.serviceRiskParameters?.denyDistance ? currentService.serviceRiskParameters.denyDistance.toString() : "0",
      },
    });
  }, [currentService, resetForm]);

  const riskChangeHandler = useCallback(
    async (rule: string, outcome: string | number | boolean) => {
      try {
        await services.changeRiskParameter({
          [rule]: outcome,
        });
        message.success(successParamChangeMessage);
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      }
    },
    [services.changeFingerprintParameters]
  );

  const handleCheckboxChange = useCallback(
    (parameter: string) => {
      return async (event: FormEvent<HTMLFormElement>) => {
        try {
          await services.changeRiskParameter({
            [parameter]: (event.target as HTMLInputElement).checked,
          });
          message.success(successParamChangeMessage);
        } catch (err: unknown | KeyriError) {
          defaultErrorHandler(err);
        }
      };
    },
    [services]
  );

  return (
    <DevicesParamsAccessBlock>
      <RiskManagementSubTitle>QR Authentication Rules:</RiskManagementSubTitle>
      <ParametersList>
        <RiskParameter data-name={"straight-line-distance"}>
          <ParameterTitleContainer maxWidth={250} minWidth={250}>
            <Icon component={ICONS.LINE_DISTANCE} style={IconStyle} />
            <ParameterTitle style={{ lineHeight: "36px" }}>Straight-line distance</ParameterTitle>
          </ParameterTitleContainer>
          <NoWrappedBlock>
            <ParameterValues>
              <RiskForm onSubmit={handleSubmit}>
                <FieldWrapper>
                  <FieldTitle>Warn</FieldTitle>
                  <RiskInputLabel>
                    <Input
                      disabled={!canEdit}
                      onBlur={handleBlur}
                      name="warnDistance"
                      placeholder="100"
                      type="number"
                      value={warnDistance}
                      onChange={handleChange}
                      error={touched.warnDistance && errors.warnDistance ? errors.warnDistance : ""}
                      preset={InputStylePreset.NarrowPaddedForPlaceholder}
                      maxWidth={130}
                      min={0}
                      max={1000}
                    />
                  </RiskInputLabel>
                </FieldWrapper>
                <FieldWrapper>
                  <FieldTitle>Deny</FieldTitle>
                  <RiskInputLabel>
                    <Input
                      disabled={!canEdit}
                      onBlur={handleBlur}
                      name="denyDistance"
                      type="number"
                      placeholder="400"
                      value={denyDistance}
                      onChange={handleChange}
                      error={touched.denyDistance && errors.denyDistance ? errors.denyDistance : ""}
                      preset={InputStylePreset.NarrowPaddedForPlaceholder}
                      maxWidth={130}
                      min={0}
                      max={1000}
                    />
                  </RiskInputLabel>
                </FieldWrapper>
                {currentService?.serviceRiskParameters?.denyDistance !== +denyDistance ||
                currentService?.serviceRiskParameters?.warnDistance !== +warnDistance ? (
                  <>
                    <SavePackageButton
                      data-status={!Object.keys(errors).length ? "active" : "disabled"}
                      disabled={!!Object.keys(errors).length}
                      data-name={"save-straight-line-distance"}
                      onClick={handleSubmit as unknown as MouseEventHandler}
                    />
                    <CancelPackageButton data-name={"reset-straight-line-distance"} onClick={cancelDistanceChanges} />
                  </>
                ) : undefined}
              </RiskForm>
            </ParameterValues>
            <StraightLineRuleSwitch>
              <KeyriSwitch disabled={true || !canEdit} checked={true} onChange={() => {}} />
            </StraightLineRuleSwitch>
          </NoWrappedBlock>
        </RiskParameter>
        <RadioOptions disabled={!canEdit} options={riskAuthOptions} onRadioChange={riskChangeHandler} />
        <RiskParameter data-name={browserDetectionOption.parameter}>
          <ParameterTitleContainer>
            <Icon component={browserDetectionOption.icon} style={IconStyle} />
            <ParameterTitle>{browserDetectionOption.title}</ParameterTitle>
          </ParameterTitleContainer>
          <NoWrappedBlock>
            <ParameterValues>
              <RiskForm onChange={handleCheckboxChange(browserDetectionOption.parameter)}>
                <HiddenInput checked={browserDetectionOption.defaultValue} id={browserDetectionOption.parameter} type={"checkbox"} disabled={true} />
                <CheckboxLabel data-status={!canEdit} htmlFor={browserDetectionOption.parameter}>
                  Warn
                </CheckboxLabel>
              </RiskForm>
            </ParameterValues>
            <KeyriSwitch
              disabled={!canEdit}
              checked={Boolean(browserDetectionOption.enabled)}
              onChange={async (value) => {
                await riskChangeHandler(browserDetectionOption.parameter, value);
              }}
            />
          </NoWrappedBlock>
        </RiskParameter>
      </ParametersList>
    </DevicesParamsAccessBlock>
  );
};
