import { ButtonContainer, ButtonIcon, ButtonText, LabelContainer, StyledDirectLink, StyledLink } from "./styles";
import { FC } from "react";
import { IButtonWithIconProps, ILinkWithIconProps } from "./props";

export const ButtonWithIcon: FC<IButtonWithIconProps> = ({
  onClick = undefined,
  icon,
  iconSize = 13,
  iconOrder,
  text,
  maxWidth,
  height,
  centerButton,
  preset,
  to,
  disabled,
  style,
  tag = "button",
  htmlFor,
  dataName,
}) => {
  return to ? (
    <StyledLink to={to}>
      <ButtonContainer data-name={dataName} style={style} maxWidth={maxWidth} height={height} centerButton={centerButton} preset={preset}>
        {icon && (
          <ButtonIcon order={iconOrder} size={iconSize}>
            {icon}
          </ButtonIcon>
        )}
        <ButtonText>{text}</ButtonText>
      </ButtonContainer>
    </StyledLink>
  ) : tag === "button" ? (
    <ButtonContainer data-name={dataName} style={style} onClick={onClick} maxWidth={maxWidth} height={height} centerButton={centerButton} preset={preset} disabled={disabled}>
      {icon && (
        <ButtonIcon order={iconOrder} size={iconSize}>
          {icon}
        </ButtonIcon>
      )}
      <ButtonText>{text}</ButtonText>
    </ButtonContainer>
  ) : (
    <LabelContainer data-name={dataName} htmlFor={htmlFor} style={style} onClick={onClick} maxWidth={maxWidth} height={height} centerButton={centerButton} preset={preset}>
      {icon && (
        <ButtonIcon order={iconOrder} size={iconSize}>
          {icon}
        </ButtonIcon>
      )}
      <ButtonText>{text}</ButtonText>
    </LabelContainer>
  );
};

export const LinkWithIcon: FC<ILinkWithIconProps> = ({ icon, iconSize = 13, iconOrder, text, maxWidth, height, centerButton, preset, href }) => {
  return (
    <StyledDirectLink href={href} maxWidth={maxWidth}>
      <ButtonContainer maxWidth={maxWidth} height={height} centerButton={centerButton} preset={preset}>
        <ButtonIcon order={iconOrder} size={iconSize}>
          {icon}
        </ButtonIcon>
        <ButtonText>{text}</ButtonText>
      </ButtonContainer>
    </StyledDirectLink>
  );
};
