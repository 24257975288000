import React, { FC, useMemo } from "react";
import { LoaderBackground } from "components/DefaultLoader/styles";
import { DefaultLoader } from "components";
import { Table } from "antd";
import { listsColumns } from "utils/tables-settings";
import { IListsTableProps } from "./props";
import { IListsRecord } from "./types";
import { useNavigate } from "react-router";
import { InternalLinks } from "../../types";
import { useStore } from "utils/hooks";
import { observer } from "mobx-react-lite";
import { UploadForm } from "../UploadForm";

const ListsTable: FC<IListsTableProps> = ({ showUploadForm }) => {
  const {
    fingerprintList: { analyticsListsData, isRecordsLoading },
  } = useStore();
  const navigate = useNavigate();

  const initialTable: Array<IListsRecord> = [
    {
      lists_category: "Country",
      timestamp: analyticsListsData.country?.latestUpdate,
      number_of_records: analyticsListsData.country?.count,
      outcome: analyticsListsData.country?.outcomeValues,
      description: "Allow, warn, or block user access from certain countries",
      link: `..${InternalLinks.Country}`,
    },
    {
      lists_category: "IP",
      timestamp: analyticsListsData.ip?.latestUpdate,
      number_of_records: analyticsListsData.ip?.count,
      outcome: analyticsListsData.ip?.outcomeValues,
      description: "Allow, warn, or block certain IPs and locations",
      link: `..${InternalLinks.IP}`,
    },
    {
      lists_category: "User ID",
      timestamp: analyticsListsData.user_id?.latestUpdate,
      number_of_records: analyticsListsData.user_id?.count,
      outcome: analyticsListsData.user_id?.outcomeValues,
      description: "Allow, warn, or block certain User IDs",
      link: `..${InternalLinks.UserID}`,
    },
    {
      lists_category: "Device ID",
      timestamp: analyticsListsData.device_id?.latestUpdate,
      number_of_records: analyticsListsData.device_id?.count,
      outcome: analyticsListsData.device_id?.outcomeValues,
      description: "Allow, warn, or block certain devices",
      link: `..${InternalLinks.DeviceID}`,
    },
    {
      lists_category: "IMEI",
      timestamp: analyticsListsData.imei?.latestUpdate,
      number_of_records: analyticsListsData.imei?.count,
      outcome: analyticsListsData.imei?.outcomeValues,
      description: "Allow, warn, or block certain IMEI numbers",
      link: `..${InternalLinks.IMEI}`,
    },
  ];

  const columns = useMemo(() => {
    return listsColumns;
  }, []);

  return (
    <>
      {showUploadForm && <UploadForm />}
      <Table
        className={"fingerprint-lists-table"}
        loading={{
          spinning: isRecordsLoading,
          indicator: (
            <LoaderBackground>
              <DefaultLoader />
            </LoaderBackground>
          ),
          wrapperClassName: "tableSpinner",
        }}
        scroll={{
          x: "max-content",
        }}
        pagination={false}
        columns={columns}
        dataSource={initialTable}
        rowClassName={"selected-row"}
        rowKey={(record) => record.lists_category}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(record.link);
            },
          };
        }}
      />
    </>
  );
};

export const ListsTableWrapper = observer(ListsTable);
