export enum InternalLinks {
  RulesList = "/",
  CustomRuleNoCodeNew = "/custom-rule/",
  CustomRuleLowCodeEdit = "/low-code-edit/:ruleId",
  CustomRuleLowCode = "/low-code-edit",
}

export interface ICustomRuleCondition {
  condition: string;
  comparator: string | null;
  value: string;
}