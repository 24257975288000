import React, { FC, useMemo } from "react";
import { Tooltip } from "antd";
import { QuestionCircleOutlined, AndroidFilled, AppleFilled } from "@ant-design/icons";
import { AppItem, AppPropertyContainer } from "components/AdminRouteWrapper/styles";
import { IAppDetailsProps } from "./props";
import { AppListBlock, PropertyName, AppListTitle, AppListContainer, AppDetailsIcon } from "./styles";
import { LoaderBackground } from "components/DefaultLoader/styles";
import { useStore } from "utils/hooks";
import { ICONS } from "assets";
import { observer } from "mobx-react-lite";
import Icon from "@ant-design/icons";
import { BlockContainer, BlockContent, BlockTitle } from "styles";
import { ChangableField, DefaultLoader } from "..";
import { ServicesStatusesEnum } from "stores/ServicesStore";
import { Role } from "@denver23/keyri-shared";

export const AppDetailsComponent: FC<IAppDetailsProps> = ({ updateService }) => {
  const {
    services: { currentService: service, servicesStatus },
    user,
  } = useStore();

  const canEdit = useMemo(() => service?.members?.find((member) => member.id === user.id)?.UserService?.role !== Role.Viewer, [user, service?.members]);

  return (
    <>
      {servicesStatus !== ServicesStatusesEnum.Fetched && (
        <LoaderBackground>
          <DefaultLoader />
        </LoaderBackground>
      )}
      <BlockContainer>
        <BlockTitle>App Settings for QR Login</BlockTitle>
        <BlockContent>
          <AppListContainer>
            <AppListBlock>
              <AppListTitle>
                <AppleFilled />
                iOS
              </AppListTitle>
              <AppItem>
                <AppPropertyContainer>
                  <PropertyName>App Store URL</PropertyName>
                  <ChangableField
                    changeable={canEdit}
                    placeholder={"App Store URL"}
                    maxlength={150}
                    icon={<Icon component={ICONS.APP_STORE} style={AppDetailsIcon} />}
                    initialValue={service?.appSettings?.iosAppStoreLink}
                    initialMode={!service?.appSettings?.iosAppStoreLink && canEdit}
                    callback={updateService("iosAppStoreLink")}
                  />
                </AppPropertyContainer>
                <AppPropertyContainer>
                  <PropertyName>Bundle ID</PropertyName>
                  <ChangableField
                    changeable={canEdit}
                    placeholder={"Bundle ID"}
                    maxlength={100}
                    icon={<Icon component={ICONS.PACKAGE} style={AppDetailsIcon} />}
                    initialValue={service?.appSettings?.iosBundleId}
                    initialMode={!service?.appSettings?.iosBundleId && canEdit}
                    callback={updateService("iosBundleId")}
                  />
                </AppPropertyContainer>
                <AppPropertyContainer>
                  <Tooltip placement={"topLeft"} title="You can find your Team ID in the Apple Member Center under the provisioning profile">
                    <PropertyName>
                      Team ID <QuestionCircleOutlined />
                    </PropertyName>
                  </Tooltip>
                  <ChangableField
                    changeable={canEdit}
                    placeholder={"Team ID"}
                    maxlength={100}
                    icon={<Icon component={ICONS.HASHTAG} style={AppDetailsIcon} />}
                    initialValue={service?.appSettings?.iosTeamId}
                    initialMode={!service?.appSettings?.iosTeamId && canEdit}
                    callback={updateService("iosTeamId")}
                  />
                </AppPropertyContainer>
              </AppItem>
            </AppListBlock>
            <AppListBlock>
              <AppListTitle>
                <AndroidFilled />
                Android
              </AppListTitle>
              <AppItem>
                <AppPropertyContainer>
                  <PropertyName>Play Store URL</PropertyName>
                  <ChangableField
                    changeable={canEdit}
                    placeholder={"Play Store URL"}
                    maxlength={150}
                    icon={<Icon component={ICONS.PLAY_MARKET} style={AppDetailsIcon} />}
                    initialValue={service?.appSettings?.androidGooglePlayLink}
                    initialMode={!service?.appSettings?.androidGooglePlayLink && canEdit}
                    callback={updateService("androidGooglePlayLink")}
                  />
                </AppPropertyContainer>

                <AppPropertyContainer>
                  <PropertyName>Package name</PropertyName>
                  <ChangableField
                    changeable={canEdit}
                    placeholder={"Package name"}
                    maxlength={100}
                    icon={<Icon component={ICONS.PACKAGE} style={AppDetailsIcon} />}
                    initialValue={service?.appSettings?.androidPackageName}
                    initialMode={!service?.appSettings?.androidPackageName && canEdit}
                    callback={updateService("androidPackageName")}
                  />
                </AppPropertyContainer>

                <AppPropertyContainer>
                  <PropertyName>SHA256 Cert Fingerprints</PropertyName>
                  <ChangableField
                    changeable={canEdit}
                    placeholder={"SHA256 Cert Fingerprints"}
                    icon={<Icon component={ICONS.HASHTAG} style={AppDetailsIcon} />}
                    initialValue={service?.appSettings?.sha256CertFingerprints ? service?.appSettings?.sha256CertFingerprints.join(",") : ""}
                    initialMode={!service?.appSettings?.sha256CertFingerprints && canEdit}
                    callback={updateService("sha256CertFingerprints")}
                  />
                </AppPropertyContainer>
              </AppItem>
            </AppListBlock>
          </AppListContainer>
        </BlockContent>
      </BlockContainer>
    </>
  );
};

export const AppDetails = observer(AppDetailsComponent);
