import { FingerprintLogEvents, FingerprintRiskParams, FingerprintSignals, ServiceType } from "./types";
import { generateQueryString } from "./helpers";
import { COLORS } from "./colors";
import { Role } from "@denver23/keyri-shared";

export enum DimensionTypes {
  QRCodeDimension = "QR_CODE_DIMENSION",
  HeaderMediumWidth = "HEADER_MEDIUM_WIDTH",
  ListBlock = "LIST_BLOCK",
  ButtonWithSmallDimension = "BUTTON_WITH_SMALL_DIMENSION",
  WhitepeperForm = "WHITEPAPER_FORM",
  MaxContent = "MAX_CONTENT",
  MaxTabletWidth = "MAX_TABLET_WIDTH",
  MaxFlexRowWidth = "MAX_FLEX_ROW_WIDTH",
  LBreakpointWidth = "L_BREAKPOINT_WIDTH",
  DefaultInputHeight = "DEFAULT_INPUT_HEIGHT",
  SmallButtonHeight = "SMALL_BUTTON_HEIGHT",
  DefaultSmallButtonRadius = "DEFAULT_SMALL_BUTTON_RADIUS",
  ContentMaxWidth = "CONTENT_MAX_WIDTH",
  DefaultSessionsPerPage = "DEFAULT_SESSIONS_PER_PAGE",
  DefaultEventsPerPage = "DEFAULT_EVENTS_PER_PAGE",
  DefaultServiceRecordsPerPage = "DEFAULT_SERVICE_RECORDS_PER_PAGE",
  DefaultDevicesPerPage = "DEFAULT_DEVICES_PER_PAGE",
  UploadComponentSmallSize = "UPLOAD_COMPONENT_SMALL_SIZE",
}

export enum KeyriLinksTypes {
  Docs = "Docs",
  Terms = "Terms",
  ContactUs = "ContactUs",
}

export enum TimePeriodsList {
  Custom = "Custom",
  AllTime = "AllTime",
  Today = "Today",
  Yesterday = "Yesterday",
  ThisWeek = "ThisWeek",
  ThisMonth = "ThisMonth",
  ThisQuarter = "ThisQuarter",
  ThisYear = "ThisYear",
  Last7Days = "Last7Days",
  Last30Days = "Last30Days",
  LastWeek = "LastWeek",
  LastMonth = "LastMonth",
  LastQuarter = "LastQuarter",
  LastYear = "LastYear",
  Last365Days = "Last365Days",
}

export enum TimeIntervals {
  Hour = "HOUR",
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
  Year = "YEAR",
}

export enum MeterApiNames {
  ApiCalls = "ApiCalls",
  FPEvents = "fp_events",
  V3Auth = "V3AUTH",
  V3AuthUnique = "V3AUTH-Unique",
  PhoneVerificationsInitiated = "phone-verifications-initiated",
  PhoneVerificationsSuccessful = "phone-verifications-successful",
}

export enum MeterAggregations {
  Sum = "SUM",
  Count = "COUNT",
  Min = "MIN",
  Max = "MAX",
}

export const KEYRI_LINKS: Record<KeyriLinksTypes, string> = {
  Docs: "https://docs.keyri.com/",
  Terms: "https://keyri.com/terms-of-service/",
  ContactUs: "https://calendly.com/keyri-grant/keyri-demo",
};

export enum Devices {
  IOS = "IOS",
  Android = "Android",
  Unknown = "Unknown",
}

export enum LocalStorage {
  Token = "token",
  RefreshToken = "refreshToken",
  Username = "username",
}

export enum SessionStorage {
  LocationAfterLogin = "locationAfterLogin",
}

export const DIMENSIONS: Record<DimensionTypes, number> = {
  [DimensionTypes.HeaderMediumWidth]: 1144,
  [DimensionTypes.ListBlock]: 823,
  [DimensionTypes.ButtonWithSmallDimension]: 595,
  [DimensionTypes.WhitepeperForm]: 780,
  [DimensionTypes.MaxContent]: 1920,
  [DimensionTypes.MaxTabletWidth]: 1199,
  [DimensionTypes.MaxFlexRowWidth]: 1280,
  [DimensionTypes.LBreakpointWidth]: 1000,
  [DimensionTypes.DefaultInputHeight]: 51,
  [DimensionTypes.SmallButtonHeight]: 32,
  [DimensionTypes.DefaultSmallButtonRadius]: 6,
  [DimensionTypes.ContentMaxWidth]: 1440,
  [DimensionTypes.DefaultSessionsPerPage]: 50,
  [DimensionTypes.DefaultEventsPerPage]: 50,
  [DimensionTypes.DefaultServiceRecordsPerPage]: 50,
  [DimensionTypes.DefaultDevicesPerPage]: 20,
  [DimensionTypes.QRCodeDimension]: 214,
  [DimensionTypes.UploadComponentSmallSize]: 650,
};

export const COLORS_SERIES = [
  COLORS.MEDIUM_PURPLE,
  COLORS.CARNATION,
  "#5b8ff9",
  "#5ad8a6",
  "#5e7092",
  "#f6bd18",
  "#6f5efa",
  "#6ec8ec",
  "#945fb9",
  "#ff9845",
  "#299796",
  "#fe99c3",
];

export enum Routes {
  Login = "/login",
  Home = "/",
  SignUp = "/sign-up",
  QRAppearance = "/:serviceId/qr-appearance/",
  KeysAndCredentials = "/:serviceId/keys-credentials",
  PlanBilling = "/:serviceId/plan-billing",
  // ServiceRiskManagement = "/:serviceId/service-risk-management",
  AuthenticationDashboard = "/:serviceId/dashboard",
  EchoDashboard = "/:serviceId/echo-dashboard",
  AuthenticationServiceLogs = "/:serviceId/service-logs",
  FingerprintDashboard = "/:serviceId/fingerprint-dashboard",
  FingerprintLogs = "/:serviceId/fingerprint-logs/*",
  FingerprintLogDetails = "/:serviceId/fingerprint-logs/:eventId/details",
  FingerprintLists = "/:serviceId/fingerprint-lists/*",
  FingerprintRiskManagement = "/:serviceId/fingerprint-risk-management/*",
  FingerprintRiskManagementNewRule = "/:serviceId/fingerprint-risk-management/custom-rule",
  FingerprintRiskManagementEditRule = "/:serviceId/fingerprint-risk-management/low-code-edit/:ruleId",
  FingerprintRiskManagementLowCodeEditor = "/:serviceId/fingerprint-risk-management/low-code-edit",
  BillingUpgrade = "/:serviceId/billing-upgrade",
  Permissions = "/:serviceId/permissions",
  Welcome = "/welcome",
  AddApplication = "/add-application",
}

function replaceRoute(route: Routes, values: Record<string, string>) {
  let result: string = route;
  const counts = (route.match(/:/g) || []).length;
  if (Object.keys(values).length < counts) result = Routes.FingerprintLogs;
  Object.keys(values).forEach((key) => (result = result.replace(`:${key}`, values[key])));
  return result;
}

export const getMainServicePage = (type: ServiceType): Routes => {
  switch (type) {
    case ServiceType.Authentication:
      return Routes.AuthenticationDashboard;
    case ServiceType.EchoGuard:
      return Routes.EchoDashboard;
    default:
      return Routes.Home;
  }
};

export const generateRoute = (route: Routes, values: Record<string, string> = {}, queryParams: Record<string, string> = {}) => {
  let routeWithService;

  switch (route) {
    case Routes.QRAppearance:
      routeWithService = replaceRoute(Routes.QRAppearance, values);
      break;
    case Routes.KeysAndCredentials:
      routeWithService = replaceRoute(Routes.KeysAndCredentials, values);
      break;
    // case Routes.ServiceRiskManagement:
    //   routeWithService = replaceRoute(Routes.ServiceRiskManagement, values);
    //   break;
    case Routes.EchoDashboard:
      routeWithService = replaceRoute(Routes.EchoDashboard, values);
      break;
    case Routes.AuthenticationDashboard:
      routeWithService = replaceRoute(Routes.AuthenticationDashboard, values);
      break;
    case Routes.AuthenticationServiceLogs:
      routeWithService = replaceRoute(Routes.AuthenticationServiceLogs, values);
      break;
    case Routes.BillingUpgrade:
      routeWithService = replaceRoute(Routes.BillingUpgrade, values);
      break;
    case Routes.Permissions:
      routeWithService = replaceRoute(Routes.Permissions, values);
      break;
    case Routes.FingerprintDashboard:
      routeWithService = replaceRoute(Routes.FingerprintDashboard, values);
      break;
    case Routes.FingerprintLogs:
      routeWithService = replaceRoute(Routes.FingerprintLogs, values);
      break;
    case Routes.FingerprintLogDetails:
      routeWithService = replaceRoute(Routes.FingerprintLogDetails, values);
      break;
    case Routes.FingerprintRiskManagement:
      routeWithService = replaceRoute(Routes.FingerprintRiskManagement, values);
      break;
    case Routes.FingerprintRiskManagementNewRule:
      routeWithService = replaceRoute(Routes.FingerprintRiskManagementNewRule, values);
      break;
    case Routes.FingerprintRiskManagementEditRule:
      routeWithService = replaceRoute(Routes.FingerprintRiskManagementEditRule, values);
      break;
    case Routes.FingerprintRiskManagementLowCodeEditor:
      routeWithService = replaceRoute(Routes.FingerprintRiskManagementLowCodeEditor, values);
      break;
    case Routes.FingerprintLists:
      routeWithService = replaceRoute(Routes.FingerprintLists, values);
      break;
    case Routes.PlanBilling:
      routeWithService = replaceRoute(Routes.PlanBilling, values);
      break;
    default:
      routeWithService = Routes.Home;
  }
  routeWithService = routeWithService.replace("/*", "");
  if (Object.keys(queryParams).length) {
    const queryString = generateQueryString(queryParams);
    routeWithService = routeWithService + `?${queryString}`;
  }
  return routeWithService;
};

export const AMBERFLO_API = "https://app.amberflo.io";

export const env = process.env.REACT_APP_BRANCH || "dev";

enum Environments {
  Development = "dev",
  Test = "test",
  Staging = "stage",
  Production = "prod",
}
function getBaseServiceData() {
  const appBranch = process.env.REACT_APP_BRANCH;
  switch (true) {
    case appBranch === Environments.Development:
      return {
        SERVICE_ID: process.env.REACT_APP_KEYRI_SERVICE_ID || "70168584-6483-4aae-a9ff-68a1e808bdc2",
        API_URL: process.env.REACT_APP_API_URL || "https://dev-api.keyri.co",
        fingerprintUrl: "https://r50xv68e3m.execute-api.eu-central-1.amazonaws.com/dev/v1/client",
      };
    case appBranch === Environments.Test:
      return {
        SERVICE_ID: process.env.REACT_APP_KEYRI_SERVICE_ID || "70168584-6483-4aae-a9ff-68a1e808bdc2",
        API_URL: process.env.REACT_APP_API_URL || "https://test-api.keyri.co",
        fingerprintUrl: "https://r50xv68e3m.execute-api.eu-central-1.amazonaws.com/test/v1/client",
      };
    case appBranch === Environments.Staging:
      return {
        SERVICE_ID: "44df6404-cda1-44a7-ad75-62e84d619b6c",
        API_URL: "https://stage-api.keyri.co",
        fingerprintUrl: "https://r50xv68e3m.execute-api.eu-central-1.amazonaws.com/stage/v1/client",
      };
    case appBranch === Environments.Production:
      return {
        SERVICE_ID: "44df6404-cda1-44a7-ad75-62e84d619b6c",
        API_URL: "https://api.keyri.co",
        fingerprintUrl: "https://fp.keyri.com/v1/client",
      };
    default:
      return {
        SERVICE_ID: "44df6404-cda1-44a7-ad75-62e84d619b6c",
        API_URL: "https://api.keyri.co",
        fingerprintUrl: "https://fp.keyri.com/v1/client",
      };
  }
}

export const { SERVICE_ID, API_URL, fingerprintUrl } = getBaseServiceData();

export enum QrCodeTypes {
  Clear = "clear",
  MiddleLogo = "middleLogo",
  BackgroundLogo = "backgroundLogo",
  BackgroundLogoWithOpacity = "backgroundLogoWithOpacity",
}

export enum LogsTableStatusesNames {
  Incomplete = "Incomplete",
  Success = "Success",
  Yes = "Yes",
  Accept = "Accept",
  Low = "Low",
  Medium = "Medium",
  Fail = "Fail",
  Reject = "Reject",
  High = "High",
  Deny = "Deny",
  Warn = "Warn",
}

export interface ILogsTableStatus {
  text: string;
  colorStyle: string;
}

export const LogsTableStatuses: Record<LogsTableStatusesNames, ILogsTableStatus> = {
  [LogsTableStatusesNames.Success]: { text: "Success", colorStyle: "green" },
  [LogsTableStatusesNames.Yes]: { text: "Yes", colorStyle: "green" },
  [LogsTableStatusesNames.Accept]: { text: "Accept", colorStyle: "green" },
  [LogsTableStatusesNames.Low]: { text: "Low", colorStyle: "green" },
  [LogsTableStatusesNames.Medium]: { text: "Medium", colorStyle: "orange" },
  [LogsTableStatusesNames.Fail]: { text: "Fail", colorStyle: "red" },
  [LogsTableStatusesNames.Reject]: { text: "Reject", colorStyle: "red" },
  [LogsTableStatusesNames.High]: { text: "High", colorStyle: "red" },
  [LogsTableStatusesNames.Deny]: { text: "Deny", colorStyle: "over-red" },
  [LogsTableStatusesNames.Incomplete]: { text: "Incomplete", colorStyle: "orange" },
  [LogsTableStatusesNames.Warn]: { text: "Warn", colorStyle: "orange" },
};

export const LogsTableRiskStatuses: Record<FingerprintRiskParams, ILogsTableStatus> = {
  [FingerprintRiskParams.Allow]: { text: "Allow", colorStyle: "green" },
  [FingerprintRiskParams.Deny]: { text: "Deny", colorStyle: "over-red" },
  [FingerprintRiskParams.Warn]: { text: "Warn", colorStyle: "orange" },
};

export const FingerprintEventsTitles: Record<FingerprintLogEvents, string> = {
  [FingerprintLogEvents.AttachNewDevice]: "Attach Device",
  [FingerprintLogEvents.Signup]: "Signup",
  [FingerprintLogEvents.Login]: "Login",
  [FingerprintLogEvents.ProfileUpdate]: "Profile Update",
  [FingerprintLogEvents.Visits]: "Visit",
  [FingerprintLogEvents.Withdrawal]: "Withdrawal",
  [FingerprintLogEvents.Deposit]: "Deposit",
  [FingerprintLogEvents.PasswordReset]: "Password Reset",
  [FingerprintLogEvents.Purchase]: "Purchase",
  [FingerprintLogEvents.Access]: "Access",
  [FingerprintLogEvents.TestSignup]: "Test::Signup",
  [FingerprintLogEvents.TestAccess]: "Test::Access",
  [FingerprintLogEvents.TestLogin]: "Test::Login",
};

export const FingerprintSignalsTitles: Record<FingerprintSignals, string> = {
  [FingerprintSignals.DeviceIDListDeny]: "Device ID List: Deny",
  [FingerprintSignals.UserIDListDeny]: "User ID List: Deny",
  [FingerprintSignals.IMEIListDeny]: "IMEI List: Deny",
  [FingerprintSignals.IPListDeny]: "IP List: Deny",
  [FingerprintSignals.CountryListDeny]: "Country List: Deny",
  [FingerprintSignals.DeviceIDListWarn]: "Device ID List: Warn",
  [FingerprintSignals.UserIDListWarn]: "User ID List: Warn",
  [FingerprintSignals.IMEIListWarn]: "IMEI List: Warn",
  [FingerprintSignals.IPListWarn]: "IP List: Warn",
  [FingerprintSignals.CountryListWarn]: "Country List: Warn",
  [FingerprintSignals.DeviceIDListAllow]: "Device ID List: Allow",
  [FingerprintSignals.UserIDListAllow]: "User ID List: Allow",
  [FingerprintSignals.IMEIListAllow]: "IMEI List: Allow",
  [FingerprintSignals.IPListAllow]: "IP List: Allow",
  [FingerprintSignals.CountryListAllow]: "Country List: Allow",
  [FingerprintSignals.MultipleAccountSignupsPerDevice]: "Multiple Account Signups per Device",
  [FingerprintSignals.NewDevice]: "New Device",
  [FingerprintSignals.TOR]: "TOR",
  [FingerprintSignals.BotDetection]: "Bot Detection",
  [FingerprintSignals.DangerousApps]: "Dangerous Apps",
  [FingerprintSignals.Debuggable]: "Debuggable",
  [FingerprintSignals.EmulatorDetection]: "Emulator Detection",
  [FingerprintSignals.HeadlessBrowser]: "Headless Browser",
  [FingerprintSignals.ImprobableTravel]: "Improbable Travel",
  [FingerprintSignals.JailbrokenRooted]: "Jailbroken/Rooted",
  [FingerprintSignals.MaliciousPackages]: "Malicious Packages",
  [FingerprintSignals.MultipleAccountAccessPerDevice]: "Multiple Account Access per Device",
  [FingerprintSignals.VpnProxy]: "VPN / Proxy",
  [FingerprintSignals.SuspiciousIP]: "Suspicious IP",
  [FingerprintSignals.NewIPCountry]: "New IP Country",
  [FingerprintSignals.NewCountry]: "New Country",
  [FingerprintSignals.MaxEventsPerTimeframe]: "Max Events Per Timeframe",
  [FingerprintSignals.SwizzlingDetected]: "Swizzling Detected",
  [FingerprintSignals.TamperingDetected]: "Tampering Detected",
  [FingerprintSignals.EstablishedUserDeviceAllow]: "Strong User History",
};

export const FingerprintRiskTitles: Record<FingerprintRiskParams, string> = {
  [FingerprintRiskParams.Allow]: "Allow",
  [FingerprintRiskParams.Warn]: "Warn",
  [FingerprintRiskParams.Deny]: "Deny",
};

export const FingerprintSignalsColors: Record<FingerprintRiskParams | "custom", string> = {
  [FingerprintRiskParams.Allow]: "green",
  [FingerprintRiskParams.Warn]: "orange",
  [FingerprintRiskParams.Deny]: "over-red",
  custom: COLORS.SELAGO,
};

export enum LogTypes {
  All = "all",
  Success = "success",
  Fail = "fail",
}

export const WIDGET_LOGIN_URL = `/KeyriQR.html`;
export const WIDGET_ATTACH_ACCOUNT = `/KeyriQR.html`;

export const roleOrders: { [key in Role]: number } = {
  [Role.Viewer]: 0,
  [Role.Editor]: 1,
  [Role.Admin]: 2,
  [Role.Owner]: 3,
  [Role.SuperAdmin]: 4,
};

export const planNaming = {
  filterRegEx: /^Fraud [a-zA-Z0-9_]* - /,
  echoFilterRegEx: /^Echo Verify*/,
};
