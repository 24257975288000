import React from "react";
import { APICallsBlock, APICallsCount, APICallsTitle, APIStatisticBlock, BarChartWrapper } from "../../styles";
import { convertNumberToAbbreviatedString } from "utils/convert-number-to-abbreviated-string";
import { DashboardBarChart } from "components";
import { BlockContent } from "styles";
import { IBarParametersBlockProps } from "./props";

export const BarParametersBlock = ({ summaryCount, resultSet, labels, title }: IBarParametersBlockProps) => {
  return (
    <BlockContent type={"small"}>
      <APICallsBlock>
        <APIStatisticBlock>
          <APICallsTitle>{title}</APICallsTitle>
          <APICallsCount>{convertNumberToAbbreviatedString(summaryCount)}</APICallsCount>
        </APIStatisticBlock>
        <BarChartWrapper>
          <DashboardBarChart resultSet={resultSet} labels={labels} />
        </BarChartWrapper>
      </APICallsBlock>
    </BlockContent>
  );
};
