import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { getSnapshot } from "mobx-state-tree";
import { ControlBlock, ControlSubBlock, EditorCustomStyles, ParametersListBlock } from "./styles";
import { PlusOutlined } from "@ant-design/icons";
import { Button, ButtonWithIcon } from "components";
import { Alert, message } from "antd";
import CodeMirror, { EditorSelection } from "@uiw/react-codemirror";
import { useNavigate } from "react-router-dom";
import { ICustomRuleLowCodeProps } from "./props";
import { json } from "@codemirror/lang-json";
import { quietlight } from "@uiw/codemirror-theme-quietlight";
import { useParams } from "react-router";
import { autocompletion } from "@codemirror/autocomplete";
import { customFieldsAutocomplete, customOperatorsAutocomplete, defaultFieldsAutocomplete } from "utils/code-mirror";
import { Fingerprints, validate_rules } from "@denver23/keyri-shared";
import { generateRoute, Routes as RoutesConstants } from "utils/constants";
import { KeyriError } from "utils/keyri-error";
import { defaultErrorHandler } from "utils/helpers";
import { defaultFingerprintSettings } from "utils/fingerprint-helper";
import { ButtonStylePreset, ButtonWithIconStylePreset } from "utils/types";
import { useStore } from "utils/hooks";

export const CustomRuleLowCode: FC<ICustomRuleLowCodeProps> = ({ canEdit }) => {
  const {
    services,
    services: { replaceServiceCustomRules },
  } = useStore();

  const { ruleId } = useParams();

  const customRules = useMemo(
    () => getSnapshot(services).currentService?.fingerprintRules?.rules.filter((rule) => !Object.keys(defaultFingerprintSettings).includes(rule.rule ?? "")),
    [getSnapshot, services]
  );
  const [editedRules, setEditedRules] = useState<string>(JSON.stringify(customRules, undefined, 2));
  const [validationResult, setValidationResult] = useState<Fingerprints.Validation.IValidateResult | null>(null);
  const navigate = useNavigate();
  const baseCursorPosition = useMemo(() => {
    if (!ruleId || !editedRules) return 0;
    return editedRules.indexOf(ruleId) ?? 0;
  }, [ruleId, editedRules]);

  const handleNewRule = useCallback(() => {
    try {
      const rules = JSON.parse(editedRules);
      rules.push({ rule: "", conditions: {}, outcome: "allow", signal: "", notes: "" });
      setEditedRules(JSON.stringify(rules, undefined, 2));
    } catch (err: unknown) {
      defaultErrorHandler(err);
    }
  }, [editedRules, setEditedRules]);

  const handleValidateButton = useCallback(() => {
    try {
      const parsedRules = JSON.parse(editedRules.replace(/\r?\n|\r/g, ""));
      const result = validate_rules(parsedRules);
      setValidationResult(result);
    } catch (err) {
      defaultErrorHandler(err);
    }
  }, [editedRules]);

  const handleCancelButton = useCallback(() => {
    navigate({ pathname: generateRoute(RoutesConstants.FingerprintRiskManagement, { serviceId: services.currentService?.id || "" }) });
  }, [services]);

  const handleSaveButton = useCallback(async () => {
    try {
      const parsedRules = JSON.parse(editedRules.replace(/\r?\n|\r/g, ""));
      const result = validate_rules(parsedRules);
      if (!result.result) {
        setValidationResult(result);
        throw new KeyriError(994);
      }
      const rules = JSON.parse(editedRules.replace(/\r?\n|\r/g, ""));
      await replaceServiceCustomRules(rules);
      message.success("Custom rules successfully updated");
    } catch (err) {
      defaultErrorHandler(err);
    }
  }, [editedRules, replaceServiceCustomRules]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <ParametersListBlock style={{ padding: "24px 60px", maxWidth: "1200px" }}>
      <EditorCustomStyles>
        <CodeMirror
          onCreateEditor={(editor) => {
            if (!editedRules || !ruleId) return;
            editor.dispatch({ selection: EditorSelection.cursor(baseCursorPosition), scrollIntoView: true });
            editor.focus();
          }}
          basicSetup={{
            bracketMatching: true,
            closeBrackets: true,
            autocompletion: true,
            lineNumbers: true,
            highlightActiveLineGutter: true,
            highlightSpecialChars: true,
            syntaxHighlighting: true,
            highlightActiveLine: true,
            highlightSelectionMatches: true,
            closeBracketsKeymap: true,
          }}
          onChange={(code) => setEditedRules(code)}
          editable={canEdit}
          extensions={[autocompletion({ override: [customOperatorsAutocomplete, defaultFieldsAutocomplete, customFieldsAutocomplete] }), json()]}
          value={editedRules ?? ""}
          height="600px"
          theme={quietlight}
        />
      </EditorCustomStyles>
      <ControlBlock style={{ justifyContent: "space-between" }}>
        <ControlSubBlock>
          <ButtonWithIcon
            disabled={!canEdit}
            onClick={handleNewRule}
            text="Add New Rule"
            icon={<PlusOutlined />}
            maxWidth={146}
            preset={ButtonWithIconStylePreset.Small}
          />
          <ButtonWithIcon
            disabled={!canEdit}
            onClick={handleValidateButton}
            text="Validate"
            icon={<PlusOutlined />}
            maxWidth={146}
            preset={ButtonWithIconStylePreset.Small}
          />
        </ControlSubBlock>
        <ControlSubBlock>
          <Button width={66} onClick={handleSaveButton} preset={ButtonStylePreset.BaseButtonWithBorder} title="Save" disabled={!canEdit} />
          <Button width={78} onClick={handleCancelButton} preset={ButtonStylePreset.BaseButtonWithBorder} title="Cancel" />
        </ControlSubBlock>
      </ControlBlock>
      <ValidationResult validationResult={validationResult} />
    </ParametersListBlock>
  );
};

const ValidationResult: FC<{ validationResult: Fingerprints.Validation.IValidateResult | null }> = ({ validationResult }) => {
  const getValidationMessage: () => string | undefined = useCallback(() => {
    try {
      if (validationResult === null || validationResult.result) return;
      const errors = validationResult?.errors;
      if (!errors) return undefined;
      return errors.map((error) => (error.index ? `Rule ${error.index + 1}: ${error.message}` : error.message)).join("\n");
    } catch (err) {
      defaultErrorHandler(err);
    }
  }, [validationResult]);

  return (
    <>
      {validationResult && validationResult.result && <Alert message="Success" description="All rules are correct." type="success" showIcon />}
      {validationResult && !validationResult.result && <Alert message="Error" description={getValidationMessage()} type="error" showIcon />}
    </>
  );
};
