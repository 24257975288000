export const COLORS = {
  ALICE_BLUE: "#F7F9FB",
  PURPLE_HEART: "#521BBC",
  RADICAL_RED: "#FF2063",
  RED: "#FF2525",
  BLACK: "#000",
  WHITE: "#fff",
  PERSIAN_INDIGO: "#330E7A",
  ALTO: "#D6D6D6",
  MEDIUM_RED_VIOLET: "#BF299E",
  ATHENS_GRAY: "#EFF0F2",
  SILVER: "#BFBFBF",
  PORT_GORE: "#1E1D52",
  TOREA_BAY: "#0a1aae",
  BOTTICELLI: "#DDE6F1",
  ELECTRIC_VIOLET: "#6919ea",
  CINNABAR: "#EB3D3D",
  SILVER_CHALICE: "#A4A4A4",
  LAVENDER: "#E8EAF8",
  ETERNITY: "#CFCBDC",
  GREEN: "#205A12",
  CLEAR_GREEN: "#00FF00",
  DARK_RED: "#A80909",
  LIGHT_PURPLE: "#BDADDA",
  LIGHT_GRAY: "#FCFCFC",
  DARK_GRAY: "#686868",
  LIGHT_PERSIAN_INDIGO: "#7B64A5",
  FAUX_PALE_LAVENDER: "#E4D6FF",
  MERCURY: "#EAEAEA",
  DARK_BLACK_GRAY: "#DCDCE0",
  LIGHT_BLACK_GRAY: "#9B96A3",
  BLACK_GRAY: "#020101",
  SECONDARY_PURPLE: "#E5E5E5",
  DARK_PURPLE: "#777EB0",
  MEDIUM_PURPLE: "#8C5BDC",
  SCORPION: "#595959",
  GRAY: "#8C8C8C",
  SELAGO: "#F9F6FD",
  MOON_RAKER: "#CDB8F0",
  NIAGARA: "#069FA2",
  ROBINS_EGG_BLUE: "#07C7CA",
  CERULEAN: "#0A9CEB",
  NARVIK: "#F0FAF6",
  DOWNY: "#69C9A3",
  GREEN_HAZE: "#03A564",
  MINE_SHAFT: "#262626",
  GALLERY: "#F0F0F0",
  ALABASTER: "#FAFAFA",
  WHITE_LILAC: "#FCFBFE",
  CARNATION: "#EF4D52",
  PUERTO_RICO: "#52C1A3",
  BLACK_SQUEEZE: "#F7FCFB",
  ICEBERG: "#D5F1E9",
  WISP_PINK: "#FEF5F5",
  CINDERELLA: "#FCDADB",
  BRANDY_PUNCH: "#CA8E32",
  BRIDAL_HEATH: "#FFF9F0",
  PEACH: "#FEE3BA",
  RAW_SIENNA: "#CC6740",
  SAUVIGNON: "#FFF5F1",
  ROMANTIC: "#FFD2C0",
  APPLE_GREEN: '#E1F4ED',
  KUMERA: '#996B26',
  TURQUOISE: '#39D2D5',
  POLAR: '#F7FDFD',
  MOON_RAKER_LIGHT: '#F0EAFA',
  WHITE_SMOKE: '#F5F5F5',
  PURE: '#0070E0',
};
