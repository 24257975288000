import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { Routes, SessionStorage } from "../../utils/constants";
import { useStore } from "../../utils/hooks";
import HiddenRoute from "./HiddenRoute";
import { IPrivateRoute } from "./props";

const PrivateRoute: FC<IPrivateRoute> = ({ hidden, isFetch, children }) => {
  const {
    user: { isLoggedIn },
  } = useStore();

  if (!isFetch && !isLoggedIn) {
    const location = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    window.sessionStorage.setItem(SessionStorage.LocationAfterLogin, location);
  }
  return (
    <>
      {isLoggedIn ? (
        <HiddenRoute condition={!hidden}>
          {children}
        </HiddenRoute>
      ) : (
        <Navigate to={Routes.Login} />
      )}
    </>
  );
};

export default PrivateRoute;
