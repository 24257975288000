import React, { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { BillingInformation, ButtonWithIcon, ChangableField, SettingsOpenedTitle, SetupBillingPlans } from "..";
import { BillingWarnALert, BlockContainer, BlockContent, BlockTitle, InfoBillingWarnIcon } from "styles";
import { useStore } from "utils/hooks";
import { ICardData, IChangeServicePlanForm, SwitchingLogic } from "utils/types";
import { changeServicePlanFormValidator } from "utils/validators";
import { ISetupBilling, ISetupBillingCard } from "./props";
import { message } from "antd";
import { useFormik } from "formik";
import Icon, { GlobalOutlined } from "@ant-design/icons";
import { ICONS } from "assets";
import { AppPropertyContainer } from "../AdminRouteWrapper/styles";
import { AppDetailsIcon, PropertyName } from "../AppDetails/styles";
import { ApplicationPropertiesBlock, ButtonValue, PropertyStringValue, SubmitBlock } from "./styles";
import { ChangePackageUrlButton } from "../ChangableField/styles";
import { observer } from "mobx-react-lite";
import { defaultErrorHandler } from "utils/helpers";
import { useLocation } from "react-router-dom";
import { defaultPlans } from "stores/BillingStore";
import { format } from "date-fns";
import { KeyriError } from "utils/keyri-error";
import { Role } from "@denver23/keyri-shared";

export const SetupBillingComponent: FC<ISetupBilling> = ({ setIsFetch, updateService }) => {
  const {
    services: { currentService: service },
    billing: { plans, currentServicePlan, currentServiceCard, changeServicePlan, addServiceCard },
    user,
  } = useStore();

  const canEdit = useMemo(() => service?.members?.find((member) => member.id === user.id)?.UserService?.role !== Role.Viewer, [user, service?.members]);

  const [cardData, setCardData] = useState<ICardData>({
    cardNumber: "",
    cardExpiry: "",
    cardCVC: "",
    cardErrors: ["cardNumber"],
    country: "",
    zip: "",
  });
  const location = useLocation();
  const [planMode, setPlanMode] = useState<boolean>(location.hash === "#billing");
  const [billingMode, setBillingMode] = useState<boolean>(location.hash === "#billing");
  const billingRef = useRef<HTMLDivElement>(null);

  const getCard = useCallback(async () => {
    const { cardNumber, cardExpiry, cardCVC, cardErrors, country, zip } = cardData;
    if (currentServiceCard?.id && !cardNumber.length) return currentServiceCard.id;

    if (!currentServiceCard?.id && (cardErrors.length || !country || !zip)) throw new KeyriError({ code: 999, message: "Credit card is invalid." });
    const [exp_month, exp_year] = cardExpiry.split(" / ").map((value) => +value);
    const cardRequest = {
      cardNumber: cardNumber.replaceAll(" ", ""),
      exp_month,
      exp_year,
      cvc: cardCVC,
      country: cardData.country,
      zip: cardData.zip,
    };
    setIsFetch(true);
    const attachedCard = await addServiceCard({
      ...cardRequest,
      serviceId: service!.id,
    });
    setIsFetch(false);
    return attachedCard.id;
  }, [cardData, service, currentServiceCard?.id, addServiceCard, setIsFetch]);

  const {
    values: { plan },
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik<IChangeServicePlanForm>({
    onSubmit: async ({ plan }) => {
      try {
        if (!service) return;

        if (plan && plan !== currentServicePlan?.id) {
          const selectedPlan = plans.find((filter) => filter.id === plan);
          if (!selectedPlan) return message.error("Plan not found");
          let cardId;
          if (selectedPlan.requiredCard) {
            cardId = await getCard();
          }

          const newSubscription = await changeServicePlan(service.id, {
            cardId,
            planId: plan,
            switchLogic: SwitchingLogic.Immediately,
          });
          setPlanMode(false);
          setBillingMode(false);
          message.success(
            `New service plan will be active from ${format(
              newSubscription.startTimeInSeconds ? newSubscription.startTimeInSeconds * 1000 : Date.now(),
              "yyyy-MM-dd"
            )}`
          );
        } else {
          await getCard();
          setPlanMode(false);
          setBillingMode(false);
        }
      } catch (err: any) {
        defaultErrorHandler(err);
      } finally {
        setIsFetch(false);
      }
    },
    initialValues: {
      plan: currentServicePlan?.id || "",
    },
    validate: changeServicePlanFormValidator,
  });

  /* Conditions for activate upgrade button */
  const submitButtonStatus: boolean = useMemo(() => {
    const { cardErrors, country, zip } = cardData;
    let selectedPlan = plans.find((planObject) => planObject.id === plan);
    if (!selectedPlan) selectedPlan = currentServicePlan;

    const isSamePlan = plan === currentServicePlan?.id;
    const isSelectedPlanRequiredCard = !!selectedPlan?.requiredCard;

    const isCurrentPlanNonChanged = !defaultPlans.includes(currentServicePlan ? currentServicePlan.name : "Custom");

    const isCardDataEmpty = (!!cardErrors.length || !country || !zip) && !currentServiceCard?.id;

    return (
      /*(isSamePlan && !isSelectedPlanRequiredCard) ||*/
      (isCurrentPlanNonChanged && !isSamePlan) ||
      (!isSamePlan && isCardDataEmpty && isSelectedPlanRequiredCard) ||
      (isSamePlan /* && isSelectedPlanRequiredCard*/ && isCardDataEmpty)
    );
  }, [plan, cardData, currentServiceCard?.id, currentServicePlan, plans]);

  const confirmButtonTitle = useMemo(() => {
    return defaultPlans.includes(currentServicePlan?.name ? currentServicePlan.name : "Custom") ? "Upgrade" : "Update";
  }, [currentServicePlan]);

  useEffect(() => {
    if (!planMode) {
      setFieldValue("plan", currentServicePlan?.id);
    }
  }, [planMode, currentServicePlan?.id, setFieldValue]);

  useEffect(() => {
    setFieldValue("plan", currentServicePlan?.id || "");
    setBillingMode(!!currentServicePlan?.requiredCard && !currentServiceCard);
  }, [currentServicePlan, currentServiceCard, setFieldValue]);

  useLayoutEffect(() => {
    if (location.hash === "#billing")
      billingRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, [location.hash]);

  return (
    <BlockContainer ref={billingRef}>
      <BlockTitle>Setup & Billing</BlockTitle>
      <BlockContent>
        <ApplicationPropertiesBlock subDomain={service?.isDomainApproved}>
          <AppPropertyContainer>
            <PropertyName fontSize={18}>Application Name</PropertyName>
            <ChangableField
              changeable={canEdit}
              icon={<Icon component={ICONS.ADD_APPLICATION} style={AppDetailsIcon} />}
              initialValue={service?.name}
              callback={updateService("applicationName")}
            />
          </AppPropertyContainer>

          <AppPropertyContainer>
            <PropertyName fontSize={18}>Domain</PropertyName>
            <ChangableField
              changeable={canEdit}
              icon={<GlobalOutlined style={AppDetailsIcon} />}
              initialValue={service?.domainName}
              callback={updateService("domainName")}
            />
          </AppPropertyContainer>

          {service?.isDomainApproved && (
            <AppPropertyContainer>
              <PropertyName>Subdomain</PropertyName>
              <ChangableField
                changeable={false}
                icon={<GlobalOutlined style={AppDetailsIcon} />}
                initialValue={service?.subDomainName}
                callback={updateService("subdomain")}
              />
            </AppPropertyContainer>
          )}
        </ApplicationPropertiesBlock>
        <AppPropertyContainer>
          <SetupBillingPlans
            handleBlur={handleBlur}
            handleChange={handleChange}
            plan={plan}
            setBillingMode={setBillingMode}
            planMode={planMode}
            setPlanMode={setPlanMode}
          />
        </AppPropertyContainer>
        <AppPropertyContainer>
          <SetupBillingCard billingMode={billingMode} setBillingMode={setBillingMode} setCardData={setCardData} plan={plan} />
          {(billingMode || planMode) && (
            <SubmitBlock>
              <ButtonWithIcon
                onClick={handleSubmit}
                icon={<Icon component={ICONS.UPGRADE} />}
                text={confirmButtonTitle}
                type={"submit"}
                maxWidth={1176}
                disabled={submitButtonStatus}
              />
            </SubmitBlock>
          )}
        </AppPropertyContainer>
      </BlockContent>
    </BlockContainer>
  );
};

const SetupBillingCard: FC<ISetupBillingCard> = ({ billingMode, setBillingMode, setCardData, plan }) => {
  const {
    services: { currentService },
    billing: { currentServicePlan, currentServiceCard },
    user,
  } = useStore();

  const canEdit = useMemo(() => currentService?.members?.find((member) => member.id === user.id)?.UserService?.role !== Role.Viewer, [user, currentService]);

  const onBillingChange = useCallback(
    (data: ICardData) => {
      setCardData(data);
    },
    [setCardData]
  );

  return (
    <>
      <SettingsOpenedTitle condition={billingMode} title={"Billing Information"} callback={() => setBillingMode(false)} />
      {!defaultPlans.includes(currentServicePlan?.name ? currentServicePlan.name : "Custom") && !currentServiceCard && (
        <BillingWarnALert
          message={"Enter credit card information to start using Keyri API"}
          type="info"
          icon={<InfoBillingWarnIcon />}
          maxWidth={1176}
          showIcon
        />
      )}
      {!billingMode ? (
        <PropertyStringValue>
          <Icon component={ICONS.PACKAGE} style={AppDetailsIcon} />
          <ButtonValue>{currentServiceCard ? `Card Ending ${currentServiceCard.cardLast4}` : "None"}</ButtonValue>
          {/*(currentServicePlan?.requiredCard || selectedPlan?.requiredCard) && */ canEdit && <ChangePackageUrlButton onClick={() => setBillingMode(true)} />}
        </PropertyStringValue>
      ) : (
        <BillingInformation onChange={onBillingChange} />
      )}
    </>
  );
};

export const SetupBilling = observer(SetupBillingComponent);
