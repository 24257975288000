import styled from "styled-components";
import { COLORS } from "../../utils/colors";

export enum DatePickerSizesEnum {
  Large = "large",
  Medium = "medium",
  Small = "small",
}

export interface IDatePickerWrapperProps {
  size: DatePickerSizesEnum;
  maxWidth: number | undefined;
  error: boolean;
}

export const DatePickerWrapper = styled.div<IDatePickerWrapperProps>`
  ${({ maxWidth }) => !!maxWidth && `max-width: ${maxWidth}px`};

  .ant-picker {
    width: 100%;

    ${({ error }) => (error ? `border-color: ${COLORS.RADICAL_RED};` : "")}
  }

  input::placeholder {
    color: ${COLORS.SILVER} !important;
  }
`;
