import { FC, useCallback, useMemo } from "react";
import { IRecommendedSettingsProps } from "./props";
import { AllowedParametersValues, FingerprintRulesList, OutcomeValues } from "utils/types";
import { RecommendedSettingsBlock } from "../../styles";
import { defaultFingerprintSettings, findFingerprintCondition } from "utils/fingerprint-helper";
import { Checkbox } from "components";
import { COLORS } from "utils/colors";
import { ParameterTitleContainer } from "styles";

export const RecommendedSettings: FC<IRecommendedSettingsProps> = ({ disabled = false, params, serviceRiskParameters, switchToDefaultValues }) => {
  const checkAuthRiskParams = useCallback(() => {
    if (serviceRiskParameters) {
      const { Allow } = AllowedParametersValues;
      return (
        serviceRiskParameters?.warnDistance === 50 &&
        serviceRiskParameters?.denyDistance === 400 &&
        serviceRiskParameters?.countryDifferential === Allow &&
        serviceRiskParameters?.warnNewBrowserDetection === true &&
        serviceRiskParameters?.denyEmulatorDetection === true &&
        serviceRiskParameters?.denyRootedJailbrokenDevice === true &&
        serviceRiskParameters?.browserTorIP === Allow &&
        serviceRiskParameters?.browserKnownAttackerIP === Allow &&
        serviceRiskParameters?.browserAnonymousProxy === Allow
      );
    }
    return false;
  }, [serviceRiskParameters]);

  const isParamsDefault = useMemo(() => {
    const authRiskParams = checkAuthRiskParams();

    const warnAfterAttempts = {
      eventsCount:
        params.find((rule) => rule.rule === FingerprintRulesList.SignalAfterAttempts && rule.outcome === OutcomeValues.Warn)?.conditions.eventLimiterCount?.[
          "$gte"
        ] || 0,
      interval:
        params.find((rule) => rule.rule === FingerprintRulesList.SignalAfterAttempts && rule.outcome === OutcomeValues.Warn)?.conditions.interval?.["$lte"] ||
        0,
    };

    const checkUniqueUsersParam = (ruleName: string, params: any, defaultParams: any) => {
      const rule = params.find((rule: any) => rule.rule === ruleName && rule.outcome === OutcomeValues.Deny);
      const condition = findFingerprintCondition("$and", "deviceModel.users._unique", rule?.conditions)?.["deviceModel.users._unique"];
      const baseCondition = findFingerprintCondition("$and", "deviceModel.users._unique", defaultParams[ruleName].conditions)?.["deviceModel.users._unique"];

      return condition["$gte"] === baseCondition["$gte"];
    };

    const denyRegistrationsEquals = checkUniqueUsersParam(FingerprintRulesList.RegistrationsPerDevice, params, defaultFingerprintSettings);
    const denyAccountAccessPerDeviceEquals = checkUniqueUsersParam(FingerprintRulesList.AccountAccessPerDevice, params, defaultFingerprintSettings);
    const warnAfterAttemptsEquals =
      warnAfterAttempts.eventsCount === defaultFingerprintSettings[FingerprintRulesList.SignalAfterAttempts].conditions?.eventLimiterCount?.["$gte"] &&
      warnAfterAttempts.interval === defaultFingerprintSettings[FingerprintRulesList.SignalAfterAttempts].conditions?.interval?.["$lte"];
    const newDeviceDetectedEqual =
      params.find((rule) => rule.rule === FingerprintRulesList.NewDeviceDetected)?.outcome ===
      defaultFingerprintSettings[FingerprintRulesList.NewDeviceDetected].outcome;
    const appVpnProxy =
      params.find((rule) => rule.rule === FingerprintRulesList.AppVpnProxy)?.outcome === defaultFingerprintSettings[FingerprintRulesList.AppVpnProxy].outcome;
    const appTorIp =
      params.find((rule) => rule.rule === FingerprintRulesList.AppTorIp)?.outcome === defaultFingerprintSettings[FingerprintRulesList.AppTorIp].outcome;
    const appSuspiciousIp =
      params.find((rule) => rule.rule === FingerprintRulesList.AppSuspiciousIp)?.outcome ===
      defaultFingerprintSettings[FingerprintRulesList.AppSuspiciousIp].outcome;
    const improbableTravel =
      params.find((rule) => rule.rule === FingerprintRulesList.ImprobableTravel)?.outcome ===
      defaultFingerprintSettings[FingerprintRulesList.ImprobableTravel].outcome;
    const newIpCountry =
      params.find((rule) => rule.rule === FingerprintRulesList.NewIpCountry)?.outcome === defaultFingerprintSettings[FingerprintRulesList.NewIpCountry].outcome;
    const emulatedDevice =
      params.find((rule) => rule.rule === FingerprintRulesList.EmulatedDevice)?.outcome ===
      defaultFingerprintSettings[FingerprintRulesList.EmulatedDevice].outcome;
    const jailbrokenRooted =
      params.find((rule) => rule.rule === FingerprintRulesList.JailbrokenRooted)?.outcome ===
      defaultFingerprintSettings[FingerprintRulesList.JailbrokenRooted].outcome;
    const dangerousApps =
      params.find((rule) => rule.rule === FingerprintRulesList.DangerousApps)?.outcome ===
      defaultFingerprintSettings[FingerprintRulesList.DangerousApps].outcome;
    const debuggable =
      params.find((rule) => rule.rule === FingerprintRulesList.Debuggable)?.outcome === defaultFingerprintSettings[FingerprintRulesList.Debuggable].outcome;
    const botDetection =
      params.find((rule) => rule.rule === FingerprintRulesList.BotDetected)?.outcome === defaultFingerprintSettings[FingerprintRulesList.BotDetected].outcome;
    // const establishedUserDeviceAllow = !!params.find(
    //   (rule) => rule.rule === FingerprintRulesList.EstablishedUserDeviceAllow && rule.outcome === OutcomeValues.Allow
    // );
    console.log({
      denyRegistrationsEquals,
      denyAccountAccessPerDeviceEquals,
      warnAfterAttemptsEquals,
      newDeviceDetectedEqual,
      appVpnProxy,
      appTorIp,
      appSuspiciousIp,
      improbableTravel,
      newIpCountry,
      emulatedDevice,
      jailbrokenRooted,
      dangerousApps,
      debuggable,
      authRiskParams,
      botDetection,
    });

    return (
      denyRegistrationsEquals &&
      denyAccountAccessPerDeviceEquals &&
      warnAfterAttemptsEquals &&
      newDeviceDetectedEqual &&
      appVpnProxy &&
      appTorIp &&
      appSuspiciousIp &&
      improbableTravel &&
      newIpCountry &&
      emulatedDevice &&
      jailbrokenRooted &&
      dangerousApps &&
      debuggable &&
      authRiskParams &&
      botDetection // &&
      //establishedUserDeviceAllow
    );
  }, [params, serviceRiskParameters]);

  return (
    <RecommendedSettingsBlock data-name="use-recommended-settings">
      <ParameterTitleContainer>
        <Checkbox color={COLORS.GRAY} defaultChecked={isParamsDefault} disabled={isParamsDefault || disabled} onChange={switchToDefaultValues}>
          Use Recommended Settings
        </Checkbox>
      </ParameterTitleContainer>
    </RecommendedSettingsBlock>
  );
};
