import styled from "styled-components";
import { FONTS } from "../../assets";
import { COLORS } from "../../utils/colors";

export const PlanContainer = styled.div`
  position: relative;
  padding: 40px 35px;
  width: 100%;
`;

export const PlanTitle = styled.div`
  font-family: ${FONTS.RUBIK};
  margin: 0;

  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-size: 20px;
  font-weight: 700;
  font-family: ${FONTS.RUBIK_BOLD};
  letter-spacing: 4px;
  line-height: normal;
  text-transform: uppercase;
`;

export const Price = styled.div`
  font-family: ${FONTS.RUBIK_SEMI_BOLD};
  font-weight: 500;
  font-size: 48px;
  line-height: normal;
  margin: 19px 0;
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const DurationPeriod = styled.span`
  font-size: 16px;
  color: ${COLORS.GRAY};
`;

export const PlussesTitle = styled.div`
  font-size: 15px;
  line-height: 23px;
`;

export const PlussesList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 7px;

  & p:last-child {
    margin-bottom: 0;
  }
`;

export const Plus = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const PlusText = styled.span`
  font-size: 14px;
  line-height: 23px;
  color: ${COLORS.SCORPION};
`;

export const AmberflowInfoIcon = styled.img`
  width: 18px;
`;

export const ContactUs = styled.a`
  font-family: ${FONTS.RUBIK};
  color: ${COLORS.SCORPION};

  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${COLORS.MEDIUM_PURPLE};
  }
`;

export const DescriptionTitle = styled.span`
  font-family: ${FONTS.RUBIK_BOLD};
`;
