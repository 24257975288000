import styled from "styled-components";

export const LeftAlignedCell = styled.div<{ direction?: "row" | "column" }>`
  display: flex;
  justify-content: start;
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  align-items: flex-start;
  gap: 8px;

  & > .ant-alert {
    margin-left: 0;
  }
`;

export const DeleteCell = styled.div`
  display: flex;
  justify-content: center;
`;
