import { ButtonWithIconStylePreset, INewCustomRuleForm, InputStylePreset, OutcomeValues } from "utils/types";
import { DeleteCell, LeftAlignedCell } from "./screenComponents/TableTitle/styles";
import { ButtonWithIcon, Input, KeyriSelect } from "components";
import { SelectSizesEnum } from "components/Select/styles";
import { format } from "date-fns";
import { DeleteOutlined } from "@ant-design/icons";
import { ListType } from "./types";
import { countriesList } from "utils/countries-list";
import { getTitleByListType, getUrlByListType } from "utils/helpers";
import { FormattedLink } from "./styles";
import { generateRoute, Routes } from "utils/constants";
import React from "react";
import { outcomeValues } from "utils/tables-settings";
import { FormikErrors, FormikTouched } from "formik";
import { ICustomRuleCondition } from "../FingerprintRiskManagement/types";
import { FieldWrapper } from "styles";
import { Autocomplete } from "components/Autocomplete";
import { AutocompleteArray } from "utils/rules-conditions-list";
import { Tooltip } from "antd";
import { COLORS } from "utils/colors";
import { ColumnsType } from "antd/es/table";

export const baseListsColumns = ({
  changeOutcome,
  deleteFunction,
}: {
  deleteFunction: (recordId: string) => void;
  changeOutcome: (recordId: string, newValue: OutcomeValues) => void;
}) => [
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    width: 200,
    render: (value: string) => <LeftAlignedCell>{value}</LeftAlignedCell>,
  },
  {
    title: "Outcome",
    dataIndex: "outcome",
    key: "outcome",
    width: 200,
    render: (value: string, record: any, index: number) => {
      return (
        <KeyriSelect
          dataName={"outcome-selector"}
          maxWidth={148}
          size={SelectSizesEnum.Small}
          onChange={(value) => changeOutcome(record.id, value)}
          name={"outcome"}
          value={value}
          options={outcomeValues}
        />
      );
    },
  },
  {
    title: "Expiry Date",
    key: "expired_date",
    dataIndex: "expired_date",
    width: 160,
    render: (value: string) => {
      const isValidTime = Date.parse(value);
      if (isNaN(isValidTime) === true) return "NA";
      return <LeftAlignedCell>{format(new Date(value), "uuuu-MM-dd")}</LeftAlignedCell>;
    },
  },
  {
    title: "Date Updated",
    key: "updatedAt",
    dataIndex: "updatedAt",
    width: 160,
    render: (value: string) => {
      const isValidTime = Date.parse(value);
      if (isNaN(isValidTime) === true) return "None";
      return <LeftAlignedCell>{format(new Date(value), "uuuu-MM-dd")}</LeftAlignedCell>;
    },
  },
  {
    title: "Updated By",
    key: "updatedBy",
    dataIndex: "updatedBy",
    width: 200,
    render: (value: any) => <LeftAlignedCell>{value.email}</LeftAlignedCell>,
  },
  {
    title: "Delete",
    key: "delete",
    dataIndex: "id",
    width: 200,
    render: (value: string, record: any) => {
      return (
        <DeleteCell>
          <ButtonWithIcon
            dataName={"delete-record-button"}
            onClick={async () => await deleteFunction(value)}
            text="Delete"
            icon={<DeleteOutlined />}
            maxWidth={130}
            height={26}
            preset={ButtonWithIconStylePreset.RedSmall}
            disabled={false}
          />
        </DeleteCell>
      );
    },
  },
  {
    title: "Notes",
    key: "notes",
    dataIndex: "notes",
    width: 300,
    render: (value: string) => {
      return <LeftAlignedCell>{value}</LeftAlignedCell>;
    },
  },
];

export const getSearchListColumns = ({
  deleteFunction,
  changeOutcome,
  queryString,
  serviceId,
}: {
  deleteFunction: (recordId: string) => void;
  changeOutcome: (recordId: string, newValue: OutcomeValues) => void;
  queryString: string;
  serviceId: string | undefined;
}) => [
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    width: 200,
    render: (value: string, record: Record<any, any>) => {
      let formattedValue = value;
      if (record.list_type === ListType.Country) {
        const fullCountryName = countriesList.find((elem) => elem.code === record.value);
        formattedValue = fullCountryName ? fullCountryName.country : value;
      }
      return (
        <LeftAlignedCell>
          {getTitleByListType(record.list_type)}: {formattedValue}
        </LeftAlignedCell>
      );
    },
  },
  {
    title: "List",
    key: "list_type",
    dataIndex: "list_type",
    width: 140,
    render: (value: ListType) => {
      return (
        <FormattedLink to={`${generateRoute(Routes.FingerprintLists, { serviceId: serviceId || "" })}${getUrlByListType(value)}${queryString}`}>
          {value}
        </FormattedLink>
      );
    },
  },
  ...baseListsColumns({ deleteFunction, changeOutcome }).slice(1),
];

export const getNewRuleColumns = ({
  handleBlur,
  handleChange,
  errors,
  touched,
  remove,
  canEdit,
  handleSelectChange,
  setFieldTouched,
  conditions,
}: {
  handleChange: (event: any) => void;
  handleBlur: (event: any) => void;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  remove: (index: number) => void;
  canEdit: boolean;
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
  handleSelectChange: (field: string) => (value: string) => Promise<FormikErrors<INewCustomRuleForm> | void>;
  conditions: Array<ICustomRuleCondition>;
}) =>
  [
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      width: 300,
      render: (value: string, record: any, index: number) => (
        <FieldWrapper>
          <Autocomplete
            error={
              (touched.conditions as any)?.[index]?.condition && (errors.conditions as any)?.[index]?.condition
                ? (errors.conditions as any)?.[index]?.condition
                : ""
            }
            placeholder={"CONDITION"}
            width={300}
            options={AutocompleteArray}
            filterOption={true}
            dropdownMatchSelectWidth={false}
            onSelect={handleSelectChange(`conditions[${index}].condition`)}
            value={value}
            onBlur={(e) => {
              setFieldTouched(`conditions[${index}].condition`, true, true);
            }}
            disabled={!canEdit}
            onChange={handleSelectChange(`conditions[${index}].condition`)}
          />
        </FieldWrapper>
      ),
    },
    {
      title: "Comparator",
      dataIndex: "comparator",
      key: "comparator",
      width: 180,
      render: (value: string, record: any, index: number) => (
        <Tooltip
          color={COLORS.CARNATION}
          title={(touched.conditions as any)?.[index]?.comparator && (errors.conditions as any)?.[index]?.comparator}
          key={`error${index}.comparator`}
        >
          <KeyriSelect
            error={
              (touched.conditions as any)?.[index]?.comparator && (errors.conditions as any)?.[index]?.comparator
                ? (errors.conditions as any)?.[index]?.comparator
                : ""
            }
            placeholder={"COMPARATOR"}
            maxWidth={148}
            size={SelectSizesEnum.Small}
            onBlur={(e) => {
              setFieldTouched(`conditions[${index}].comparator`, true, true);
            }}
            onChange={handleSelectChange(`conditions[${index}].comparator`)}
            name={`conditions[${index}].comparator`}
            value={value}
            options={[
              { label: "$not", value: "$not" },
              { label: "$in", value: "$in" },
              { label: "$nin", value: "$nin" },
              { label: "$gte", value: "$gte" },
              { label: "$gt", value: "$gt" },
              { label: "$lte", value: "$lte" },
              { label: "$lt", value: "$lt" },
              { label: "$ne", value: "$ne" },
              { label: "$eq", value: "$eq" },
            ]}
            disabled={!canEdit}
          />
        </Tooltip>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: 300,
      render: (value: string, record: any, index: number) => (
        <FieldWrapper>
          <Input
            disabled={!canEdit}
            onBlur={handleBlur}
            name={`conditions[${index}].value`}
            value={value}
            width={300}
            placeholder={"VALUE"}
            onChange={handleChange}
            error={(touched.conditions as any)?.[index]?.value && (errors.conditions as any)?.[index]?.value ? (errors.conditions as any)?.[index]?.value : ""}
            preset={InputStylePreset.NarrowPaddedForPlaceholder}
            maxWidth={300}
          />
        </FieldWrapper>
      ),
    },
    {
      title: "Operator",
      dataIndex: "operator",
      key: "operator",
      width: 180,
      render: (value: string, record: any, index: number) => (
        <Tooltip color={COLORS.CARNATION} title={touched.operator && errors.operator} key={`error${index}.operator`}>
          {conditions.length > 1 && (
            <KeyriSelect
              error={touched.operator && errors.operator ? (errors.operator as any) : ""}
              placeholder={"$AND,$OR"}
              maxWidth={148}
              size={SelectSizesEnum.Small}
              onBlur={(e) => {
                setFieldTouched(`operator`, true, true);
              }}
              onChange={handleSelectChange(`operator`)}
              name={`operator`}
              value={value}
              options={[
                { label: "$and", value: "$and" },
                { label: "$or", value: "$or" },
              ]}
              disabled={!canEdit || index > 0}
            />
          )}
        </Tooltip>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (value: string, record: any, index: number) => {
        return index ? (
          <DeleteCell>
            <ButtonWithIcon
              dataName={"delete-record-button"}
              onClick={() => remove(index)}
              text="Delete"
              icon={<DeleteOutlined />}
              maxWidth={130}
              height={26}
              preset={ButtonWithIconStylePreset.RedSmall}
              disabled={!canEdit}
            />
          </DeleteCell>
        ) : (
          ""
        );
      },
    },
  ] as ColumnsType<any>;
