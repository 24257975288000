import React, { FC, useMemo, useState } from "react";
import { fingerprintDevicesColumns } from "../../../../utils/tables-settings";
import { Table } from "antd";
import { LoaderBackground } from "../../../../components/DefaultLoader/styles";
import { DefaultLoader } from "../../../../components";
import { DIMENSIONS } from "../../../../utils/constants";
import { IDevicesTableProps } from "./props";
import { DeviceInfo } from "../DeviceInfo";
import { SearchBlock } from "styles";

export const DevicesTable: FC<IDevicesTableProps> = ({ userData, onSearch, searchQueryValue, isEventsLoading, page, onPageChange, eventsCount, devices }) => {
  const columns = useMemo(() => {
    return fingerprintDevicesColumns;
  }, []);

  const [searchQuery, setSearchQuery] = useState(searchQueryValue);

  return (
    <>
      <SearchBlock
        placeholder="Search User ID"
        style={{ maxWidth: 505 }}
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        onSearch={onSearch}
      />
      <DeviceInfo userData={userData} />
      <Table
        scroll={{ x: "max-content" }}
        loading={{
          spinning: isEventsLoading,
          indicator: (
            <LoaderBackground>
              <DefaultLoader />
            </LoaderBackground>
          ),
          wrapperClassName: "tableSpinner",
        }}
        pagination={{
          current: page,
          onChange: onPageChange,
          position: ["bottomCenter"],
          pageSize: DIMENSIONS.DEFAULT_SESSIONS_PER_PAGE,
          total: eventsCount,
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
        columns={columns}
        dataSource={devices}
      />
    </>
  );
};
