import styled from 'styled-components';

export const LoaderBackground = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0 !important;
  bottom: 0;
  right: 0;
  left: 0 !important;
  width: 100%;
  z-index: 999;
  height: 100%;
  background-color: rgba(256, 256, 256, 0.5);
`;

export const LoaderWrapper = styled.div`
  flex-grow: 1;
  align-self: stretch;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
