import React, { FC, useCallback, useEffect } from "react";
import { Checkbox, message, Table } from "antd";
import "./TableTitle.css";
import { DeleteOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, ButtonWithIcon, Input, KeyriDatePicker, KeyriSelect } from "components";
import { DeleteCell, LeftAlignedCell } from "./styles";
import { InfoWarnIcon, WarnAlert } from "styles";
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import { SelectSizesEnum } from "components/Select/styles";
import { ITableNewRecordProps, ITableTitleProps } from "./props";
import { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import { useStore } from "utils/hooks";
import { KeyriError } from "utils/keyri-error";
import { outcomeValues } from "utils/tables-settings";
import { fingerprintListsAPI } from "utils/api";
import { defaultErrorHandler } from "utils/helpers";
import { fingerprintListTableValidator } from "utils/validators";
import { ButtonStylePreset, ButtonWithIconStylePreset, IFingerprintListRecord, InputStylePreset, OutcomeValues } from "utils/types";

const initRecordsData: Array<IFingerprintListRecord> = [{ value: "", expired_date: undefined, notes: "", outcome: OutcomeValues.Allow }];

export const TableTitle: FC<ITableTitleProps> = ({
  showErrorMessages = false,
  customValidator,
  valueField,
  inputPlaceholder,
  list_type,
  handleUploadButton,
  uploadedRecords,
}) => {
  const {
    services: { currentService: service },
    fingerprintList: { loadDataList },
  } = useStore();

  const onSubmitHandler = useCallback(async (values, { resetForm }) => {
    try {
      if (!service) throw new KeyriError(998);
      const records = values.records.map((record: IFingerprintListRecord) => ({ ...record, list_type }));
      const { override } = values;
      await fingerprintListsAPI.createNewRecords({ serviceId: service.id, records, override });
      message.success("Records successfully added. Please allow up to 30 minutes for the system to update.");
      await loadDataList(service.id);
      resetForm();
    } catch (err: unknown | KeyriError) {
      defaultErrorHandler(err);
    }
  }, []);

  const validator = useCallback((data) => {
    const errors = fingerprintListTableValidator(data);
    if (customValidator) customValidator(data, errors);
    return errors;
  }, []);

  const formik = useFormik({
    initialValues: { records: initRecordsData, override: false },
    validate: validator,
    onSubmit: onSubmitHandler,
  });

  useEffect(() => {
    if (Array.isArray(uploadedRecords)) formik.setFieldValue("records", uploadedRecords);
  }, [uploadedRecords]);

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name={"records"}
        render={({
          form: {
            setFieldValue,
            errors,
            touched,
            values: { records, override },
            handleChange,
            handleBlur,
            handleSubmit,
            ...formHelpers
          },
          pop,
          push,
          remove,
          ...arrayHelpers
        }) => (
          <>
            <Table.Summary.Row className={"fingerprint-lists-table-summary"}>
              <Table.Summary.Cell index={0}>
                <LeftAlignedCell>
                  <ButtonWithIcon
                    style={{ padding: "0px 8px" }}
                    text="Add Another"
                    icon={<PlusOutlined />}
                    maxWidth={130}
                    height={26}
                    preset={ButtonWithIconStylePreset.Small}
                    onClick={() => {
                      push({ value: "", expired_date: undefined, notes: "", outcome: OutcomeValues.Allow });
                    }}
                  />
                </LeftAlignedCell>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <LeftAlignedCell style={{ padding: "4px 0px" }}>
                  <ButtonWithIcon
                    type={"submit"}
                    style={{ padding: "0px 8px" }}
                    text="Upload CSV"
                    icon={<UploadOutlined />}
                    maxWidth={130}
                    height={26}
                    preset={ButtonWithIconStylePreset.Small}
                    onClick={handleUploadButton}
                  />
                </LeftAlignedCell>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={5} align={"left"} className={"table-title-cell-left-align"}>
                <Checkbox checked={override} name={"override"} onChange={handleChange}>
                  Override Existing List
                </Checkbox>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            {records.length
              ? records.map((value: IFingerprintListRecord, index: number) => (
                  <TableNewValue
                    key={`new-service-record-${index}`}
                    inputPlaceholder={inputPlaceholder}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    index={index}
                    record={value}
                    deleteCallback={() => remove(index)}
                    errors={errors[index]}
                    touched={(touched as any)?.records?.[index]}
                    valueField={valueField}
                    showErrorMessages={showErrorMessages}
                  />
                ))
              : ""}
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <LeftAlignedCell>
                  <Button width={66} onClick={handleSubmit} preset={ButtonStylePreset.BaseButtonWithBorder} title="Save" />
                  <Button
                    width={78}
                    onClick={() => {
                      setFieldValue("records", initRecordsData);
                    }}
                    preset={ButtonStylePreset.BaseButtonWithBorder}
                    title="Cancel"
                  />
                </LeftAlignedCell>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <LeftAlignedCell>Total New Records: {records.length === 1 ? (records[0].value ? records.length : 0) : records.length}</LeftAlignedCell>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={5} align={"left"} className={"table-title-cell-left-align"}>
                <LeftAlignedCell>Tip: Upload a CSV file to quickly add multiple records at once</LeftAlignedCell>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )}
      />
    </FormikProvider>
  );
};

const TableNewValue: FC<ITableNewRecordProps> = ({
  showErrorMessages,
  inputPlaceholder,
  onChange,
  setFieldValue,
  onBlur,
  record,
  index,
  deleteCallback,
  touched = {},
  errors = {},
  valueField,
}) => {
  const handleDateChange = useCallback(
    (date: any) => {
      const UTCMilliseconds = date?.valueOf();
      setFieldValue(`records[${index}].expired_date`, UTCMilliseconds, true);
    },
    [setFieldValue]
  );

  const handleSelectChange = useCallback(
    (field: string) => {
      return (value: string) => setFieldValue(`records[${index}].${field}`, value, true);
    },
    [index]
  );

  const disabledDate: RangePickerProps["disabledDate"] = useCallback((current) => {
    return current && current < moment().startOf("day");
  }, []);

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={1}>
        {valueField ? (
          valueField({
            maxWidth: 148,
            size: SelectSizesEnum.Small,
            onBlur: onBlur,
            onChange: handleSelectChange("value"),
            name: `records[${index}].value`,
            value: record.value || undefined,
            error: touched.value && (errors as FormikErrors<any>).value ? (errors as any).value : "",
            placeholder: inputPlaceholder,
          })
        ) : (
          <LeftAlignedCell direction={"column"}>
            <Input
              disabled={false}
              onBlur={onBlur}
              name={`records[${index}].value`}
              value={record.value}
              onChange={onChange}
              error={touched.value && (errors as FormikErrors<any>).value ? (errors as any).value : ""}
              preset={InputStylePreset.NarrowPaddedForPlaceholder}
              maxWidth={148}
              placeholder={inputPlaceholder}
            />
            {showErrorMessages && touched.value && (errors as any).value && (
              <WarnAlert message={(errors as any).value} type="info" icon={<InfoWarnIcon />} showIcon maxWidth={148} />
            )}
          </LeftAlignedCell>
        )}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={2}>
        <KeyriSelect
          maxWidth={148}
          size={SelectSizesEnum.Small}
          onBlur={onBlur}
          onChange={handleSelectChange("outcome")}
          name={`records[${index}].outcome`}
          value={record.outcome}
          options={outcomeValues}
        />
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3} colSpan={3}>
        <KeyriDatePicker
          error={!!(errors as FormikErrors<any>).expired_date}
          maxWidth={148}
          value={record.expired_date ? moment(record.expired_date).valueOf() : undefined}
          onChange={handleDateChange}
          name={"expired_date"}
          placeholder={"Expiry Date"}
          disabledDate={disabledDate}
        />
      </Table.Summary.Cell>
      <Table.Summary.Cell index={4}>
        <DeleteCell>
          <ButtonWithIcon
            onClick={deleteCallback}
            text="Delete"
            icon={<DeleteOutlined />}
            maxWidth={130}
            height={26}
            preset={ButtonWithIconStylePreset.RedSmall}
            disabled={false}
          />
        </DeleteCell>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={5}>
        <Input
          disabled={false}
          onBlur={onBlur}
          name={`records[${index}].notes`}
          value={record.notes}
          onChange={onChange}
          preset={InputStylePreset.NarrowPaddedForPlaceholder}
          maxWidth={270}
          placeholder={"Notes"}
        />
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};
