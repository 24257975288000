import React, { FC, useCallback, useEffect } from "react";
import { BlockTitle, MenuItemContainer, MenuItemContent } from "styles";
import { DashboardLineChart, KeyriSelect, Multiselect, RadioButtons, StackedBarChart } from "components";
import { ControlBlock, FlexGroup, LogChart, SubFlexGroup } from "./styles";
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from "chart.js";
import { DatePicker } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { IActivityReportScreen } from "./props";
import { FingerprintLogEvents, FingerprintRiskParams, ILogsQueryParams } from "utils/types";
import { useStore, useTabTitle } from "utils/hooks";
import { MeterApiNames, TimePeriodsList } from "utils/constants";
import { switchUTCTimeToLocale } from "utils/time-periods";
import { generateQueryString } from "utils/helpers";
import { COLORS } from "utils/colors";
import { SelectSizesEnum } from "components/Select/styles";
import { BarParametersBlock } from "./screenComponents/BarParametersBlock";
import { ChartBlock } from "./screenComponents/ChartBlock";
import { MultiselectModes } from "components/Multiselect/props";
import { useAnalyticsData } from "./hooks";

const { RangePicker } = DatePicker;

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement, BarElement);

export const ActivityReportScreen: FC<IActivityReportScreen> = () => {
  useTabTitle("Activity Report");
  const {
    services: { currentService },
  } = useStore();

  const navigate = useNavigate();
  const {
    periodQuery,
    selectedPeriod,
    handlePeriod,
    apiData,
    loginChartData,
    V3AuthData,
    chartEvents,
    chartResults,
    setChartEvents,
    setChartResults,
    groupedEventsData,
    isLoading,
    error,
    amberfloMeter,
    setAmberfloMeter,
    eventsChartError,
  } = useAnalyticsData();

  // Universal function for redirect with query params
  const navigateQuery = useCallback(
    (queryParams) => {
      const searchParams = generateQueryString(queryParams);

      navigate({ search: searchParams });
    },
    [navigate]
  );

  useEffect(() => {
    if (!currentService) return;
    const params: ILogsQueryParams = {
      selectedPeriod,
      from: +periodQuery.from,
      amberfloMeter,
      chartResults,
      chartEvents,
    };
    if (periodQuery.to) params.to = +periodQuery.to;

    navigateQuery(params);
  }, [currentService, periodQuery, selectedPeriod, amberfloMeter, chartResults, chartEvents, navigateQuery]);

  return (
    <MenuItemContainer>
      <MenuItemContent>
        <BlockTitle>Dashboard</BlockTitle>
        <FlexGroup>
          <SubFlexGroup>
            <RadioButtons
              defaultValue={selectedPeriod}
              list={[
                { value: TimePeriodsList.Last7Days, title: "1W", handler: handlePeriod(TimePeriodsList.Last7Days) },
                { value: TimePeriodsList.Last30Days, title: "1M", handler: handlePeriod(TimePeriodsList.Last30Days) },
                { value: TimePeriodsList.Last365Days, title: "1Y", handler: handlePeriod(TimePeriodsList.Last365Days) },
                { value: TimePeriodsList.Custom, title: "Custom" },
              ]}
            />
            <RangePicker
              style={{ maxWidth: "260px" }}
              picker="date"
              format="MMM D, YYYY"
              value={[
                moment(switchUTCTimeToLocale(periodQuery.from) * 1000),
                moment(periodQuery.to ? switchUTCTimeToLocale(periodQuery.to) * 1000 : Date.now()),
              ]}
              separator={<ArrowRightOutlined style={{ color: COLORS.SILVER }} />}
              disabledDate={(currentData) => currentData.isAfter(moment(Date.now()))}
              onChange={handlePeriod(TimePeriodsList.Custom)}
            />
          </SubFlexGroup>
        </FlexGroup>
        <BarParametersBlock title={"MAUS"} labels={V3AuthData.labels} resultSet={V3AuthData.resultSet} summaryCount={V3AuthData.apiCount} />
        <BarParametersBlock title={"Api Calls"} labels={apiData.labels} resultSet={apiData.resultSet} summaryCount={apiData.apiCount} />
        <ChartBlock title={"Events"} isLoading={isLoading} error={eventsChartError}>
          <>
            <ControlBlock justifyContent={"flex-end"}>
              <Multiselect
                defaultValues={chartEvents}
                mode={MultiselectModes.Multiple}
                rightPadding={85}
                key={"Event"}
                placeholder={"Filter by"}
                filterPlaceholder={"Event"}
                style={{ width: "330px" }}
                onChange={(values) => setChartEvents(values as Array<FingerprintLogEvents>)}
                options={[
                  { label: "Login", value: FingerprintLogEvents.Login },
                  { label: "Pwd reset", value: FingerprintLogEvents.PasswordReset },
                  { label: "Access", value: FingerprintLogEvents.Access },
                  { label: "Deposit", value: FingerprintLogEvents.Deposit },
                  { label: "Attach New Device", value: FingerprintLogEvents.AttachNewDevice },
                  { label: "Profile Update", value: FingerprintLogEvents.ProfileUpdate },
                  { label: "Purchase", value: FingerprintLogEvents.Purchase },
                  { label: "Signup", value: FingerprintLogEvents.Signup },
                  { label: "Withdrawal", value: FingerprintLogEvents.Withdrawal },
                ]}
              />
              <Multiselect
                defaultValues={chartResults}
                mode={MultiselectModes.Multiple}
                rightPadding={85}
                key={"Result"}
                placeholder={"Filter by"}
                filterPlaceholder={"Result"}
                style={{ width: "330px" }}
                onChange={(values) => setChartResults(values as Array<FingerprintRiskParams>)}
                options={[
                  { label: "Allow", value: FingerprintRiskParams.Allow },
                  { label: "Warn", value: FingerprintRiskParams.Warn },
                  { label: "Deny", value: FingerprintRiskParams.Deny },
                ]}
              />
            </ControlBlock>
            <LogChart>
              <StackedBarChart data={groupedEventsData} />
            </LogChart>
          </>
        </ChartBlock>
        <ChartBlock title={"Logins"} isLoading={isLoading} error={error}>
          <>
            <ControlBlock justifyContent={"flex-end"}>
              <KeyriSelect
                showPlaceholderInfinitely={true}
                size={SelectSizesEnum.Small}
                defaultValue={amberfloMeter}
                options={[
                  { label: "Login Type", value: MeterApiNames.V3Auth },
                  { label: "Fingerprint Events", value: MeterApiNames.FPEvents },
                ]}
                name={"amberfloFilter"}
                maxWidth={330}
                width={330}
                placeholder={"Filter by"}
                onChange={setAmberfloMeter}
              />
            </ControlBlock>
            <LogChart>
              <DashboardLineChart resultSet={loginChartData.resultSet} labels={loginChartData.labels} group={"riskStatus"} />
            </LogChart>
          </>
        </ChartBlock>
      </MenuItemContent>
    </MenuItemContainer>
  );
};
