import React, { FC, FormEvent, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { IFingerprintRiskManagementProps } from "./props";
import { BlockTitle, MenuItemContainer, MenuItemContent } from "styles";
import { useCurrentPath, useStore, useTabTitle } from "utils/hooks";
import { LoaderBackground } from "components/DefaultLoader/styles";
import { AlertMessage, DefaultLoader, RadioButtons } from "components";
import { RulesListScreen } from "./screenComponents/RulesListScreen";
import { Route, Routes, useNavigate } from "react-router-dom";
import { InternalLinks } from "./types";
import { CustomRuleLowCode, CustomRuleNoCode } from "./screenComponents";
import { generateRoute, Routes as RoutesConstants } from "utils/constants";
import { ServicesStatusesEnum } from "stores/ServicesStore";
import { Role } from "@denver23/keyri-shared";

export const successParamChangeMessage = "Service parameter updated. Please allow up to 30 minutes for the system to update.";

export const FingerprintRiskManagement: FC<IFingerprintRiskManagementProps> = () => {
  useTabTitle("Fingerprint Risk Management");

  const {
    services: { servicesStatus, currentService },
    billing: { isSubscriptionFetch, currentServicePlan },
    user,
  } = useStore();
  const navigate = useNavigate();
  const path = useCurrentPath();
  const tabValue = useMemo(() => {
    switch (path?.path as RoutesConstants) {
      case RoutesConstants.FingerprintRiskManagementNewRule:
        return InternalLinks.CustomRuleNoCodeNew;
      case RoutesConstants.FingerprintRiskManagementLowCodeEditor:
        return InternalLinks.CustomRuleLowCode;
      case RoutesConstants.FingerprintRiskManagementEditRule:
        return InternalLinks.CustomRuleLowCode;
      case RoutesConstants.FingerprintRiskManagement:
        return InternalLinks.RulesList;
      default:
        return InternalLinks.RulesList;
    }
  }, [path]);

  const handleTabButtons = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      switch ((event.target as any).value) {
        case InternalLinks.RulesList:
          return navigate({ pathname: generateRoute(RoutesConstants.FingerprintRiskManagement, { serviceId: currentService?.id || "" }) });
        case InternalLinks.CustomRuleNoCodeNew:
          return navigate({ pathname: generateRoute(RoutesConstants.FingerprintRiskManagementNewRule, { serviceId: currentService?.id || "" }) });
        case InternalLinks.CustomRuleLowCode:
          return navigate({ pathname: generateRoute(RoutesConstants.FingerprintRiskManagementLowCodeEditor, { serviceId: currentService?.id || "" }) });
        default:
          return navigate(generateRoute(RoutesConstants.FingerprintLogs, { serviceId: currentService?.id || "" }));
      }
    },
    [currentService]
  );

  const canEdit = useMemo<boolean>(
    () =>
      currentService?.members?.find((member) => member.id === user.id)?.UserService?.role !== Role.Viewer &&
      (!!currentServicePlan?.permissions?.includes("Risk Analytics") || !!currentServicePlan?.customPermissions?.includes("Risk Analytics")),
    [user, currentServicePlan, currentService?.members]
  );

  return (
    <MenuItemContainer>
      {(servicesStatus === ServicesStatusesEnum.Loading || isSubscriptionFetch) && (
        <LoaderBackground>
          <DefaultLoader />
        </LoaderBackground>
      )}
      <MenuItemContent>
        <BlockTitle>Risk Management</BlockTitle>
        {!currentServicePlan?.permissions?.includes("Risk Analytics") && !currentServicePlan?.customPermissions?.includes("Risk Analytics") && (
          <AlertMessage
            message={"Upgrade your plan to add and customize risk rules."}
            currentService={currentService}
            type="warning"
            showIcon
            style={{ maxWidth: "680px" }}
          />
        )}
        <RadioButtons
          defaultValue={tabValue}
          handler={handleTabButtons}
          list={[
            { value: InternalLinks.RulesList, title: "Rules List" },
            { value: InternalLinks.CustomRuleNoCodeNew, title: "No Code" },
            { value: InternalLinks.CustomRuleLowCode, title: "Low Code Editor" },
          ]}
        />
        <Routes>
          <Route path={InternalLinks.RulesList} element={<RulesListScreen canEdit={canEdit} />} />
          <Route path={InternalLinks.CustomRuleNoCodeNew} element={<CustomRuleNoCode canEdit={canEdit} />} />
          <Route path={InternalLinks.CustomRuleLowCodeEdit} element={<CustomRuleLowCode canEdit={canEdit} />} />
          <Route path={InternalLinks.CustomRuleLowCode} element={<CustomRuleLowCode canEdit={canEdit} />} />
        </Routes>
      </MenuItemContent>
    </MenuItemContainer>
  );
};

export const FingerprintRiskManagementScreen = observer(FingerprintRiskManagement);
