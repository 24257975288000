import { FC, useCallback, useEffect } from "react";
import IRegistrationQRCodeProps from "./props";
import { Iframe, QRCodeWrapper, TipText } from "./styles";
import { KeyriError } from "../../utils/keyri-error";
import { defaultErrorHandler } from "../../utils/helpers";

export const QRCode: FC<IRegistrationQRCodeProps> = ({ src, action, optionalTip, dimension }) => {
  const handlePost = useCallback(
    async (event: MessageEvent) => {
      // For make request from browser run command:
      // window.postMessage({keyri: true, data: JSON.stringify({payload: JSON.stringify({email: 'test@email.com', timestamp_nonce: 'any_timestamp', signature: 'any_signature'})}), type: 'session_validate'})
      if (
        event.data.keyri &&
        event.data.data &&
        document.location.origin === event.origin &&
        ["session_validate", "socket_error"].includes(event?.data?.type)
      ) {
        const { data } = event;

        try {
          if (!data.error) {
            action(JSON.parse(data.data), (event?.source as any)?.frameElement.dataset.src);
          } else {
            throw new Error(data.data[0].toUpperCase() + data.data.slice(1).toLowerCase());
          }
        } catch (err: unknown | KeyriError) {
          defaultErrorHandler(err);
        }
      }
    },
    [action]
  );

  const postMessageHandler = useCallback((message) => handlePost(message), [handlePost]);

  const handleIframeLoad = () => {
    window.addEventListener("message", postMessageHandler, false);
  };

  useEffect(() => {
    return () => window.removeEventListener("message", postMessageHandler, false);
  }, [postMessageHandler]);

  return (
    <QRCodeWrapper dimension={dimension - 20}>
      {optionalTip && <TipText>{optionalTip}</TipText>}
      <Iframe data-src={src} src={src} id="qr-frame" scrolling="no" dimension={dimension - 20} onLoad={handleIframeLoad} />
    </QRCodeWrapper>
  );
};
