import { COLORS } from "../../utils/colors";
import { Bar } from "react-chartjs-2";
import { FC, useMemo } from "react";
import { IDashboardBarProps } from "./props";
import { format } from "date-fns";
import { switchUTCTimeToLocale } from "../../utils/time-periods";

export const DashboardBarChart: FC<IDashboardBarProps> = ({ resultSet, labels }) => {
  const datasets = useMemo(
    () => [
      {
        label: `result`,
        data: resultSet.map((item: any) => item.value),
        backgroundColor: COLORS.MEDIUM_PURPLE,
        fill: false,
      },
    ],
    [resultSet]
  );

  const commonOptions = {
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    redraw: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    minBarLength: 2,
    maxBarThickness: datasets[0].data.length > 10 ? 5 : 30,
    scales: {
      x: {
        display: false,
        beginAtZero: true,
        stacked: true,
      },
      y: {
        display: false,
        beginAtZero: false,
        max: Math.max(...datasets[0].data) > 0 ? Math.max(...datasets[0].data) : 10,
        stacked: true,
      },
    },
  };

  const data = {
    labels: labels.map((time) => format(new Date(switchUTCTimeToLocale(time) * 1000), "MM/dd/yyyy")),
    datasets,
  };
  return <Bar data={data} options={commonOptions} />;
};
