import {
  CurrentAccount,
  CurrentAccountBlock,
  CurrentAccountIcon,
  CurrentAccountName,
  CurrentAccountTextIcon,
  SelectButtonContainer,
  ServiceSelect,
  ServiceSelectorWrapper,
  WhiteScreen,
} from "./styles";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { TraillingLine } from "../AdminPanelMenu/styles";
import { generateRoute, Routes as RoutesConstants, Routes } from "../../utils/constants";
import { ButtonWithIcon } from "../ButtonWithIcon";
import Icon from "@ant-design/icons";
import { ICONS } from "../../assets";
import { ButtonWithIconStylePreset } from "../../utils/types";
import { useCurrentPath, useStore } from "../../utils/hooks";
import { Select, Tooltip } from "antd";
import { ParameterTitle } from "../../styles";

const { Option } = Select;

export const ServiceSelectorMenu: FC<any> = () => {
  return (
    <ServiceSelectorWrapper>
      <ServiceSelector />
    </ServiceSelectorWrapper>
  );
};

export const ServiceSelector: FC<any> = () => {
  const [servicesListDropdown, setOpenList] = useState<boolean>(false);

  const {
    services: { servicesList, currentService },
  } = useStore();
  const path = useCurrentPath();

  const whiteScreenRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (e: Event) => {
      const eventNode = e.target as Node;
      if ((!whiteScreenRef.current || whiteScreenRef.current.contains(eventNode)) && whiteScreenRef.current?.getAttribute("data-open")) {
        setOpenList(false);
      }
    },
    [whiteScreenRef]
  );

  useEffect(() => {
    const handleCloseList = () => setOpenList(false);
    window.addEventListener("scroll", handleCloseList);
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("scroll", handleCloseList);
      window.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <ServiceSelect
        value={currentService ? currentService.name : ""}
        bordered={false}
        open={servicesListDropdown}
        onClick={() => setOpenList(!servicesListDropdown)}
        dropdownStyle={{ borderRadius: "10px" }}
      >
        {servicesList.map((service) => (
          <Option value={service.name} key={service.id}>
            {currentService?.id === service.id ? (
              <CurrentAccountBlock>
                {service.logo ? <CurrentAccountIcon src={service.logo} /> : <CurrentAccountTextIcon>{service.name[0]}</CurrentAccountTextIcon>}
                <CurrentAccountName>
                  <ParameterTitle maxWidth={140}>
                    <Tooltip title={service.name}>{service.name}</Tooltip>
                  </ParameterTitle>
                </CurrentAccountName>
              </CurrentAccountBlock>
            ) : (
              <CurrentAccount to={generateRoute((Routes as any)[path?.name ?? ""], { serviceId: service.id })}>
                {service.logo ? <CurrentAccountIcon src={service.logo} /> : <CurrentAccountTextIcon>{service.name[0]}</CurrentAccountTextIcon>}
                <CurrentAccountName>
                  <ParameterTitle maxWidth={140}>
                    <Tooltip title={service.name}>{service.name}</Tooltip>
                  </ParameterTitle>
                </CurrentAccountName>
              </CurrentAccount>
            )}
          </Option>
        ))}
        <Option disabled key="trailing line">
          <TraillingLine />
        </Option>
        <Option key="add new application">
          <SelectButtonContainer>
            <ButtonWithIcon
              to={`${RoutesConstants.Welcome}?redirect=false`}
              icon={<Icon component={ICONS.PLUS} />}
              text="Add Application"
              preset={ButtonWithIconStylePreset.Dashed}
            />
          </SelectButtonContainer>
        </Option>
      </ServiceSelect>
      <WhiteScreen visible={servicesListDropdown} data-open={servicesListDropdown} ref={whiteScreenRef} />
    </>
  );
};
