import styled from "styled-components";
import { DIMENSIONS } from "../../utils/constants";
import { FONTS } from '../../assets';
import { COLORS } from '../../utils/colors';

interface IApplicationProps {
  subDomain?: boolean;
}

export const ApplicationPropertiesBlock = styled.div<IApplicationProps>`
  display: grid;
  grid-template-columns: repeat(${({subDomain}) => subDomain ? 3 : 2}, 1fr);
  gap: 56px;

  @media (max-width: ${DIMENSIONS.MAX_FLEX_ROW_WIDTH}px) {
    grid-template-rows: repeat(${({subDomain}) => subDomain ? 3 : 2}, 1fr);
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const SubmitBlock = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
`;

export const PropertyStringValue = styled.div`
  display: 'flex';
  height: 51px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ButtonValue = styled.div`
  font-family: ${FONTS.RUBIK};
  font-weight: 700;
  font-size: 16px;
  color: ${COLORS.GRAY};
`;
