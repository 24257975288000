import styled from "styled-components";
import { Link } from "react-router-dom";
import { COLORS } from "../../utils/colors";

export const ListControlPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
`;

export const FormattedLink = styled(Link)`
  text-transform: capitalize;
  color: ${COLORS.MEDIUM_PURPLE};
  &:hover {
    color: ${COLORS.MEDIUM_PURPLE};
  }
`;

export const ControlButtonsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: nowrap;
`;
