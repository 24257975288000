import styled from "styled-components";
import { COLORS } from "../../../../utils/colors";
import {DIMENSIONS} from "../../../../utils/constants";

export const ParametersListBlock = styled.div`
  padding: 60px;
  max-width: 680px;

  background-color: ${COLORS.WHITE};
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.04);
  border-radius: 20px;

  @media (max-width: ${DIMENSIONS.LIST_BLOCK}px) {
    padding: 30px;
  }
`;

export const EditorCustomStyles = styled.div`
  & .cm-editor,
  & .cm-scroller {
    background-color: ${COLORS.ALICE_BLUE};
    border-radius: 16px;
  }

  & .cm-gutters {
    background-color: ${COLORS.ALICE_BLUE};
  }
`;

export const ControlSubBlock = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 8px;
`;

export const ControlBlock = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
`;
