import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { MeterApiNames, TimeIntervals, TimePeriodsList } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { fillEmptyDaysWithZero, parseQuery, validateTimeFromQuery } from "utils/helpers";
import { periodToNumberQuery, switchLocaleTimeToUTC } from "../../utils/time-periods";
import { useAmberfloRequest, useStore } from "utils/hooks";
import { amberfloRequests } from "utils/amberflo";
import { analyticsAPI } from "utils/api";
import { FingerprintLogEvents, FingerprintRiskParams } from "utils/types";
import { message } from "antd";
import { EventsReducer } from "./reducers";
import { endOfDay } from "date-fns";

interface ILoginChartData {
  resultSet: Array<any>;
  labels: Array<number>;
}

interface IApiData {
  resultSet: Array<any>;
  labels: Array<number>;
  apiCount: number;
}

interface ICurrentQueryParams {
  selectedPeriod: TimePeriodsList;
  periodQuery: { from: number; to?: number };
  amberfloMeter: MeterApiNames;
  chartEvents: Array<FingerprintLogEvents>;
  chartResults: Array<FingerprintRiskParams>;
}

export function useAnalyticsData() {
  const {
    services: { currentService },
  } = useStore();

  // Get all initializing params from query string or set default
  const location = useLocation();
  const currentQueryParams = useMemo<ICurrentQueryParams>(() => {
    const params = parseQuery(location.search);
    return {
      selectedPeriod: params.selectedPeriod ? (params.selectedPeriod as TimePeriodsList) : TimePeriodsList.Last7Days,
      periodQuery:
        params.selectedPeriod === TimePeriodsList.Custom
          ? {
              from: validateTimeFromQuery(params.from?.toString()),
              to: params.to ? validateTimeFromQuery(params.to?.toString()) : undefined,
            }
          : periodToNumberQuery(params.selectedPeriod ? params.selectedPeriod.toString() : TimePeriodsList.Last7Days),
      chartEvents: (params.chartEvents && params.chartEvents.length
        ? params.chartEvents.toString().split(",").filter((value: any) => (Object.values(FingerprintLogEvents) as Array<string>).includes(value))
        : []) as Array<FingerprintLogEvents>,
      chartResults: (params.chartResults && params.chartResults.length
        ? params.chartResults.toString().split(",").filter((value: any) => (Object.values(FingerprintRiskParams) as Array<string>).includes(value))
        : []) as Array<FingerprintRiskParams>,
      amberfloMeter: ((Object.values(MeterApiNames) as Array<string>).includes(params.amberfloMeter?.toString())
        ? params.amberfloMeter
        : MeterApiNames.V3Auth) as MeterApiNames,
    };
  }, [location.search]);

  const [loginChartData, setLoginChartData] = useState<ILoginChartData>({
    resultSet: [],
    labels: [],
  });
  const [V3AuthData, setV3AuthData] = useState<IApiData>({
    resultSet: [],
    labels: [],
    apiCount: 0,
  });
  const [apiData, setApiData] = useState<IApiData>({
    resultSet: [],
    labels: [],
    apiCount: 0,
  });
  const [selectedPeriod, setSelectedPeriod] = useState<TimePeriodsList>(currentQueryParams.selectedPeriod);
  const [periodQuery, setPeriodQuery] = useState<{ from: number; to?: number }>(currentQueryParams.periodQuery);

  const [{ groupedEventsData, chartEvents, chartResults, eventsChartError, isChartLoading }, eventsDispatch] = useReducer(EventsReducer, {
    groupedEventsData: { labels: [], datasets: [] },
    chartEvents: currentQueryParams.chartEvents,
    chartResults: currentQueryParams.chartResults,
    isChartLoading: false,
    eventsChartError: null,
  });

  const [amberfloMeter, setAmberfloMeter] = useState<MeterApiNames>(currentQueryParams.amberfloMeter);

  const { isLoading, tokenError, requestError } = useAmberfloRequest({
    requests: [
      {
        body: amberfloRequests.getGroupingDataRequest({
          meterApiType: amberfloMeter,
          periodQuery: periodQuery,
          groupBy: "accepted",
        }),
        callback: (item) => {
          const sortedItems = item.clientMeters.sort((firstElem: any, secondElem: any) => (firstElem.group.groupInfo.accepted === "true" ? -1 : 1));
          setLoginChartData({
            resultSet: sortedItems,
            labels: item.secondsSinceEpochIntervals,
          });
        },
      },
      {
        body: amberfloRequests.getGroupingDataRequest({
          meterApiType: MeterApiNames.V3AuthUnique,
          periodQuery: periodQuery,
          timeGroupingInterval: TimeIntervals.Day,
        }),
        callback: (item) => {
          const resultSet = item.clientMeters[0]
            ? item.clientMeters[0].values
            : Array(item.secondsSinceEpochIntervals.length).fill({
                value: 0,
              });
          setV3AuthData({
            ...apiData,
            resultSet,
            apiCount: item.clientMeters[0] ? item.clientMeters[0].groupValue : 0,
            labels: item.secondsSinceEpochIntervals,
          });
        },
      },
      {
        body: amberfloRequests.getGroupingDataRequest({
          meterApiType: MeterApiNames.FPEvents,
          periodQuery: periodQuery,
          timeGroupingInterval: TimeIntervals.Day,
        }),
        callback: (item) => {
          const resultSet = item.clientMeters[0]
            ? item.clientMeters[0].values
            : Array(item.secondsSinceEpochIntervals.length).fill({
                value: 0,
              });
          setApiData({
            ...apiData,
            resultSet,
            apiCount: item.clientMeters[0] ? item.clientMeters[0].groupValue : 0,
            labels: item.secondsSinceEpochIntervals,
          });
        },
      },
    ],
    currentService,
    periodQuery,
  });

  const handlePeriod = useCallback((field: TimePeriodsList) => {
    return (value: any) => {
      setSelectedPeriod(field);
      switch (field) {
        case TimePeriodsList.Custom: {
          const to = switchLocaleTimeToUTC(Math.floor(endOfDay(value[1].unix() * 1000).getTime() / 1000));
          const calculatedEndDate = to * 1000 > Date.now() ? Math.floor(Date.now() / 1000) : to;
          setPeriodQuery({
            from: switchLocaleTimeToUTC(+value[0].format("X")),
            to: calculatedEndDate,
          });
          break;
        }
        default: {
          setPeriodQuery(periodToNumberQuery(field));
        }
      }
    };
  }, []);

  useEffect(() => {
    if (!currentService) return;
    const params = {
      events: chartEvents,
      results: chartResults,
      period: { ...periodQuery },
    };

    params.period.from = +periodQuery.from * 1000;
    if (periodQuery.to) params.period.to = +periodQuery.to * 1000;

    eventsDispatch({ type: "SET_IS_CHART_LOADING", payload: true });
    analyticsAPI
      .getServiceFingerprintAnalytics(currentService.id, params)
      .then(({ data: { data } }) => {
        const resultData = fillEmptyDaysWithZero(data, new Date(periodQuery.from * 1000), new Date(periodQuery.to ? periodQuery.to * 1000 : Date.now()));
        const labels = [
          ...new Set(
            resultData.map((item: any) => {
              const date = new Date(`${item.date}T12:00:00Z`);
              return date.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit" });
            })
          ),
        ];
        const datasets = [
          {
            label: "Allow",
            data: resultData.map((item: any) => +item.allowCount),
            backgroundColor: "#A37CE3",
          },
          {
            label: "Warn",
            data: resultData.map((item: any) => +item.warnCount),
            backgroundColor: "#CDB8F0",
          },
          {
            label: "Deny",
            data: resultData.map((item: any) => +item.denyCount),
            backgroundColor: "#432C69",
          },
        ];
        eventsDispatch({
          type: "SET_GROUPED_EVENTS",
          payload: {
            labels,
            datasets,
          },
        });
      })
      .catch((err) => {
        message.error(err.message);
        eventsDispatch({ type: "SET_CHART_ERROR", payload: err.message });
      })
      .finally(() => {
        eventsDispatch({ type: "SET_IS_CHART_LOADING", payload: false });
      });
  }, [currentService?.id, selectedPeriod, chartEvents, periodQuery, chartResults]);

  return {
    isLoading: isLoading || isChartLoading,
    error: requestError,
    eventsChartError,
    selectedPeriod,
    periodQuery,
    apiData,
    V3AuthData,
    loginChartData,
    handlePeriod,
    chartEvents,
    chartResults,
    setChartEvents: (values: Array<FingerprintLogEvents>) => eventsDispatch({ type: "SET_CHART_EVENTS", payload: values }),
    setChartResults: (values: Array<FingerprintRiskParams>) => eventsDispatch({ type: "SET_CHART_RESULTS", payload: values }),
    groupedEventsData,
    amberfloMeter,
    setAmberfloMeter,
  };
}
