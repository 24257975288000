import React, { FC, useCallback, useMemo } from "react";
import { LoaderBackground } from "components/DefaultLoader/styles";
import { DefaultLoader } from "components";
import { DIMENSIONS, generateRoute, Routes } from "utils/constants";
import { Table } from "antd";
import { IEventsTableProps } from "./props";
import { fingerprintEventsColumns } from "utils/tables-settings";
import { useNavigate } from "react-router-dom";
import { useStore } from "utils/hooks";

export const EventsTable: FC<IEventsTableProps> = ({ isEventsLoading, page, onPageChange, eventsCount, events }) => {
  const navigate = useNavigate();

  const {
    services: { currentService },
  } = useStore();

  const handleRowDblClick = useCallback(
    (record: any) => {
      navigate(generateRoute(Routes.FingerprintLogDetails, { serviceId: currentService?.id || "", eventId: record.id }));
    },
    [navigate, currentService]
  );

  return (
    <Table
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => handleRowDblClick(record),
        };
      }}
      scroll={{ x: "max-content" }}
      loading={{
        spinning: isEventsLoading,
        indicator: (
          <LoaderBackground>
            <DefaultLoader />
          </LoaderBackground>
        ),
        wrapperClassName: "tableSpinner",
      }}
      pagination={{
        current: page,
        onChange: onPageChange,
        position: ["bottomCenter"],
        pageSize: DIMENSIONS.DEFAULT_SESSIONS_PER_PAGE,
        total: eventsCount,
        showSizeChanger: false,
        hideOnSinglePage: true,
      }}
      columns={fingerprintEventsColumns}
      dataSource={events}
      rowClassName={"selected-row"}
    />
  );
};
