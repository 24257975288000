import { FC, useEffect, useMemo } from "react";
import { AdminContentWrapper, ContentWrapper, GeneralWrapper } from "./styles";
import { useStore } from "../../utils/hooks";
import { observer } from "mobx-react-lite";
import PrivateRoute from "../Routes/PrivateRoute";
import { useLocation, useNavigate, useParams } from "react-router";
import { generateRoute, Routes } from "../../utils/constants";
import { DefaultLoader } from "../DefaultLoader";
import { LoaderBackground } from "../DefaultLoader/styles";
import { IAdminRouteWrapperProps } from "./props";
import { AdminPanelHeader, AdminPanelMenu, ServiceSelectorMenu } from "..";
import { getDefaultServiceScreen, parseQuery } from "../../utils/helpers";
import { ServicesStatusesEnum } from "../../stores/ServicesStore";

const AdminRouteComponent: FC<IAdminRouteWrapperProps> = ({
  children,
  hidden = false,
  isMenuHidden = false,
  backgroundImage = false,
  isFetch,
  handleModal,
  ...props
}) => {
  const navigate = useNavigate();
  const { serviceId } = useParams();
  const location = useLocation();
  const redirect = useMemo<boolean>(() => (parseQuery(location.search).redirect === "false" ? false : true), [location.search]);

  const {
    user: { isLoggedIn },
    services,
    services: { currentService, servicesList, clearCurrentService, servicesStatus },
    billing: { getCurrentServicePlan, isPlansFetch },
  } = useStore();

  const isLoading = useMemo(() => isFetch || servicesStatus !== ServicesStatusesEnum.Fetched || isPlansFetch, [isFetch, servicesStatus, isPlansFetch]);

  useEffect(() => {
    if (!isLoggedIn && !isFetch) navigate(Routes.Login);

    // 1. Do nothing before data don't loaded
    if (!isLoggedIn || isFetch || servicesStatus !== ServicesStatusesEnum.Fetched) return;
    // 2. Do nothing if it default screens
    if (
      (location.pathname as Routes) === Routes.AddApplication ||
      (location.pathname === Routes.Welcome && (!redirect || !servicesList || servicesList.length === 0))
    )
      return;
    // 3. Redirect to default screen if services array is empty
    if (!servicesList || servicesList.length === 0) return navigate({ pathname: Routes.Welcome, search: location.search });

    // 4. Change current service or ignore any additional request if serviceId didn't changed
    if (serviceId && servicesList.findIndex((service) => service.id === serviceId) !== -1) {
      if (currentService?.id === serviceId) return;
      services.setCurrentService(serviceId);
      getCurrentServicePlan(serviceId);
      return;
    }
    // 5. Reset page if it something unexists and redirect to first service from services array
    clearCurrentService();
    navigate(generateRoute(getDefaultServiceScreen(servicesList[0].type), { serviceId: servicesList[0].id }));
  }, [isLoggedIn, servicesList, servicesStatus, isFetch, serviceId, location.pathname, getCurrentServicePlan, navigate, redirect]);

  return (
    <GeneralWrapper backgroundImage={backgroundImage}>
      <AdminPanelHeader handleModal={handleModal} />
      <AdminContentWrapper>
        {!isMenuHidden && <AdminPanelMenu />}

        {isLoading ? (
          <LoaderBackground>
            <DefaultLoader />
          </LoaderBackground>
        ) : (
          <ContentWrapper menuVisible={!isMenuHidden}>
            {!isMenuHidden && <ServiceSelectorMenu />}
            <PrivateRoute hidden={hidden} isFetch={isFetch} {...props}>
              {children}
            </PrivateRoute>
          </ContentWrapper>
        )}
      </AdminContentWrapper>
    </GeneralWrapper>
  );
};

export const AdminRouteWrapper = observer(AdminRouteComponent);
