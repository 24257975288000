import styled from "styled-components";
import { FONTS } from "../../assets";
import { COLORS } from "../../utils/colors";
import { ButtonWithIconStylePreset } from "../../utils/types";
import { Link } from "react-router-dom";
import { DIMENSIONS, DimensionTypes } from "../../utils/constants";

export interface IButtonContainerProps {
  maxWidth?: number;
  type?: "submit" | "button" | "reset" | undefined;
  preset?: ButtonWithIconStylePreset;
  height?: number;
  centerButton?: boolean;
}

export interface IIconOrderProps {
  order?: number;
  size: number;
}

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  & > div {
    margin: 0;
  }

  :link {
    text-decoration: none;
    color: ${COLORS.PURPLE_HEART};
  }
  :visited {
    text-decoration: none;
    color: ${COLORS.PURPLE_HEART};
  }
`;

export const StyledDirectLink = styled.a<IButtonContainerProps>`
  display: flex;
  align-items: center;
  transition: all 0.5s linear;
  background-color: ${COLORS.SELAGO};
  max-width: ${({ maxWidth }) => (!!maxWidth ? `${maxWidth}px` : "100%")};

  & > div {
    margin: 0;
  }

  &:link {
    text-decoration: none;
    color: ${COLORS.PURPLE_HEART};
  }
  &:visited {
    text-decoration: none;
    color: ${COLORS.PURPLE_HEART};
  }
`;

export const BaseButtonStyles = `
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s linear;
`;

export const BasePseudo = `
  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: 0.5;
    border-color: #D9D9D9;
    background-color: #F5F5F5;
    color: ${COLORS.SILVER};
  }
  
  &:hover {
    background: none;
  }
  
  &:focus {
    border: 1px solid ${COLORS.MEDIUM_PURPLE};
    box-shadow: 0px 0px 6px rgba(140, 91, 220, 0.4);
    outline: none;
  }
`;

export const PurplePseudoStyles = `
  &:disabled {
    background-color: ${COLORS.LIGHT_PURPLE} !important;
    color: ${COLORS.LIGHT_PURPLE}  !important;
  }
`;

export const BaseSizes = `
  height: ${DIMENSIONS[DimensionTypes.DefaultInputHeight]}px;
  width: 100%;
  margin: 0 auto;
  gap: 8px;
  font-family: ${FONTS.RUBIK};
  border-radius: ${DIMENSIONS[DimensionTypes.DefaultSmallButtonRadius]}px;
`;

export const BaseColors = `
  color: ${COLORS.MEDIUM_PURPLE};
  background: ${COLORS.SELAGO};
  border: 1px solid ${COLORS.MEDIUM_PURPLE};
`;

function getButtonStyles({ preset = ButtonWithIconStylePreset.Default, maxWidth, height, centerButton }: IButtonContainerProps) {
  switch (preset) {
    case ButtonWithIconStylePreset.Default:
      return `
          ${BaseButtonStyles};
          ${BaseColors};
          ${BaseSizes};
          ${BasePseudo};
          max-width: ${maxWidth ? `${maxWidth}px` : "100%"};
          height: ${height ? `${height}px` : `${DIMENSIONS[DimensionTypes.SmallButtonHeight]}px`};
          margin: ${centerButton ? "0 auto" : "0"};
          font-size: 14px;
        `;

    case ButtonWithIconStylePreset.Dashed:
      return `
          ${BaseButtonStyles};
          ${BaseColors};
          ${BaseSizes};
          ${BasePseudo};
          max-width: ${maxWidth ? `${maxWidth}px` : "100%"};
          height: 46px;
          font-size: 15px;
          color: ${COLORS.GRAY};
          border: 1px dashed ${COLORS.GRAY};
        `;
    case ButtonWithIconStylePreset.Small:
      return `
        ${BaseButtonStyles};
        ${BaseColors};
        ${BaseSizes};
        ${BasePseudo};
          max-width: ${maxWidth ? `${maxWidth}px` : "100%"};
          height: ${height ? `${height}px` : `${DIMENSIONS[DimensionTypes.SmallButtonHeight]}px`};
          padding: 0 16px;
          font-size: 14px;
          margin: 0;
        `;
    case ButtonWithIconStylePreset.GreenSmall:
      return `
          ${BaseButtonStyles};
          ${BaseColors};
          ${BaseSizes};
          ${BasePseudo};
          width: 160px;
          height: 32px;
          padding: 0 12px;
          font-size: 14px;
          margin: 0;
          
          color: ${COLORS.GREEN_HAZE};
          background-color: ${COLORS.APPLE_GREEN};
          border: 1px solid ${COLORS.GREEN_HAZE};
        `;
    case ButtonWithIconStylePreset.RedSmall:
      return `
          ${BaseButtonStyles};
          ${BaseColors};
          ${BaseSizes};
          ${BasePseudo};
          width: 160px;
          max-width: ${maxWidth ? `${maxWidth}px` : "100%"};
          height: ${height ? `${height}px` : `${DIMENSIONS[DimensionTypes.SmallButtonHeight]}px`};
          padding: 0 12px;
          font-size: 14px;
          margin: 0;
          
          color: ${COLORS.CARNATION};
          background-color: ${COLORS.WISP_PINK};
          border: 1px solid ${COLORS.CINDERELLA};
        `;
    case ButtonWithIconStylePreset.GreenDashed:
      return `
          ${BaseButtonStyles};
          ${BaseColors};
          ${BaseSizes};
          ${BasePseudo};
          width: 100%;
          padding: 0 9px 0 24px;
          margin: 0;
          
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 24px;
          
          font-weight: 800;
          font-size: 14px;
          line-height: 17px;
          color: ${COLORS.NIAGARA};
          border: 1px dashed ${COLORS.TURQUOISE};
          border-radius: 8px;
          background-color: ${COLORS.POLAR};
        `;
    case ButtonWithIconStylePreset.Borderless:
      return `
          ${BaseButtonStyles};
          ${BaseColors};
          ${BaseSizes};
          ${BasePseudo};
          border: none;
          box-shadow: none;
          height: ${height ? `${height}px` : `${DIMENSIONS[DimensionTypes.DefaultInputHeight]}px`};
          padding: 0 10px;
        `;
  }
}

export const ButtonContainer = styled.button<IButtonContainerProps>(getButtonStyles);
export const LabelContainer = styled.label<IButtonContainerProps>(getButtonStyles);

export const ButtonIcon = styled.div<IIconOrderProps>`
  ${({ order }) => (order ? `order: ${order}` : "")};
  font-size: ${({ size }) => `${size}px`};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonText = styled.div`
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
