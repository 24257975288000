import React, { FC, useCallback, useMemo } from "react";
import { InfoCircleFilled, PlusOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { COLORS } from "utils/colors";
import { ButtonStylePreset, ButtonWithIconStylePreset } from "utils/types";
import { Button, ButtonWithIcon, KeyriSwitch } from "components";
import { message, Tooltip } from "antd";
import { ControlButtons } from "./styles";
import { useStore } from "utils/hooks";
import { observer } from "mobx-react-lite";
import { defaultFingerprintSettings, getEnabledValue } from "utils/fingerprint-helper";
import { generateRoute, Routes as RoutesConstants } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { ICustomRulesViewProps } from "./props";
import { NoWrappedBlock } from "screens/FingerprintRiskManagement/styles";
import {
  DevicesParamsAccessBlock,
  ParametersList,
  ParameterTitle,
  ParameterTitleContainer,
  ParameterValues,
  RiskManagementSubTitle,
  RiskParameter,
} from "styles";

const CustomRulesViewComponent: FC<ICustomRulesViewProps> = ({ canEdit, handleEnableSwitch }) => {
  const {
    services: { currentService, removeFingerprintRule },
  } = useStore();
  const navigate = useNavigate();
  const customRules = useMemo(() => {
    const defaultRulesNames = Object.keys(defaultFingerprintSettings);
    return currentService?.fingerprintRules?.rules?.toJSON()
      ? currentService?.fingerprintRules?.rules?.toJSON().filter((rule) => !defaultRulesNames.includes(rule?.rule ? rule.rule : "custom") && rule.rule)
      : [];
  }, [currentService]);

  const handleRemoveRuleButton = useCallback(
    async (ruleName: string) => {
      try {
        await removeFingerprintRule(ruleName);
        message.success("Rule successfully deleted");
      } catch (err: any) {
        message.error(err.message);
      }
    },
    [removeFingerprintRule]
  );

  return (
    <DevicesParamsAccessBlock>
      <RiskManagementSubTitle>Customized Risk Rules:</RiskManagementSubTitle>
      <ParametersList>
        {customRules.map((customRule) => (
          <RiskParameter>
            <ParameterTitleContainer minWidth={250} maxWidth={250}>
              <UnorderedListOutlined style={{ color: COLORS.MEDIUM_PURPLE }} />
              <ParameterTitle>{customRule.rule}</ParameterTitle>
              <Tooltip title={`${customRule.rule} - ${customRule.notes ? customRule.notes : JSON.stringify(customRule.conditions, undefined, 2)}`}>
                <InfoCircleFilled style={{ color: COLORS.MEDIUM_PURPLE }} color={COLORS.MEDIUM_PURPLE} />
              </Tooltip>
            </ParameterTitleContainer>
            <NoWrappedBlock>
              <ParameterValues style={{ gap: "9px" }}>
                <Button
                  disabled={true}
                  width={153}
                  preset={ButtonStylePreset.PurpleButtonWithBorder}
                  title={customRule.outcome.charAt(0).toUpperCase() + customRule.outcome.slice(1)}
                />
                <Button
                  onClick={() =>
                    navigate({
                      pathname: generateRoute(RoutesConstants.FingerprintRiskManagementEditRule, {
                        serviceId: currentService?.id || "",
                        ruleId: customRule?.rule || "",
                      }),
                    })
                  }
                  preset={ButtonStylePreset.SmallGreyButton}
                  title="Edit"
                />
                <Button
                  onClick={() => customRule?.rule && handleRemoveRuleButton(customRule?.rule)}
                  preset={ButtonStylePreset.SmallGreyButton}
                  title="Delete"
                />
              </ParameterValues>
              <KeyriSwitch disabled={!canEdit} checked={getEnabledValue(customRule)} onChange={handleEnableSwitch(String(customRule.rule))} />
            </NoWrappedBlock>
          </RiskParameter>
        ))}
      </ParametersList>
      <ControlButtons>
        <ButtonWithIcon
          type={"submit"}
          disabled={!canEdit}
          text="Add Rule (No Code)"
          icon={<PlusOutlined />}
          maxWidth={185}
          preset={ButtonWithIconStylePreset.Small}
          onClick={() => {
            return navigate({ pathname: generateRoute(RoutesConstants.FingerprintRiskManagementNewRule, { serviceId: currentService?.id || "" }) });
          }}
        />
        <ButtonWithIcon
          type={"submit"}
          disabled={!canEdit}
          text="Add Rule (Low Code Editor)"
          icon={<PlusOutlined />}
          maxWidth={235}
          preset={ButtonWithIconStylePreset.Small}
          onClick={() => navigate({ pathname: generateRoute(RoutesConstants.FingerprintRiskManagementLowCodeEditor, { serviceId: currentService?.id || "" }) })}
        />
      </ControlButtons>
    </DevicesParamsAccessBlock>
  );
};

export const CustomRulesView = observer(CustomRulesViewComponent);
