import { TimePeriodsList } from "./constants";
import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  previousMonday,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from "date-fns";

export interface IQueryPeriod {
  from: number;
  to?: number;
}

export enum TimeQueryFormat {
  Seconds = "seconds",
  Milliseconds = "milliseconds",
}

export const periodToNumberQuery = (period: string, format: TimeQueryFormat = TimeQueryFormat.Seconds, convertToTimezone: boolean = true): IQueryPeriod => {
  const resultQuery: { from: number; to?: number } = {
    from: Math.round(startOfDay(subDays(Date.now(), 7)).getTime() / 1000),
  };

  switch (period) {
    case TimePeriodsList.Today: {
      const from = Math.round(startOfToday().getTime() / 1000);
      resultQuery.from = from;
      break;
    }
    case TimePeriodsList.Yesterday: {
      const from = Math.round(startOfYesterday().getTime() / 1000);
      const to = Math.round(endOfYesterday().getTime() / 1000);
      resultQuery.from = from;
      resultQuery.to = to;
      break;
    }
    case TimePeriodsList.ThisWeek: {
      const from = Math.round(previousMonday(Date.now()).getTime() / 1000);
      resultQuery.from = from;
      break;
    }
    case TimePeriodsList.ThisMonth: {
      const from = Math.round(startOfMonth(Date.now()).getTime() / 1000);
      resultQuery.from = from;
      break;
    }
    case TimePeriodsList.ThisQuarter: {
      const from = Math.round(startOfQuarter(Date.now()).getTime() / 1000);
      resultQuery.from = from;
      break;
    }
    case TimePeriodsList.ThisYear: {
      const from = Math.round(startOfYear(Date.now()).getTime() / 1000);
      resultQuery.from = from;
      break;
    }
    case TimePeriodsList.Last7Days: {
      const from = Math.round(startOfDay(subDays(Date.now(), 6)).getTime() / 1000);
      resultQuery.from = from;
      break;
    }
    case TimePeriodsList.Last30Days: {
      const from = Math.round(startOfDay(subDays(Date.now(), 30)).getTime() / 1000);
      resultQuery.from = from;
      break;
    }
    case TimePeriodsList.LastWeek: {
      const from = Math.round(startOfWeek(subWeeks(Date.now(), 1)).getTime() / 1000);
      const to = Math.round(endOfWeek(subWeeks(Date.now(), 1)).getTime() / 1000);
      resultQuery.from = from;
      resultQuery.to = to;
      break;
    }
    case TimePeriodsList.LastMonth: {
      const from = Math.round(startOfMonth(subMonths(Date.now(), 1)).getTime() / 1000);
      const to = Math.round(endOfMonth(subMonths(Date.now(), 1)).getTime() / 1000);
      resultQuery.from = from;
      resultQuery.to = to;
      break;
    }
    case TimePeriodsList.LastQuarter: {
      const from = Math.round(startOfQuarter(subQuarters(Date.now(), 1)).getTime() / 1000);
      const to = Math.round(endOfQuarter(subQuarters(Date.now(), 1)).getTime() / 1000);
      resultQuery.from = from;
      resultQuery.to = to;
      break;
    }
    case TimePeriodsList.LastYear: {
      const from = Math.round(startOfYear(subYears(Date.now(), 1)).getTime() / 1000);
      const to = Math.round(endOfYear(subYears(Date.now(), 1)).getTime() / 1000);
      resultQuery.from = from;
      resultQuery.to = to;
      break;
    }
    case TimePeriodsList.Last365Days: {
      const from = Math.round(subYears(Date.now(), 1).getTime() / 1000);
      resultQuery.from = from;
      break;
    }
    default:
      return {
        from: Math.round(startOfDay(subDays(Date.now(), 7)).getTime() / 1000),
      };
  }

  if (convertToTimezone) {
    Object.keys(resultQuery).forEach((key: any) => ((resultQuery as any)[key] = switchLocaleTimeToUTC((resultQuery as any)[key])));
  }

  switch (format) {
    case TimeQueryFormat.Milliseconds: {
      Object.keys(resultQuery).forEach((key: any) => ((resultQuery as any)[key] = (resultQuery as any)[key] * 1000));
      break;
    }
    default:
      break;
  }

  return resultQuery;
};

export function switchLocaleTimeToUTC(seconds: number): number {
  const timezoneOffset: number = new Date().getTimezoneOffset() * 60;
  return seconds - timezoneOffset;
}

export function switchUTCTimeToLocale(seconds: number) {
  const timezoneOffset: number = new Date().getTimezoneOffset() * 60;
  return +seconds + +timezoneOffset;
}
