import { Line } from "react-chartjs-2";
import { FC, useMemo } from "react";
import { IDashboardLineProps, ILineValue } from "./props";
import { LineWrapper } from "./styles";
import { format } from "date-fns";
import { switchUTCTimeToLocale } from "utils/time-periods";
import { COLORS_SERIES } from "utils/constants";

export const DashboardLineChart: FC<IDashboardLineProps> = ({ resultSet, labels, group }) => {
  const datasets = useMemo(
    () =>
      resultSet.length
        ? resultSet.map(
            (s: { color?: string; group: { groupInfo: Record<string, string> }; groupValue: number; values: Array<ILineValue> }, index: number) => ({
              label: s.group.groupInfo.accepted === "true" ? "Success" : "Fail",
              data: s.values.map((item) => item.value),
              borderColor: s.color ?? COLORS_SERIES[index],
              pointRadius: 1,
              tension: 0.1,
              pointHoverRadius: 1,
              borderWidth: 2,
              tickWidth: 1,
              fill: false,
            })
          )
        : [
            {
              label: "Success",
              data: new Array(labels.length).fill(0),
              borderColor: COLORS_SERIES[0],
              pointRadius: 1,
              tension: 0.1,
              pointHoverRadius: 1,
              borderWidth: 2,
              tickWidth: 1,
              fill: false,
            },
            {
              label: "Fail",
              data: new Array(labels.length).fill(0),
              borderColor: COLORS_SERIES[1],
              pointRadius: 1,
              tension: 0.1,
              pointHoverRadius: 1,
              borderWidth: 2,
              tickWidth: 1,
              fill: false,
            },
          ],
    [resultSet, group]
  );
  const data = {
    labels: labels.map((time) => format(new Date(switchUTCTimeToLocale(time) * 1000), "MM/dd")),
    datasets,
  };
  return (
    <LineWrapper>
      <Line
        data={data}
        options={{
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
          },
          layout: {
            padding: {
              left: 28,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },
          plugins: {
            legend: {
              display: true,
              position: "bottom" as "bottom",
              align: "start" as "start",
              labels: {
                usePointStyle: true,
                pointStyle: "circle" as "circle",
                boxWidth: 7,
                padding: 32,
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: true,
                maxRotation: 0,
                padding: 12,
                minRotation: 0,
                maxTicksLimit: 7,
              },
            },
            y: {
              position: "right",
              suggestedMax: 10,
              suggestedMin: 0,
              ticks: {
                autoSkip: true,
                maxTicksLimit: 6,
                //count: 5,
                precision: 0,
              },
            },
          },
        }}
      />
    </LineWrapper>
  );
};
