import { XRAY } from "@keyri/xray";
import { FingerprintSignalsColors, fingerprintUrl } from "./constants";
import {
  FingerprintLogEvents,
  FingerprintRiskParams,
  FingerprintRulesList,
  FingerprintSignals,
  IChangeFingerprintRuleValue,
  IFingerprintRule,
  OutcomeValues,
} from "./types";
import { Operators } from "../screens/FingerprintRiskManagement/screenComponents/CustomRuleNoCode/props";
import { isJSON } from "@denver23/keyri-shared";
export const rpPublicKey = "MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEfex9n+3uUvLm9fEaqOTy4szQjP/uEwdeFu6LYhJHjuJAf0xREbMNC5kNRHVjMinh+VSAjNKPn6y2tT2wgQvFPQ==";

export interface IEvent {
  ciphertext: string;
  salt: string;
  iv: string;
  publicEncryptionKey: string;
  apiCiphertextSignature: string;
}

export function convertStringToFingerprintRuleValue(value: string) {
  if (isJSON(value)) return JSON.parse(value);
  if (["true", "false"].includes(value)) {
    return value === "true" ? true : false;
  }
  return isNaN(+value) ? value : +value;
}

export function findFingerprintCondition(operator: Operators, param: string, conditions?: Record<Operators, any>) {
  return conditions?.[operator]?.find((condition: any) => typeof condition[param] === "object");
}

export function getRuleConditions(searchedRule: string, rules?: Array<any>) {
  return rules?.find((oldRule) => oldRule.rule === searchedRule)?.conditions?.$and;
}

export const defaultFingerprintSettings: Record<Exclude<FingerprintRulesList, "established_user_device_allow">, IChangeFingerprintRuleValue> = {
  [FingerprintRulesList.RegistrationsPerDevice]: {
    conditions: {
      $and: [{ "deviceModel.users._unique": { $gte: 2 } }],
    },
    outcome: OutcomeValues.Deny,
    rule: FingerprintRulesList.RegistrationsPerDevice,
  },
  [FingerprintRulesList.AccountAccessPerDevice]: {
    conditions: {
      $and: [{ "deviceModel.users._unique": { $gte: 2 } }],
    },
    outcome: OutcomeValues.Deny,
    rule: FingerprintRulesList.AccountAccessPerDevice,
  },
  [FingerprintRulesList.SignalAfterAttempts]: {
    conditions: {
      eventLimiterCount: {
        $gte: 3,
      },
      interval: {
        $lte: 10,
      },
    },
    outcome: OutcomeValues.Warn,
    rule: FingerprintRulesList.SignalAfterAttempts,
  },
  [FingerprintRulesList.NewDeviceDetected]: { outcome: OutcomeValues.Warn, rule: FingerprintRulesList.NewDeviceDetected },
  [FingerprintRulesList.AppVpnProxy]: { outcome: OutcomeValues.Warn, rule: FingerprintRulesList.AppVpnProxy },
  [FingerprintRulesList.AppTorIp]: { outcome: OutcomeValues.Deny, rule: FingerprintRulesList.AppTorIp },
  [FingerprintRulesList.AppSuspiciousIp]: { outcome: OutcomeValues.Warn, rule: FingerprintRulesList.AppSuspiciousIp },
  [FingerprintRulesList.ImprobableTravel]: { outcome: OutcomeValues.Warn, rule: FingerprintRulesList.ImprobableTravel },
  [FingerprintRulesList.NewIpCountry]: { outcome: OutcomeValues.Warn, rule: FingerprintRulesList.NewIpCountry },
  [FingerprintRulesList.EmulatedDevice]: { outcome: OutcomeValues.Deny, rule: FingerprintRulesList.EmulatedDevice },
  [FingerprintRulesList.JailbrokenRooted]: { outcome: OutcomeValues.Deny, rule: FingerprintRulesList.JailbrokenRooted },
  [FingerprintRulesList.SwizzledTempered]: { outcome: OutcomeValues.Deny, rule: FingerprintRulesList.SwizzledTempered },
  [FingerprintRulesList.DangerousApps]: { outcome: OutcomeValues.Deny, rule: FingerprintRulesList.DangerousApps },
  [FingerprintRulesList.Debuggable]: { outcome: OutcomeValues.Deny, rule: FingerprintRulesList.Debuggable },
  [FingerprintRulesList.BotDetected]: { outcome: OutcomeValues.Deny, rule: FingerprintRulesList.BotDetected },
  //[FingerprintRulesList.EstablishedUserDeviceAllow]: { outcome: OutcomeValues.Allow, rule: FingerprintRulesList.EstablishedUserDeviceAllow },
};

export const signalsToRiskParamsConverter: { [key in FingerprintSignals]?: FingerprintRulesList } = {
  [FingerprintSignals.MultipleAccountSignupsPerDevice]: FingerprintRulesList.RegistrationsPerDevice,
  [FingerprintSignals.NewDevice]: FingerprintRulesList.NewDeviceDetected,
  [FingerprintSignals.TOR]: FingerprintRulesList.AppTorIp,
  [FingerprintSignals.BotDetection]: FingerprintRulesList.BotDetected,
  [FingerprintSignals.DangerousApps]: FingerprintRulesList.DangerousApps,
  [FingerprintSignals.Debuggable]: FingerprintRulesList.Debuggable,
  [FingerprintSignals.EmulatorDetection]: FingerprintRulesList.EmulatedDevice,
  [FingerprintSignals.ImprobableTravel]: FingerprintRulesList.ImprobableTravel,
  [FingerprintSignals.JailbrokenRooted]: FingerprintRulesList.JailbrokenRooted,
  [FingerprintSignals.MaliciousPackages]: FingerprintRulesList.DangerousApps,
  [FingerprintSignals.MultipleAccountAccessPerDevice]: FingerprintRulesList.AccountAccessPerDevice,
  [FingerprintSignals.VpnProxy]: FingerprintRulesList.AppVpnProxy,
  [FingerprintSignals.SuspiciousIP]: FingerprintRulesList.AppSuspiciousIp,
  [FingerprintSignals.NewIPCountry]: FingerprintRulesList.NewIpCountry,
  [FingerprintSignals.NewCountry]: FingerprintRulesList.NewIpCountry,
  [FingerprintSignals.MaxEventsPerTimeframe]: FingerprintRulesList.SignalAfterAttempts,
  [FingerprintSignals.SwizzlingDetected]: FingerprintRulesList.SwizzledTempered,
  [FingerprintSignals.TamperingDetected]: FingerprintRulesList.SwizzledTempered,
  [FingerprintSignals.EstablishedUserDeviceAllow]: FingerprintRulesList.EstablishedUserDeviceAllow,
};

export function getSignalColor(value: FingerprintSignals, currentService: any) {
  const paramsName: FingerprintRulesList | undefined = signalsToRiskParamsConverter[value];
  if (!currentService?.fingerprintRules) return FingerprintSignalsColors.deny;
  const riskParamsValue: FingerprintRiskParams = paramsName
    ? currentService?.fingerprintRules.rules.find((elem: any) => elem.rule === paramsName)?.outcome
    : currentService?.fingerprintRules.rules.find((elem: any) => elem.signal === value)?.outcome;
  if (riskParamsValue && Object.values(FingerprintRiskParams).includes(riskParamsValue)) return FingerprintSignalsColors[riskParamsValue];
  if (value && value.includes(FingerprintRiskParams.Deny)) return FingerprintSignalsColors.deny;
  if (value && value.includes(FingerprintRiskParams.Warn)) return FingerprintSignalsColors.warn;
  if (value && value.includes(FingerprintRiskParams.Allow)) return FingerprintSignalsColors.allow;
  return FingerprintSignalsColors.custom;
}

export function getEnabledValue(rule: IFingerprintRule | undefined): boolean {
  return typeof rule?.enabled === "boolean" ? rule.enabled : true;
}

interface IFingerprintService {
  publicKey: string;
  constructor: Function;
  createEvent: (eventType: FingerprintLogEvents, username: string) => Promise<boolean | null>;
}

class FingerprintHelper implements IFingerprintService {
  private xray: XRAY;
  publicKey: string;
  private queue: Array<any> = [];
  private isLoopRun = false;
  constructor(rpPublicKey: string) {
    this.xray = new XRAY();
    this.publicKey = rpPublicKey;
  }

  private async runLoop(): Promise<void> {
    if (this.isLoopRun) return;
    if (this.queue.length) {
      this.isLoopRun = true;
      const callback = this.queue.shift();
      await callback();
      setTimeout(
        (() => {
          this.isLoopRun = false;
          this.runLoop();
        }).bind(this),
        3000
      );
    }
  }

  async createEvent(eventType: FingerprintLogEvents, username: string): Promise<boolean | null> {
    return new Promise((resolve, reject) => {
      this.queue.push(async () => {
        await this.sendEvent(eventType, username);
        resolve(true);
      });
      this.runLoop()
        .then(() => resolve(null))
        .catch((err) => {
          console.error(err);
          resolve(null);
        });
    });
  }

  private async sendEvent(eventType: any, username: string): Promise<void> {
    // @ts-ignore
    this.xray.scan(eventType, username, this.publicKey, 5000, fingerprintUrl).catch((err) => console.error(err.message));
  }
}

export const fingerprintHelper = new FingerprintHelper(rpPublicKey);
