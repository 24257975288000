import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { FONTS } from "../../assets";
import { MultiselectModes } from "./props";

interface IMultiselectWrapper {
  filterPlaceholder: boolean;
  filterPlaceholderTitle?: string;
  placeholder?: string;
  padding?: number;
  mode: MultiselectModes | undefined;
}

interface IOptionCheck {
  padding?: boolean;
}

const multiselectPlaceholder = `
  position: absolute;
  right: 0px;
  transform: translateX(-70px);
  color: ${COLORS.MINE_SHAFT} !important;
  line-height: 32px !important;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  max-width: 70px;
  z-index: 10;
  font-family: ${FONTS.RUBIK};
  font-size: 14px;
  position: absolute;
  user-select: none;
  cursor: pointer;
`;

export const MultiselectWrapper = styled.div<IMultiselectWrapper>`
  position: relative;

  .ant-tag.ant-tag-has-color {
    color: #262626 !important;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    .anticon {
      color: ${COLORS.GRAY} !important;
    }
  }

  .ant-select.custom-multiselect > .ant-select-selector {
    ${({ filterPlaceholder }: IMultiselectWrapper) => {
      return filterPlaceholder ? "padding-right: 100px !important;" : "";
    }}
    padding-top: 0 !important;
  }

  .ant-select.custom-multiselect > .ant-select-selector:after {
    ${({ filterPlaceholder, filterPlaceholderTitle, padding }: IMultiselectWrapper) => {
      const paddingStyle = padding ? `transform: translateX(-${padding}px) !important;` : "";
      return filterPlaceholder ? multiselectPlaceholder + `content: '${filterPlaceholderTitle}' !important;` + paddingStyle : "";
    }}
  }

  .custom-multiselect {
    height: 32px;
    border-radius: 4px !important;
    border: 1px solid #d6d6d6 !important;
    box-shadow: none !important;
    background-color: #fff !important;
  }

  .custom-multiselect.ant-select-open {
    border-color: #a37ce3 !important;
  }

  .custom-multiselect > .ant-select-selector > .ant-select-selection-item {
    ${({ mode }) => (mode === undefined ? "text-align: right;" : "")}
  }

  .custom-multiselect > .ant-select-selector > .ant-select-selection-item::after {
    ${({ mode, placeholder = "Filter by" }) =>
      mode === undefined ? `display: block;text-align: left; content: '${placeholder}'; position: absolute; top: 0; visibility: visible` : ""}
  }

  .custom-multiselect .ant-select-selection-placeholder {
    color: ${COLORS.GRAY};
  }

  .custom-multiselect > .ant-select-selector {
    height: 32px !important;
    border: none !important;
    box-shadow: none !important;
    padding: 1px 12px 1px 6px !important;
  }
  .ant-select-selection-placeholder {
    left: 6px !important;
    right: 6px !important;
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LabelWrapper = styled.div<IOptionCheck>`
  width: ${({ padding }) => (!!padding ? `calc(100% - 35px)` : "100%")};
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const RemoveAllSelector = styled.button`
  padding: 12px 15px 8px 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  color: ${COLORS.CARNATION};
  align-items: center;
`;
