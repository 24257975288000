import React, { PropsWithChildren, useCallback } from "react";
import { Checkbox, Divider, Select, Tag } from "antd";
import { IMultiselectProps } from "./props";
import { LabelWrapper, MultiselectWrapper, OptionWrapper, RemoveAllSelector } from "./styles";
import { CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

export function Multiselect<ChangeType extends string>({
  mode,
  placeholder = "Filter by",
  options = [],
  defaultValues = [],
  style,
  filterPlaceholder,
  onChange,
  rightPadding,
}: PropsWithChildren<IMultiselectProps<ChangeType>>) {
  const handleRemoveButton = () => {
    if (typeof onChange === "function") onChange([]);
  };

  const handleCloseTag = useCallback(
    (value) => {
      return () => {
        if (typeof onChange === "function") onChange(Array.isArray(defaultValues) ? defaultValues.filter((item) => item !== value) : undefined);
      };
    },
    [onChange]
  );

  const handleChange = (values: Array<ChangeType> | ChangeType | string | undefined) => {
    if (typeof onChange === "function") onChange(values);
  };

  return (
    <MultiselectWrapper
      placeholder={placeholder}
      mode={mode}
      filterPlaceholder={!!filterPlaceholder}
      padding={rightPadding}
      filterPlaceholderTitle={filterPlaceholder}
    >
      <Select
        value={defaultValues}
        mode={mode}
        className={"custom-multiselect"}
        allowClear
        style={style}
        placeholder={placeholder}
        onChange={handleChange}
        showArrow={true}
        showSearch={false}
        maxTagCount={"responsive"}
        tagRender={(tag) => (
          <Tag closable={true} color={"white"} onClose={handleCloseTag(tag.value)}>
            {options.find((item) => item.value === tag.value)?.label}
          </Tag>
        )}
        menuItemSelectedIcon={<></>}
        dropdownClassName={"multiselect-dropdown"}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider type={"horizontal"} />
            <RemoveAllSelector onClick={handleRemoveButton}>
              <span>Remove All</span>
              <CloseOutlined size={13} />
            </RemoveAllSelector>
          </div>
        )}
      >
        {options?.map((option) => {
          return (
            <Option value={option.value} key={option.value}>
              <OptionWrapper>
                <LabelWrapper padding={!!mode}>{option.label}</LabelWrapper>
                {mode && <Checkbox checked={defaultValues?.includes(`${option.value}` as ChangeType)} />}
              </OptionWrapper>
            </Option>
          );
        })}
      </Select>
    </MultiselectWrapper>
  );
}
