import styled from 'styled-components';
import { COLORS } from '../../utils/colors';
import Icon from '@ant-design/icons';
import { FONTS } from '../../assets';
import { DeleteOutlined } from "@ant-design/icons";

export const DeleteDescription = styled.div`
  margin-bottom: 24px;
`;

export const DeleteAppButton = styled.button`
  height: 40px;
  padding: 0 16px;
  margin-top: 24px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  user-select: none;
  
  background: #FEECED;
  border: 1px solid ${COLORS.CARNATION};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.5s linear;
  width: 100%;
  
  &:hover > div, &:hover {
    background: none;
  }
`;

export const DeleteButtonText = styled.div`
  font-family: ${FONTS.RUBIK};
  font-size: 16px;
  line-height: 23px;
  color: ${COLORS.CARNATION};
`;

export const DeleteIcon = styled(DeleteOutlined)`
  color: ${COLORS.CARNATION};
`;

export const InputImageDanger = styled(Icon)`
  color: ${COLORS.MEDIUM_PURPLE};
  position: absolute;
  height: 18px;
  width: 18px;
  left: 22px;
  top: 50%;
  transform: translateY(-50%);
  
  & > svg {
    height: 18px;
    width: 18px;
  }
`;
