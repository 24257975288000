import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { FONTS } from "../../assets";

export enum SelectSizesEnum {
  Large = "large",
  Medium = "medium",
  Small = "small",
}
export interface ISelectWrapperProps {
  size: SelectSizesEnum;
  maxWidth: number | undefined;
  showPlaceholderInfinitely: boolean;
  placeholder?: string;
  width?: number;
  error?: string;
}

export const SelectWrapper = styled.div<ISelectWrapperProps>`
  ${({ maxWidth }) => !!maxWidth && `max-width: ${maxWidth}px`};
  ${({ width }) => !!width && `width: ${width}px`};
  ${({ showPlaceholderInfinitely, placeholder }) =>
    showPlaceholderInfinitely &&
    `
      .ant-select-selection-item {
        text-align: right !important;
      }
      .ant-select-selection-placeholder {
        display: none !important;
      }
      .ant-select-selector:before {
        display: block;
        position: absolute;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        content: '${placeholder}' !important;
        font-family: ${FONTS.RUBIK};
        color: ${COLORS.GRAY};
      }
    `}
  .ant-select {
    background-color: ${COLORS.WHITE};
    width: 100%;
    ${({ error }) => (error ? `border-color: ${COLORS.RADICAL_RED} !important;` : "")}
  }

  .ant-select-selection-item {
    text-align: left;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  .ant-select,
  .ant-select-selector {
    box-shadow: none !important;
  }

  input {
    outline-offset: 0;
  }

  ${({ size }) =>
    size === SelectSizesEnum.Small &&
    `
      .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: 20px;
      }
      .ant-select, .ant-select-selector {
        height: 32px !important;
        border-radius: 4px !important;
        border-color: ${COLORS.ALTO};
      }
      
      .ant-select-selector {
        border: none !important;
      }
  `}
`;
