import Papa from "papaparse";

export function readFile(file: any): Promise<{ columns: Array<string>; data: Array<any> }> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function () {
      const data = Papa.parse(reader.result as any, { header: true });

      if (data.errors.length) {
        reject(data.errors);
      } else {
        resolve({ columns: data.meta.fields || [], data: data.data });
      }
    };
  });
}
