import React, { FC, useCallback } from "react";
import { IconStyle, KeyItem, KeyList, KeyName, KeyTitle } from "./styles";
import Icon from "@ant-design/icons";
import { ICONS } from "../../assets";
import { ButtonWithIcon } from "..";
import { ButtonWithIconStylePreset, ServiceType } from "../../utils/types";
import { useStore } from "../../utils/hooks";
import { BlockContainer, BlockContent, BlockTitle } from "../../styles";
import { IKeysNCredentials } from "./props";
import { copyToClipboard } from "../../utils/helpers";
import { message } from "antd";
import { CryptoModule } from "../../utils/crypto-helpers";
import { textDownloader } from "../../utils/text-downloader";

export const KeysNCredentials: FC<IKeysNCredentials> = () => {
  const {
    services: { currentService },
    services,
  } = useStore();

  const handleCopyClick = useCallback((value: string | null | undefined, successMessage: string) => {
    return () => {
      if (value) {
        copyToClipboard(value ? value : "");
        message.destroy();
        message.info(successMessage);
      }
    };
  }, []);

  const makeSignatureKeys = useCallback(async () => {
    const cryptoModule = new CryptoModule();
    const keys = await cryptoModule.generateKeyPair();
    await services.updateCurrentService({ passKeyPublicKey: keys.publicKeyB64 });
    textDownloader(JSON.stringify(keys));
  }, []);

  return (
    <BlockContainer>
      <BlockTitle>Keys & Credentials</BlockTitle>
      <BlockContent>
        <KeyList>
          {currentService?.type === ServiceType.Authentication && (
            <KeyItem>
              <KeyTitle>
                <Icon component={ICONS.KEY} />
                <KeyName>App Key</KeyName>
              </KeyTitle>
              <ButtonWithIcon
                text={currentService?.application?.appKey || "no service key"}
                icon={<Icon component={ICONS.COPY} style={IconStyle} />}
                iconOrder={1}
                preset={ButtonWithIconStylePreset.GreenDashed}
                onClick={handleCopyClick(currentService?.application?.appKey, "Application Key copied")}
                disabled={!currentService?.domainVerifiedRecord}
              />
            </KeyItem>
          )}
          <KeyItem>
            <KeyTitle>
              <Icon component={ICONS.KEY} />
              <KeyName>API Key</KeyName>
            </KeyTitle>
            <ButtonWithIcon
              text={currentService?.application?.whiteLabelAppKey || "no service key"}
              icon={<Icon component={ICONS.COPY} style={IconStyle} />}
              iconOrder={1}
              preset={ButtonWithIconStylePreset.GreenDashed}
              onClick={handleCopyClick(currentService?.application?.whiteLabelAppKey, "API Key copied")}
              disabled={currentService?.type === ServiceType.Authentication && !currentService?.domainVerifiedRecord}
            />
          </KeyItem>
          {currentService?.type === ServiceType.Authentication && (
            <KeyItem>
              <KeyTitle>
                <Icon component={ICONS.KEY} />
                <KeyName>Service Encryption Key</KeyName>
              </KeyTitle>
              {currentService?.application?.passKeyPublicKey ? (
                <ButtonWithIcon
                  text={currentService?.application.passKeyPublicKey}
                  icon={<Icon component={ICONS.COPY} style={IconStyle} />}
                  iconOrder={1}
                  preset={ButtonWithIconStylePreset.GreenDashed}
                  onClick={handleCopyClick(currentService?.application.passKeyPublicKey, "Pass Key copied")}
                  disabled={!currentService?.domainVerifiedRecord}
                />
              ) : (
                <ButtonWithIcon
                  style={{ justifyContent: "center" }}
                  text={"Generate Fraud API Encryption Keys"}
                  preset={ButtonWithIconStylePreset.GreenDashed}
                  onClick={makeSignatureKeys}
                />
              )}
            </KeyItem>
          )}
        </KeyList>
      </BlockContent>
    </BlockContainer>
  );
};
