import styled from "styled-components";

export const SplittedTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const NoWrappedBlock = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 30px;

  @media (max-width: 890px) {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`;

export const RecommendedSettingsBlock = styled.div``;