import React, { FC, Fragment, useCallback } from "react";
import {
  ImageWithRequirementsBlock,
  ImageWrapper,
  PlaceholderWrapper,
  Requirement,
  RequirementExecutedValue,
  RequirementsBlock,
  RequirementsBlockHeader,
  RequirementsList,
  RequirementValue,
  UploadedImage,
} from "./styles";
import { COLORS } from "../../utils/colors";
import { IImagePlaceholderProps } from "./props";
import Icon from "@ant-design/icons";
import { ICONS } from "../../assets";
import { Requirements } from "../../screens/ServiceSettingsScreen";
import { Upload } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { UploadFile } from "antd/es/upload/interface";

const { Dragger } = Upload;

const requirementsList: Record<keyof Requirements, string> = {
  fileType: "PNG file type",
  fileSize: "Under 500KB file size",
  resolution: "At least 200x200",
};

export const ImagePlaceholder: FC<IImagePlaceholderProps> = ({ src, width, height, id, requirements, onChange }) => {
  const handleUploadFile = useCallback(
    (event: UploadChangeParam<UploadFile<any>> | { file: File }) => {
      onChange(event);
    },
    [onChange]
  );

  return (
    <ImageWithRequirementsBlock>
      <Dragger
        name={id}
        multiple={false}
        onChange={handleUploadFile}
        onDrop={(e) => {
          handleUploadFile({ file: e.dataTransfer.files[0] });
        }}
        showUploadList={false}
        accept={".png"}
        beforeUpload={() => false}
      >
        {src ? (
          <PlaceholderWrapper htmlFor={id}>
            <UploadedImage src={src} alt="" />
          </PlaceholderWrapper>
        ) : (
          <ImageWrapper htmlFor={id} width={width} height={height}>
            <Icon
              component={ICONS.PLUS}
              style={{
                fontSize: "24px",
                color: COLORS.MEDIUM_PURPLE,
              }}
            />
            Upload
          </ImageWrapper>
        )}
      </Dragger>
      <RequirementsBlock>
        <RequirementsBlockHeader>Requirements</RequirementsBlockHeader>
        <RequirementsList>
          {Object.keys(requirements).map((item) => (
            <Fragment key={item}>
              <Requirement>
                <RequirementExecutedValue executed={requirements[item as keyof Requirements]} />
                <RequirementValue>{requirementsList[item as keyof Requirements]}</RequirementValue>
              </Requirement>
            </Fragment>
          ))}
        </RequirementsList>
      </RequirementsBlock>
    </ImageWithRequirementsBlock>
  );
};
