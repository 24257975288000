export function convertNumberToAbbreviatedString(value: number): string {
  let result = value.toString();
  switch (true) {
    case value < 1e3:
      break;
    case value >= 1e3 && value < 1e6:
      result = +(value / 1e3).toFixed(1) + "K";
      break;
    case value >= 1e6 && value < 1e9:
      result = +(value / 1e6).toFixed(1) + "M";
      break;
    case value >= 1e9 && value < 1e12:
      result = +(value / 1e9).toFixed(1) + "B";
      break;
    case value >= 1e12:
      result = +(value / 1e12).toFixed(1) + "T";
      break;
    default:
      break;
  }

  return result;
}
