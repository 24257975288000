import { FC, useCallback } from "react";
import { ButtonWithIcon } from "..";
import { ICONS } from "../../assets";
import {
  BlockContainer,
  BlockContent,
  BlockTitle,
  Index,
  Step,
  StepAction,
  StepDescription,
  StepExplanation,
  StepInfo,
  StepList,
  StepText,
} from "../../styles";
import { COLORS } from "../../utils/colors";
import { ButtonWithIconStylePreset } from "../../utils/types";
import Icon from "@ant-design/icons";
import { useStore } from "../../utils/hooks";
import { IconStyle } from "../KeysNCredentials/styles";
import { IVerifyDomain } from "./props";
import { copyToClipboard, defaultErrorHandler } from "../../utils/helpers";
import { message } from "antd";

export const VerifyDomain: FC<IVerifyDomain> = () => {
  const {
    services,
    services: { currentService: service },
  } = useStore();

  const handleDomainRecordClick = useCallback(() => {
    if (service?.domainVerifiedRecord) {
      copyToClipboard(service.domainVerifiedRecord);
      message.info("Domain record copied");
    }
  }, [service]);

  const handleVerifyDomainButton = useCallback(async () => {
    try {
      if (service?.id) {
        const { domainName } = await services.verifyCurrentServiceDomain();
        if (domainName) message.success(`Domain ${domainName} is verified`);
      }
    } catch (err: any) {
      defaultErrorHandler(err);
    }
  }, [service, services]);

  return (
    <BlockContainer>
      <BlockTitle>Verify Your Domain Ownership</BlockTitle>
      {/*<Alert*/}
      {/*  message="Verify domain ownership to start using Keyri API"*/}
      {/*  type="warning"*/}
      {/*  showIcon*/}
      {/*/>*/}
      <BlockContent>
        <StepList>
          <Step>
            <StepExplanation align="flex-start">
              <Index color={COLORS.ROBINS_EGG_BLUE}>1</Index>
              <StepInfo>
                <StepText>Add the following to your domain’s TXT Record. 👉</StepText>
              </StepInfo>
            </StepExplanation>
            <StepAction>
              <ButtonWithIcon
                text={service?.domainVerifiedRecord || "no service key"}
                icon={<Icon component={ICONS.COPY} style={IconStyle} />}
                iconOrder={1}
                preset={ButtonWithIconStylePreset.GreenDashed}
                onClick={handleDomainRecordClick}
                disabled={!service?.domainVerifiedRecord}
              />
            </StepAction>
          </Step>
          <Step>
            <StepExplanation align="flex-start">
              <Index color={COLORS.MEDIUM_PURPLE}>2</Index>
              <StepInfo>
                <StepText>When done, click the Check Now button. 🤝</StepText>
                <StepDescription>It may take 24 hours for the TXT record to update.</StepDescription>
              </StepInfo>
            </StepExplanation>
            <StepAction>
              <ButtonWithIcon
                text="Check Now"
                icon={<Icon component={ICONS.VERIFY_CLOUD} />}
                preset={ButtonWithIconStylePreset.Default}
                onClick={handleVerifyDomainButton}
                disabled={!service?.domainVerifiedRecord}
              />
            </StepAction>
          </Step>
        </StepList>
      </BlockContent>
    </BlockContainer>
  );
};
