import React, { FC, useEffect, useMemo } from "react";
import { BlockContent, BlockTitle, FormFieldWrapper, InfoWarnIcon, MenuItemContainer, MenuItemContent, WarnAlert } from "../../../../styles";
import { ApplicationInfo, CloseLink, Line } from "../../styles";
import { Input, message } from "antd";
import Icon from "@ant-design/icons";
import { ICONS } from "../../../../assets";
import { ButtonWithIcon } from "../../../../components";
import { IAddEchoApplicationProps } from "./props";
import { useFormik } from "formik";
import { INewEchoApplicationForm, ServiceType } from "../../../../utils/types";
import { generateRoute, Routes } from "../../../../utils/constants";
import { KeyriError } from "../../../../utils/keyri-error";
import { defaultErrorHandler } from "../../../../utils/helpers";
import { useStore } from "../../../../utils/hooks";
import { useNavigate } from "react-router-dom";
import { createEchoServiceFormValidator } from "../../../../utils/validators";

export const AddEchoService: FC<IAddEchoApplicationProps> = ({ setIsFetch, closeLink, onBillingChange, cardData }) => {
  const {
    services: { createNewService },
    billing: { plans },
  } = useStore();

  const navigate = useNavigate();

  const {
    values: { name, plan },
    handleChange,
    errors,
    handleSubmit,
    handleBlur,
    touched,
    isValid,
    setFieldValue,
    validateForm,
  } = useFormik<INewEchoApplicationForm>({
    onSubmit: async ({ plan }) => {
      try {
        /* Checking existing plan before continue create service */
        const selectedPlan = plans.find((filter) => filter.id === plan);
        if (!selectedPlan) return message.error("Plan not found");

        const payload = {
          name,
          planId: selectedPlan.id,
          type: ServiceType.EchoGuard,
        };
        const service = await createNewService(payload);

        navigate(generateRoute(Routes.AuthenticationDashboard, { serviceId: service.id }));
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      }
    },
    initialValues: {
      name: "",
      plan: plans.find((plan) => plan.name === "Echo Verify - Launch")?.id ?? "", // TODO: Change when will be added new plans
    },
    validate: createEchoServiceFormValidator,
  });

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const submitButtonStatus: boolean = useMemo(() => {
    const { cardErrors, country, zip } = cardData;
    let selectedPlan = plans.find((planObject) => planObject.id === plan);

    const isSelectedPlanRequiredCard = !!selectedPlan?.requiredCard;
    const isCardDataEmpty = !!cardErrors.length || !country || !zip;
    //const isFieldsTouched = !!Object.keys(touched).length;

    return (isCardDataEmpty && isSelectedPlanRequiredCard) || /*!isFieldsTouched ||*/ !isValid || !plan;
  }, [isValid, touched, plan, cardData, plans]);

  return (
    <MenuItemContainer maxWidth={1320}>
      <MenuItemContent>
        <BlockTitle>Add Your Application</BlockTitle>
        <BlockContent>
          <ApplicationInfo>
            <FormFieldWrapper>
              <Input
                name="name"
                type="text"
                value={name}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Application Name"
                prefix={<Icon component={ICONS.ADD_APPLICATION} />}
              />
              {touched.name && errors.name && errors.name && <WarnAlert message={errors.name} type="info" icon={<InfoWarnIcon />} showIcon maxWidth={380} />}
            </FormFieldWrapper>
          </ApplicationInfo>
          {/*<Billing*/}
          {/*  defaultPlanTypes={IPlanTypes.EchoGuard}*/}
          {/*  planTypesSelector={false}*/}
          {/*  selectedPlan={plan}*/}
          {/*  onChange={onBillingChange}*/}
          {/*  handleChange={handleChange}*/}
          {/*  handleBlur={handleBlur}*/}
          {/*/>*/}
          <Line />
          <ButtonWithIcon
            onClick={handleSubmit}
            icon={<Icon component={ICONS.PLUS} />}
            text="Add Application"
            maxWidth={936}
            type={"submit"}
            centerButton
            disabled={submitButtonStatus}
          />
          <CloseLink to={closeLink}>Cancel</CloseLink>
        </BlockContent>
      </MenuItemContent>
    </MenuItemContainer>
  );
};
