import { SplittedTitle } from "screens/FingerprintRiskManagement/styles";
import { RecommendedSettings } from "screens/FingerprintRiskManagement/screenComponents/RecommendedSettings";
import { FingerprintRulesList, IServiceRiskParameters, OutcomeValues } from "utils/types";
import { COLORS } from "utils/colors";
import { RegistrationsPerDevice } from "screens/FingerprintRiskManagement/screenComponents/RegistrationsPerDevice";
import { getEnabledValue, getRuleConditions } from "utils/fingerprint-helper";
import { AccountAccessPerDevice } from "screens/FingerprintRiskManagement/screenComponents/AccountAccessPerDevice";
import { WarnSetting } from "screens/FingerprintRiskManagement/screenComponents/WarnSetting";
import { RadioOptions } from "screens/FingerprintRiskManagement/screenComponents/RadioOptions";
import React, { useCallback, useMemo } from "react";
import { useStore } from "utils/hooks";
import { IBaseRiskRulesProps } from "./props";
import { KeyriError } from "utils/keyri-error";
import { defaultErrorHandler } from "utils/helpers";
import { ICONS } from "assets";
import { ParametersList, RiskManagementSubTitle } from "styles";

export const BaseRiskRules = ({ canEdit, switchToDefaultValues, handleEnableSwitch, riskChangeHandler }: IBaseRiskRulesProps) => {
  const {
    services,
    services: { currentService },
  } = useStore();

  const handleCountChange = useCallback(
    (rule: FingerprintRulesList, outcome: OutcomeValues) => {
      const oldConditions =
        getRuleConditions(rule, currentService?.fingerprintRules?.rules).filter((condition: any) => condition["deviceModel.users._unique"] === undefined) ?? [];
      return async (value: number) => {
        await services.changeFingerprintParameters([
          { rule, outcome, conditions: { $and: [...oldConditions, { ["deviceModel.users._unique"]: { $gte: value } }] } },
        ]);
      };
    },
    [services, currentService]
  );

  const handleAttemptsChange = useCallback(async (eventLimiterCount: number, interval: number) => {
    try {
      await services.changeFingerprintParameters([
        { rule: FingerprintRulesList.SignalAfterAttempts, conditions: { eventLimiterCount: { $gte: eventLimiterCount }, interval: { $lte: interval } } },
      ]);
    } catch (err: unknown | KeyriError) {
      defaultErrorHandler(err);
    }
  }, []);

  const riskWebOptions = useMemo(() => {
    const { currentService } = services;
    return [
      {
        parameter: FingerprintRulesList.NewDeviceDetected,
        title: "New Device Detected",
        defaultValue:
          currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.NewDeviceDetected)?.outcome || OutcomeValues.Allow,
        icon: ICONS.DEVICE,
        enabled: getEnabledValue(currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.NewDeviceDetected)),
      },
      {
        parameter: FingerprintRulesList.AppVpnProxy,
        title: "VPN/Proxy",
        defaultValue: currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.AppVpnProxy)?.outcome || OutcomeValues.Allow,
        icon: ICONS.ALARM,
        enabled: getEnabledValue(currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.AppVpnProxy)),
      },
      {
        parameter: FingerprintRulesList.AppTorIp,
        title: "TOR IP",
        defaultValue: currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.AppTorIp)?.outcome || OutcomeValues.Allow,
        icon: ICONS.TOR_FILLED,
        enabled: getEnabledValue(currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.AppTorIp)),
      },
      {
        parameter: FingerprintRulesList.AppSuspiciousIp,
        title: "Suspicious IP",
        defaultValue:
          currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.AppSuspiciousIp)?.outcome || OutcomeValues.Allow,
        icon: ICONS.MASK2,
        enabled: getEnabledValue(currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.AppSuspiciousIp)),
      },
      {
        parameter: FingerprintRulesList.ImprobableTravel,
        title: "Improbable Travel",
        defaultValue:
          currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.ImprobableTravel)?.outcome || OutcomeValues.Allow,
        icon: ICONS.COMPASS,
        enabled: getEnabledValue(currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.ImprobableTravel)),
      },
      {
        parameter: FingerprintRulesList.NewIpCountry,
        title: "New IP Country",
        defaultValue: currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.NewIpCountry)?.outcome || OutcomeValues.Allow,
        icon: ICONS.COUNTRY2,
        enabled: getEnabledValue(currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.NewIpCountry)),
      },
      {
        parameter: FingerprintRulesList.BotDetected,
        title: "Bot Detected",
        defaultValue: currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.BotDetected)?.outcome || OutcomeValues.Allow,
        icon: ICONS.BOT_DETECTED,
        enabled: getEnabledValue(currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.BotDetected)),
      },
      // {
      //   parameter: FingerprintRulesList.EstablishedUserDeviceAllow,
      //   title: "Strong User History",
      //   defaultValue:
      //     currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.EstablishedUserDeviceAllow)?.outcome || "ignore",
      //   icon: ICONS.ALARM,
      //   buttonsList: [
      //     { value: OutcomeValues.Allow, title: "Allow" },
      //     { value: "ignore", title: "Ignore" },
      //   ],
      // },
    ];
  }, [services.currentService]);

  const getUniqueUsersParam = useCallback(
    (ruleName: string): string => {
      const ruleConditions = currentService?.fingerprintRules?.rules.find((rule) => rule.rule === ruleName && rule.outcome === OutcomeValues.Deny)?.conditions[
        "$and"
      ];

      const initValue = ruleConditions.find((condition: any) => typeof condition["deviceModel.users._unique"]?.["$gte"] === "number")?.[
        "deviceModel.users._unique"
      ]["$gte"];
      return initValue || "0";
    },
    [currentService]
  );

  return currentService ? (
    <>
      <SplittedTitle>
        <RiskManagementSubTitle>Pre-Configured Risk Rules:</RiskManagementSubTitle>
      </SplittedTitle>
      <SplittedTitle>
        <RiskManagementSubTitle>
          {services.currentService!.fingerprintRules?.rules && (
            <RecommendedSettings
              disabled={!canEdit}
              serviceRiskParameters={services.currentService?.serviceRiskParameters as IServiceRiskParameters}
              switchToDefaultValues={switchToDefaultValues}
              params={services.currentService!.fingerprintRules.rules}
            /> // TODO: incorrect work, check how it working
          )}
        </RiskManagementSubTitle>
        <RiskManagementSubTitle color={COLORS.GRAY}>Enabled</RiskManagementSubTitle>
      </SplittedTitle>
      <ParametersList>
        <RegistrationsPerDevice
          handleEnableSwitch={handleEnableSwitch}
          disabled={!canEdit}
          onSubmit={handleCountChange(FingerprintRulesList.RegistrationsPerDevice, OutcomeValues.Deny)}
          initialValue={parseInt(getUniqueUsersParam(FingerprintRulesList.RegistrationsPerDevice))}
          ruleStatus={getEnabledValue(
            currentService.fingerprintRules?.rules.find(
              (rule) => rule.rule === FingerprintRulesList.RegistrationsPerDevice && rule.outcome === OutcomeValues.Deny
            )
          )}
        />
        <AccountAccessPerDevice
          handleEnableSwitch={handleEnableSwitch}
          ruleStatus={getEnabledValue(
            currentService.fingerprintRules?.rules.find(
              (rule) => rule.rule === FingerprintRulesList.AccountAccessPerDevice && rule.outcome === OutcomeValues.Deny
            )
          )}
          disabled={!canEdit}
          onSubmit={handleCountChange(FingerprintRulesList.AccountAccessPerDevice, OutcomeValues.Deny)}
          initialValue={parseInt(getUniqueUsersParam(FingerprintRulesList.AccountAccessPerDevice))}
        />
        <WarnSetting
          handleEnableSwitch={handleEnableSwitch}
          ruleStatus={getEnabledValue(
            currentService.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.SignalAfterAttempts && rule.outcome === OutcomeValues.Warn)
          )}
          disabled={!canEdit}
          onSubmit={handleAttemptsChange}
          initialValues={{
            eventsCount: parseInt(
              currentService.fingerprintRules?.rules.find(
                (rule) => rule.rule === FingerprintRulesList.SignalAfterAttempts && rule.outcome === OutcomeValues.Warn
              )?.conditions.eventLimiterCount?.["$gte"] || "0"
            ),
            interval: parseInt(
              currentService.fingerprintRules?.rules.find(
                (rule) => rule.rule === FingerprintRulesList.SignalAfterAttempts && rule.outcome === OutcomeValues.Warn
              )?.conditions.interval?.["$lte"] || "0"
            ),
          }}
        />
        <RadioOptions disabled={!canEdit} options={riskWebOptions} handleEnableSwitch={handleEnableSwitch} onRadioChange={riskChangeHandler} />
      </ParametersList>
    </>
  ) : (
    <></>
  );
};
