import styled from "styled-components";
import { COLORS } from "utils/colors";
import { FONTS, IMAGES } from "assets";
import { DIMENSIONS } from "utils/constants";

interface IPlaceholderProps {
  width?: number;
  height?: number;
}

interface IRequirementExecutedValue {
  executed: boolean;
}

export const ImageWrapper = styled.label<IPlaceholderProps>`
  width: ${({ width }) => (width ? width : 220)}px;
  height: ${({ height }) => (height ? height : 220)}px;
  background-color: ${COLORS.WHITE_LILAC};
  border: 1px dashed ${COLORS.MEDIUM_PURPLE};
  display: flex;
  border-radius: 13px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-family: ${FONTS.RUBIK};
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.MEDIUM_PURPLE};
`;

export const UploadedImage = styled.img<IPlaceholderProps>`
  width: ${({ width }) => (width ? width : 200)}px;
  height: ${({ height }) => (height ? height : 200)}px;
  box-sizing: border-box;
  object-fit: cover;

  padding: 8px;
`;

export const PlaceholderWrapper = styled.label<IPlaceholderProps>`
  position: relative;
  width: ${({ width }) => (width ? width : 220)}px;
  height: ${({ height }) => (height ? height : 220)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: ${FONTS.RUBIK};
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  border-radius: 13px;
  border: 1px solid ${COLORS.ALTO};
  cursor: pointer;
`;

export const ImageWithRequirementsBlock = styled.div`
  display: flex;
  gap: 36px;

  @media (max-width: ${DIMENSIONS.L_BREAKPOINT_WIDTH}px) {
    flex-wrap: wrap;
  }
`;

export const RequirementsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const RequirementsBlockHeader = styled.div`
  font-family: "Miriam Libre";
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.GRAY};
`;

export const RequirementsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Requirement = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;

  font-family: ${FONTS.RUBIK};
`;

export const RequirementExecutedValue = styled.div<IRequirementExecutedValue>`
  width: 24px;
  height: 24px;
  background: url(${({ executed }) => (executed ? `${IMAGES.REQUIREMENT_SUCCESS}` : `${IMAGES.REQUIREMENT_FAIL}`)}) no-repeat 0 center;
`;

export const RequirementValue = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.SCORPION};
`;
