import axios, { AxiosError, AxiosResponse } from "axios";

import { AMBERFLO_API, API_URL, DIMENSIONS, LocalStorage, LogTypes } from "./constants";
import {
  FingerprintLogEvents,
  FingerprintRiskParams,
  ICardInitializingData,
  IChangeFingerprintRuleValue,
  IChangeServicePlanData,
  IChangeServiceRecords,
  IChangeUserRole,
  ICreateServiceRecords,
  ICreateServiceRuleResponse,
  ICreditCardResponse,
  ICreditCardsResponse,
  IDeleteServiceRecords,
  IEventDetailsResponse,
  IFingerprintDevicesResponse,
  IFingerprintEventsResponse,
  IFingerprintRule,
  IFingerprintUserDataResponse,
  IGetEventDetailsRequest,
  IGetFingerprintDataRequest,
  IGetFingerprintServiceRecordsRequest,
  IGetServiceRulesResponse,
  IGetSubscriptionResponse,
  Invite,
  IPlansResponse,
  IPutUserUpdate,
  ISessionsResponse,
  ISubscriptionResponse,
  IUpdateServiceFingerprintParametersResponse,
  IUserData,
  IUserResponse,
  UserService,
} from "./types";
import { store } from "../index";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import { IAttachAccountV3Payload } from "../components";
import { fingerprintHelper } from "./fingerprint-helper";
import { defaultErrorHandler } from "./helpers";
import { KeyriError } from "./keyri-error";
import { Role, Services, Response, Auth } from "@denver23/keyri-shared";

let isAppInitInFingerprintSystem = false;

async function handleInitFingerprintApp(data: AxiosResponse<Response.IApiResponse<IUserResponse>>) {
  try {
    if (!isAppInitInFingerprintSystem) await fingerprintHelper.createEvent(FingerprintLogEvents.Access, data.data.data.user.email);
    return data;
  } catch (err) {
    defaultErrorHandler(err);
  }
}

export const authAPI = {
  login: (data: any) => {
    return client.post<Response.IApiResponse<Auth.Response.V3Login>>("/auth/v3-login", data).then((res) => responseHandler(res));
  },
  confirmLogin: (token: string) => client.post<Response.IApiResponse<Auth.Response.VerifyLogin>>("/auth/verify-login", { token }),
  loginByEmail: (data: Record<string, unknown>) => {
    return client.post<Response.IApiResponse<Auth.Response.Login>>("/auth/login", data);
  },
  refreshToken: (body: any) => {
    const token = localStorage.getItem(LocalStorage.Token);
    return axios.post<any, AxiosResponse<Response.IApiResponse<Auth.Response.RefreshToken>>>("/auth/refresh", body, {
      baseURL: API_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  signUp: (data: any) => client.post<Response.IApiResponse<Auth.Response.Register>>("/auth", data),
};

export const userAPI = {
  me: () => client.get<Response.IApiResponse<IUserResponse>>("/users/me").then((data) => handleInitFingerprintApp(data)),
  attachKeyriAccount: (body: IAttachAccountV3Payload) => client.post<Response.IApiResponse<IUserResponse>>("/users/attach-keyri-account", body),
  //@todo - logout flow on Nest.js
  logout: () =>
    client.post("users/logout", {
      refreshToken: localStorage.getItem(LocalStorage.RefreshToken),
    }),
  updateUser: (data: IPutUserUpdate) => client.put<Response.IApiResponse<IUserData>>("users/me", data),
  changeUserRole: (userId: string, data: IChangeUserRole) =>
    client.post<Response.IApiResponse<{ userService: UserService }>>(`users/change-role/${userId}`, data),
  changeInviteRole: (inviteId: string, data: IChangeUserRole) =>
    client.post<Response.IApiResponse<{ invite: Invite }>>(`users/change-invite-role/${inviteId}`, data),
};

export const analyticsAPI = {
  getAmberfloAnalytics: (config: any, token: string) =>
    amberflo.post("/customer-portal/usage-batch", config, {
      headers: { "x-api-key": token },
    }),
  getAmberfloToken: (serviceId: string) => client.get<Response.IApiResponse<{ sessionToken: string }>>(`/analytics/amberflo/${serviceId}`),
  getServiceSessions: ({
    serviceId,
    limit = DIMENSIONS.DEFAULT_SESSIONS_PER_PAGE,
    skip = 0,
    period = {},
    logsType,
    searchQuery,
  }: {
    serviceId: string;
    limit?: number;
    skip?: number;
    period?: { from?: number; to?: number };
    logsType?: LogTypes;
    searchQuery?: string;
  }) => {
    return client.get<Response.IApiResponse<ISessionsResponse>>(`/analytics/${serviceId}/sessions`, {
      params: {
        limit,
        skip,
        from: period.from,
        to: period.to,
        type: logsType,
        searchQuery,
      },
    });
  },
  getServiceFingerprintAnalytics: (
    serviceId: string,
    { events, results, period }: { events: Array<FingerprintLogEvents>; results: Array<FingerprintRiskParams>; period: { from: number; to?: number } }
  ) =>
    client.get<Response.IApiResponse<Array<{ date: string; count: string; allowCount: string; warnCount: string; denyCount: string }>>>(
      `/analytics/${serviceId}/fingerprint-analytics`,
      {
        params: {
          events,
          results,
          from: period.from,
          to: period.to,
        },
      }
    ),
};

export const serviceAPI = {
  createService: async (body: Services.Requests.ICreateServiceRequest): Promise<any> =>
    client.post<Response.IApiResponse<Services.Responses.ICreateServiceResponse>>("/service", body),
  updateService: async (serviceId: string, formData: any) =>
    client.put<Response.IApiResponse<Services.Responses.IUpdateServiceResponse>>(`service/${serviceId}`, formData),
  updateServiceRiskParameters: async (serviceId: string, data: Services.Requests.IUpdateServiceRiskParametersRequest) =>
    client.put<Response.IApiResponse<Services.Responses.IUpdateServiceRiskParametersResponse>>(`service/${serviceId}/risk-parameters`, data),
  getServiceInfo: async (serviceId: string) => client.get<Response.IApiResponse<Services.Responses.IGetServiceResponse>>(`/service/${serviceId}`),
  verifyServiceDomain: async (serviceId: string) =>
    client.get<Response.IApiResponse<Services.Responses.IVerifyDomainResponse>>(`/service/${serviceId}/verify-domain`),
  getUserServices: async (signal?: AbortSignal) => client.get<Response.IApiResponse<Services.Responses.IGetUserServicesResponse>>("/service", { signal }),
  deleteService: async (serviceId: string) => client.delete(`service/${serviceId}`),
  inviteUsers: async (serviceId: string, role: Role, emails: Array<string>) =>
    client.post<Response.IApiResponse<Services.Responses.IInviteUserResponse>>(`/service/${serviceId}/invite`, {
      role,
      emails,
    } as Services.Requests.IInviteUserRequest),
  removeInvite: async (serviceId: string, email: string) =>
    client.post<Response.IApiResponse<Services.Responses.IDeleteUserInviteResponse>>(`/service/${serviceId}/delete-invite`, {
      email,
    } as Services.Requests.IDeleteUserInviteRequest),
  removeMember: async (serviceId: string, userId: string) =>
    client.post<Response.IApiResponse<Services.Responses.IRemoveMemberResponse>>(`/service/${serviceId}/remove-member`, {
      userId,
    } as Services.Requests.IRemoveMemberRequest),
};

export const subscriptionAPI = {
  getPlans: async () => client.get<Response.IApiResponse<IPlansResponse>>("/plans"),
  getServiceSubscription: async (serviceId: string) => client.get<Response.IApiResponse<IGetSubscriptionResponse>>(`/payment/subscriptions/${serviceId}`),
  getServiceCreditCard: async (serviceId: string) => client.get<Response.IApiResponse<ICreditCardsResponse>>(`/payment/card/${serviceId}`),
  addServiceCard: async ({ serviceId, ...card }: ICardInitializingData) =>
    client.post<Response.IApiResponse<ICreditCardResponse>>(`/payment/card/${serviceId}`, card),
  changeServicePlan: async (serviceId: string, data: IChangeServicePlanData) =>
    client.post<Response.IApiResponse<ISubscriptionResponse>>(`/payment/subscription/${serviceId}`, data),
};

export const fingerprintAPI = {
  getServiceEvents: async ({ serviceId, ...params }: IGetFingerprintDataRequest) =>
    client.get<Response.IApiResponse<IFingerprintEventsResponse>>(`/fingerprint/${serviceId}/events`, { params }),
  getUserEvents: async ({ serviceId, ...params }: IGetFingerprintDataRequest) =>
    client.get<Response.IApiResponse<IFingerprintEventsResponse>>(`/fingerprint/${serviceId}/user-events`, { params }),
  getUserData: async ({ serviceId, ...params }: Partial<IGetFingerprintDataRequest>) =>
    client.get<Response.IApiResponse<IFingerprintUserDataResponse>>(`/fingerprint/${serviceId}/user-info`, { params }),
  getUserDevices: async ({ serviceId, ...params }: IGetFingerprintDataRequest) =>
    client.get<Response.IApiResponse<IFingerprintDevicesResponse>>(`/fingerprint/${serviceId}/user-devices`, { params }),
  getEventDetails: async ({ serviceId, eventId }: IGetEventDetailsRequest) =>
    client.get<Response.IApiResponse<IEventDetailsResponse>>(`/fingerprint/${serviceId}/event/${eventId}`),
  updateFingerprintParameters: async (serviceId: string, fingerprintRules: Array<IChangeFingerprintRuleValue>) =>
    client.put<Response.IApiResponse<IUpdateServiceFingerprintParametersResponse>>(`/fingerprint/${serviceId}/fingerprint-rules`, { fingerprintRules }),
  replaceCustomRulesParameters: async (serviceId: string, fingerprintRules: Array<IFingerprintRule>) =>
    client.put<Response.IApiResponse<IUpdateServiceFingerprintParametersResponse>>(`/fingerprint/${serviceId}/custom-rules`, { fingerprintRules }),
  removeFingerprintRule: async (serviceId: string, rule: string) =>
    client.put<Response.IApiResponse<IUpdateServiceFingerprintParametersResponse>>(`/fingerprint/${serviceId}/remove-rule`, { rule }),
};

export const fingerprintListsAPI = {
  createNewRecords: async ({ serviceId, records, override }: ICreateServiceRecords) =>
    client.post<Response.IApiResponse<ICreateServiceRuleResponse>>(`/service-list/${serviceId}`, { records, override }),
  getServiceRecords: async ({ serviceId, ...params }: IGetFingerprintServiceRecordsRequest) =>
    client.get<Response.IApiResponse<IGetServiceRulesResponse>>(`/service-list/${serviceId}`, { params }),
  deleteServiceRecord: async ({ serviceId, recordId }: IDeleteServiceRecords) =>
    client.delete<Response.IApiResponse<boolean>>(`/service-list/${serviceId}/${recordId}`),
  changeServiceRecords: async ({ serviceId, recordId, outcome }: IChangeServiceRecords) =>
    client.post<Response.IApiResponse<boolean>>(`/service-list/${serviceId}/${recordId}`, { outcome }),
  getServiceRecordsInfo: async (serviceId: string) => client.get<Response.IApiResponse<IGetServiceRulesResponse>>(`/service-list/${serviceId}/info`),
};

const client = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
});

const amberflo = axios.create({
  baseURL: AMBERFLO_API,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});

export const loadAxiosConfig = () => {
  if (localStorage) {
    const token = localStorage.getItem(LocalStorage.Token);
    const refreshToken = localStorage.getItem(LocalStorage.RefreshToken);
    if (refreshToken && token) {
      client.defaults.headers.common.Authorization = `Bearer ${token}`;
      return true;
    }
  }
  return false;
};

export const clearAxiosConfig = () => {
  localStorage.removeItem(LocalStorage.Token);
  localStorage.removeItem(LocalStorage.RefreshToken);
  localStorage.removeItem(LocalStorage.Username);
  delete client.defaults.headers.common.Authorization;
};

export const responseHandler = (response: AxiosResponse<Response.IApiResponse<Auth.Response.V3Login | Auth.Response.VerifyLogin>>) => {
  if (response && response.data) {
    const { refreshToken, token, user } = response.data.data;
    if (refreshToken && token) {
      client.defaults.headers.common.Authorization = `Bearer ${token}`;
      localStorage.setItem(LocalStorage.Token, token);
      localStorage.setItem(LocalStorage.RefreshToken, refreshToken);
      localStorage.setItem(LocalStorage.Username, user.name);
    }
  }
  return response;
};

const isAccessTokenExpired = (exp: number): boolean => {
  const accessTokenExpDate = exp - 10;
  const nowTime = Math.floor(new Date().getTime() / 1000);

  return accessTokenExpDate <= nowTime;
};

export const refreshTokens = async (refreshTokenOld: string) => {
  const {
    data: { data: result },
  } = await authAPI.refreshToken({
    refreshToken: refreshTokenOld,
  });

  const { refreshToken, token } = result;
  localStorage.setItem(LocalStorage.Token, token);
  localStorage.setItem(LocalStorage.RefreshToken, refreshToken);

  return token;
};

const checkTokensInterceptor = async (request: any) => {
  const accessToken = localStorage.getItem(LocalStorage.Token);
  const refreshTokenOld = localStorage.getItem(LocalStorage.RefreshToken);

  if (!accessToken || !refreshTokenOld) {
    if (!isAppInitInFingerprintSystem) {
      isAppInitInFingerprintSystem = true;
      if (!window.location.pathname.includes("/login?token="))
        fingerprintHelper.createEvent(FingerprintLogEvents.Visits, "undefined").catch((err) => console.error(err));
    }
    clearAxiosConfig();
    delete request.headers.Authorization;
    return request;
  }

  // fixme
  let exp = Number(JSON.parse(atob(accessToken.split(".")[1])).exp);

  if (isAccessTokenExpired(exp)) {
    try {
      const token = await refreshTokens(refreshTokenOld);

      if (request && request.headers) request.headers.Authorization = `Bearer ${token}`;
      return request;
    } catch (err) {
      clearAxiosConfig();
      delete request.headers?.Authorization;
      return Promise.reject(err);
    }
  } else {
    isAppInitInFingerprintSystem = true;
    if (request && request.headers) request.headers.Authorization = `Bearer ${accessToken}`;
    return request;
  }
};

const responseErrorInterceptor = (error: AxiosError) => {
  if (error.response?.data.error) throw new KeyriError(error.response.data.error);
  throw new KeyriError({ code: 999, message: error.message });
};

client.interceptors.request.use(checkTokensInterceptor);
client.interceptors.response.use((response) => response, responseErrorInterceptor);

export async function storageTokenChange(event: any) {
  if (event.key === LocalStorage.Token && event.newValue === null) {
    store.user.setLoggedIn(false);
    store.user.resetUserData();
    applySnapshot(store.user, getSnapshot(store.user));
  }
}
