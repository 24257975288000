import { Bar } from "react-chartjs-2";
import { useMemo } from "react";
import { IDashboardBarProps } from "./props";
import { getRoundToValue } from "utils/helpers";
import { BarWrapper } from "./styles";

export const StackedBarChart = ({ data: { datasets, labels } }: IDashboardBarProps) => {
  const maxYValue = useMemo(() => {
    const maxCalculatedValue = labels
      ? Math.max(
          ...labels.map((elem, index) =>
            datasets.reduce((acc, currentValue) => {
              return acc + currentValue.data[index];
            }, 0)
          )
        )
      : 0;
    const roundTo = getRoundToValue(maxCalculatedValue);
    const roundedValue = Math.ceil(maxCalculatedValue / roundTo) * roundTo;
    return maxCalculatedValue > 0 ? roundedValue : 10;
  }, [labels, datasets]);

  const commonOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 28,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom" as "bottom",
        align: "start" as "start",
        labels: {
          usePointStyle: true,
          pointStyle: "circle" as "circle",
          boxWidth: 7,
          padding: 32,
        },
      },
    },
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    maxBarThickness: labels && labels.length > 10 ? 10 : 30,
    scales: {
      x: {
        display: true,
        beginAtZero: true,
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          padding: 12,
          minRotation: 0,
          maxTicksLimit: 7,
        },
      },
      y: {
        position: "right" as "right",
        suggestedMax: 10,
        suggestedMin: 0,
        display: true,
        beginAtZero: true,
        max: maxYValue,
        stacked: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 6,
          //count: 5,
          precision: 0,
        },
      },
    },
  };
  return (
    <BarWrapper>
      <Bar data={{ datasets, labels }} options={commonOptions} />
    </BarWrapper>
  );
};
