import styled from 'styled-components';
import { FONTS, IMAGES } from '../../assets';
import { COLORS } from '../../utils/colors';
import { DIMENSIONS, DimensionTypes } from '../../utils/constants';

export const Container = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FieldWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1176px;
`;

export const CountryContainer = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: ${DIMENSIONS.MAX_TABLET_WIDTH}px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const Country = styled.div`
  position: relative;
  flex: 50%;
  
  & .ant-select-selection-search-input {
    height: 47px !important;
    padding-left: 35px !important;
  }
`;

export const Zip = styled.input`
  flex: 50%;

  height: ${DIMENSIONS[DimensionTypes.DefaultInputHeight]}px;
  padding: 16px 46px;
  position: relative;

  font-family: ${FONTS.RUBIK};
  font-size: 16px;
  line-height: 19px;
  color: ${COLORS.GRAY};

  background: url(${IMAGES.ZIP_ICON}) no-repeat 20px center;
  border: 1px solid ${COLORS.ALTO};
  border-radius: 0 8px 8px 0 ;
  outline: none;
  
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  &::placeholder {
    color: ${COLORS.GRAY};
  }
  
  &:focus {
    border: 1px solid ${COLORS.MEDIUM_PURPLE};
  }

  @media (max-width: ${DIMENSIONS.MAX_TABLET_WIDTH}px) {
    border-radius: 8px;
  }
`;
