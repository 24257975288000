import React, { FC, Fragment, useCallback, useMemo, useState } from "react";
import { useStore } from "utils/hooks";
import { defaultPlans, IPlanMX } from "stores/BillingStore";
import { PlanInput, PlanLabel, Plans } from "../Billing/styles";
import { filterPlans, sortPlansHelper } from "utils/helpers";
import { PlanInfo } from "../PlanInfo";
import { InfoWarnIcon, WarnAlert } from "styles";
import { ICONS } from "assets";
import { AppDetailsIcon } from "../AppDetails/styles";
import { ChangePackageUrlButton } from "../ChangableField/styles";
import { format } from "date-fns";
import Icon from "@ant-design/icons";
import { ISetupPlans } from "./props";
import { ButtonValue, PropertyStringValue } from "./styles";
import { SettingsOpenedTitle } from "../SettingsOpenedTitle";
import { DefaultLoader } from "../DefaultLoader";
import { observer } from "mobx-react-lite";
import { IPlanTypes } from "../Billing";
import { Role } from "@denver23/keyri-shared";

const SetupBillingPlansComponent: FC<ISetupPlans> = ({ handleChange, handleBlur, setBillingMode, plan, planMode, setPlanMode }) => {
  const {
    services: { currentService },
    billing: { plans: defaultPlansObjects, currentServicePlan, currentServiceCard, latestInvoice, isSubscriptionFetch, isCardFetch },
    user,
  } = useStore();

  const [plansType, setPlanType] = useState<IPlanTypes>(IPlanTypes.AuthFraud);

  /* Permissions for editing service */
  const canEdit = useMemo(
    () => currentService?.members?.find((member) => member.id === user.id)?.UserService?.role !== Role.Viewer,
    [user, currentService?.members]
  );

  const isCurrentPlanDefault = useMemo(() => {
    return defaultPlans.includes(currentServicePlan?.name ? currentServicePlan.name : "Custom");
  }, [currentServicePlan]);

  /* Built plans array with actual custom plan instead of Enterprise */
  const plans = useMemo(() => {
    return filterPlans(defaultPlansObjects, plansType).map((plan: IPlanMX) => {
      if (!isCurrentPlanDefault && plan.name === "Enterprise") {
        return {
          ...plan,
          id: currentServicePlan?.id ? currentServicePlan.id : "",
          requiredCard: !!currentServicePlan?.requiredCard,
        };
      }
      return plan;
    });
  }, [plansType, defaultPlansObjects, currentServicePlan, isCurrentPlanDefault]);

  const initialPlanValue = useMemo(
    () => (currentServicePlan ? `${currentServicePlan.name}: ${currentServicePlan?.amberfloDescription}` : "Plan not found"),
    [currentServicePlan]
  );

  const handlePlanChange = useCallback(
    (event: any) => {
      const selectedPlan = plans.find((item) => item.id === event.target.value);
      if (!selectedPlan) return;
      handleChange(event);
      if (selectedPlan.requiredCard && !currentServiceCard?.id) {
        setBillingMode(true);
      } else {
        setBillingMode(false);
      }
    },
    [handleChange, plans, currentServiceCard?.id, setBillingMode]
  );

  const contactKeyriConditionMessage = useMemo(() => {
    const isDefaultPlan = defaultPlans.includes(currentServicePlan?.name ? currentServicePlan.name : "Custom");
    const isSelectedPlanEnterprise = plan === plans.find((defaultPlan) => defaultPlan.name === "Enterprise")?.id;
    const isCurrentPlanSelected = plan === currentServicePlan?.id;

    return !isDefaultPlan && !isSelectedPlanEnterprise && !isCurrentPlanSelected;
  }, [currentServicePlan, plan, plans]);

  const initialSelectedPlan = useMemo(() => {
    const isDefaultPlan = plans.findIndex((planObject) => planObject.id === plan);
    if (isDefaultPlan !== -1) return plan;
    const enterprisePlan = plans.find((planObject) => planObject.name === "Enterprise");

    return enterprisePlan ? enterprisePlan.id : plan;
  }, [plan, plans]);

  return isSubscriptionFetch || isCardFetch ? (
    <DefaultLoader />
  ) : (
    <>
      <SettingsOpenedTitle
        condition={planMode}
        title={"Plan"}
        secondaryTitle={"Plans"}
        callback={() => {
          setPlanMode(false);
        }}
      />
      {planMode && canEdit ? (
        <>
          <Plans>
            {plans
              .slice()
              .sort(sortPlansHelper)
              .map((item, index) => (
                <Fragment key={item.name}>
                  <PlanInput
                    type="radio"
                    name="plan"
                    id={item.id}
                    defaultChecked={initialSelectedPlan === item.id}
                    value={item.id}
                    onChange={handlePlanChange}
                    onBlur={handleBlur}
                  />
                  <PlanLabel htmlFor={item.id} data-name={`Plan ${item.name}`}>
                    <PlanInfo plan={item} />
                  </PlanLabel>
                </Fragment>
              ))}
          </Plans>
          {plans.find((planObject: IPlanMX) => planObject.id === plan)?.name === "Enterprise" && isCurrentPlanDefault && (
            <WarnAlert
              message={"You will be contacted by the Keyri sales team within 2 business days to finalize the terms of your Enterprise Plan."}
              type="info"
              icon={<InfoWarnIcon />}
              maxWidth={1176}
              showIcon
            />
          )}
          {contactKeyriConditionMessage && (
            <WarnAlert message={"Please contact Keyri sales team to update your pricing plan."} type="info" icon={<InfoWarnIcon />} maxWidth={1176} showIcon />
          )}
        </>
      ) : (
        <>
          <PropertyStringValue>
            <Icon component={ICONS.PACKAGE} style={AppDetailsIcon} />
            <ButtonValue>{initialPlanValue ? initialPlanValue : "None"}</ButtonValue>
            {canEdit && (
              <ChangePackageUrlButton
                data-type={"change-plan"}
                onClick={() => {
                  setPlanMode(true);
                }}
              />
            )}
          </PropertyStringValue>
          {currentServicePlan?.name === "Scaling" && (
            <PropertyStringValue>
              <Icon component={ICONS.PACKAGE} style={AppDetailsIcon} />
              <ButtonValue>
                Next Invoice Date: {latestInvoice && format(new Date(latestInvoice ? latestInvoice.invoiceEndTimeInSeconds * 1000 : Date.now()), "do MMMM y")}
              </ButtonValue>
            </PropertyStringValue>
          )}
        </>
      )}
    </>
  );
};

export const SetupBillingPlans = observer(SetupBillingPlansComponent);
