import { CompletionContext, CompletionResult } from "@codemirror/autocomplete";
import { RulesConditionsList } from "./rules-conditions-list";
import { OperatorsEnum } from "@denver23/keyri-shared";

function parseCodeToStacks(context: CompletionContext) {
  const { state } = context;
  const cursorPos = state.selection.main.from;
  const stack = [];
  let key = "";
  for (let i = 0; i <= cursorPos; i++) {
    const symbol = state.doc.sliceString(i, i + 1);

    if (symbol === "{") {
      stack.push(key);
      key = "";
    } else if (symbol === "}") {
      stack.pop();
    } else if (symbol === '"') {
      const endQuotePos = state.doc.sliceString(i).indexOf('"', 1);
      if (endQuotePos !== -1) {
        key = state.doc.sliceString(i + 1, i + endQuotePos);
        i += endQuotePos;
      }
    }
  }
  return stack;
}

function isCursorInsideFieldObject(context: CompletionContext, field: string): boolean {
  const stack = parseCodeToStacks(context);
  if (stack.includes(field)) return true;
  return false;
}

function getNestingLevel(context: CompletionContext): number {
  const stack = parseCodeToStacks(context);
  return stack.length;
}

function isItFieldValue(context: CompletionContext): boolean {
  const { state } = context;
  const cursorPos = state.selection.main.from;
  const line = state.doc.lineAt(cursorPos);
  if (line.text.includes(":")) return true;
  return false;
}

function getWordBeforeCursor(context: CompletionContext): { from: number; to: number; word: string } {
  const { state } = context;
  const cursorPos = state.selection.main.from;
  let startPos = cursorPos;
  while (startPos > 0 && /[\w.]/.test(state.doc.sliceString(startPos - 1, startPos))) {
    startPos--;
  }
  // Get the word before the cursor
  const wordBeforeCursor = state.doc.sliceString(startPos, cursorPos);
  // Convert the word to an array of symbols
  return { from: startPos, to: cursorPos, word: wordBeforeCursor };
}

const customOperatorsAutocomplete = (context: CompletionContext): CompletionResult | null => {
  const { state } = context;
  const cursorPos = state.selection.main.from;

  for (let i = cursorPos - 1; i >= cursorPos - 15; i--) {
    // Get the symbol at the current position
    const symbol = state.doc.sliceString(i, i + 1);

    // If the symbol is a '$', return the position
    if (symbol === "$" && isCursorInsideFieldObject(context, "conditions")) {
      const suggestions = Object.values(OperatorsEnum)
        .filter((op) => new RegExp("^" + state.doc.sliceString(i, cursorPos).replace("$", "\\$")).test(op))
        .map((op) => ({ label: op }));
      return suggestions.length ? { from: i, to: cursorPos, options: suggestions } : null;
    }
  }

  return null;
};

const customFieldsAutocomplete = (context: CompletionContext): CompletionResult | null => {
  const { from, to, word } = getWordBeforeCursor(context);

  if (word.length && isCursorInsideFieldObject(context, "conditions")) {
    const suggestions = RulesConditionsList.filter((op) => new RegExp("^" + word).test(op)).map((op) => ({
      label: op,
    }));
    return suggestions.length ? { from: from, to: to, options: suggestions } : null;
  }
  return null;
};

const defaultFieldsAutocomplete = (context: CompletionContext): CompletionResult | null => {
  const { state } = context;
  const cursorPos = state.selection.main.from;
  const nestedLevel = getNestingLevel(context);
  if (nestedLevel === 1 && !isItFieldValue(context)) {
    const { from, to, word } = getWordBeforeCursor(context);
    const defaultFields = ["rule", "conditions", "outcome", "signal", "notes", "enabled", "strength"];
    const suggestions = defaultFields.filter((field) => new RegExp("^" + word).test(field)).map((field) => ({ label: field }));
    return suggestions.length ? { from: from, to: cursorPos, options: suggestions } : null;
  }

  return null;
};

export { customOperatorsAutocomplete, defaultFieldsAutocomplete, customFieldsAutocomplete };
