import { Link } from "react-router-dom";
import styled from "styled-components";

import { FONTS } from "../../assets";
import { COLORS } from "../../utils/colors";
import { DIMENSIONS, DimensionTypes } from "../../utils/constants";
import { ButtonStylePreset } from "../../utils/types";
import { BaseButtonStyles, BaseColors, BasePseudo, BaseSizes, PurplePseudoStyles } from "../ButtonWithIcon/styles";

interface IButtonContainerProps {
  preset?: ButtonStylePreset;
  styles?: string;
  width?: number;
  disabled?: boolean;
}

interface ILinkProps {
  preset?: ButtonStylePreset;
  applyAddedStyles?: boolean;
  disabled?: boolean;
}

const Base = `
  cursor: pointer;
  margin: 3px;
  border-radius: 10px;
  padding: 0px;
  background: none;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  font-family: ${FONTS.ROBOTO};
  border: none;
  user-select: none;
`;

const DimensionStylesForSmallButton = `
  font-weight: 400;
  padding: 5px 16px;
  font-size: 14px;
  line-height: 14px;
`;

export const ButtonContainer = styled.div<IButtonContainerProps>(
  ({ preset = ButtonStylePreset.BaseButton, width, disabled }) => {
    switch (preset) {
      case ButtonStylePreset.BaseButton:
        return `
          ${Base};
          color: ${COLORS.GRAY};
          text-align: center;
          background-color: rgba(0, 0, 0, 0);
          ${DimensionStylesForSmallButton};
        `;
      case ButtonStylePreset.BaseButtonWithBorder:
        return `
          ${BaseButtonStyles};
          ${BaseColors};
          ${BaseSizes};
          ${BasePseudo};
          ${width ? `width: ${width}px;` : ""}
          color: ${COLORS.GRAY};
          background-color: rgba(0, 0, 0, 0);
          border-color: ${COLORS.GRAY};
          
          &:hover {
            background: #F5F5F5;
            transition: 250ms background;
          }
          height: ${DIMENSIONS[DimensionTypes.SmallButtonHeight]}px;
          padding: 0 16px;
          margin: 0;
          `;
      case ButtonStylePreset.PurpleButtonWithBorder:
        return `
          ${BaseButtonStyles}
          ${BaseColors}
          ${BaseSizes}
          ${BasePseudo}
          ${PurplePseudoStyles}
          ${width ? `width: ${width}px;` : ""}
          color: ${COLORS.LIGHT_PURPLE};
          background-color: rgba(0, 0, 0, 0);
          border-color: ${COLORS.LIGHT_PURPLE};
          
          &:hover {
            background: #F5F5F5;
            transition: 250ms background;
          }
          height: ${DIMENSIONS[DimensionTypes.SmallButtonHeight]}px;
          padding: 0 16px;
          margin: 0;
          `;
      case ButtonStylePreset.SmallGreyButton:
        return `
          ${BaseButtonStyles}
          ${width ? `width: ${width}px;` : ""}
          font-family: ${FONTS.ROBOTO};
          font-size: 12px;
          font-weight: 400;
          border-radius: 4px;
          color: ${COLORS.GRAY};
          background-color: ${COLORS.WHITE_SMOKE};
          border-color: ${COLORS.GRAY};
          border: 1px solid;
          
          &:hover {
            background: #F5F5F5;
            transition: 250ms background;
          }
          height: 23px;
          padding: 2px 12px;
          margin: 0;
        `;
      case ButtonStylePreset.LightPurpleButton:
        return `
            ${Base};
            margin: 0;
            width: 100%;
            height: ${DIMENSIONS[DimensionTypes.DefaultInputHeight]}px;
            
            font-family: ${FONTS.RUBIK};
            font-weight: 400;
            font-size: 15px;
            line-height: 23px;
            
            display: flex;
            justify-content: center;
            align-items: center;
            
            background: ${COLORS.SELAGO};     
            border: 1px solid ${COLORS.MEDIUM_PURPLE};
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
            border-radius: 6px;
            color: ${COLORS.MEDIUM_PURPLE};
            transition: all 0.5s linear;
            
            &[disabled] {
              cursor: not-allowed;
              pointer-events: all !important;
              opacity: 0.5;
              border-color: #D9D9D9;
              background-color: #F5F5F5;
              color: ${COLORS.SILVER};
            }
            
            &:hover {
              background: ${COLORS.WHITE_LILAC};
            }
            
            &:active {
              background: #F0EAFA; 
            }
          `;

      default:
        break;
    }
  },
  ({ styles }) => styles
);

export const ButtonText = styled.span<ILinkProps>`
  font-family: ${FONTS.RUBIK};
  text-align: center;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin: 3px;

  & > div {
    margin: 0;
  }

  :link {
    text-decoration: none;
    color: ${COLORS.PURPLE_HEART};
  }
  :visited {
    text-decoration: none;
    color: ${COLORS.PURPLE_HEART};
  }
`;
