import { SelectProps } from "antd";
import { CSSProperties } from "react";

export enum MultiselectModes {
  Multiple = 'multiple',
  Tags = 'tags'
}
export interface IMultiselectProps<ChangeType extends string> {
  options: SelectProps["options"];
  style?: CSSProperties;
  filterPlaceholder?: string;
  onChange?: (values: Array<ChangeType> | ChangeType | string | undefined) => void;
  defaultValues?: Array<ChangeType> | ChangeType;
  mode?: MultiselectModes;
  rightPadding?: number;
  placeholder?: string;
}
