import React, { FC, useEffect } from "react";
import { InputTitle, InputWrapperElement, StyledLine, UpdateProfileButton } from "./styles";
import { InputImage, RequiredFieldInfo } from "../../styles";
import { IProfileForm } from "../../utils/types";
import { useFormik } from "formik";
import { ActivateText } from "../AdminPanelHeader/styles";
import { Input, message, Modal } from "antd";
import { useStore } from "../../utils/hooks";
import { observer } from "mobx-react-lite";
import { defaultErrorHandler } from "../../utils/helpers";
import { editUserFormValidator } from '../../utils/validators';
import { IMAGES } from '../../assets';

const EditUserProfileComponent: FC<any> = ({ visible, onCancel, ...props }) => {
  const { user } = useStore();
  const {
    values: { email, name },
    handleChange,
    errors,
    handleSubmit,
    handleBlur,
    touched,
    setValues,
  } = useFormik<IProfileForm>({
    onSubmit: async (payload) => {
      try {
        if (!email.length && !name.length) {
          message.error("All fields are empty");
          return;
        }
        await user.updateUserProfile(payload);
        message.success("Profile successfully updated");
        onCancel();
      } catch (err: any) {
        defaultErrorHandler(err);
      }
    },
    initialValues: {
      email: user?.email ? user.email : "",
      name: user?.name ? user.name : "",
    },
    validate: editUserFormValidator,
  });

  useEffect(() => {
    setValues({
      email: user?.email ? user.email : "",
      name: user?.name ? user.name : "",
    });
  }, [user.email, user.name, setValues]);

  return (
    <Modal footer={null} title={"Update Profile"} visible={visible} onCancel={onCancel} wrapClassName={"default-middle-modal"}>
      <form onSubmit={handleSubmit}>
        <InputWrapperElement>
          <InputTitle>Full Name</InputTitle>
          <Input
            name="name"
            type="name"
            value={name}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Full Name"
            prefix={<InputImage src={IMAGES.FULL_NAME} alt="Person field" />}
            maxLength={32}
          />
          <RequiredFieldInfo>{touched.name && errors.name && errors.name}</RequiredFieldInfo>
        </InputWrapperElement>
        <InputWrapperElement>
          <InputTitle>Your Email</InputTitle>
          <Input
            name="email"
            type="email"
            value={email}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Email Address"
            prefix={<InputImage src={IMAGES.EMAIL} alt="Email field" />}
            maxLength={42}
          />
          <RequiredFieldInfo>{touched.email && errors.email && errors.email}</RequiredFieldInfo>
        </InputWrapperElement>
        <StyledLine />
        <UpdateProfileButton type={"submit"} disabled={(user?.email === email && user.name === name) || !!Object.keys(errors).length}>
          <ActivateText>Update</ActivateText>
        </UpdateProfileButton>
      </form>
    </Modal>
  );
};

export const EditUserProfile = observer(EditUserProfileComponent);
