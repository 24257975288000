import { Checkbox } from "antd";
import styled from "styled-components";
import { COLORS } from "../../utils/colors";

export const KeyriCheckbox = styled(Checkbox)<{ color?: string }>`
  .ant-checkbox-disabled > .ant-checkbox-inner {
    border: none;
  }
  span:last-child {
    ${({ color }) => (color ? `color: ${color}` : "")}
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${COLORS.WHITE};
  }
`;
