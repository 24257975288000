import styled from "styled-components";
import { GeneralBlockShadow } from "../../../../styles";
import { COLORS } from "../../../../utils/colors";
import { FONTS } from "../../../../assets";
import { DIMENSIONS } from "../../../../utils/constants";

export const EventDetailsBlock = styled.div`
  column-count: 2;
  column-gap: 0;
  background-color: ${COLORS.WHITE};

  @media (max-width: ${DIMENSIONS.HEADER_MEDIUM_WIDTH}px) {
    column-count: 1;
  }
`;

export const EventDetailsWrapper = styled.div`
  ${GeneralBlockShadow};
  padding: 24px 20px;
`;

export const EventInfoBlock = styled.div`
  background-color: ${COLORS.WHITE};
  break-inside: avoid;
  padding: 10px 10px 35px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${DIMENSIONS.L_BREAKPOINT_WIDTH}px) {
    width: 100%;
  }
`;

export const EventDetailTitle = styled.div`
  font-family: ${FONTS.RUBIK};
  font-size: 18px;
  margin-bottom: 24px;
`;

export const DetailsList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0;
  padding: 0;
`;

export const DetailItem = styled.li`
  display: flex;
`;

export const DetailTitle = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-start;
  color: ${COLORS.MINE_SHAFT};
`;

export const DetailValue = styled.div`
  white-space: wrap;
`;

export const ShortValue = styled.div`
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DetailValuesList = styled.div`
  color: ${COLORS.GRAY};
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const IconStyle = {
  fontSize: "13px",
  lineHeight: "16px",
  color: `${COLORS.MEDIUM_PURPLE}`,
  marginRight: "12px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const ErrorMsg = styled.div`
  width: 100%;
  text-align: center;
  font-family: ${FONTS.RUBIK};
  color: ${COLORS.GRAY};
  font-size: 17px;
`;
