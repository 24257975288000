import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { Link } from "react-router-dom";
import { FONTS } from '../../assets';

export const ApplicationInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px 24px;
`;

export const Line = styled.div`
  border: 1px solid ${COLORS.GALLERY};
`;

export const CloseLink = styled(Link)`
  font-family: ${FONTS.RUBIK};
  font-size: 15px;
  color: ${COLORS.GRAY};
  align-self: center;
  
  &:hover {
    color: ${COLORS.RED};
  }
`;
