import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { IColoredTableItemProps } from "./props";

export const SearchResultsCount = styled.span`
  width: max-content;
  color: ${COLORS.GRAY};
  white-space: nowrap;
`;

export const MultiselectWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
`;

export const BaseColoredTableItem = `
  font-size: 12px;
  line-height: 19px;
  text-transform: capitalize;
  border-radius: 4px;
  padding: 1px 8px;
  border-width: 1px;
  border-style: solid;
  
`;

export const ColoredTableItem = styled.div<IColoredTableItemProps>(({ colorValue }) => {
  switch (colorValue) {
    case "green":
      return `
          ${BaseColoredTableItem};
          color: ${COLORS.PUERTO_RICO};
          background-color: ${COLORS.BLACK_SQUEEZE};
          border-color: ${COLORS.ICEBERG};
        `;
    case "orange":
      return `
          ${BaseColoredTableItem};
          color: ${COLORS.BRANDY_PUNCH};
          background-color: ${COLORS.BRIDAL_HEATH};
          border-color: ${COLORS.PEACH};
        `;
    case "red":
      return `
          ${BaseColoredTableItem};
          color: ${COLORS.CARNATION};
          background-color: ${COLORS.WISP_PINK};
          border-color: ${COLORS.CINDERELLA};
        `;
    case "over-red":
      return `
          ${BaseColoredTableItem};
          color: ${COLORS.RAW_SIENNA};
          background-color: ${COLORS.SAUVIGNON};
          border-color: ${COLORS.ROMANTIC};
        `;
    default:
      return `
        ${BaseColoredTableItem};
        color: ${COLORS.MEDIUM_PURPLE};
        background-color: ${colorValue};
        border-color: ${COLORS.MEDIUM_PURPLE};
      `;
  }
});

export const CroppedColumn = styled.div<IColoredTableItemProps>`
  ${({ colorValue }) => {
    return colorValue ? `color: ${colorValue};` : "";
  }}
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 120px;
`;
