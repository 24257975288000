import React, { FC, MouseEventHandler, useCallback, useEffect } from "react";
import { message } from "antd";
import { useFormik } from "formik";
import Icon from "@ant-design/icons";

import { IRegistrationsPerDeviceProps } from "./props";
import { ICONS } from "../../../../assets";
import { Input, KeyriSwitch } from "../../../../components";
import { defaultErrorHandler } from "../../../../utils/helpers";
import { FingerprintRulesList, InputStylePreset, IRegistrationsPerDeviceForm } from "../../../../utils/types";
import { registrationsPerDeviceFormValidator } from "../../../../utils/validators";
import { successParamChangeMessage } from "../../index";
import { KeyriError } from "../../../../utils/keyri-error";
import { NoWrappedBlock } from "../../styles";
import {
  CancelPackageButton,
  FieldWrapper,
  IconStyle,
  ParameterTitle,
  ParameterTitleContainer,
  ParameterValues,
  RiskFormDefault,
  RiskParameter,
  SavePackageButton,
} from "styles";

export const RegistrationsPerDevice: FC<IRegistrationsPerDeviceProps> = ({ disabled = false, initialValue, ruleStatus, handleEnableSwitch, onSubmit }) => {
  const {
    values: { denyRegistrationsPerDevices },
    handleChange,
    errors,
    handleSubmit,
    handleBlur,
    touched,
    resetForm,
  } = useFormik<IRegistrationsPerDeviceForm>({
    onSubmit: async () => {
      try {
        await onSubmit(denyRegistrationsPerDevices);
        message.success(successParamChangeMessage);
      } catch (err: unknown | KeyriError) {
        resetForm();
        defaultErrorHandler(err);
      }
    },
    initialValues: {
      denyRegistrationsPerDevices: initialValue,
    },
    validate: registrationsPerDeviceFormValidator,
  });

  useEffect(() => {
    errors.denyRegistrationsPerDevices && message.error(errors.denyRegistrationsPerDevices);
  }, [errors.denyRegistrationsPerDevices]);

  const cancelValueChanges = useCallback(() => {
    resetForm({
      values: {
        denyRegistrationsPerDevices: initialValue,
      },
    });
  }, [resetForm]);

  return (
    <RiskParameter data-name="registrations-per-device">
      <ParameterTitleContainer maxWidth={250} minWidth={250}>
        <Icon component={ICONS.USER_CARD} style={IconStyle} />
        <ParameterTitle style={{ lineHeight: "36px" }}>Registrations per Device</ParameterTitle>
      </ParameterTitleContainer>

      <NoWrappedBlock>
        <ParameterValues>
          <RiskFormDefault onSubmit={handleSubmit}>
            <FieldWrapper>
              <Input
                disabled={disabled || !ruleStatus}
                onBlur={handleBlur}
                name="denyRegistrationsPerDevices"
                type="number"
                value={denyRegistrationsPerDevices.toString()}
                onChange={handleChange}
                error={touched.denyRegistrationsPerDevices && errors.denyRegistrationsPerDevices ? errors.denyRegistrationsPerDevices : ""}
                preset={InputStylePreset.NarrowPaddedForPlaceholder}
                maxWidth={80}
                min={0}
                max={1000}
              />
            </FieldWrapper>

            {denyRegistrationsPerDevices !== initialValue && (
              <>
                <SavePackageButton
                  data-status={!Object.keys(errors).length ? "active" : "disabled"}
                  disabled={!!errors?.denyRegistrationsPerDevices}
                  data-name={"deny-registrations-per-device"}
                  onClick={handleSubmit as unknown as MouseEventHandler}
                />
                <CancelPackageButton data-name={"reset-deny-registrations-per-device"} onClick={cancelValueChanges} />
              </>
            )}
          </RiskFormDefault>
        </ParameterValues>
        <KeyriSwitch disabled={disabled} checked={ruleStatus} onChange={handleEnableSwitch(FingerprintRulesList.RegistrationsPerDevice)} />
      </NoWrappedBlock>
    </RiskParameter>
  );
};
