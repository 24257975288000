import styled from "styled-components";
import { COLORS } from "../../../../utils/colors";
import { FONTS } from "../../../../assets";
import Icon, { FolderOutlined } from "@ant-design/icons";
import { DIMENSIONS } from "../../../../utils/constants";

const baseUploadBlockSettings = `
  width: 50%;
  padding: 0px 15px 15px 15px;
  box-sizing: border-box;
  
  @media (max-width: ${DIMENSIONS.UPLOAD_COMPONENT_SMALL_SIZE}px) {
    width: 100%;
  }
  
  @media (max-width: ${DIMENSIONS.LIST_BLOCK}px) and (min-width: ${DIMENSIONS.UPLOAD_COMPONENT_SMALL_SIZE}px) {
    width: 50%;
  }
  
  @media (max-width: ${DIMENSIONS.L_BREAKPOINT_WIDTH}px) and (min-width: ${DIMENSIONS.LIST_BLOCK}px) {
    width: 100%;
  }
`;

export const TemplateLink = styled.a`
  color: ${COLORS.PURE};
  text-decoration: underline;
`;

export const UploadFormWrapper = styled.div`
  border-radius: 8px;
  width: 100%;
  background-color: ${COLORS.WHITE};
  display: flex;
  flex-wrap: wrap;
`;

export const UploadTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: ${FONTS.RUBIK_SEMI_BOLD};
  padding: 15px;
  width: 100%;
`;

export const DescriptionBlock = styled.div`
  ${baseUploadBlockSettings}
`;

export const UploadInstructionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const InstructionItemNumber = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid ${COLORS.MEDIUM_PURPLE};
  color: ${COLORS.MEDIUM_PURPLE};
  font-size: 15px;
  font-weight: 500;
  font-family: ${FONTS.RUBIK_SEMI_BOLD};
  margin-right: 16px;
  user-select: none;
`;

export const InstructionItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  font-family: ${FONTS.RUBIK};
  font-size: 15px;
  color: ${COLORS.GRAY};
  line-height: 17px;
`;

export const DragNDropBlock = styled.div`
  ${baseUploadBlockSettings}
`;

export const UploadControlBlock = styled.div`
  margin: 0;
  border-top: 1px solid ${COLORS.GRAY};
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const UploadButtons = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const UploadStatus = styled.span`
  display: flex;
  align-items: center;
  font-family: ${FONTS.RUBIK};
  padding: 15px;
  font-size: 14px;
  color: ${COLORS.GRAY};
`;

export const DragNDropZone = styled.div`
  border: 5px solid ${COLORS.MOON_RAKER_LIGHT};
  border-radius: 10px;
  width: 100%;
  min-height: 132px;
  height: 100%;
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const FolderIcon = styled(FolderOutlined)`
  color: ${COLORS.MEDIUM_PURPLE};
`;

export const UploadText = styled.span`
  color: ${COLORS.MEDIUM_PURPLE};
  cursor: pointer;
`;

export const CloudIcon = styled(Icon)`
  width: 32px;
  height: 23px;
  color: ${COLORS.MEDIUM_PURPLE};
  & > svg {
    width: 32px;
    height: 23px;
    color: ${COLORS.MEDIUM_PURPLE};
  }
`;

export const UploadZoneTitle = styled.span`
  color: ${COLORS.GRAY};
  font-family: ${FONTS.RUBIK};
  font-size: 15px;
`;
