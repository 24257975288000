import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { AddPasslessLogin, AdminRouteWrapper, PublicRouteWrapper } from "./components";
import { Routes as RoutesConstants } from "./utils/constants";
import {
  ActivityReportScreen,
  AddApplicationScreen,
  EchoReportScreen,
  FingerprintListsScreenWrapper,
  FingerprintLogsScreen,
  FingerprintRiskManagementScreen,
  LoginScreen,
  LogsScreen,
  MainScreen,
  Permissions,
  PlanBillingScreen,
  ServiceKeysCredentials,
  ServiceSettingsScreen,
  SignUpScreen,
  WelcomeScreen,
} from "./screens/";
import { useAuthenticatedUser, useStore, useUserServices } from "./utils/hooks";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga";
import { storageTokenChange } from "./utils/api";
import { ServiceTypeRouteRestriction } from "./components/Routes/ServiceTypeRouteRestriction";
import { ServiceType } from "./utils/types";
import { observer } from "mobx-react-lite";

const TRACKING_ID = "UA-198352952-2";
ReactGA.initialize(TRACKING_ID);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const { isFetch, setIsFetch, AuthMe } = useAuthenticatedUser();
  useUserServices();
  const {
    billing: { getPlans },
  } = useStore();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleModal = useCallback(() => setIsModalVisible((prevState) => !prevState), []);

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  useEffect(() => {
    window.addEventListener("storage", storageTokenChange, false);
    ReactGA.pageview(window.location.pathname + window.location.search);
    return () => {
      window.removeEventListener("storage", storageTokenChange);
    };
  }, []);

  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route
          path={RoutesConstants.SignUp}
          element={
            <PublicRouteWrapper>
              <SignUpScreen />
            </PublicRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.Login}
          element={
            <PublicRouteWrapper>
              <LoginScreen authCallback={AuthMe} />
            </PublicRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.Home}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} handleModal={handleModal}>
              <MainScreen />
            </AdminRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.QRAppearance}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} handleModal={handleModal}>
              <ServiceTypeRouteRestriction type={ServiceType.Authentication}>
                <ServiceSettingsScreen />
              </ServiceTypeRouteRestriction>
            </AdminRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.KeysAndCredentials}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} handleModal={handleModal}>
              <ServiceTypeRouteRestriction type={ServiceType.Authentication}>
                <ServiceKeysCredentials setIsFetch={setIsFetch} />
              </ServiceTypeRouteRestriction>
            </AdminRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.PlanBilling}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} handleModal={handleModal}>
              <ServiceTypeRouteRestriction type={ServiceType.Authentication}>
                <PlanBillingScreen setIsFetch={setIsFetch} />
              </ServiceTypeRouteRestriction>
            </AdminRouteWrapper>
          }
        />
        {/*<Route*/}
        {/*  path={RoutesConstants.ServiceRiskManagement}*/}
        {/*  element={*/}
        {/*    <AdminRouteWrapper isFetch={isFetch} hidden={false} handleModal={handleModal}>*/}
        {/*      <ServiceTypeRouteRestriction type={ServiceType.Authentication}>*/}
        {/*        <RiskManagementScreen />*/}
        {/*      </ServiceTypeRouteRestriction>*/}
        {/*    </AdminRouteWrapper>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path={RoutesConstants.EchoDashboard}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} handleModal={handleModal}>
              <ServiceTypeRouteRestriction type={ServiceType.EchoGuard}>
                <EchoReportScreen />
              </ServiceTypeRouteRestriction>
            </AdminRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.AuthenticationDashboard}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} handleModal={handleModal}>
              <ServiceTypeRouteRestriction type={ServiceType.Authentication}>
                <ActivityReportScreen />
              </ServiceTypeRouteRestriction>
            </AdminRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.AuthenticationServiceLogs}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} handleModal={handleModal}>
              <ServiceTypeRouteRestriction type={ServiceType.Authentication}>
                <LogsScreen />
              </ServiceTypeRouteRestriction>
            </AdminRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.FingerprintDashboard}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} handleModal={handleModal}>
              <ServiceTypeRouteRestriction type={ServiceType.Authentication}>
                <ActivityReportScreen />
              </ServiceTypeRouteRestriction>
            </AdminRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.FingerprintLists}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} handleModal={handleModal}>
              <ServiceTypeRouteRestriction type={ServiceType.Authentication}>
                <FingerprintListsScreenWrapper />
              </ServiceTypeRouteRestriction>
            </AdminRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.FingerprintLogs}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} handleModal={handleModal}>
              <ServiceTypeRouteRestriction type={ServiceType.Authentication}>
                <FingerprintLogsScreen />
              </ServiceTypeRouteRestriction>
            </AdminRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.FingerprintRiskManagement}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} handleModal={handleModal}>
              <ServiceTypeRouteRestriction type={ServiceType.Authentication}>
                <FingerprintRiskManagementScreen />
              </ServiceTypeRouteRestriction>
            </AdminRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.Welcome}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} isMenuHidden={true} backgroundImage={true} handleModal={handleModal}>
              <WelcomeScreen />
            </AdminRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.AddApplication}
          element={
            <AdminRouteWrapper isFetch={isFetch} hidden={false} isMenuHidden={true} adminWrapperPadding="0" handleModal={handleModal}>
              <AddApplicationScreen setIsFetch={setIsFetch} />
            </AdminRouteWrapper>
          }
        />
        <Route
          path={RoutesConstants.Permissions}
          element={
            <AdminRouteWrapper handleModal={handleModal} isFetch={isFetch} hidden={false} adminWrapperPadding="0">
              <Permissions />
            </AdminRouteWrapper>
          }
        />
        <Route path={"*"} element={<Navigate to={"/welcome"} replace={true} />} />
      </SentryRoutes>
      <AddPasslessLogin isModalVisible={isModalVisible} handleModal={handleModal} />
    </BrowserRouter>
  );
}

export default observer(App);
