import React, { FC } from "react";
import { RadioButton, RadioGroup } from "./styles";
import { IRadioButtonsProps } from "./props";

export const RadioButtons: FC<IRadioButtonsProps> = ({ handler, defaultValue, list, style }) => {
  return (
    <RadioGroup value={defaultValue} style={style}>
      {list.map((key) => (
        <RadioButton value={key.value} onClick={key.handler ? key.handler : handler} key={key.value}>
          {key.title}
        </RadioButton>
      ))}
    </RadioGroup>
  );
};
