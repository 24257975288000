import { FingerprintLogResult, FingerprintRiskParams, FingerprintSignals } from "../../utils/types";

export enum InternalLogsLinks {
  MainLogsPage = "/",
  EventDetails = "/:eventId/details",
}

export enum FingerprintModes {
  Events = "Events",
  Users = "Users",
  Devices = "Devices",
}

export interface ITableSignal {
  title: string;
  name: FingerprintSignals;
  color: string;
}

export interface ITableEvent {
  time: string;
  userId: string | null;
  deviceId: string;
  event: string;
  result: FingerprintLogResult;
  location: Array<string>;
  deviceType: string;
  client: string;
  risk: FingerprintRiskParams;
  signals: Array<ITableSignal>;
  key: string;
  id: string;
}

export interface ITableDevice {
  deviceId: string;
  lastLocation: Array<string>;
  deviceType: string;
  client: string;
  activity: Array<string>;
  lastActivity: string;
  signals: Array<ITableSignal>;
}
