import styled from "styled-components";
import { FONTS } from "../../assets";
import { COLORS } from "../../utils/colors";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { DIMENSIONS } from "../../utils/constants";

export const HeaderContainer = styled.div`
  padding: 32px 60px;

  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  background: ${COLORS.ALABASTER};

  @media (max-width: ${DIMENSIONS.WHITEPAPER_FORM}px) {
    padding: 32px 30px;
  }
`;

export const LogoButton = styled(Link)``;

export const Logo = styled.img`
  width: 85px;
`;

export const UserSettings = styled.div`
  display: flex;
  gap: 16px;
`;

export const ActivatePassless = styled.div`
  height: 40px;
  padding: 0 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  background: none;
  border: 1px solid ${COLORS.MEDIUM_PURPLE};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.5s linear;

  &:hover,
  &:active {
    background: ${COLORS.MOON_RAKER_LIGHT};
  }
`;

export const ActivateIcon = styled.img`
  width: 10px;
  height: 13px;
`;

export const ActivateText = styled.div`
  font-family: ${FONTS.RUBIK};
  font-size: 15px;
  line-height: 23px;
  color: ${COLORS.MEDIUM_PURPLE};
`;

export const Profile = styled.div`
  height: 40px;
  width: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${COLORS.SELAGO};
  border: 1px solid ${COLORS.MEDIUM_PURPLE};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.5s linear;

  &:hover > div,
  &:hover {
    background: none;
  }
`;

export const ProfileIcon = styled(UserOutlined)`
  font-size: 13px;
  color: ${COLORS.MEDIUM_PURPLE};
`;
