import { IServiceTypeRouteRestriction } from "./props";
import React, { FC } from "react";
import { useStore } from "../../utils/hooks";
import { generateRoute, getMainServicePage } from "../../utils/constants";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

const ServiceTypeRouteRestrictionComponent: FC<IServiceTypeRouteRestriction> = ({ type, children }) => {
  const {
    services: { currentService },
  } = useStore();

  return !currentService || currentService.type === type ? (
    <>{children}</>
  ) : (
    <Navigate to={generateRoute(getMainServicePage(currentService.type), { serviceId: currentService.id })} />
  );
};

export const ServiceTypeRouteRestriction = observer(ServiceTypeRouteRestrictionComponent);
