import { CheckboxGroup } from "../../CheckboxOptionsGroup";
import React, { useMemo } from "react";
import { IMobileSettingsProps } from "./props";
import { FingerprintRulesList, OutcomeValues } from "utils/types";
import { ICONS } from "assets";
import { getEnabledValue } from "utils/fingerprint-helper";
import { useStore } from "utils/hooks";
import { DevicesParamsAccessBlock, ParametersList, RiskManagementSubTitle } from "styles";

export const MobileSettings = ({ canEdit, handleEnableSwitch, riskChangeHandler }: IMobileSettingsProps) => {
  const {
    services: { currentService },
  } = useStore();

  const riskMobileOptions = useMemo(
    () => [
      {
        parameter: FingerprintRulesList.EmulatedDevice,
        defaultValue: currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.EmulatedDevice)?.outcome || OutcomeValues.Allow,
        title: "Emulated Device",
        icon: ICONS.MASK,
        enabled: getEnabledValue(currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.EmulatedDevice)),
      },
      {
        parameter: FingerprintRulesList.JailbrokenRooted,
        defaultValue:
          currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.JailbrokenRooted)?.outcome || OutcomeValues.Allow,
        title: "Jailbroken / Rooted",
        icon: ICONS.TAG,
        enabled: getEnabledValue(currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.JailbrokenRooted)),
      },
      {
        parameter: FingerprintRulesList.SwizzledTempered,
        defaultValue:
          currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.SwizzledTempered)?.outcome || OutcomeValues.Allow,
        title: "Swizzled / Tampered",
        icon: ICONS.NOT_EQUAL,
        enabled: getEnabledValue(currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.SwizzledTempered)),
      },
      {
        parameter: FingerprintRulesList.DangerousApps,
        defaultValue: currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.DangerousApps)?.outcome || OutcomeValues.Allow,
        title: "Dangerous Apps / Malicious Pkgs",
        icon: ICONS.DANGEROUS,
        enabled: getEnabledValue(currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.DangerousApps)),
      },
      {
        parameter: FingerprintRulesList.Debuggable,
        defaultValue: currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.Debuggable)?.outcome || OutcomeValues.Allow,
        title: "Debuggable",
        icon: ICONS.BUG,
        enabled: getEnabledValue(currentService?.fingerprintRules?.rules.find((rule) => rule.rule === FingerprintRulesList.Debuggable)),
      },
    ],
    [currentService]
  );

  return (
    <DevicesParamsAccessBlock>
      <RiskManagementSubTitle>Mobile App Security Settings (Enabled by Default):</RiskManagementSubTitle>
      <ParametersList>
        <CheckboxGroup handleEnableSwitch={handleEnableSwitch} disabled={true || !canEdit} options={riskMobileOptions} onCheckboxChange={riskChangeHandler} />
      </ParametersList>
    </DevicesParamsAccessBlock>
  );
};
