import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { FONTS } from "../../assets";
import { Radio } from "antd";
import { DIMENSIONS } from "../../utils/constants";

interface IColorProps {
  color: typeof COLORS[keyof typeof COLORS];
}

interface IMinWidthProps {
  minWidth?: number;
}

interface IPercentStyle {
  colorType: boolean;
}

export const Log = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const APIStatisticBlock = styled.div`
  align-items: flex-start;
  height: 57px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 0;
  max-width: 20%;
  min-width: 80px;
  padding: 0px;
  box-sizing: border-box;
`;

export const PercentDiffBlock = styled.div<IPercentStyle>`
  position: absolute;
  bottom: 12px;
  display: flex;
  flex-direction: row;
  right: -5px;
  transform: translateX(100%);
  font-size: 12px;
  line-height: 12px;
  align-items: flex-end;
  font-family: ${FONTS.RUBIK};
  color: ${({ colorType }) => (colorType ? COLORS.GREEN_HAZE : COLORS.RED)};
`;

export const LogNameContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  font-family: ${FONTS.MIRIAM_LIBRE};
  font-size: 13px;
  line-height: 16px;
  color: ${COLORS.SCORPION};
`;

export const LogName = styled.div`
  font-weight: 700;
  font-size: 12px;

  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const LogInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;

  width: 40%;
  min-width: 200px;
  max-width: 400px;
`;

export const LogCountsBlock = styled.div`
  display: flex;
  min-width: 200px;
  justify-content: space-around;
  align-items: flex-end;
`;

export const LogInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  font-family: ${FONTS.RUBIK};
`;

export const LogItem = styled.div<IColorProps>`
  font-size: 13px;
  color: ${COLORS.GRAY};

  position: relative;
  padding: 0 0 0 13px;

  &::before {
    content: "";
    width: 7px;
    height: 7px;

    position: absolute;
    left: 0;
    top: 6px;

    background: ${({ color }) => color};
    border-radius: 28px;
  }
`;

export const LogCount = styled.div`
  font-weight: 300;
  line-height: 33px;
  font-size: 30px;
  text-transform: uppercase;
  color: ${COLORS.SCORPION};
  font-family: ${FONTS.RUBIK_LIGHT};
`;

export const LogChart = styled.div``;

export const DoughnutReport = styled.div`
  width: 80px;
  height: 80px;
`;

export const APICallsBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const APICallsTitle = styled.span`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${COLORS.SCORPION};
  font-family: ${FONTS.MIRIAM_LIBRE};
`;

export const APICallsCount = styled.span`
  font-size: 30px;
  color: ${COLORS.SCORPION};
  font-family: ${FONTS.RUBIK_LIGHT};
  font-weight: 300;
  position: relative;
`;

export const BarChartWrapper = styled.div`
  height: 57px;
  flex-grow: 1;
  flex-shrink: 1;
  width: 80%;
`;

export const FlexGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  @media (max-width: ${DIMENSIONS.MAX_TABLET_WIDTH}px) {
    flex-wrap: wrap;
  }
`;

export const SubFlexGroup = styled.div<IMinWidthProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}px;` : "")}

  @media (max-width: ${DIMENSIONS.L_BREAKPOINT_WIDTH}px) {
    flex-wrap: wrap;
  }
`;

export const SelectDeviceContainer = styled.div``;
export const RadioGroup = styled(Radio.Group)``;

export const RadioButton = styled(Radio.Button)`
  font-family: ${FONTS.RUBIK};
  font-size: 14px;
  color: ${COLORS.SCORPION};
  background-color: ${COLORS.WHITE};

  &:hover {
    color: ${COLORS.MINE_SHAFT} !important;
    background-color: ${COLORS.WHITE_LILAC};
  }
`;

export const LogsContainer = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: ${DIMENSIONS.MAX_TABLET_WIDTH}px) {
    flex-wrap: wrap;
  }
`;

export const ControlBlock = styled.div<{ justifyContent?: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: ${({justifyContent}) => justifyContent ? justifyContent : 'space-between'};
`;
