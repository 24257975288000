import { flow, types } from "mobx-state-tree";
import { authAPI, clearAxiosConfig, responseHandler, userAPI } from "utils/api";
import { AxiosResponse } from "axios";
import { FingerprintLogEvents, IUserResponse } from "utils/types";
import { fingerprintHelper } from "utils/fingerprint-helper";
import { Auth, Response, Role } from "@denver23/keyri-shared";

const UserStore = types
  .model("UsersStore", {
    isFetch: types.boolean,
    id: types.maybe(types.string),
    createdAt: types.maybe(types.string),
    name: types.maybe(types.string),
    email: types.maybe(types.string),
    isLoggedIn: false,
    isSuperAdmin: false,
  })
  .actions((self) => ({
    loadMe: flow(function* () {
      try {
        self.isFetch = true;
        const {
          data: {
            data: { user },
          },
        }: AxiosResponse<Response.IApiResponse<IUserResponse>> = yield userAPI.me();
        self.id = user.id;
        self.createdAt = user.createdAt;
        self.name = user.name;
        self.email = user.email;
        self.isSuperAdmin = user.role === Role.SuperAdmin;
      } catch (err) {
        throw err;
      } finally {
        self.isFetch = false;
      }
    }),
    confirmUserAccount: flow(function* (verifyToken: string) {
      try {
        self.isFetch = true;
        const { data } = yield authAPI.confirmLogin(verifyToken);
        const { user } = data.data;
        fingerprintHelper.createEvent(FingerprintLogEvents.Login, user.email).catch((err) => console.error(err));

        self.id = user.id;
        self.createdAt = user.createdAt;
        self.name = user.name;
        self.email = user.email;
        self.isSuperAdmin = user.role === Role.SuperAdmin;
        responseHandler({ data } as AxiosResponse<Response.IApiResponse<Auth.Response.VerifyLogin>>);
        self.isLoggedIn = true;
      } catch (err) {
        throw err;
      } finally {
        self.isFetch = false;
      }
    }),
    setLoggedIn: function (value: boolean) {
      self.isLoggedIn = value;
    },
    resetUserData: function () {
      self.id = undefined;
      self.createdAt = undefined;
      self.name = undefined;
      self.email = undefined;
      self.isSuperAdmin = false;
    },
    updateUserProfile: flow(function* (data: { name?: string; email?: string }) {
      if (data.name) fingerprintHelper.createEvent(FingerprintLogEvents.ProfileUpdate, self.email as string).catch((err) => console.error(err));
      const {
        data: { data: responseData },
      } = yield userAPI.updateUser(data);

      self.id = responseData.user.id;
      self.createdAt = responseData.user.createdAt;
      self.name = responseData.user.name;
      self.email = responseData.user.email;
      self.isSuperAdmin = responseData.user.role === Role.SuperAdmin;
    }),
  }))
  .actions((self) => ({
    logout: function () {
      //yield userAPI.logout();
      clearAxiosConfig();
      self.isLoggedIn = false;
      self.resetUserData();
    },
  }));

export default UserStore;
