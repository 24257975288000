const mobileDevices: string[] = ["iPhone", "iPad", "iPod", "Android"];

export enum DesktopEnum {
  Windows = "Windows",
  Mac = "Mac OS",
  Unknown = "Unknown",
}

export function getOSWithVersionByUserAgent(userAgent: string): { name: string; platform: string } {
  // system
  let os = { name: "unknown", platform: "unknown" };
  const clientStrings: Array<{ s: string; r: RegExp; platform: string }> = [
    { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/, platform: "Desktop" },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/, platform: "Desktop" },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/, platform: "Desktop" },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/, platform: "Desktop" },
    { s: "Windows Vista", r: /Windows NT 6.0/, platform: "Desktop" },
    { s: "Windows Server 2003", r: /Windows NT 5.2/, platform: "Desktop" },
    { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/, platform: "Desktop" },
    { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/, platform: "Desktop" },
    { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/, platform: "Desktop" },
    { s: "Windows 98", r: /(Windows 98|Win98)/, platform: "Desktop" },
    { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/, platform: "Desktop" },
    { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/, platform: "Desktop" },
    { s: "Windows CE", r: /Windows CE/, platform: "Desktop" },
    { s: "Windows 3.11", r: /Win16/, platform: "Desktop" },
    { s: "Android", r: /Android/, platform: "Mobile" },
    { s: "Open BSD", r: /OpenBSD/, platform: "Desktop" },
    { s: "Sun OS", r: /SunOS/, platform: "Desktop" },
    { s: "Chrome OS", r: /CrOS/, platform: "Desktop" },
    { s: "Linux", r: /(Linux|X11(?!.*CrOS))/, platform: "Desktop" },
    { s: "iOS", r: /(iPhone|iPad|iPod)/, platform: "Mobile" },
    { s: "Mac OS X", r: /Mac OS X/, platform: "Desktop" },
    { s: "Mac OS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/, platform: "Desktop" },
    { s: "QNX", r: /QNX/, platform: "Desktop" },
    { s: "UNIX", r: /UNIX/, platform: "Desktop" },
    { s: "BeOS", r: /BeOS/, platform: "Desktop" },
    { s: "OS/2", r: /OS\/2/, platform: "Desktop" },
    { s: "Search Bot", r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/, platform: "Other" },
  ];
  for (let id in clientStrings) {
    let cs = clientStrings[id];
    if (cs.r.test(userAgent)) {
      os = { name: cs.s, platform: cs.platform };
      break;
    }
  }
  return os;
}

export function getOSbyUserAgent(userAgent: string): { name: string; platform: string } {
  // system
  let os = { name: "unknown", platform: "unknown" };
  const clientStrings: Array<{ s: string; r: RegExp; platform: string }> = [
    { s: "Windows", r: /Windows/, platform: "Desktop" },
    { s: "Android", r: /Android/, platform: "Mobile" },
    { s: "Open BSD", r: /OpenBSD/, platform: "Desktop" },
    { s: "Sun OS", r: /SunOS/, platform: "Desktop" },
    { s: "Chrome OS", r: /CrOS/, platform: "Desktop" },
    { s: "Linux", r: /(Linux|X11(?!.*CrOS))/, platform: "Desktop" },
    { s: "iOS", r: /(iPhone|iPad|iPod)/, platform: "Mobile" },
    { s: "MacOS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh|Mac OS)/, platform: "Desktop" },
    { s: "QNX", r: /QNX/, platform: "Desktop" },
    { s: "UNIX", r: /UNIX/, platform: "Desktop" },
    { s: "BeOS", r: /BeOS/, platform: "Desktop" },
    { s: "OS/2", r: /OS\/2/, platform: "Desktop" },
    { s: "Search Bot", r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/, platform: "Other" },
  ];
  for (let id in clientStrings) {
    let cs = clientStrings[id];
    if (cs.r.test(userAgent)) {
      os = { name: cs.s, platform: cs.platform };
      break;
    }
  }
  return os;
}

export function getBrowserByUserAgent(userAgent: string) {
  let browserName;
  switch (true) {
    case /edg/i.test(userAgent):
      browserName = "Edge";
      break;
    case /chrome|chromium|crios/i.test(userAgent):
      browserName = "Chrome";
      break;
    case /safari/i.test(userAgent):
      browserName = "Safari";
      break;
    case /firefox|fxios/i.test(userAgent):
      browserName = "Firefox";
      break;
    case /opr\//i.test(userAgent):
      browserName = "Opera";
      break;
    case /msie/i.test(userAgent):
      browserName = "IE";
      break;
    case /trident/i.test(userAgent):
      browserName = "IE";
      break;
    default:
      browserName = "Unknown browser";
  }

  return browserName;
}

export enum DevicesEnum {
  Android = "Android",
  iOS = "iOS",
  Other = "Other",
}

export function isMobileDevice() {
  let isMobile: boolean = false;
  mobileDevices.forEach((item) => {
    const check: number = navigator.userAgent.indexOf(item);
    if (check !== -1) {
      isMobile = true;
    }
  });
  return isMobile;
}

export const getDeviceFromUserAgent = (userAgent: string, { platform }: { platform?: boolean } = {}): string => {
  const browserName = getBrowserByUserAgent(userAgent);
  const os = getOSbyUserAgent(userAgent);

  return `${browserName} on ${os.name}${platform ? `  (${os.platform})` : ""}`;
};
