import { DurationPeriod, Name, PlanContainer, PlanTitle, PlussesList, Price } from "./styles";
import React, { FC, useMemo } from "react";
import { IPlanInfoProps } from "./props";
import { ShortDescription } from "../SetupBillingPlans/styles";

enum PriceTypes {
  Free = "Free",
  Custom = "Custom",
}

const intervalTitle: Record<string, string> = {
  calendar_month: "month",
  month: "month",
};

const planShorts: Record<string, string> = {
  Developer: "Free to start using Keyri today",
  Scaling: "Launch and scale production applications with unlimited calls",
  Enterprise: "Tailored solutions with dedicated support",
};

function ConverterToComponent({ htmlString }: { htmlString: string }) {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export const PlanInfo: FC<IPlanInfoProps> = ({ plan: { name, price, interval, description, amberfloDescription }, shortDescription }) => {
  const planPrice = useMemo(() => (typeof price === "number" ? `$${price}` : PriceTypes.Custom), [price]);
  const formattedName = useMemo(() => {
    return name.replace(/^Fraud [a-zA-Z0-9_]* - /, "");
  }, [name]);

  return (
    <PlanContainer>
      <PlanTitle>
        <Name>{formattedName}</Name>
        <ShortDescription>{shortDescription ? shortDescription : planShorts[name]}</ShortDescription>
        <Price>
          {planPrice === PriceTypes.Custom ? (
            "Custom"
          ) : (
            <>
              {planPrice}
              {interval && <DurationPeriod> / {intervalTitle[interval]}</DurationPeriod>}
            </>
          )}
        </Price>
      </PlanTitle>
      <PlussesList>
        <ConverterToComponent htmlString={description ?? ""} />
      </PlussesList>
    </PlanContainer>
  );
};
