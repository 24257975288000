import React, { FC } from "react";
import { DatePicker } from "antd";
import { IKeyriDatePickerProps } from "./props";
import { DatePickerSizesEnum, DatePickerWrapper } from "./styles";
import moment from "moment";

export const KeyriDatePicker: FC<IKeyriDatePickerProps> = ({ value, error = false, size = DatePickerSizesEnum.Medium, maxWidth, ...props }) => {
  return (
    <DatePickerWrapper error={error} size={size} maxWidth={maxWidth}>
      <DatePicker value={value ? moment(value) : undefined} {...props} />
    </DatePickerWrapper>
  );
};
