import React, { FC } from "react";
import { message, Modal } from "antd";
import { InputWrapperElement, StyledLine } from "../EditUserProfile/styles";
import { InputLabel, RequiredFieldInfo } from "../../styles";
import { Input } from "../Input";
import { IDeleteAppForm, InputStylePreset } from "../../utils/types";
import { useFormik } from "formik";
import { DeleteAppButton, DeleteButtonText, DeleteDescription, DeleteIcon, InputImageDanger } from "./styles";
import { ICONS } from "../../assets";
import { AppDetailsIcon } from "../AppDetails/styles";
import { useStore } from "../../utils/hooks";
import { useNavigate } from "react-router-dom";
import { generateRoute, Routes } from "../../utils/constants";
import { observer } from "mobx-react-lite";
import { defaultErrorHandler } from "../../utils/helpers";

const DeleteAppModalComponent: FC<any> = ({ visible, onCancel }) => {
  const {
    services: { currentService },
    services,
  } = useStore();
  const navigate = useNavigate();
  const {
    values: { appName },
    handleChange,
    errors,
    handleSubmit,
    handleBlur,
    touched,
  } = useFormik<IDeleteAppForm>({
    onSubmit: async (payload) => {
      try {
        if (!currentService || appName !== currentService.name.toUpperCase()) {
          message.error("Please confirm you entered the correct confirmation code");
          return;
        }
        const { status, updatedServicesList } = await services.deleteCurrentService();
        if (status === 204) message.success("Service was deleted");
        if (updatedServicesList![0]) {
          navigate(generateRoute(Routes.KeysAndCredentials, { serviceId: updatedServicesList![0].id }));
        } else {
          navigate(Routes.Welcome);
        }
      } catch (err: any) {
        defaultErrorHandler(err);
      }
    },
    initialValues: {
      appName: "",
    },
  });

  return (
    <Modal footer={null} title={"Delete Application"} visible={visible} onCancel={onCancel} wrapClassName={"default-middle-modal"}>
      <DeleteDescription>
        This will permanently delete all records pertaining to this application. Any existing integrations to it will stop working, preventing users to
        authenticate into it.
        <br />
        <br />
        To   confirm delete, please enter the application name “{currentService?.name.toUpperCase()}” in all-caps.
      </DeleteDescription>
      <form onSubmit={handleSubmit}>
        <InputWrapperElement>
          <InputLabel>
            <InputImageDanger component={ICONS.DANGER} style={AppDetailsIcon} />
            <Input
              name="appName"
              type="appName"
              placeholder="Enter Confirmation"
              value={appName}
              onChange={handleChange}
              onBlur={handleBlur}
              preset={InputStylePreset.DangerInput}
              maxWidth={330}
            />
          </InputLabel>
          <RequiredFieldInfo>{touched.appName && errors.appName && errors.appName}</RequiredFieldInfo>
        </InputWrapperElement>
        <StyledLine />
        <DeleteAppButton type={"submit"}>
          <DeleteIcon />
          <DeleteButtonText>Confirm Delete</DeleteButtonText>
        </DeleteAppButton>
      </form>
    </Modal>
  );
};

export const DeleteAppModal = observer(DeleteAppModalComponent);
