import React, { FC } from "react";
import { AdditionalBlock, FormWrapper, TitleMessage } from "../../styles";
import { IMAGES } from "../../assets";
import { COLORS } from "../../utils/colors";
import { VerificationContainer, VerificationImage } from "./styles";
import { IMailVerificationProps } from "./props";

export const MailVerification: FC<IMailVerificationProps> = ({ type }) => {
  return (
    <FormWrapper maxWidth={414} maxHeight={467}>
      <VerificationContainer>
        <VerificationImage src={IMAGES.REGISTRATION_MAIL} alt="Please verify your email" />
        <TitleMessage>
          We’ve emailed you a {type === "login" ? "login" : "sign-up"} link
        </TitleMessage>
      </VerificationContainer>
      <AdditionalBlock color={COLORS.GRAY} fontSize="14px">
        Please check your spam folder if you do not see it in your inbox.
      </AdditionalBlock>
      <AdditionalBlock color={COLORS.GRAY} fontSize="14px">
        Please verify your email address by clicking the emailed link to start using Keyri.
      </AdditionalBlock>
    </FormWrapper>
  );
};
