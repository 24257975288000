import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { IColoredTableItemProps } from "./props";
import { LockOutlined } from "@ant-design/icons";

export const SearchResultsCount = styled.span`
  width: max-content;
  color: ${COLORS.GRAY};
  white-space: nowrap;
`;

export const BaseColoredTableItem = `
  font-size: 12px;
  line-height: 19px;
  text-transform: capitalize;
  border-radius: 4px;
  padding: 1px 8px;
  border-width: 1px;
  border-style: solid;
  
`;

export const ColoredTableItem = styled.div<IColoredTableItemProps>(
  ({ colorValue }) => {
    switch (colorValue) {
      case "green":
        return `
          ${BaseColoredTableItem};
          color: ${COLORS.PUERTO_RICO};
          background-color: ${COLORS.BLACK_SQUEEZE};
          border-color: ${COLORS.ICEBERG};
        `;
      case "orange":
        return `
          ${BaseColoredTableItem};
          color: ${COLORS.BRANDY_PUNCH};
          background-color: ${COLORS.BRIDAL_HEATH};
          border-color: ${COLORS.PEACH};
        `;
      case "red":
        return `
          ${BaseColoredTableItem};
          color: ${COLORS.CARNATION};
          background-color: ${COLORS.WISP_PINK};
          border-color: ${COLORS.CINDERELLA};
        `;
      case "over-red":
        return `
          ${BaseColoredTableItem};
          color: ${COLORS.RAW_SIENNA};
          background-color: ${COLORS.SAUVIGNON};
          border-color: ${COLORS.ROMANTIC};
        `;
    }
  }
);

export const DeniedAccessTableItem = styled(LockOutlined)`
  ${BaseColoredTableItem};

  font-size: 10px;
  line-height: 12px;

  padding: 5px 8px 4px;
  color: ${COLORS.MINE_SHAFT};
  background-color: ${COLORS.GALLERY};
  border-color: ${COLORS.ALTO};
`;
