import { message } from "antd";
import { IPlanMX } from "stores/BillingStore";
import * as Sentry from "@sentry/react";
import { KeyriError } from "./keyri-error";
import { InternalLinks, ListType } from "screens/FingerprintListsScreen/types";
import { OutcomeValues, ServiceType } from "./types";
import { planNaming, Routes } from "./constants";
import { IPlanTypes } from "components";
import { ICustomRuleCondition } from "screens/FingerprintRiskManagement/types";
import { convertStringToFingerprintRuleValue } from "./fingerprint-helper";

export const detectTargetMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);
};

export const sortPlansHelper = (first: IPlanMX, second: IPlanMX) => {
  return first.order - second.order;
};

export const parseQuery = (queryString: string): Record<string, string | string[]> => {
  const query: { [key: string]: string | string[] } = {};
  if (!queryString) return query;
  const pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split("=");
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1] || "");
    if (key !== "") {
      if (query.hasOwnProperty(key)) {
        if (Array.isArray(query[key])) {
          (query[key] as Array<any>).push(value);
        } else {
          query[key] = [query[key] as string, value];
        }
      } else {
        query[key] = value;
      }
    }
  }
  return query;
};

export const copyToClipboard = async (str: string) => {
  if (!navigator.clipboard) return false;
  try {
    await navigator.clipboard.writeText(str);
    return true;
  } catch (err) {
    return false;
  }
};

export const imageSize = async (url: string): Promise<{ width: number; height: number }> => {
  const img = document.createElement("img");
  const promise: Promise<{ width: number; height: number }> = new Promise((resolve, reject) => {
    img.onload = () => {
      // Natural size is the actual image size regardless of rendering.
      // The 'normal' `width`/`height` are for the **rendered** size.
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      // Resolve promise with the width and height
      resolve({ width, height });
    };

    // Reject promise on error
    img.onerror = reject;
  });

  // Setting the source makes it start downloading and eventually call `onload`
  img.src = url;
  return promise;
};

export const defaultErrorHandler = (err: any | KeyriError) => {
  if (err.code === 1037) return;
  message.error(err.message);
  if (err instanceof KeyriError) return;
  Sentry.captureException(err);
};

export function parseQueryArray(value: string | undefined): Array<string> {
  return !!value && value?.split(",").length ? value?.split(",") : [];
}

export function getTitleByListType(listType: ListType) {
  switch (listType) {
    case ListType.Country:
      return "Country";
    case ListType.IMEI:
      return "IMEI";
    case ListType.UserID:
      return "User ID";
    case ListType.DeviceID:
      return "Device ID";
    case ListType.IP:
      return "IP";
    default:
      return "Value";
  }
}

export function getUrlByListType(listType: ListType): InternalLinks | "" {
  switch (listType) {
    case ListType.Country:
      return InternalLinks.Country;
    case ListType.IP:
      return InternalLinks.IP;
    case ListType.DeviceID:
      return InternalLinks.DeviceID;
    case ListType.UserID:
      return InternalLinks.UserID;
    case ListType.IMEI:
      return InternalLinks.IMEI;
    default:
      return "";
  }
}

export function sortOutcomeValues(outcomes: any): Array<OutcomeValues> {
  const outcomeIndexes: Record<OutcomeValues, number> = {
    [OutcomeValues.Allow]: 0,
    [OutcomeValues.Warn]: 1,
    [OutcomeValues.Deny]: 2,
  };
  return [...outcomes].sort((firstOutcome, secondOutcome) =>
    outcomeIndexes[firstOutcome as OutcomeValues] > outcomeIndexes[secondOutcome as OutcomeValues] ? 1 : -1
  );
}

export function getLocationString(location: { city: string; region: string; country: string; regionCode: string }): string {
  return `${location.city}, ${location.country === "United States" ? location.regionCode : location.country}`;
}

export function formatLocation<T extends { location: string; ip: string } | Array<{ location: string; ip: string }>>(locations: T): string | string[] {
  const formatSingleLocation = (location: { location: string; ip: string }): string | null => {
    const parsedLocation = JSON.parse(location.location);
    if (!parsedLocation.region) return null;
    const locationString = getLocationString(parsedLocation);
    return `${locationString} (${location.ip})`;
  };

  if (Array.isArray(locations)) {
    const formattedLocations = locations.map(formatSingleLocation).filter(Boolean);
    return formattedLocations as string[];
  } else {
    const formattedLocation = formatSingleLocation(locations);
    return formattedLocation ? formattedLocation : "";
  }
}

export function generateQueryString(queryParams: Record<string, string | number | boolean | Array<string | number | boolean>>): string {
  const params = new URLSearchParams();
  for (const key in queryParams) {
    const value = queryParams[key];
    if (Array.isArray(value)) {
      value.forEach((val) => params.append(key, val.toString()));
    } else {
      params.append(key, value.toString());
    }
  }
  return params.toString();
}

export function filterPlans(plans: Array<any>, plansType: IPlanTypes) {
  let filteredPlans: Array<any> = plans;
  const { filterRegEx, echoFilterRegEx } = planNaming;
  switch (plansType) {
    case IPlanTypes.AuthFraud:
      filteredPlans = plans.filter((plan) => !plan.name.match(filterRegEx) && !plan.name.match(echoFilterRegEx));
      break;
    case IPlanTypes.EchoGuard:
      filteredPlans = plans.filter((plan) => plan.name.match(echoFilterRegEx));
      break;
  }
  return filteredPlans;
}

export function getDefaultServiceScreen(serviceType: ServiceType): Routes {
  switch (serviceType) {
    case ServiceType.Authentication:
      return Routes.AuthenticationDashboard;
    case ServiceType.EchoGuard:
      return Routes.EchoDashboard;
    default:
      return Routes.AddApplication;
  }
}

export function buildNoCodeRuleCondition(condition: ICustomRuleCondition) {
  return {
    [condition.condition]: { [`${condition.comparator}`]: convertStringToFingerprintRuleValue(condition.value) },
  };
}

export const initConditionValue = { condition: "", comparator: null, value: "" };
export const initConditionsData: Array<ICustomRuleCondition> = [initConditionValue];

export function convertRuleConditionToFormFields(condition: any) {
  if (typeof condition !== "object") return initConditionsData;
  const conditionKeys = Object.keys(condition);
  if (!conditionKeys.length) return initConditionsData;
  const comparatorKeys = Object.keys(condition[conditionKeys[0]]);
  if (!comparatorKeys.length) return initConditionsData;
  switch (comparatorKeys[0]) {
    case "$in":
      return { condition: condition[conditionKeys[0]][comparatorKeys[0]], comparator: comparatorKeys[0], value: conditionKeys[0] };
    case "$nin":
      return { condition: condition[conditionKeys[0]][comparatorKeys[0]], comparator: comparatorKeys[0], value: conditionKeys[0] };
    default:
      return { condition: conditionKeys[0], comparator: comparatorKeys[0], value: condition[conditionKeys[0]][comparatorKeys[0]] };
  }
}

export function escapeString(str: string) {
  return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export const getRoundToValue = (maxCalculatedValue: number): number => {
  switch (true) {
    case maxCalculatedValue <= 10:
      return 10;
    case maxCalculatedValue <= 100:
      return 25;
    case maxCalculatedValue <= 500:
      return 100;
    case maxCalculatedValue <= 1000:
      return 250;
    case maxCalculatedValue <= 10000:
      return 1000;
    default:
      return 2500;
  }
};

function generateDateRange(startDate: Date, endDate: Date): Array<Date> {
  const currentDate = startDate;
  const dates = [];

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

// Fill empty days with zero
export function fillEmptyDaysWithZero(groupedEvents: any[], startDate: Date, endDate: Date): any[] {
  const dateRange = generateDateRange(startDate, endDate);
  const filledGroupedEvents = dateRange.map((date) => {
    const dateString = date.toISOString().split("T")[0]; // Get date string in 'YYYY-MM-DD' format
    const event = groupedEvents.find((event) => event.date === dateString);

    if (event) {
      return event;
    } else {
      return {
        date: dateString,
        count: 0,
        allowCount: 0,
        denyCount: 0,
        warnCount: 0,
      };
    }
  });

  return filledGroupedEvents;
}

export function validateTimeFromQuery(time: string): number {
  return isNaN(Number(time)) ? 0 : Math.floor(Number(time));
}

export function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
