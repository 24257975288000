import { FC } from "react";
import { MainContentWrapper } from "../../styles";
import { useStore } from "../../utils/hooks";
import { Navigate } from "react-router";
import { Routes } from "../../utils/constants";
import IPublicRouteProps from "./props";
import { Background } from "./styles";
import { observer } from "mobx-react-lite";
import { DefaultLoader } from "../DefaultLoader";
import { LoaderBackground } from "../DefaultLoader/styles";

const PublicRouteWrapperComponent: FC<IPublicRouteProps> = ({ redirect = true, children }) => {
  const {
    user: { isLoggedIn, isFetch },
  } = useStore();

  return (
    <>
      {isFetch ? (
        <>
          <LoaderBackground>
            <DefaultLoader />
          </LoaderBackground>
        </>
      ) : (
        isLoggedIn && redirect && <Navigate to={Routes.Home} />
      )}
      <Background>
        <MainContentWrapper>{children}</MainContentWrapper>
      </Background>
    </>
  );
};

export const PublicRouteWrapper = observer(PublicRouteWrapperComponent);
