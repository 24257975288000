import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BlockTitle, MenuItemContainer, MenuItemContent } from "styles";
import { IPermissions } from "./props";
import { Input, message, Select, Table, Tag, Tooltip } from "antd";
import { CloseOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import { AddNewMemberContainer, DeleteButton, NewMembersBlock, RoleNameField, SelectContainer } from "./styles";
import { ButtonWithIcon, DefaultLoader } from "components";
import { COLORS } from "utils/colors";
import { useStore } from "utils/hooks";
import { IMemberStatus, IServiceMember } from "utils/types";
import { defaultErrorHandler } from "utils/helpers";
import { roleOrders } from "utils/constants";
import { LoaderBackground } from "components/DefaultLoader/styles";
import { KeyriError } from "utils/keyri-error";
import { ServicesStatusesEnum } from "stores/ServicesStore";
import { Role } from "@denver23/keyri-shared";

const { Option } = Select;

export const Permissions: FC<IPermissions> = () => {
  const {
    services: { currentService },
    user,
  } = useStore();

  const currentUserRole = useMemo(() => currentService?.members?.find((member) => member.id === user.id)?.UserService?.role, [user, currentService?.members]);

  return (
    <MenuItemContainer>
      <MenuItemContent>
        <BlockTitle>Members</BlockTitle>
        {currentUserRole !== Role.Viewer && <AddNewMember />}
        <MembersTable />
      </MenuItemContent>
    </MenuItemContainer>
  );
};

const MembersTable: FC<any> = () => {
  const {
    services: { currentService, changeUserRole, changeInviteRole, removeMember, deleteInvite, setCurrentService, servicesStatus },
    user,
  } = useStore();
  const currentUserRole = useMemo(() => currentService?.members?.find((member) => member.id === user.id)?.UserService?.role, [currentService, user.id]);

  const handleRoleChange = useCallback(
    (id: string) => {
      return async (role: Role) => {
        try {
          if (!currentService) return;

          const payload = {
            serviceId: currentService.id,
            role,
          };
          let finalMember;
          switch (true) {
            case currentService.members?.findIndex((member) => member.id === id) !== -1: {
              await changeUserRole(id, payload);
              finalMember = currentService.members?.find((member) => member.id === id);
              break;
            }
            case currentService.invited?.findIndex((member) => member.id === id) !== -1: {
              const invite = await changeInviteRole(id, payload);
              finalMember = invite;
              break;
            }
            default: {
              throw new KeyriError(997);
            }
          }

          message.success(`Assigned new role to user ${finalMember?.email} - ${role}`);
        } catch (err: unknown | KeyriError) {
          defaultErrorHandler(err);
        }
      };
    },
    [changeInviteRole, changeUserRole, currentService]
  );

  const handleDeleteMember = useCallback(
    (member: IServiceMember) => {
      return async () => {
        try {
          if (!currentService) return;
          if (member.status === IMemberStatus.Accepted) {
            await removeMember(currentService.id, member.id);
          } else {
            await deleteInvite(currentService.id, member.email);
          }
          await setCurrentService(currentService.id);
          message.success("Member was deleted");
        } catch (err: unknown | KeyriError) {
          defaultErrorHandler(err);
        }
      };
    },
    [currentService, deleteInvite, removeMember, setCurrentService]
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 269,
      render(text: string) {
        return {
          props: {
            style: { color: `${COLORS.MINE_SHAFT}` },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 269,
      render(text: string) {
        return {
          props: {
            style: { color: `${COLORS.GRAY}` },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 300,
      render(text: string) {
        return {
          props: {
            style: { color: `${COLORS.GRAY}` },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Permissions",
      key: "role",
      dataIndex: "role",
      width: 180,
      render(userRole: Role, item: IServiceMember) {
        const options = Object.values(Role).filter((role) => roleOrders[role] < roleOrders[currentUserRole ? (currentUserRole as Role) : Role.Viewer]);
        return {
          props: {
            style: { color: `${COLORS.GRAY}` },
          },
          children:
            item.edited && options.length > 1 ? (
              <Select
                defaultValue={userRole}
                className="member-access-select member-access-select--table"
                bordered={false}
                onChange={handleRoleChange(item.id)}
              >
                <Option value={Role.Admin}>Admin</Option>
                <Option value={Role.Viewer}>Viewer</Option>
              </Select>
            ) : (
              <RoleNameField>{userRole}</RoleNameField>
            ),
        };
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      width: 100,
      render(text: string, member: IServiceMember) {
        return {
          props: {
            style: { color: `${COLORS.GRAY}` },
          },
          children: member.edited && (
            <DeleteButton onClick={handleDeleteMember(member)}>
              <CloseOutlined />
            </DeleteButton>
          ),
        };
      },
    },
  ];

  const tableMembers: Array<IServiceMember> = useMemo(() => {
    if (!currentService) return [];

    const invitedUsers = currentService.invited?.map((invite) => {
      const isUserRoleUpperMember = roleOrders[currentUserRole as Role] >= roleOrders[invite.role as Role] && currentUserRole !== Role.Viewer;
      return {
        id: invite.id,
        name: "Non-existing user",
        email: invite.email,
        status: IMemberStatus.Invited,
        role: invite.role as Role,
        edited: isUserRoleUpperMember,
      };
    });
    const members = currentService.members?.map((member) => {
      const isUserRoleUpperMember =
        roleOrders[currentUserRole as Role] >= roleOrders[member.UserService?.role as Role] &&
        currentUserRole !== Role.Viewer &&
        member.UserService?.role !== Role.Owner &&
        user.id !== member.id;
      return {
        id: member.id,
        name: member.name,
        email: member.email,
        status: IMemberStatus.Accepted,
        role: member.UserService?.role as Role,
        edited: isUserRoleUpperMember,
      };
    });
    return [...(invitedUsers || []), ...(members ? members : [])];
  }, [currentService, currentUserRole]);

  return (
    <Table
      loading={{
        spinning: servicesStatus === ServicesStatusesEnum.Loading,
        indicator: (
          <LoaderBackground>
            <DefaultLoader />
          </LoaderBackground>
        ),
        wrapperClassName: "tableSpinner",
      }}
      scroll={{
        x: "max-content",
      }}
      className={"members-table"}
      columns={columns}
      dataSource={tableMembers}
      pagination={false}
    />
  );
};

const AddNewMember: FC<any> = () => {
  const {
    services: { currentService, setCurrentService, inviteUsers },
  } = useStore();
  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const [inviteRole, setInviteRole] = useState<Role>(Role.Viewer);
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  useEffect(() => {
    if (inputVisible && typeof (inputRef.current as any)?.focus === "function") {
      // @ts-ignore
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    // @ts-ignore
    if (typeof (editInputRef.current as any)?.focus === "function") editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }

    setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  }, []);

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };

  const handleInviteRoleChange = useCallback((role: Role) => {
    setInviteRole(role);
  }, []);

  const handleInvite = useCallback(async () => {
    try {
      if (!currentService) return;
      await inviteUsers(currentService.id, inviteRole, tags);
      await setCurrentService(currentService.id);
      message.success(tags.length > 1 ? "Users were invited" : "User was invited");
    } catch (err: any) {
      defaultErrorHandler(err);
    }
  }, [tags, inviteRole, currentService, inviteUsers, setCurrentService]);

  return (
    <AddNewMemberContainer>
      <NewMembersBlock>
        {tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={editInputRef}
                key={tag}
                size="small"
                className="tag-input"
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
              />
            );
          }

          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag className="edit-tag" key={tag} closable onClose={() => handleClose(tag)}>
              <span
                onDoubleClick={(e) => {
                  if (index !== 0) {
                    setEditInputIndex(index);
                    setEditInputValue(tag);
                    e.preventDefault();
                  }
                }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            className="tag-input"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag className="site-tag-plus" onClick={showInput}>
            <PlusOutlined /> New member
          </Tag>
        )}
        <SelectContainer>
          <Select defaultValue={inviteRole} className="member-access-select" bordered={false} onChange={handleInviteRoleChange}>
            <Option value={Role.Admin}>Admin</Option>
            <Option value={Role.Viewer}>Viewer</Option>
          </Select>
        </SelectContainer>
      </NewMembersBlock>
      <ButtonWithIcon text="Invite" icon={<SendOutlined />} maxWidth={150} height={39} centerButton onClick={handleInvite} disabled={!tags.length} />
    </AddNewMemberContainer>
  );
};
