import React, { FC, useCallback } from "react";
import { AllowedParametersValues, OutcomeValues } from "utils/types";
import { CustomRulesView } from "./CustomRulesView";
import { ParametersListBlock } from "styles";
import { useStore } from "utils/hooks";
import { KeyriError } from "utils/keyri-error";
import { defaultErrorHandler } from "utils/helpers";
import { IRulesListScreenProps } from "./props";
import { message } from "antd";
import { successParamChangeMessage } from "screens/FingerprintRiskManagement";
import { defaultFingerprintSettings } from "utils/fingerprint-helper";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { BaseRiskRules } from "./BaseRiskRules";
import { MobileSettings } from "./MobileSettings";
import { QRAuthRules } from "./QRAuthRules";

export const RulesListScreen: FC<IRulesListScreenProps> = ({ canEdit }) => {
  const { services } = useStore();

  const handleEnableSwitch = useCallback(
    (rule: string) => {
      return async (enabled: boolean) => {
        try {
          await services.changeFingerprintParameters([{ rule, enabled }]);
          message.success(successParamChangeMessage);
        } catch (err: unknown | KeyriError) {
          defaultErrorHandler(err);
        }
      };
    },
    [services]
  );

  const riskChangeHandler = useCallback(
    async (rule: string, outcome: string) => {
      if (outcome === "ignore") return services.removeFingerprintRule(rule);
      return services.changeFingerprintParameters([{ rule: rule, outcome: outcome as OutcomeValues }]);
    },
    [services.changeFingerprintParameters]
  );

  const switchToDefaultValues = useCallback(
    async (e: CheckboxChangeEvent) => {
      try {
        if (e.target.checked) {
          await services.changeFingerprintParameters(Object.values(defaultFingerprintSettings));
          await services.changeRiskParameter({
            countryDifferential: AllowedParametersValues.Allow,
            warnDistance: 50,
            denyDistance: 400,
            warnNewBrowserDetection: true,
            denyEmulatorDetection: true,
            denyRootedJailbrokenDevice: true,
            browserTorIP: AllowedParametersValues.Allow,
            browserKnownAttackerIP: AllowedParametersValues.Allow,
            browserAnonymousProxy: AllowedParametersValues.Allow,
          });
          message.success("Service parameters was successfully changed to recommended");
        }
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      }
    },
    [services]
  );

  return (
    <>
      <ParametersListBlock>
        <BaseRiskRules
          canEdit={canEdit}
          handleEnableSwitch={handleEnableSwitch}
          riskChangeHandler={riskChangeHandler}
          switchToDefaultValues={switchToDefaultValues}
        />
        <CustomRulesView canEdit={canEdit} handleEnableSwitch={handleEnableSwitch} />
        <MobileSettings canEdit={canEdit} handleEnableSwitch={handleEnableSwitch} riskChangeHandler={riskChangeHandler} />
        <QRAuthRules canEdit={canEdit} />
      </ParametersListBlock>
    </>
  );
};
