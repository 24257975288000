import React, { FC, useState } from "react";
import { useFormik } from "formik";

import { Button, MailVerification } from "../../components";
import { ButtonStylePreset, FingerprintLogEvents, ISignUpForm } from "../../utils/types";
import { signUpFormValidator } from "../../utils/validators";
import { useTabTitle } from "../../utils/hooks";
import { authAPI } from "../../utils/api";
import { KEYRI_LINKS, Routes } from "../../utils/constants";
import ISingUpScreenProps from "./props";
import {
  AdditionalBlock,
  ChangeRoute,
  DefaultForm,
  ExternalLink,
  FormContentWrapper,
  FormFieldWrapper,
  FormHeader,
  FormWrapper,
  GoBackButton,
  InfoWarnIcon,
  InputImage,
  Logo,
  MainWrapper,
  WarnAlert,
} from "../../styles";
import { IMAGES } from "../../assets";
import { COLORS } from "../../utils/colors";
import { defaultErrorHandler } from "../../utils/helpers";
import { Input } from "antd";
import { fingerprintHelper } from "../../utils/fingerprint-helper";
import { KeyriError } from "../../utils/keyri-error";

export enum SignUpSteps {
  UserGeneralInfo = "UserGeneralInfo",
  MailVerificationScreen = "MailVerificationScreen",
}

export const SignUpScreen: FC<ISingUpScreenProps> = () => {
  const [stage, setStage] = useState<SignUpSteps>(SignUpSteps.UserGeneralInfo);

  useTabTitle("Registration");

  const {
    values: { fullName, email },
    handleChange,
    errors,
    handleSubmit,
    handleBlur,
    touched,
    setErrors,
  } = useFormik<ISignUpForm>({
    onSubmit: async () => {
      try {
        await fingerprintHelper.createEvent(FingerprintLogEvents.Signup, email);
        const {
          data: {
            data: { user },
          },
        } = await authAPI.signUp({ email: email.toLowerCase(), name: fullName });
        if (user) return setStage(SignUpSteps.MailVerificationScreen);
      } catch (err: unknown | KeyriError) {
        if (err instanceof KeyriError && err.code === 1027) {
          setErrors({
            response: `It looks like there’s already an account associated with this email. Log in to continue.`,
          });
        } else {
          defaultErrorHandler(err);
        }
      }
    },
    initialValues: {
      fullName: "",
      email: "",
      response: "",
    },
    validate: signUpFormValidator,
  });

  const SignUpMain = () => {
    switch (stage) {
      case SignUpSteps.UserGeneralInfo: {
        return (
          <FormWrapper>
            <FormHeader>Get started for free</FormHeader>
            {errors.response && <WarnAlert message={errors.response} type="info" icon={<InfoWarnIcon />} showIcon maxWidth={380} />}
            <DefaultForm onSubmit={handleSubmit}>
              <FormFieldWrapper minWidth={380}>
                {touched.fullName && errors.fullName && <WarnAlert message={errors.fullName} type="info" icon={<InfoWarnIcon />} showIcon maxWidth={380} />}

                <Input
                  name="fullName"
                  type="text"
                  value={fullName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Your Full Name"
                  prefix={<InputImage src={IMAGES.FULL_NAME} alt="Person field" />}
                />
              </FormFieldWrapper>
              <FormFieldWrapper minWidth={380}>
                {touched.email && errors.email && <WarnAlert message={errors.email} type="info" icon={<InfoWarnIcon />} showIcon maxWidth={380} />}

                <Input
                  name="email"
                  type="email"
                  value={email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Your Email"
                  prefix={<InputImage src={IMAGES.EMAIL} alt="Email field" />}
                />
              </FormFieldWrapper>
              <Button type={"submit"} preset={ButtonStylePreset.LightPurpleButton} title={"Sign up"} onClick={handleSubmit} />
            </DefaultForm>

            <AdditionalBlock color={COLORS.GRAY} fontSize="14px">
              Already have an account?
              <ChangeRoute to={Routes.Login}> Log In</ChangeRoute>
            </AdditionalBlock>
          </FormWrapper>
        );
      }

      case SignUpSteps.MailVerificationScreen: {
        return <MailVerification type={'sign-up'} />;
      }
    }
  };

  const UnderFormInfo = () => {
    switch (stage) {
      case SignUpSteps.UserGeneralInfo: {
        return (
          <AdditionalBlock color={COLORS.MOON_RAKER}>
            By clicking Sign Up you agree to Keyri’s
            <ExternalLink href={KEYRI_LINKS.Terms} target="_blank">
              {" "}
              Terms of Service
            </ExternalLink>
            . You may receive email notifications from us and can opt out at any time.
          </AdditionalBlock>
        );
      }
      case SignUpSteps.MailVerificationScreen: {
        return (
          <AdditionalBlock color={COLORS.GRAY}>
            <ChangeRoute to={Routes.Home}>Click here </ChangeRoute>if you were not redirected to the Dashboard automatically after email address verification.
          </AdditionalBlock>
        );
      }
      default: {
        return (
          <AdditionalBlock color={COLORS.GRAY}>
            If you're lost,
            <GoBackButton> Click here </GoBackButton>
            to turn back to the Sign Up page.
          </AdditionalBlock>
        );
      }
    }
  };

  return (
    <MainWrapper>
      <FormContentWrapper>
        <a href="https://keyri.com">
          <Logo src={IMAGES.NEW_LOGO} alt="Keyri logo" />
        </a>
        {SignUpMain()}
        {UnderFormInfo()}
      </FormContentWrapper>
    </MainWrapper>
  );
};
