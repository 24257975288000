import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { DIMENSIONS } from "../../utils/constants";
import { LabelsInput } from "../../styles";

export const AddNewMemberContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: ${DIMENSIONS.L_BREAKPOINT_WIDTH}px) {
    flex-wrap: wrap;
  }
`;

export const DeleteButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    color: ${COLORS.RED};
  }
`;

export const RoleNameField = styled.div`
  text-transform: capitalize;
`;

export const NewMembersBlock = styled.div`
  ${LabelsInput};
  padding: 7px 104px 7px 12px;
  width: 100%;
`;

export const SelectContainer = styled.div`
  position: absolute;
  right: 0;
`;
