import styled from "styled-components";

export const VerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const VerificationImage = styled.img`
  margin: 0 0 22px;
`;
