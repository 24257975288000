import React, { FC } from "react";
import { SubBlockTitle } from "../Billing/styles";
import { CancelButton } from "./styles";

export const SettingsOpenedTitle: FC<{
  condition: boolean;
  title: string;
  secondaryTitle?: string;
  callback: () => void;
}> = ({ condition, title, secondaryTitle, callback }) => {
  return (
    <SubBlockTitle>
      {!condition ? (
        title
      ) : (
        <>
          <div>{secondaryTitle ? secondaryTitle : title}</div>
          <CancelButton onClick={callback}>Cancel</CancelButton>
        </>
      )}
    </SubBlockTitle>
  );
};
