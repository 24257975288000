import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { BlockContent, BlockTitle, MenuItemContainer, MenuItemContent } from "styles";
import {
  CustomizeQRBlock,
  ImageBlockHeader,
  ImagePreviewBlock,
  ImageUploadBlock,
  QRExampleBorder,
  QRExampleImage,
  QRExampleWrapper,
  ServiceLogo,
  SettingsSubHeaderTitle,
} from "./styles";
import { IMAGES } from "assets";
import { Button } from "components/Button";
import { IQRExampleProps, IServiceSettingsProps } from "./props";
import { QrCodeTypes } from "utils/constants";
import { message } from "antd";
import { ImagePlaceholder } from "components/ImagePlaceholder";
import { DefaultLoader } from "components/DefaultLoader";
import { observer } from "mobx-react-lite";
import { AlertMessage } from "components";
import { UploadChangeParam } from "antd/es/upload";
import { UploadFile } from "antd/es/upload/interface";
import { ServicesStatusesEnum } from "stores/ServicesStore";
import { useStore, useTabTitle } from "utils/hooks";
import { ButtonStylePreset } from "utils/types";
import { defaultErrorHandler, imageSize } from "utils/helpers";
import { KeyriError } from "utils/keyri-error";
import { Role } from "@denver23/keyri-shared";

export type Requirements = {
  fileType: boolean;
  fileSize: boolean;
  resolution: boolean;
};

export const ServiceSettingsScreenComponent: FC<IServiceSettingsProps> = () => {
  useTabTitle("Service Settings");

  // Component State
  const {
    services: { currentService, updateCurrentService, servicesStatus },
    billing: { currentServicePlan },
    user,
  } = useStore();

  const canEdit = useMemo<boolean>(
    () => currentService?.members?.find((member) => member.id === user.id)?.UserService?.role !== Role.Viewer,
    [user, currentService?.members]
  );

  const [permissions, setPermissions] = useState<Array<string>>([...(currentServicePlan?.permissions ?? []), ...(currentServicePlan?.customPermissions ?? [])]);
  const [qrLogo, setQRLogo] = useState<UploadFile<any> | File | null>(null);
  const [qrLogoImage, setLogoImage] = useState<string | null>(currentService?.qrLogo ? currentService.qrLogo : null);
  const [requirements, setRequirements] = useState<Requirements>({
    fileType: !!currentService?.qrLogo,
    fileSize: !!currentService?.qrLogo,
    resolution: !!currentService?.qrLogo,
  });

  const handleShowLogo = useCallback(
    async (event: UploadChangeParam<UploadFile<any>> | { file: File }): Promise<void> => {
      if (event.file) {
        const file = event.file;
        if (!file) return;
        setQRLogo(file);
        const reader = new FileReader();
        const readCallback = async (e: ProgressEvent<FileReader>) => {
          typeof e.target?.result === "string" && setLogoImage(e.target?.result);
          const { width, height } = await imageSize(e.target?.result as string);
          setRequirements({
            fileType: file.type === "image/png",
            fileSize: !!file && file?.size ? file.size < 512000 : false,
            resolution: width <= 200 && height <= 200,
          });
          reader.removeEventListener("loadend", readCallback);
        };
        reader.addEventListener("loadend", readCallback);
        reader.readAsDataURL(file as any as Blob);
      } else {
        setQRLogo(null);
        setLogoImage(currentService?.qrLogo ? currentService.qrLogo : null);
      }
    },
    [currentService?.qrLogo]
  );

  const handleUploadLogo = useCallback(async () => {
    try {
      // Check image requirements
      if (!Object.values(requirements).every((el: boolean) => el)) return message.error("Image not suitable for requirements");

      // Creating request data and sending request for update logo
      const formData = new FormData();
      formData.append("qrCodeType", QrCodeTypes.MiddleLogo);
      if (qrLogo) formData.append("qrLogo", qrLogo as any as Blob);
      await updateCurrentService(formData);
      message.success("Service was updated successfully");
      setQRLogo(null);
    } catch (err: unknown | KeyriError) {
      defaultErrorHandler(err);
    }
  }, [qrLogo, requirements, updateCurrentService]);

  useEffect(() => {
    setLogoImage(currentService?.qrLogo ? currentService.qrLogo : null);
    setPermissions([...(currentServicePlan?.permissions ?? []), ...(currentServicePlan?.customPermissions ?? [])]);
  }, [currentService, currentServicePlan]);

  return servicesStatus !== ServicesStatusesEnum.Fetched ? (
    <DefaultLoader />
  ) : (
    <MenuItemContainer>
      <MenuItemContent>
        <BlockTitle>QR Appearance</BlockTitle>
        {!permissions.includes("QR Styling") && (
          <AlertMessage
            currentService={currentService}
            message={"Upgrade your plan for QR Appearance customizations to take effect."}
            type="warning"
            showIcon
            style={{ maxWidth: "680px" }}
          />
        )}
        <BlockContent>
          <SettingsSubHeaderTitle>Improve user experience and confidence by adding a logo to your QR codes.</SettingsSubHeaderTitle>
          <CustomizeQRBlock>
            {canEdit && (
              <ImageUploadBlock>
                <ImageBlockHeader>Upload a Logo</ImageBlockHeader>
                <ImagePlaceholder requirements={requirements} onChange={handleShowLogo} src={qrLogoImage} id={"qrLogo"} />
              </ImageUploadBlock>
            )}
            <ImagePreviewBlock>
              <ImageBlockHeader>Preview</ImageBlockHeader>
              <QRExample handleSaveButton={handleUploadLogo} showSaveButton={!!qrLogo} logoUrl={qrLogoImage} requirements={requirements} />
            </ImagePreviewBlock>
          </CustomizeQRBlock>
        </BlockContent>
      </MenuItemContent>
    </MenuItemContainer>
  );
};

const QRExample: FC<IQRExampleProps> = ({ logoUrl, showSaveButton, handleSaveButton, requirements }) => {
  return (
    <QRExampleWrapper>
      <QRExampleBorder>
        <QRExampleImage>
          {logoUrl && <ServiceLogo src={logoUrl} />}
          <img src={IMAGES.REGISTRATION_QR_CODE} width={194} alt="" />
        </QRExampleImage>
      </QRExampleBorder>
      {showSaveButton && (
        <Button
          onClick={handleSaveButton}
          preset={ButtonStylePreset.LightPurpleButton}
          title={"Save"}
          width={200}
          disabled={!Object.values(requirements).every((el: boolean) => el)}
        />
      )}
    </QRExampleWrapper>
  );
};

export const ServiceSettingsScreen = observer(ServiceSettingsScreenComponent);
