import styled from "styled-components";
import { FONTS } from "assets";
import { COLORS } from "utils/colors";

export const AmberfloWidgetStyles = styled.div`
  margin: 16px 0px;
  width: 100%;
  font-family: ${FONTS.RUBIK} !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > * {
    font-family: ${FONTS.RUBIK} !important;
  }

  & .aflo-headingContainer {
    font-size: 18px !important;
  }

  & .aflo-headerWrapper {
    border: none !important;
  }

  & > div.aflo-component.aflo-wrapper div.aflo-view *,
  & h5 {
    font-family: ${FONTS.RUBIK} !important;
    color: ${COLORS.SCORPION} !important;
  }

  & div.aflo-component.aflo-container {
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.04) !important;
  }

  .aflo-wrapper {
    width: 100%;

    .aflo-container {
      border-radius: 20px;
      padding: 60px;
    }
  }
`;
