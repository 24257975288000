export const RulesConditionsList = [
  "changeHistory.device.asn_name.changes_last_10_minutes",
  "changeHistory.device.asn_name.changes_last_24_hours",
  "changeHistory.device.asn_name.changes_last_7_days",
  "changeHistory.device.asn_name.changes_last_hour",
  "changeHistory.device.city.changes_last_10_minutes",
  "changeHistory.device.city.changes_last_24_hours",
  "changeHistory.device.city.changes_last_7_days",
  "changeHistory.device.city.changes_last_hour",
  "changeHistory.device.continent_code.changes_last_10_minutes",
  "changeHistory.device.continent_code.changes_last_24_hours",
  "changeHistory.device.continent_code.changes_last_7_days",
  "changeHistory.device.continent_code.changes_last_hour",
  "changeHistory.device.country_code.changes_last_10_minutes",
  "changeHistory.device.country_code.changes_last_24_hours",
  "changeHistory.device.country_code.changes_last_7_days",
  "changeHistory.device.country_code.changes_last_hour",
  "changeHistory.device.dayOfWeek.changes_last_10_minutes",
  "changeHistory.device.dayOfWeek.changes_last_24_hours",
  "changeHistory.device.dayOfWeek.changes_last_7_days",
  "changeHistory.device.dayOfWeek.changes_last_hour",
  "changeHistory.device.dayPart.changes_last_10_minutes",
  "changeHistory.device.dayPart.changes_last_24_hours",
  "changeHistory.device.dayPart.changes_last_7_days",
  "changeHistory.device.dayPart.changes_last_hour",
  "changeHistory.device.eventType.changes_last_10_minutes",
  "changeHistory.device.eventType.changes_last_24_hours",
  "changeHistory.device.eventType.changes_last_7_days",
  "changeHistory.device.eventType.changes_last_hour",
  "changeHistory.device.ip.changes_last_10_minutes",
  "changeHistory.device.ip.changes_last_24_hours",
  "changeHistory.device.ip.changes_last_7_days",
  "changeHistory.device.ip.changes_last_hour",
  "changeHistory.device.ip_timezone_name.changes_last_10_minutes",
  "changeHistory.device.ip_timezone_name.changes_last_24_hours",
  "changeHistory.device.ip_timezone_name.changes_last_7_days",
  "changeHistory.device.ip_timezone_name.changes_last_hour",
  "changeHistory.device.is_anonymous.changes_last_10_minutes",
  "changeHistory.device.is_anonymous.changes_last_24_hours",
  "changeHistory.device.is_anonymous.changes_last_7_days",
  "changeHistory.device.is_anonymous.changes_last_hour",
  "changeHistory.device.is_datacenter.changes_last_10_minutes",
  "changeHistory.device.is_datacenter.changes_last_24_hours",
  "changeHistory.device.is_datacenter.changes_last_7_days",
  "changeHistory.device.is_datacenter.changes_last_hour",
  "changeHistory.device.is_proxy.changes_last_10_minutes",
  "changeHistory.device.is_proxy.changes_last_24_hours",
  "changeHistory.device.is_proxy.changes_last_7_days",
  "changeHistory.device.is_proxy.changes_last_hour",
  "changeHistory.device.is_tor.changes_last_10_minutes",
  "changeHistory.device.is_tor.changes_last_24_hours",
  "changeHistory.device.is_tor.changes_last_7_days",
  "changeHistory.device.is_tor.changes_last_hour",
  "changeHistory.device.is_vpn.changes_last_10_minutes",
  "changeHistory.device.is_vpn.changes_last_24_hours",
  "changeHistory.device.is_vpn.changes_last_7_days",
  "changeHistory.device.is_vpn.changes_last_hour",
  "changeHistory.device.language.changes_last_10_minutes",
  "changeHistory.device.language.changes_last_24_hours",
  "changeHistory.device.language.changes_last_7_days",
  "changeHistory.device.language.changes_last_hour",
  "changeHistory.device.latitude.changes_last_10_minutes",
  "changeHistory.device.latitude.changes_last_24_hours",
  "changeHistory.device.latitude.changes_last_7_days",
  "changeHistory.device.latitude.changes_last_hour",
  "changeHistory.device.lieProbability.changes_last_10_minutes",
  "changeHistory.device.lieProbability.changes_last_24_hours",
  "changeHistory.device.lieProbability.changes_last_7_days",
  "changeHistory.device.lieProbability.changes_last_hour",
  "changeHistory.device.longitude.changes_last_10_minutes",
  "changeHistory.device.longitude.changes_last_24_hours",
  "changeHistory.device.longitude.changes_last_7_days",
  "changeHistory.device.longitude.changes_last_hour",
  "changeHistory.device.region_code.changes_last_10_minutes",
  "changeHistory.device.region_code.changes_last_24_hours",
  "changeHistory.device.region_code.changes_last_7_days",
  "changeHistory.device.region_code.changes_last_hour",
  "changeHistory.device.state.changes_last_10_minutes",
  "changeHistory.device.state.changes_last_24_hours",
  "changeHistory.device.state.changes_last_7_days",
  "changeHistory.device.state.changes_last_hour",
  "changeHistory.device.system.changes_last_10_minutes",
  "changeHistory.device.system.changes_last_24_hours",
  "changeHistory.device.system.changes_last_7_days",
  "changeHistory.device.system.changes_last_hour",
  "changeHistory.device.time_zone_location.changes_last_10_minutes",
  "changeHistory.device.time_zone_location.changes_last_24_hours",
  "changeHistory.device.time_zone_location.changes_last_7_days",
  "changeHistory.device.time_zone_location.changes_last_hour",
  "changeHistory.device.userAgentParsed.changes_last_10_minutes",
  "changeHistory.device.userAgentParsed.changes_last_24_hours",
  "changeHistory.device.userAgentParsed.changes_last_7_days",
  "changeHistory.device.userAgentParsed.changes_last_hour",
  "changeHistory.device.wagId.changes_last_10_minutes",
  "changeHistory.device.wagId.changes_last_24_hours",
  "changeHistory.device.wagId.changes_last_7_days",
  "changeHistory.device.wagId.changes_last_hour",
  "changeHistory.user.asn_name.changes_last_10_minutes",
  "changeHistory.user.asn_name.changes_last_24_hours",
  "changeHistory.user.asn_name.changes_last_7_days",
  "changeHistory.user.asn_name.changes_last_hour",
  "changeHistory.user.city.changes_last_10_minutes",
  "changeHistory.user.city.changes_last_24_hours",
  "changeHistory.user.city.changes_last_7_days",
  "changeHistory.user.city.changes_last_hour",
  "changeHistory.user.continent_code.changes_last_10_minutes",
  "changeHistory.user.continent_code.changes_last_24_hours",
  "changeHistory.user.continent_code.changes_last_7_days",
  "changeHistory.user.continent_code.changes_last_hour",
  "changeHistory.user.country_code.changes_last_10_minutes",
  "changeHistory.user.country_code.changes_last_24_hours",
  "changeHistory.user.country_code.changes_last_7_days",
  "changeHistory.user.country_code.changes_last_hour",
  "changeHistory.user.dayOfWeek.changes_last_10_minutes",
  "changeHistory.user.dayOfWeek.changes_last_24_hours",
  "changeHistory.user.dayOfWeek.changes_last_7_days",
  "changeHistory.user.dayOfWeek.changes_last_hour",
  "changeHistory.user.dayPart.changes_last_10_minutes",
  "changeHistory.user.dayPart.changes_last_24_hours",
  "changeHistory.user.dayPart.changes_last_7_days",
  "changeHistory.user.dayPart.changes_last_hour",
  "changeHistory.user.eventType.changes_last_10_minutes",
  "changeHistory.user.eventType.changes_last_24_hours",
  "changeHistory.user.eventType.changes_last_7_days",
  "changeHistory.user.eventType.changes_last_hour",
  "changeHistory.user.ip.changes_last_10_minutes",
  "changeHistory.user.ip.changes_last_24_hours",
  "changeHistory.user.ip.changes_last_7_days",
  "changeHistory.user.ip.changes_last_hour",
  "changeHistory.user.ip_timezone_name.changes_last_10_minutes",
  "changeHistory.user.ip_timezone_name.changes_last_24_hours",
  "changeHistory.user.ip_timezone_name.changes_last_7_days",
  "changeHistory.user.ip_timezone_name.changes_last_hour",
  "changeHistory.user.is_anonymous.changes_last_10_minutes",
  "changeHistory.user.is_anonymous.changes_last_24_hours",
  "changeHistory.user.is_anonymous.changes_last_7_days",
  "changeHistory.user.is_anonymous.changes_last_hour",
  "changeHistory.user.is_datacenter.changes_last_10_minutes",
  "changeHistory.user.is_datacenter.changes_last_24_hours",
  "changeHistory.user.is_datacenter.changes_last_7_days",
  "changeHistory.user.is_datacenter.changes_last_hour",
  "changeHistory.user.is_proxy.changes_last_10_minutes",
  "changeHistory.user.is_proxy.changes_last_24_hours",
  "changeHistory.user.is_proxy.changes_last_7_days",
  "changeHistory.user.is_proxy.changes_last_hour",
  "changeHistory.user.is_tor.changes_last_10_minutes",
  "changeHistory.user.is_tor.changes_last_24_hours",
  "changeHistory.user.is_tor.changes_last_7_days",
  "changeHistory.user.is_tor.changes_last_hour",
  "changeHistory.user.is_vpn.changes_last_10_minutes",
  "changeHistory.user.is_vpn.changes_last_24_hours",
  "changeHistory.user.is_vpn.changes_last_7_days",
  "changeHistory.user.is_vpn.changes_last_hour",
  "changeHistory.user.language.changes_last_10_minutes",
  "changeHistory.user.language.changes_last_24_hours",
  "changeHistory.user.language.changes_last_7_days",
  "changeHistory.user.language.changes_last_hour",
  "changeHistory.user.latitude.changes_last_10_minutes",
  "changeHistory.user.latitude.changes_last_24_hours",
  "changeHistory.user.latitude.changes_last_7_days",
  "changeHistory.user.latitude.changes_last_hour",
  "changeHistory.user.lieProbability.changes_last_10_minutes",
  "changeHistory.user.lieProbability.changes_last_24_hours",
  "changeHistory.user.lieProbability.changes_last_7_days",
  "changeHistory.user.lieProbability.changes_last_hour",
  "changeHistory.user.longitude.changes_last_10_minutes",
  "changeHistory.user.longitude.changes_last_24_hours",
  "changeHistory.user.longitude.changes_last_7_days",
  "changeHistory.user.longitude.changes_last_hour",
  "changeHistory.user.region_code.changes_last_10_minutes",
  "changeHistory.user.region_code.changes_last_24_hours",
  "changeHistory.user.region_code.changes_last_7_days",
  "changeHistory.user.region_code.changes_last_hour",
  "changeHistory.user.state.changes_last_10_minutes",
  "changeHistory.user.state.changes_last_24_hours",
  "changeHistory.user.state.changes_last_7_days",
  "changeHistory.user.state.changes_last_hour",
  "changeHistory.user.system.changes_last_10_minutes",
  "changeHistory.user.system.changes_last_24_hours",
  "changeHistory.user.system.changes_last_7_days",
  "changeHistory.user.system.changes_last_hour",
  "changeHistory.user.time_zone_location.changes_last_10_minutes",
  "changeHistory.user.time_zone_location.changes_last_24_hours",
  "changeHistory.user.time_zone_location.changes_last_7_days",
  "changeHistory.user.time_zone_location.changes_last_hour",
  "changeHistory.user.userAgentParsed.changes_last_10_minutes",
  "changeHistory.user.userAgentParsed.changes_last_24_hours",
  "changeHistory.user.userAgentParsed.changes_last_7_days",
  "changeHistory.user.userAgentParsed.changes_last_hour",
  "changeHistory.user.wagId.changes_last_10_minutes",
  "changeHistory.user.wagId.changes_last_24_hours",
  "changeHistory.user.wagId.changes_last_7_days",
  "changeHistory.user.wagId.changes_last_hour",
  "deviceModel.daysDeviceSeenCount",
  "deviceModel.daysUserSeenOnDevice",
  "deviceModel.daysUserSeenOnDeviceCount",
  "deviceModel.deviceAgeGlobal",
  "deviceModel.deviceAgeLocal",
  "deviceModel.deviceAgeUser",
  "deviceModel.deviceId",
  "deviceModel.events.${eventType}.${userId}",
  "deviceModel.language",
  "deviceModel.lieProbability",
  "deviceModel.system",
  "deviceModel.time_zone_location",
  "deviceModel.userAgentParsed",
  "deviceModel.users.${userId}",
  "deviceModel.wagId",
  "instanceDeltas.asn_name.changed",
  "instanceDeltas.city.changed",
  "instanceDeltas.ip.changed",
  "instanceDeltas.ip_timezone_name.changed",
  "instanceDeltas.latitude.changed",
  "instanceDeltas.longitude.changed",
  "instanceDeltas.region_code.changed",
  "instanceDeltas.time_zone_location.changed",
  "instanceDeltas.wagId.changed",
  "instanceDeltas.asn_name.to",
  "instanceDeltas.city.to",
  "instanceDeltas.ip.to",
  "instanceDeltas.ip_timezone_name.to",
  "instanceDeltas.latitude.to",
  "instanceDeltas.longitude.to",
  "instanceDeltas.region_code.to",
  "instanceDeltas.time_zone_location.to",
  "instanceDeltas.wagId.to",
  "ipData.asn_name",
  "ipData.asn_route",
  "ipData.ip",
  "ipGeoData.city",
  "ipGeoData.continent_code",
  "ipGeoData.country_code",
  "ipGeoData.ip_timezone_name",
  "ipGeoData.latitude",
  "ipGeoData.longitude",
  "ipGeoData.region_code",
  "threat.improbableTravel",
  "threat.ip_blocklists",
  "threat.is_anonymous",
  "threat.is_bogon",
  "threat.is_datacenter",
  "threat.is_icloud_relay",
  "threat.is_known_abuser",
  "threat.is_known_attacker",
  "threat.is_proxy",
  "threat.is_threat",
  "threat.is_timezone_inconsistent",
  "threat.is_tor",
  "threat.is_vpn",
  "timeData.globalDayOfMonth",
  "timeData.globalDayOfWeek",
  "timeData.globalHour",
  "timeData.globalMinute",
  "timeData.globalMonth",
  "timeData.globalSecond",
  "timeData.globalYear",
  "timeData.localDayOfMonth",
  "timeData.localDayOfWeek",
  "timeData.localHour",
  "timeData.localMonth",
  "timeData.localYear",
  "userModel.asn_name.${ipData.asn_name}.age",
  "userModel.asn_name.${ipData.asn_name}.counter",
  "userModel.asn_name.${ipData.asn_name}.firstdate",
  "userModel.asn_name.${ipData.asn_name}.last_10_minutes",
  "userModel.asn_name.${ipData.asn_name}.last_24_hours",
  "userModel.asn_name.${ipData.asn_name}.last_hour",
  "userModel.asn_name.${ipData.asn_name}.last_month",
  "userModel.asn_name.${ipData.asn_name}.last_used",
  "userModel.asn_name.${ipData.asn_name}.lastdate",
  "userModel.asn_name.${ipData.asn_name}.percent",
  "userModel.asn_name.is_anonymous",
  "userModel.city.${ipGeoData.city}.age",
  "userModel.city.${ipGeoData.city}.counter",
  "userModel.city.${ipGeoData.city}.firstdate",
  "userModel.city.${ipGeoData.city}.last_10_minutes",
  "userModel.city.${ipGeoData.city}.last_24_hours",
  "userModel.city.${ipGeoData.city}.last_hour",
  "userModel.city.${ipGeoData.city}.last_month",
  "userModel.city.${ipGeoData.city}.last_used",
  "userModel.city.${ipGeoData.city}.lastdate",
  "userModel.city.${ipGeoData.city}.percent",
  "userModel.city.is_anonymous",
  "userModel.continent_code.${ipGeoData.continent_code}.age",
  "userModel.continent_code.${ipGeoData.continent_code}.counter",
  "userModel.continent_code.${ipGeoData.continent_code}.firstdate",
  "userModel.continent_code.${ipGeoData.continent_code}.last_10_minutes",
  "userModel.continent_code.${ipGeoData.continent_code}.last_24_hours",
  "userModel.continent_code.${ipGeoData.continent_code}.last_hour",
  "userModel.continent_code.${ipGeoData.continent_code}.last_month",
  "userModel.continent_code.${ipGeoData.continent_code}.last_used",
  "userModel.continent_code.${ipGeoData.continent_code}.lastdate",
  "userModel.continent_code.${ipGeoData.continent_code}.percent",
  "userModel.continent_code.is_anonymous",
  "userModel.country_code.${ipGeoData.country_code}.age",
  "userModel.country_code.${ipGeoData.country_code}.counter",
  "userModel.country_code.${ipGeoData.country_code}.firstdate",
  "userModel.country_code.${ipGeoData.country_code}.last_10_minutes",
  "userModel.country_code.${ipGeoData.country_code}.last_24_hours",
  "userModel.country_code.${ipGeoData.country_code}.last_hour",
  "userModel.country_code.${ipGeoData.country_code}.last_month",
  "userModel.country_code.${ipGeoData.country_code}.last_used",
  "userModel.country_code.${ipGeoData.country_code}.lastdate",
  "userModel.country_code.${ipGeoData.country_code}.percent",
  "userModel.country_code.is_anonymous",
  "userModel.dayOfWeek.${timeData.localDayOfWeek}.age",
  "userModel.dayOfWeek.${timeData.localDayOfWeek}.counter",
  "userModel.dayOfWeek.${timeData.localDayOfWeek}.firstdate",
  "userModel.dayOfWeek.${timeData.localDayOfWeek}.last_10_minutes",
  "userModel.dayOfWeek.${timeData.localDayOfWeek}.last_24_hours",
  "userModel.dayOfWeek.${timeData.localDayOfWeek}.last_hour",
  "userModel.dayOfWeek.${timeData.localDayOfWeek}.last_month",
  "userModel.dayOfWeek.${timeData.localDayOfWeek}.last_used",
  "userModel.dayOfWeek.${timeData.localDayOfWeek}.lastdate",
  "userModel.dayOfWeek.${timeData.localDayOfWeek}.percent",
  "userModel.dayOfWeek.is_anonymous",
  "userModel.dayPart.[dayPart].age",
  "userModel.dayPart.[dayPart].counter",
  "userModel.dayPart.[dayPart].firstdate",
  "userModel.dayPart.[dayPart].last_10_minutes",
  "userModel.dayPart.[dayPart].last_24_hours",
  "userModel.dayPart.[dayPart].last_hour",
  "userModel.dayPart.[dayPart].last_month",
  "userModel.dayPart.[dayPart].last_used",
  "userModel.dayPart.[dayPart].lastdate",
  "userModel.dayPart.[dayPart].percent",
  "userModel.dayPart.is_anonymous",
  "userModel.deviceId.${deviceModel.deviceId}.age",
  "userModel.deviceId.${deviceModel.deviceId}.counter",
  "userModel.deviceId.${deviceModel.deviceId}.firstdate",
  "userModel.deviceId.${deviceModel.deviceId}.last_10_minutes",
  "userModel.deviceId.${deviceModel.deviceId}.last_24_hours",
  "userModel.deviceId.${deviceModel.deviceId}.last_hour",
  "userModel.deviceId.${deviceModel.deviceId}.last_month",
  "userModel.deviceId.${deviceModel.deviceId}.last_used",
  "userModel.deviceId.${deviceModel.deviceId}.lastdate",
  "userModel.deviceId.${deviceModel.deviceId}.percent",
  "userModel.deviceId.is_anonymous",
  "userModel.eventType.${eventType}.age",
  "userModel.eventType.${eventType}.counter",
  "userModel.eventType.${eventType}.firstdate",
  "userModel.eventType.${eventType}.last_10_minutes",
  "userModel.eventType.${eventType}.last_24_hours",
  "userModel.eventType.${eventType}.last_hour",
  "userModel.eventType.${eventType}.last_month",
  "userModel.eventType.${eventType}.last_used",
  "userModel.eventType.${eventType}.lastdate",
  "userModel.eventType.${eventType}.percent",
  "userModel.eventType.is_anonymous",
  "userModel.ip.${ipData.ip}.age",
  "userModel.ip.${ipData.ip}.counter",
  "userModel.ip.${ipData.ip}.firstdate",
  "userModel.ip.${ipData.ip}.last_10_minutes",
  "userModel.ip.${ipData.ip}.last_24_hours",
  "userModel.ip.${ipData.ip}.last_hour",
  "userModel.ip.${ipData.ip}.last_month",
  "userModel.ip.${ipData.ip}.last_used",
  "userModel.ip.${ipData.ip}.lastdate",
  "userModel.ip.${ipData.ip}.percent",
  "userModel.ip.is_anonymous",
  "userModel.ip_timezone_name.${ipGeoData.ip_timezone_name}.age",
  "userModel.ip_timezone_name.${ipGeoData.ip_timezone_name}.counter",
  "userModel.ip_timezone_name.${ipGeoData.ip_timezone_name}.firstdate",
  "userModel.ip_timezone_name.${ipGeoData.ip_timezone_name}.last_10_minutes",
  "userModel.ip_timezone_name.${ipGeoData.ip_timezone_name}.last_24_hours",
  "userModel.ip_timezone_name.${ipGeoData.ip_timezone_name}.last_hour",
  "userModel.ip_timezone_name.${ipGeoData.ip_timezone_name}.last_month",
  "userModel.ip_timezone_name.${ipGeoData.ip_timezone_name}.last_used",
  "userModel.ip_timezone_name.${ipGeoData.ip_timezone_name}.lastdate",
  "userModel.ip_timezone_name.${ipGeoData.ip_timezone_name}.percent",
  "userModel.ip_timezone_name.is_anonymous",
  "userModel.is_anonymous.${threat.is_anonymous}.age",
  "userModel.is_anonymous.${threat.is_anonymous}.counter",
  "userModel.is_anonymous.${threat.is_anonymous}.firstdate",
  "userModel.is_anonymous.${threat.is_anonymous}.last_10_minutes",
  "userModel.is_anonymous.${threat.is_anonymous}.last_24_hours",
  "userModel.is_anonymous.${threat.is_anonymous}.last_hour",
  "userModel.is_anonymous.${threat.is_anonymous}.last_month",
  "userModel.is_anonymous.${threat.is_anonymous}.last_used",
  "userModel.is_anonymous.${threat.is_anonymous}.lastdate",
  "userModel.is_anonymous.${threat.is_anonymous}.percent",
  "userModel.is_anonymous.is_anonymous",
  "userModel.is_datacenter.${threat.is_datacenter}.age",
  "userModel.is_datacenter.${threat.is_datacenter}.counter",
  "userModel.is_datacenter.${threat.is_datacenter}.firstdate",
  "userModel.is_datacenter.${threat.is_datacenter}.last_10_minutes",
  "userModel.is_datacenter.${threat.is_datacenter}.last_24_hours",
  "userModel.is_datacenter.${threat.is_datacenter}.last_hour",
  "userModel.is_datacenter.${threat.is_datacenter}.last_month",
  "userModel.is_datacenter.${threat.is_datacenter}.last_used",
  "userModel.is_datacenter.${threat.is_datacenter}.lastdate",
  "userModel.is_datacenter.${threat.is_datacenter}.percent",
  "userModel.is_datacenter.is_anonymous",
  "userModel.is_proxy.${threat.is_proxy}.age",
  "userModel.is_proxy.${threat.is_proxy}.counter",
  "userModel.is_proxy.${threat.is_proxy}.firstdate",
  "userModel.is_proxy.${threat.is_proxy}.last_10_minutes",
  "userModel.is_proxy.${threat.is_proxy}.last_24_hours",
  "userModel.is_proxy.${threat.is_proxy}.last_hour",
  "userModel.is_proxy.${threat.is_proxy}.last_month",
  "userModel.is_proxy.${threat.is_proxy}.last_used",
  "userModel.is_proxy.${threat.is_proxy}.lastdate",
  "userModel.is_proxy.${threat.is_proxy}.percent",
  "userModel.is_proxy.is_anonymous",
  "userModel.is_tor.${threat.is_tor}.age",
  "userModel.is_tor.${threat.is_tor}.counter",
  "userModel.is_tor.${threat.is_tor}.firstdate",
  "userModel.is_tor.${threat.is_tor}.last_10_minutes",
  "userModel.is_tor.${threat.is_tor}.last_24_hours",
  "userModel.is_tor.${threat.is_tor}.last_hour",
  "userModel.is_tor.${threat.is_tor}.last_month",
  "userModel.is_tor.${threat.is_tor}.last_used",
  "userModel.is_tor.${threat.is_tor}.lastdate",
  "userModel.is_tor.${threat.is_tor}.percent",
  "userModel.is_tor.is_anonymous",
  "userModel.is_vpn.${threat.is_vpn}.age",
  "userModel.is_vpn.${threat.is_vpn}.counter",
  "userModel.is_vpn.${threat.is_vpn}.firstdate",
  "userModel.is_vpn.${threat.is_vpn}.last_10_minutes",
  "userModel.is_vpn.${threat.is_vpn}.last_24_hours",
  "userModel.is_vpn.${threat.is_vpn}.last_hour",
  "userModel.is_vpn.${threat.is_vpn}.last_month",
  "userModel.is_vpn.${threat.is_vpn}.last_used",
  "userModel.is_vpn.${threat.is_vpn}.lastdate",
  "userModel.is_vpn.${threat.is_vpn}.percent",
  "userModel.is_vpn.is_anonymous",
  "userModel.language.[language].age",
  "userModel.language.[language].counter",
  "userModel.language.[language].firstdate",
  "userModel.language.[language].last_10_minutes",
  "userModel.language.[language].last_24_hours",
  "userModel.language.[language].last_hour",
  "userModel.language.[language].last_month",
  "userModel.language.[language].last_used",
  "userModel.language.[language].lastdate",
  "userModel.language.[language].percent",
  "userModel.language.is_anonymous",
  "userModel.latitude.${ipGeoData.latitude}.age",
  "userModel.latitude.${ipGeoData.latitude}.counter",
  "userModel.latitude.${ipGeoData.latitude}.firstdate",
  "userModel.latitude.${ipGeoData.latitude}.last_10_minutes",
  "userModel.latitude.${ipGeoData.latitude}.last_24_hours",
  "userModel.latitude.${ipGeoData.latitude}.last_hour",
  "userModel.latitude.${ipGeoData.latitude}.last_month",
  "userModel.latitude.${ipGeoData.latitude}.last_used",
  "userModel.latitude.${ipGeoData.latitude}.lastdate",
  "userModel.latitude.${ipGeoData.latitude}.percent",
  "userModel.latitude.is_anonymous",
  "userModel.lieProbability.${deviceModel.lieProbability}.age",
  "userModel.lieProbability.${deviceModel.lieProbability}.counter",
  "userModel.lieProbability.${deviceModel.lieProbability}.firstdate",
  "userModel.lieProbability.${deviceModel.lieProbability}.last_10_minutes",
  "userModel.lieProbability.${deviceModel.lieProbability}.last_24_hours",
  "userModel.lieProbability.${deviceModel.lieProbability}.last_hour",
  "userModel.lieProbability.${deviceModel.lieProbability}.last_month",
  "userModel.lieProbability.${deviceModel.lieProbability}.last_used",
  "userModel.lieProbability.${deviceModel.lieProbability}.lastdate",
  "userModel.lieProbability.${deviceModel.lieProbability}.percent",
  "userModel.lieProbability.is_anonymous",
  "userModel.longitude.${ipGeoData.longitude}.age",
  "userModel.longitude.${ipGeoData.longitude}.counter",
  "userModel.longitude.${ipGeoData.longitude}.firstdate",
  "userModel.longitude.${ipGeoData.longitude}.last_10_minutes",
  "userModel.longitude.${ipGeoData.longitude}.last_24_hours",
  "userModel.longitude.${ipGeoData.longitude}.last_hour",
  "userModel.longitude.${ipGeoData.longitude}.last_month",
  "userModel.longitude.${ipGeoData.longitude}.last_used",
  "userModel.longitude.${ipGeoData.longitude}.lastdate",
  "userModel.longitude.${ipGeoData.longitude}.percent",
  "userModel.longitude.is_anonymous",
  "userModel.region_code.${ipGeoData.region_code}.age",
  "userModel.region_code.${ipGeoData.region_code}.counter",
  "userModel.region_code.${ipGeoData.region_code}.firstdate",
  "userModel.region_code.${ipGeoData.region_code}.last_10_minutes",
  "userModel.region_code.${ipGeoData.region_code}.last_24_hours",
  "userModel.region_code.${ipGeoData.region_code}.last_hour",
  "userModel.region_code.${ipGeoData.region_code}.last_month",
  "userModel.region_code.${ipGeoData.region_code}.last_used",
  "userModel.region_code.${ipGeoData.region_code}.lastdate",
  "userModel.region_code.${ipGeoData.region_code}.percent",
  "userModel.region_code.is_anonymous",
  "userModel.state.[state].age",
  "userModel.state.[state].counter",
  "userModel.state.[state].firstdate",
  "userModel.state.[state].last_10_minutes",
  "userModel.state.[state].last_24_hours",
  "userModel.state.[state].last_hour",
  "userModel.state.[state].last_month",
  "userModel.state.[state].last_used",
  "userModel.state.[state].lastdate",
  "userModel.state.[state].percent",
  "userModel.state.is_anonymous",
  "userModel.system.${deviceModel.system}.age",
  "userModel.system.${deviceModel.system}.counter",
  "userModel.system.${deviceModel.system}.firstdate",
  "userModel.system.${deviceModel.system}.last_10_minutes",
  "userModel.system.${deviceModel.system}.last_24_hours",
  "userModel.system.${deviceModel.system}.last_hour",
  "userModel.system.${deviceModel.system}.last_month",
  "userModel.system.${deviceModel.system}.last_used",
  "userModel.system.${deviceModel.system}.lastdate",
  "userModel.system.${deviceModel.system}.percent",
  "userModel.system.is_anonymous",
  "userModel.time_zone_location.${deviceModel.time_zone_location}.age",
  "userModel.time_zone_location.${deviceModel.time_zone_location}.counter",
  "userModel.time_zone_location.${deviceModel.time_zone_location}.firstdate",
  "userModel.time_zone_location.${deviceModel.time_zone_location}.last_10_minutes",
  "userModel.time_zone_location.${deviceModel.time_zone_location}.last_24_hours",
  "userModel.time_zone_location.${deviceModel.time_zone_location}.last_hour",
  "userModel.time_zone_location.${deviceModel.time_zone_location}.last_month",
  "userModel.time_zone_location.${deviceModel.time_zone_location}.last_used",
  "userModel.time_zone_location.${deviceModel.time_zone_location}.lastdate",
  "userModel.time_zone_location.${deviceModel.time_zone_location}.percent",
  "userModel.time_zone_location.is_anonymous",
  "userModel.userAgentParsed.${deviceModel.userAgentParsed}.age",
  "userModel.userAgentParsed.${deviceModel.userAgentParsed}.counter",
  "userModel.userAgentParsed.${deviceModel.userAgentParsed}.firstdate",
  "userModel.userAgentParsed.${deviceModel.userAgentParsed}.last_10_minutes",
  "userModel.userAgentParsed.${deviceModel.userAgentParsed}.last_24_hours",
  "userModel.userAgentParsed.${deviceModel.userAgentParsed}.last_hour",
  "userModel.userAgentParsed.${deviceModel.userAgentParsed}.last_month",
  "userModel.userAgentParsed.${deviceModel.userAgentParsed}.last_used",
  "userModel.userAgentParsed.${deviceModel.userAgentParsed}.lastdate",
  "userModel.userAgentParsed.${deviceModel.userAgentParsed}.percent",
  "userModel.userAgentParsed.is_anonymous",
  "userModel.wagId.${deviceModel.wagId}.age",
  "userModel.wagId.${deviceModel.wagId}.counter",
  "userModel.wagId.${deviceModel.wagId}.firstdate",
  "userModel.wagId.${deviceModel.wagId}.last_10_minutes",
  "userModel.wagId.${deviceModel.wagId}.last_24_hours",
  "userModel.wagId.${deviceModel.wagId}.last_hour",
  "userModel.wagId.${deviceModel.wagId}.last_month",
  "userModel.wagId.${deviceModel.wagId}.last_used",
  "userModel.wagId.${deviceModel.wagId}.lastdate",
  "userModel.wagId.${deviceModel.wagId}.percent",
  "userModel.wagId.is_anonymous",
  "deviceModel.events.${eventType}._unique",
  "deviceModel.events.${eventType}._usage",
  "deviceModel.users._unique",
  "deviceModel.users._usage",
  "historicalData.device._denials_10_minutes",
  "historicalData.device._denials_all",
  "historicalData.device._denials_day",
  "historicalData.device._denials_hour",
  "historicalData.device._percent_denial_10_minutes",
  "historicalData.device._percent_denial_all",
  "historicalData.device._percent_denial_day",
  "historicalData.device._percent_denial_hour",
  "historicalData.device._totals_10_minutes",
  "historicalData.device._totals_all",
  "historicalData.device._totals_day",
  "historicalData.device._totals_hour",
  "historicalData.device.events.${eventType}._denials_10_minutes",
  "historicalData.device.events.${eventType}._denials_all",
  "historicalData.device.events.${eventType}._denials_day",
  "historicalData.device.events.${eventType}._denials_hour",
  "historicalData.device.events.${eventType}._percent_denial_10_minutes",
  "historicalData.device.events.${eventType}._percent_denial_all",
  "historicalData.device.events.${eventType}._percent_denial_day",
  "historicalData.device.events.${eventType}._percent_denial_hour",
  "historicalData.device.events.${eventType}._totals_10_minutes",
  "historicalData.device.events.${eventType}._totals_all",
  "historicalData.device.events.${eventType}._totals_day",
  "historicalData.device.events.${eventType}._totals_hour",
  "historicalData.device.uniqueDevice._denials_10_minutes",
  "historicalData.device.uniqueDevice._denials_all",
  "historicalData.device.uniqueDevice._denials_day",
  "historicalData.device.uniqueDevice._denials_hour",
  "historicalData.device.uniqueDevice._totals_10_minutes",
  "historicalData.device.uniqueDevice._totals_all",
  "historicalData.device.uniqueDevice._totals_day",
  "historicalData.device.uniqueDevice._totals_hour",
  "historicalData.device.uniqueIP._denials_10_minutes",
  "historicalData.device.uniqueIP._denials_all",
  "historicalData.device.uniqueIP._denials_day",
  "historicalData.device.uniqueIP._denials_hour",
  "historicalData.device.uniqueIP._totals_10_minutes",
  "historicalData.device.uniqueIP._totals_all",
  "historicalData.device.uniqueIP._totals_day",
  "historicalData.device.uniqueIP._totals_hour",
  "historicalData.device.uniqueUser._denials_10_minutes",
  "historicalData.device.uniqueUser._denials_all",
  "historicalData.device.uniqueUser._denials_day",
  "historicalData.device.uniqueUser._denials_hour",
  "historicalData.device.uniqueUser._totals_10_minutes",
  "historicalData.device.uniqueUser._totals_all",
  "historicalData.device.uniqueUser._totals_day",
  "historicalData.device.uniqueUser._totals_hour",
  "historicalData.ip._denials_10_minutes",
  "historicalData.ip._denials_all",
  "historicalData.ip._denials_day",
  "historicalData.ip._denials_hour",
  "historicalData.ip._percent_denial_10_minutes",
  "historicalData.ip._percent_denial_all",
  "historicalData.ip._percent_denial_day",
  "historicalData.ip._percent_denial_hour",
  "historicalData.ip._totals_10_minutes",
  "historicalData.ip._totals_all",
  "historicalData.ip._totals_day",
  "historicalData.ip._totals_hour",
  "historicalData.ip.events.${eventType}._denials_10_minutes",
  "historicalData.ip.events.${eventType}._denials_all",
  "historicalData.ip.events.${eventType}._denials_day",
  "historicalData.ip.events.${eventType}._denials_hour",
  "historicalData.ip.events.${eventType}._percent_denial_10_minutes",
  "historicalData.ip.events.${eventType}._percent_denial_all",
  "historicalData.ip.events.${eventType}._percent_denial_day",
  "historicalData.ip.events.${eventType}._percent_denial_hour",
  "historicalData.ip.events.${eventType}._totals_10_minutes",
  "historicalData.ip.events.${eventType}._totals_all",
  "historicalData.ip.events.${eventType}._totals_day",
  "historicalData.ip.events.${eventType}._totals_hour",
  "historicalData.ip.uniqueDevice._denials_10_minutes",
  "historicalData.ip.uniqueDevice._denials_all",
  "historicalData.ip.uniqueDevice._denials_day",
  "historicalData.ip.uniqueDevice._denials_hour",
  "historicalData.ip.uniqueDevice._totals_10_minutes",
  "historicalData.ip.uniqueDevice._totals_all",
  "historicalData.ip.uniqueDevice._totals_day",
  "historicalData.ip.uniqueDevice._totals_hour",
  "historicalData.ip.uniqueIP._denials_10_minutes",
  "historicalData.ip.uniqueIP._denials_all",
  "historicalData.ip.uniqueIP._denials_day",
  "historicalData.ip.uniqueIP._denials_hour",
  "historicalData.ip.uniqueIP._totals_10_minutes",
  "historicalData.ip.uniqueIP._totals_all",
  "historicalData.ip.uniqueIP._totals_day",
  "historicalData.ip.uniqueIP._totals_hour",
  "historicalData.ip.uniqueUser._denials_10_minutes",
  "historicalData.ip.uniqueUser._denials_all",
  "historicalData.ip.uniqueUser._denials_day",
  "historicalData.ip.uniqueUser._denials_hour",
  "historicalData.ip.uniqueUser._totals_10_minutes",
  "historicalData.ip.uniqueUser._totals_all",
  "historicalData.ip.uniqueUser._totals_day",
  "historicalData.ip.uniqueUser._totals_hour",
  "historicalData.user._denials_10_minutes",
  "historicalData.user._denials_all",
  "historicalData.user._denials_day",
  "historicalData.user._denials_hour",
  "historicalData.user._percent_denial_10_minutes",
  "historicalData.user._percent_denial_all",
  "historicalData.user._percent_denial_day",
  "historicalData.user._percent_denial_hour",
  "historicalData.user._totals_10_minutes",
  "historicalData.user._totals_all",
  "historicalData.user._totals_day",
  "historicalData.user._totals_hour",
  "historicalData.user.events.${eventType}._denials_10_minutes",
  "historicalData.user.events.${eventType}._denials_all",
  "historicalData.user.events.${eventType}._denials_day",
  "historicalData.user.events.${eventType}._denials_hour",
  "historicalData.user.events.${eventType}._percent_denial_10_minutes",
  "historicalData.user.events.${eventType}._percent_denial_all",
  "historicalData.user.events.${eventType}._percent_denial_day",
  "historicalData.user.events.${eventType}._percent_denial_hour",
  "historicalData.user.events.${eventType}._totals_10_minutes",
  "historicalData.user.events.${eventType}._totals_all",
  "historicalData.user.events.${eventType}._totals_day",
  "historicalData.user.events.${eventType}._totals_hour",
  "historicalData.user.uniqueDevice._denials_10_minutes",
  "historicalData.user.uniqueDevice._denials_all",
  "historicalData.user.uniqueDevice._denials_day",
  "historicalData.user.uniqueDevice._denials_hour",
  "historicalData.user.uniqueDevice._totals_10_minutes",
  "historicalData.user.uniqueDevice._totals_all",
  "historicalData.user.uniqueDevice._totals_day",
  "historicalData.user.uniqueDevice._totals_hour",
  "historicalData.user.uniqueIP._denials_10_minutes",
  "historicalData.user.uniqueIP._denials_all",
  "historicalData.user.uniqueIP._denials_day",
  "historicalData.user.uniqueIP._denials_hour",
  "historicalData.user.uniqueIP._totals_10_minutes",
  "historicalData.user.uniqueIP._totals_all",
  "historicalData.user.uniqueIP._totals_day",
  "historicalData.user.uniqueIP._totals_hour",
  "historicalData.user.uniqueUser._denials_10_minutes",
  "historicalData.user.uniqueUser._denials_all",
  "historicalData.user.uniqueUser._denials_day",
  "historicalData.user.uniqueUser._denials_hour",
  "historicalData.user.uniqueUser._totals_10_minutes",
  "historicalData.user.uniqueUser._totals_all",
  "historicalData.user.uniqueUser._totals_day",
  "historicalData.user.uniqueUser._totals_hour",
  "userModel.asn_name._count",
  "userModel.asn_name._count_10_minutes",
  "userModel.asn_name._count_1_hour",
  "userModel.asn_name._count_1_month",
  "userModel.asn_name._count_24_hours",
  "userModel.asn_name._lastdate",
  "userModel.asn_name._lastentry",
  "userModel.asn_name._unique",
  "userModel.asn_name._unique_10_minutes",
  "userModel.asn_name._unique_1_hour",
  "userModel.asn_name._unique_1_month",
  "userModel.asn_name._unique_24_hours",
  "userModel.city._count",
  "userModel.city._count_10_minutes",
  "userModel.city._count_1_hour",
  "userModel.city._count_1_month",
  "userModel.city._count_24_hours",
  "userModel.city._lastdate",
  "userModel.city._lastentry",
  "userModel.city._unique",
  "userModel.city._unique_10_minutes",
  "userModel.city._unique_1_hour",
  "userModel.city._unique_1_month",
  "userModel.city._unique_24_hours",
  "userModel.continent_code._count",
  "userModel.continent_code._count_10_minutes",
  "userModel.continent_code._count_1_hour",
  "userModel.continent_code._count_1_month",
  "userModel.continent_code._count_24_hours",
  "userModel.continent_code._lastdate",
  "userModel.continent_code._lastentry",
  "userModel.continent_code._unique",
  "userModel.continent_code._unique_10_minutes",
  "userModel.continent_code._unique_1_hour",
  "userModel.continent_code._unique_1_month",
  "userModel.continent_code._unique_24_hours",
  "userModel.country_code._count",
  "userModel.country_code._count_10_minutes",
  "userModel.country_code._count_1_hour",
  "userModel.country_code._count_1_month",
  "userModel.country_code._count_24_hours",
  "userModel.country_code._lastdate",
  "userModel.country_code._lastentry",
  "userModel.country_code._unique",
  "userModel.country_code._unique_10_minutes",
  "userModel.country_code._unique_1_hour",
  "userModel.country_code._unique_1_month",
  "userModel.country_code._unique_24_hours",
  "userModel.dayOfWeek._count",
  "userModel.dayOfWeek._count_10_minutes",
  "userModel.dayOfWeek._count_1_hour",
  "userModel.dayOfWeek._count_1_month",
  "userModel.dayOfWeek._count_24_hours",
  "userModel.dayOfWeek._lastdate",
  "userModel.dayOfWeek._lastentry",
  "userModel.dayOfWeek._unique",
  "userModel.dayOfWeek._unique_10_minutes",
  "userModel.dayOfWeek._unique_1_hour",
  "userModel.dayOfWeek._unique_1_month",
  "userModel.dayOfWeek._unique_24_hours",
  "userModel.dayPart._count",
  "userModel.dayPart._count_10_minutes",
  "userModel.dayPart._count_1_hour",
  "userModel.dayPart._count_1_month",
  "userModel.dayPart._count_24_hours",
  "userModel.dayPart._lastdate",
  "userModel.dayPart._lastentry",
  "userModel.dayPart._unique",
  "userModel.dayPart._unique_10_minutes",
  "userModel.dayPart._unique_1_hour",
  "userModel.dayPart._unique_1_month",
  "userModel.dayPart._unique_24_hours",
  "userModel.deviceId._count",
  "userModel.deviceId._count_10_minutes",
  "userModel.deviceId._count_1_hour",
  "userModel.deviceId._count_1_month",
  "userModel.deviceId._count_24_hours",
  "userModel.deviceId._lastdate",
  "userModel.deviceId._lastentry",
  "userModel.deviceId._unique",
  "userModel.deviceId._unique_10_minutes",
  "userModel.deviceId._unique_1_hour",
  "userModel.deviceId._unique_1_month",
  "userModel.deviceId._unique_24_hours",
  "userModel.eventType._count",
  "userModel.eventType._count_10_minutes",
  "userModel.eventType._count_1_hour",
  "userModel.eventType._count_1_month",
  "userModel.eventType._count_24_hours",
  "userModel.eventType._lastdate",
  "userModel.eventType._lastentry",
  "userModel.eventType._unique",
  "userModel.eventType._unique_10_minutes",
  "userModel.eventType._unique_1_hour",
  "userModel.eventType._unique_1_month",
  "userModel.eventType._unique_24_hours",
  "userModel.ip._count",
  "userModel.ip._count_10_minutes",
  "userModel.ip._count_1_hour",
  "userModel.ip._count_1_month",
  "userModel.ip._count_24_hours",
  "userModel.ip._lastdate",
  "userModel.ip._lastentry",
  "userModel.ip._unique",
  "userModel.ip._unique_10_minutes",
  "userModel.ip._unique_1_hour",
  "userModel.ip._unique_1_month",
  "userModel.ip._unique_24_hours",
  "userModel.ip_timezone_name._count",
  "userModel.ip_timezone_name._count_10_minutes",
  "userModel.ip_timezone_name._count_1_hour",
  "userModel.ip_timezone_name._count_1_month",
  "userModel.ip_timezone_name._count_24_hours",
  "userModel.ip_timezone_name._lastdate",
  "userModel.ip_timezone_name._lastentry",
  "userModel.ip_timezone_name._unique",
  "userModel.ip_timezone_name._unique_10_minutes",
  "userModel.ip_timezone_name._unique_1_hour",
  "userModel.ip_timezone_name._unique_1_month",
  "userModel.ip_timezone_name._unique_24_hours",
  "userModel.is_anonymous._count",
  "userModel.is_anonymous._count_10_minutes",
  "userModel.is_anonymous._count_1_hour",
  "userModel.is_anonymous._count_1_month",
  "userModel.is_anonymous._count_24_hours",
  "userModel.is_anonymous._lastdate",
  "userModel.is_anonymous._lastentry",
  "userModel.is_anonymous._unique",
  "userModel.is_anonymous._unique_10_minutes",
  "userModel.is_anonymous._unique_1_hour",
  "userModel.is_anonymous._unique_1_month",
  "userModel.is_anonymous._unique_24_hours",
  "userModel.is_datacenter._count",
  "userModel.is_datacenter._count_10_minutes",
  "userModel.is_datacenter._count_1_hour",
  "userModel.is_datacenter._count_1_month",
  "userModel.is_datacenter._count_24_hours",
  "userModel.is_datacenter._lastdate",
  "userModel.is_datacenter._lastentry",
  "userModel.is_datacenter._unique",
  "userModel.is_datacenter._unique_10_minutes",
  "userModel.is_datacenter._unique_1_hour",
  "userModel.is_datacenter._unique_1_month",
  "userModel.is_datacenter._unique_24_hours",
  "userModel.is_proxy._count",
  "userModel.is_proxy._count_10_minutes",
  "userModel.is_proxy._count_1_hour",
  "userModel.is_proxy._count_1_month",
  "userModel.is_proxy._count_24_hours",
  "userModel.is_proxy._lastdate",
  "userModel.is_proxy._lastentry",
  "userModel.is_proxy._unique",
  "userModel.is_proxy._unique_10_minutes",
  "userModel.is_proxy._unique_1_hour",
  "userModel.is_proxy._unique_1_month",
  "userModel.is_proxy._unique_24_hours",
  "userModel.is_tor._count",
  "userModel.is_tor._count_10_minutes",
  "userModel.is_tor._count_1_hour",
  "userModel.is_tor._count_1_month",
  "userModel.is_tor._count_24_hours",
  "userModel.is_tor._lastdate",
  "userModel.is_tor._lastentry",
  "userModel.is_tor._unique",
  "userModel.is_tor._unique_10_minutes",
  "userModel.is_tor._unique_1_hour",
  "userModel.is_tor._unique_1_month",
  "userModel.is_tor._unique_24_hours",
  "userModel.is_vpn._count",
  "userModel.is_vpn._count_10_minutes",
  "userModel.is_vpn._count_1_hour",
  "userModel.is_vpn._count_1_month",
  "userModel.is_vpn._count_24_hours",
  "userModel.is_vpn._lastdate",
  "userModel.is_vpn._lastentry",
  "userModel.is_vpn._unique",
  "userModel.is_vpn._unique_10_minutes",
  "userModel.is_vpn._unique_1_hour",
  "userModel.is_vpn._unique_1_month",
  "userModel.is_vpn._unique_24_hours",
  "userModel.language._count",
  "userModel.language._count_10_minutes",
  "userModel.language._count_1_hour",
  "userModel.language._count_1_month",
  "userModel.language._count_24_hours",
  "userModel.language._lastdate",
  "userModel.language._lastentry",
  "userModel.language._unique",
  "userModel.language._unique_10_minutes",
  "userModel.language._unique_1_hour",
  "userModel.language._unique_1_month",
  "userModel.language._unique_24_hours",
  "userModel.latitude._count",
  "userModel.latitude._count_10_minutes",
  "userModel.latitude._count_1_hour",
  "userModel.latitude._count_1_month",
  "userModel.latitude._count_24_hours",
  "userModel.latitude._lastdate",
  "userModel.latitude._lastentry",
  "userModel.latitude._unique",
  "userModel.latitude._unique_10_minutes",
  "userModel.latitude._unique_1_hour",
  "userModel.latitude._unique_1_month",
  "userModel.latitude._unique_24_hours",
  "userModel.lieProbability._count",
  "userModel.lieProbability._count_10_minutes",
  "userModel.lieProbability._count_1_hour",
  "userModel.lieProbability._count_1_month",
  "userModel.lieProbability._count_24_hours",
  "userModel.lieProbability._lastdate",
  "userModel.lieProbability._lastentry",
  "userModel.lieProbability._unique",
  "userModel.lieProbability._unique_10_minutes",
  "userModel.lieProbability._unique_1_hour",
  "userModel.lieProbability._unique_1_month",
  "userModel.lieProbability._unique_24_hours",
  "userModel.longitude._count",
  "userModel.longitude._count_10_minutes",
  "userModel.longitude._count_1_hour",
  "userModel.longitude._count_1_month",
  "userModel.longitude._count_24_hours",
  "userModel.longitude._lastdate",
  "userModel.longitude._lastentry",
  "userModel.longitude._unique",
  "userModel.longitude._unique_10_minutes",
  "userModel.longitude._unique_1_hour",
  "userModel.longitude._unique_1_month",
  "userModel.longitude._unique_24_hours",
  "userModel.region_code._count",
  "userModel.region_code._count_10_minutes",
  "userModel.region_code._count_1_hour",
  "userModel.region_code._count_1_month",
  "userModel.region_code._count_24_hours",
  "userModel.region_code._lastdate",
  "userModel.region_code._lastentry",
  "userModel.region_code._unique",
  "userModel.region_code._unique_10_minutes",
  "userModel.region_code._unique_1_hour",
  "userModel.region_code._unique_1_month",
  "userModel.region_code._unique_24_hours",
  "userModel.state._count",
  "userModel.state._count_10_minutes",
  "userModel.state._count_1_hour",
  "userModel.state._count_1_month",
  "userModel.state._count_24_hours",
  "userModel.state._lastdate",
  "userModel.state._lastentry",
  "userModel.state._unique",
  "userModel.state._unique_10_minutes",
  "userModel.state._unique_1_hour",
  "userModel.state._unique_1_month",
  "userModel.state._unique_24_hours",
  "userModel.system._count",
  "userModel.system._count_10_minutes",
  "userModel.system._count_1_hour",
  "userModel.system._count_1_month",
  "userModel.system._count_24_hours",
  "userModel.system._lastdate",
  "userModel.system._lastentry",
  "userModel.system._unique",
  "userModel.system._unique_10_minutes",
  "userModel.system._unique_1_hour",
  "userModel.system._unique_1_month",
  "userModel.system._unique_24_hours",
  "userModel.time_zone_location._count",
  "userModel.time_zone_location._count_10_minutes",
  "userModel.time_zone_location._count_1_hour",
  "userModel.time_zone_location._count_1_month",
  "userModel.time_zone_location._count_24_hours",
  "userModel.time_zone_location._lastdate",
  "userModel.time_zone_location._lastentry",
  "userModel.time_zone_location._unique",
  "userModel.time_zone_location._unique_10_minutes",
  "userModel.time_zone_location._unique_1_hour",
  "userModel.time_zone_location._unique_1_month",
  "userModel.time_zone_location._unique_24_hours",
  "userModel.userAgentParsed._count",
  "userModel.userAgentParsed._count_10_minutes",
  "userModel.userAgentParsed._count_1_hour",
  "userModel.userAgentParsed._count_1_month",
  "userModel.userAgentParsed._count_24_hours",
  "userModel.userAgentParsed._lastdate",
  "userModel.userAgentParsed._lastentry",
  "userModel.userAgentParsed._unique",
  "userModel.userAgentParsed._unique_10_minutes",
  "userModel.userAgentParsed._unique_1_hour",
  "userModel.userAgentParsed._unique_1_month",
  "userModel.userAgentParsed._unique_24_hours",
  "userModel.wagId._count",
  "userModel.wagId._count_10_minutes",
  "userModel.wagId._count_1_hour",
  "userModel.wagId._count_1_month",
  "userModel.wagId._count_24_hours",
  "userModel.wagId._lastdate",
  "userModel.wagId._lastentry",
  "userModel.wagId._unique",
  "userModel.wagId._unique_10_minutes",
  "userModel.wagId._unique_1_hour",
  "userModel.wagId._unique_1_month",
  "userModel.wagId._unique_24_hours",
];

export const AutocompleteArray = RulesConditionsList.map((condition) => ({ label: condition, value: condition }));
