import { cast, flow, getRoot, types } from "mobx-state-tree";
import { fingerprintAPI, serviceAPI, userAPI } from "../utils/api";
import { IChangeFingerprintRuleValue, IChangeUserRole, IFingerprintRule, IGetServiceResponse, IService, IServicesResponse, ServiceType } from "../utils/types";
import { AxiosResponse } from "axios";
import { defaultErrorHandler } from "../utils/helpers";
import { KeyriError } from "../utils/keyri-error";
import { Response, Role, Services } from "@denver23/keyri-shared";

export enum ServicesStatusesEnum {
  "NotLoaded",
  "Loading",
  "Fetched",
}

const Service = types.model("Service", {
  id: types.identifier,
  name: types.string,
  logo: types.maybeNull(types.string),
  type: types.enumeration<ServiceType>("ServiceType", Object.values(ServiceType)),
});

const Application = types.model({
  id: types.string,
  serviceId: types.string,
  whiteLabelAppKey: types.maybeNull(types.string),
  appKey: types.maybeNull(types.string),
  widgetKey: types.maybeNull(types.string),
  createdAt: types.string,
  updatedAt: types.string,
  passKeyPublicKey: types.maybeNull(types.string),
});

const AppSettings = types.model({
  androidPackageName: types.maybeNull(types.string),
  sha256CertFingerprints: types.maybeNull(types.array(types.string)),
  androidGooglePlayLink: types.maybeNull(types.string),
  iosBundleId: types.maybeNull(types.string),
  iosTeamId: types.maybeNull(types.string),
  iosAppStoreLink: types.maybeNull(types.string),
});

const RiskParameters = types.model({
  warnDistance: types.maybeNull(types.number),
  denyDistance: types.maybeNull(types.number),
  countryDifferential: types.string,
  browserTorIP: types.string,
  browserKnownAttackerIP: types.string,
  browserAnonymousProxy: types.string,
  id: types.string,
  serviceId: types.string,
  warnNewBrowserDetection: types.boolean,
  denyEmulatorDetection: types.boolean,
  denyRootedJailbrokenDevice: types.boolean,
});

const UserService = types.model({
  serviceId: types.string,
  userId: types.string,
  id: types.string,
  role: types.string,
});

const Member = types.model({
  createdAt: types.maybe(types.string),
  email: types.string,
  emailVerified: types.boolean,
  name: types.string,
  updatedAt: types.maybe(types.string),
  id: types.string,
  UserService: types.maybe(UserService),
});

const Invited = types.model({
  email: types.string,
  id: types.string,
  role: types.string,
  serviceId: types.string,
});

const Rule = types.model({
  rule: types.maybe(types.string),
  eventType: types.maybe(types.string),
  conditions: types.frozen(),
  outcome: types.string,
  signal: types.string,
  type: types.maybe(types.string),
  notes: types.maybe(types.string),
  enabled: types.maybe(types.boolean),
  strength: types.maybe(types.number),
});

const FingerprintRules = types.model({
  id: types.string,
  rules: types.array(Rule),
  serviceId: types.string,
  createdAt: types.string,
  updatedAt: types.string,
});

const CurrentService = types.model({
  id: types.identifier,
  application: types.maybe(Application),
  isValid: types.boolean,
  name: types.string,
  logo: types.maybeNull(types.string),
  createdAt: types.string,
  updatedAt: types.string,
  qrCodeType: types.string,
  qrLogo: types.maybeNull(types.string),
  appSettings: types.maybeNull(AppSettings),
  domainName: types.maybeNull(types.string),
  domainVerifiedRecord: types.maybeNull(types.string),
  isDomainApproved: types.boolean,
  subDomainName: types.maybeNull(types.string),
  serviceRiskParameters: types.maybe(RiskParameters),
  fingerprintRules: types.maybe(FingerprintRules),
  members: types.maybe(types.array(Member)),
  invited: types.maybe(types.array(Invited)),
  type: types.enumeration<ServiceType>("ServiceType", Object.values(ServiceType)),
});

const ServicesArray = types.array(Service);

const ServicesStore = types
  .model("ServicesStore", {
    servicesList: ServicesArray,
    currentService: types.maybe(CurrentService),
    settingsChanged: types.boolean,
    servicesStatus: types.number,
  })
  .actions((self) => ({
    loadUserServices: flow(function* (signal?: AbortSignal) {
      try {
        self.servicesStatus = ServicesStatusesEnum.Loading;
        const {
          data: { data },
        }: AxiosResponse<Response.IApiResponse<IServicesResponse>> = yield serviceAPI.getUserServices(signal);
        const services = data?.services.map(({ id, name, logo, type }: IService) => ({
          id: id,
          name,
          logo,
          type,
        }));
        self.servicesList = ServicesArray.create(services);
        self.servicesStatus = ServicesStatusesEnum.Fetched;
        return services;
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      }
      self.servicesStatus = ServicesStatusesEnum.NotLoaded;
      return [];
    }),
    clearCurrentService: () => {
      self.currentService = undefined;
    },
    setCurrentService: flow(function* (id: string) {
      try {
        //if (self.currentService?.id === id) return; TODO: add this if no need update service for every menu changing
        self.servicesStatus = ServicesStatusesEnum.Loading;
        const {
          data: {
            data: { service },
          },
        }: AxiosResponse<Response.IApiResponse<Services.Responses.IGetServiceResponse>> = yield serviceAPI.getServiceInfo(id);
        self.currentService = CurrentService.create(service as Services.IService);
        (getRoot(self) as any).billing.loadServiceCard(service.id);
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      } finally {
        self.servicesStatus = ServicesStatusesEnum.Fetched;
      }
    }),
    addServiceToList: (service: { id: string; name: string; type: ServiceType }) => {
      self.servicesList.push(service);
    },
    updateCurrentService: flow(function* (data: Partial<IService> | FormData | { passKeyPublicKey: string }) {
      try {
        if (!self.currentService?.id) throw new KeyriError(995);
        self.servicesStatus = ServicesStatusesEnum.Loading;
        const {
          data: {
            data: { service },
          },
        }: AxiosResponse<Response.IApiResponse<IGetServiceResponse>> = yield serviceAPI.updateService(self.currentService.id, data);
        self.currentService = CurrentService.create(service as IService);
        return service;
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
      } finally {
        self.servicesStatus = ServicesStatusesEnum.Fetched;
      }
    }),
    verifyCurrentServiceDomain: flow(function* () {
      try {
        if (!self.currentService?.id) return;
        self.servicesStatus = ServicesStatusesEnum.Loading;
        const {
          data: { data },
        } = yield serviceAPI.verifyServiceDomain(self.currentService.id);
        self.currentService = { ...self.currentService, ...data };
        return data;
      } catch (err: unknown | KeyriError) {
        defaultErrorHandler(err);
        return {};
      } finally {
        self.servicesStatus = ServicesStatusesEnum.Fetched;
      }
    }),
    createNewService: flow(function* (payload: Services.Requests.ICreateServiceRequest) {
      try {
        self.servicesStatus = ServicesStatusesEnum.Loading;
        const {
          data: {
            data: { service },
          },
        } = yield serviceAPI.createService(payload);

        self.servicesList.push({ id: service.id, name: service.name, type: service.type });
        return service;
      } catch (err: unknown | KeyriError) {
        self.servicesStatus = ServicesStatusesEnum.NotLoaded;
        throw err;
      } finally {
        self.servicesStatus = ServicesStatusesEnum.Fetched;
      }
    }),
    deleteCurrentService: flow(function* () {
      try {
        self.servicesStatus = ServicesStatusesEnum.Loading;
        if (!self.currentService) throw new KeyriError(998);
        const response = yield serviceAPI.deleteService(self.currentService.id);
        self.servicesList = self.servicesList.filter(({ id }) => id !== self.currentService!.id) as any;
        return {
          status: response.status,
          updatedServicesList: self.servicesList,
        };
      } catch (err: unknown | KeyriError) {
        self.servicesStatus = ServicesStatusesEnum.NotLoaded;
        throw err;
      } finally {
        self.servicesStatus = ServicesStatusesEnum.Fetched;
      }
    }),
    changeRiskParameter: flow(function* (data: { [key: string]: string | number | boolean }) {
      try {
        if (self?.currentService?.serviceRiskParameters) {
          // @ts-ignore
          self.currentService.serviceRiskParameters = {
            ...self.currentService.serviceRiskParameters,
            ...data,
            warnDistance: data.warnDistance ? +data.warnDistance : self.currentService.serviceRiskParameters.warnDistance,
            denyDistance: data.denyDistance ? +data.denyDistance : self.currentService.serviceRiskParameters.denyDistance,
          };
          self.servicesStatus = ServicesStatusesEnum.Loading;
          const {
            data: {
              data: { serviceRiskParameters },
            },
          } = yield serviceAPI.updateServiceRiskParameters(self.currentService.id, {
            ...data,
            warnDistance: data.warnDistance ? +data.warnDistance : undefined,
            denyDistance: data.denyDistance ? +data.denyDistance : undefined,
          });
          self.currentService.serviceRiskParameters = serviceRiskParameters;
          self.settingsChanged = true;
          return serviceRiskParameters;
        } else {
          throw new KeyriError(995);
        }
      } catch (err: unknown | KeyriError) {
        throw err;
      } finally {
        self.servicesStatus = ServicesStatusesEnum.Fetched;
      }
    }),
    removeFingerprintRule: flow(function* (rule: string) {
      try {
        if (!self?.currentService) throw new KeyriError(995);
        self.servicesStatus = ServicesStatusesEnum.Loading;
        const {
          data: {
            data: { fingerprintRules },
          },
        } = yield fingerprintAPI.removeFingerprintRule(self.currentService.id, rule);
        self.currentService.fingerprintRules = fingerprintRules;
      } catch (err) {
        throw err;
      } finally {
        self.servicesStatus = ServicesStatusesEnum.Fetched;
      }
    }),
    changeFingerprintParameters: flow(function* (data: Array<IChangeFingerprintRuleValue>) {
      try {
        if (self?.currentService) {
          self.servicesStatus = ServicesStatusesEnum.Loading;
          const {
            data: {
              data: { fingerprintRules },
            },
          } = yield fingerprintAPI.updateFingerprintParameters(self.currentService.id, data);
          self.currentService.fingerprintRules = fingerprintRules;
          return fingerprintRules;
        } else {
          throw new KeyriError(995);
        }
      } catch (err: unknown | KeyriError) {
        throw err;
      } finally {
        self.servicesStatus = ServicesStatusesEnum.Fetched;
      }
    }),
    replaceServiceCustomRules: flow(function* (data: Array<IFingerprintRule>) {
      try {
        if (self?.currentService) {
          self.servicesStatus = ServicesStatusesEnum.Loading;
          const {
            data: {
              data: { fingerprintRules },
            },
          } = yield fingerprintAPI.replaceCustomRulesParameters(self.currentService.id, data);
          self.currentService.fingerprintRules = fingerprintRules;
          return fingerprintRules;
        } else {
          throw new KeyriError(995);
        }
      } catch (err: unknown | KeyriError) {
        throw err;
      } finally {
        self.servicesStatus = ServicesStatusesEnum.Fetched;
      }
    }),
    changeUserRole: flow(function* (userId: string, body: IChangeUserRole) {
      const {
        data: {
          data: { userService },
        },
      } = yield userAPI.changeUserRole(userId, body);
      self.currentService!.members = cast(
        self.currentService!.members?.map((member) => {
          if (member.id !== userId) return member;
          member.UserService = UserService.create(userService);
          return member;
        })
      );
      return userService;
    }),
    changeInviteRole: flow(function* (inviteId: string, body: IChangeUserRole) {
      const {
        data: {
          data: { invite },
        },
      } = yield userAPI.changeInviteRole(inviteId, body);
      self.currentService!.invited = cast(
        self.currentService!.invited?.map((member) => {
          if (member.id !== inviteId) return member;
          return Invited.create(invite);
        })
      );
      return invite;
    }),
    inviteUsers: flow(function* (serviceId: string, role: Role, emails: Array<string>) {
      const {
        data: {
          data: { emails: resultArray },
        },
      } = yield serviceAPI.inviteUsers(serviceId, role, emails);
      return resultArray;
    }),
    removeMember: flow(function* (serviceId: string, userId: string) {
      yield serviceAPI.removeMember(serviceId, userId);
    }),
    deleteInvite: flow(function* (serviceId: string, email: string) {
      yield serviceAPI.removeInvite(serviceId, email);
    }),
  }));

export default ServicesStore;
