import React, { FC } from "react";
import { AllowedParametersValues } from "../../../../utils/types";
import { RadioButton, RadioGroup, RiskForm } from "./styles";
import { IRiskRadioButtonsProps } from "./props";

export const RiskRadioButtons: FC<IRiskRadioButtonsProps> = ({ handler, value, disabled = false, buttonsList, defaultValue, width }) => {
  return (
    <RiskForm onChange={handler} width={width}>
      <RadioGroup disabled={disabled} defaultValue={defaultValue} value={value}>
        {buttonsList ? (
          buttonsList.map((button) => <RadioButton width={width} value={button.value}>{button.title}</RadioButton>)
        ) : (
          <>
            <RadioButton value={AllowedParametersValues.Allow}>Allow</RadioButton>
            <RadioButton value={AllowedParametersValues.Warn}>Warn</RadioButton>
            <RadioButton value={AllowedParametersValues.Deny}>Deny</RadioButton>
          </>
        )}
      </RadioGroup>
    </RiskForm>
  );
};
