import styled from "styled-components";
import {COLORS} from "../../utils/colors";
import {DIMENSIONS} from "../../utils/constants";
import {Link} from "react-router-dom";
import {Select} from "antd";

interface IWhiteScreenProps {
  visible: boolean;
}

export const ServiceSelectorWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  height: max-content;
  display: none;
  min-width: 260px;
  width: 260px;
  margin: 0 42px 0 0;
  padding: 24px;
  align-self: flex-start;

  //position: sticky;
  top: 20px;
  z-index: 10;
  
  flex-direction: column;
  gap: 8px;

  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  
  @media (max-width: ${DIMENSIONS.LIST_BLOCK}px) {
    display: flex;
    padding: 0px;
    margin: 10px 10px 10px 0px;
  }
`;

export const CurrentAccount = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CurrentAccountBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CurrentAccountIcon = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  flex-shrink: 0;
`;

export const CurrentAccountName = styled.div`
  font-size: 14px;
  line-height: 17px;

  color: ${COLORS.SCORPION};
`;

export const CurrentAccountTextIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.MOON_RAKER};
  text-transform: uppercase;
  flex-shrink: 0;
`;

export const SelectButtonContainer = styled.div``;

export const ServiceSelect = styled(Select)`
  z-index: 12;
`;

export const WhiteScreen = styled.div<IWhiteScreenProps>`
  visibility: ${({visible}) => visible ? 'visible' : 'hidden'};
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(256,256,256, 0.8);
  z-index: 11;
`;