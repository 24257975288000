import styled from "styled-components";
import { PlusOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { COLORS } from "../../utils/colors";
import { FONTS, IMAGES } from "../../assets";
import { DIMENSIONS, DimensionTypes } from '../../utils/constants';

interface IHiddenProps {
  hidden?: boolean;
}

export const EditButtonStyles = `
  display: block;
  flex-shrink: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: cover;
  padding: 0;
  margin: 0;
  border: 0;
`;

export const PropertyValue = styled.div`
  height: ${DIMENSIONS[DimensionTypes.DefaultInputHeight]}px;
  
  font-family: ${FONTS.RUBIK};
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${COLORS.GRAY};

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PackageNameLabel = styled.label<IHiddenProps>`
  display: ${({hidden}) => hidden ? 'none' : 'flex'};
  align-items: center;
  position: relative;
  height: ${DIMENSIONS[DimensionTypes.DefaultInputHeight]}px;
  width: 100%;
`;

export const PropertyString = styled.div<IHiddenProps>`
  display: ${({hidden}) => hidden ? 'none' : 'flex'};
  height: ${DIMENSIONS[DimensionTypes.DefaultInputHeight]}px;
  line-height: 19px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PackageNameIcon = styled.div`
  position: absolute;
  left: 20px;
  top: 16px;
`;

export const PackageNameInput = styled.input`
  border: 1px solid ${COLORS.ALTO};
  border-width: 1px 0 1px 1px;
  border-radius: 4px 0 0 4px;
  padding: 0 10px 0 46px;
  max-width: 300px;
  align-self: stretch;

  &:focus {
    outline: none;
    border-color: ${COLORS.MEDIUM_PURPLE};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  }
`;

const packagesButtonStyles = `
  height: ${DIMENSIONS[DimensionTypes.DefaultInputHeight]}px;
  width: ${DIMENSIONS[DimensionTypes.DefaultInputHeight]}px;

  font-size: 13px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0 5px 5px 0;
  border: 1px solid ${COLORS.MEDIUM_PURPLE};
  background-color: ${COLORS.SELAGO};
  color: ${COLORS.MEDIUM_PURPLE};
  cursor: pointer;
  transition: all 0.5s linear;
  
  &:hover {
    background-color: ${COLORS.WHITE_LILAC};
  }
`;

export const SavePackageButton = styled(PlusOutlined)`
  ${packagesButtonStyles}
`;
export const UpdatePackageButton = styled(CheckOutlined)`
  ${packagesButtonStyles}
`;

export const CancelPackageButton = styled(CloseOutlined)`
  height: 30px;
  width: 30px;
  margin-left: 10px;

  font-size: 13px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px 5px 5px 5px;
  border: 1px solid ${COLORS.RADICAL_RED};
  color: ${COLORS.RADICAL_RED};
  background-color: ${COLORS.ROMANTIC};
  cursor: pointer;
  transition: all 0.5s linear;
  
  &:hover {
    background-color: ${COLORS.WHITE_LILAC};
  }
`;

export const ChangePackageUrlButton = styled.button`
  ${EditButtonStyles};
  background: url(${IMAGES.EDIT}) no-repeat center center;
  background-color: none;
`;
