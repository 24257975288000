import {
  AdminMenuWrapper,
  HiddenMenuElement,
  MenuList,
  MenuSection,
  SectionExternalItem,
  SectionHeader,
  SectionItemIconContainer,
  SectionItemText,
  TraillingLine,
} from "./styles";
import {
  DashboardOutlined,
  LayoutOutlined,
  QrcodeOutlined,
  SafetyOutlined,
  SelectOutlined,
  TeamOutlined,
  ToolOutlined,
  ProjectOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import { FC, useCallback } from "react";
import { generateRoute, KEYRI_LINKS, Routes as RoutesConstants } from "utils/constants";
import { useStore } from "utils/hooks";
import { MenuLink } from "../MenuLink";
import { observer } from "mobx-react-lite";
import { IAdminPanelMenu } from "./props";
import { ServiceSelector } from "..";
import { ServiceType } from "utils/types";

export const AdminPanelMenuComponent: FC<IAdminPanelMenu> = () => {
  const {
    services: { currentService },
  } = useStore();

  const getMenuByServiceType = useCallback(() => {
    switch (currentService?.type) {
      case ServiceType.Authentication:
        return (
          <>
            <MenuSection>
              <SectionHeader>Event Details</SectionHeader>
              <MenuList>
                <MenuLink
                  icon={<DashboardOutlined />}
                  title="Dashboard"
                  to={generateRoute(RoutesConstants.AuthenticationDashboard, { serviceId: currentService?.id || "" })}
                />
                <MenuLink icon={<LayoutOutlined />} title="Logs" to={generateRoute(RoutesConstants.FingerprintLogs, { serviceId: currentService?.id || "" })} />
                <MenuLink
                  icon={<BarsOutlined />}
                  title="Lists"
                  to={generateRoute(RoutesConstants.FingerprintLists, { serviceId: currentService?.id || "" })}
                />
                <MenuLink
                  icon={<SafetyOutlined />}
                  title="Risk Management"
                  to={generateRoute(RoutesConstants.FingerprintRiskManagement, { serviceId: currentService?.id || "" })}
                />
                {/*<MenuLink*/}
                {/*  icon={<LayoutOutlined />}*/}
                {/*  title="Logs"*/}
                {/*  to={generateRoute(RoutesConstants.AuthenticationServiceLogs, { serviceId: currentService?.id || "" })}*/}
                {/*/>*/}
                {/*<MenuLink*/}
                {/*  icon={<SafetyOutlined />}*/}
                {/*  title="Risk Management"*/}
                {/*  to={generateRoute(RoutesConstants.ServiceRiskManagement, { serviceId: currentService?.id || "" })}*/}
                {/*/>*/}
              </MenuList>
            </MenuSection>
            <TraillingLine />
            <MenuSection>
              <SectionHeader>Manage</SectionHeader>
              <MenuList>
                <MenuLink
                  icon={<QrcodeOutlined />}
                  title="QR Appearance"
                  to={generateRoute(RoutesConstants.QRAppearance, { serviceId: currentService?.id || "" })}
                />
                <MenuLink
                  icon={<ToolOutlined />}
                  title="Setup & Credentials"
                  to={generateRoute(RoutesConstants.KeysAndCredentials, { serviceId: currentService?.id || "" })}
                />
                <MenuLink
                  icon={<TeamOutlined />}
                  title="Members & Permissions"
                  to={generateRoute(RoutesConstants.Permissions, { serviceId: currentService?.id || "" })}
                />
                <MenuLink
                  icon={<ProjectOutlined />}
                  title="Plan & Billing"
                  to={generateRoute(RoutesConstants.PlanBilling, { serviceId: currentService?.id || "" })}
                />
                <SectionExternalItem href={KEYRI_LINKS.Docs} target="_blank">
                  <SectionItemIconContainer>
                    <SelectOutlined />
                  </SectionItemIconContainer>
                  <SectionItemText>Documentation</SectionItemText>
                </SectionExternalItem>
              </MenuList>
            </MenuSection>
          </>
        );
      case ServiceType.EchoGuard:
        return (
          <>
            <MenuSection>
              <SectionHeader>EchoGuard</SectionHeader>
              <MenuList>
                <MenuLink
                  icon={<DashboardOutlined />}
                  title="Dashboard"
                  to={generateRoute(RoutesConstants.EchoDashboard, { serviceId: currentService?.id || "" })}
                />
                <MenuLink
                  icon={<TeamOutlined />}
                  title="Members & Permissions"
                  to={generateRoute(RoutesConstants.Permissions, { serviceId: currentService?.id || "" })}
                />
                <SectionExternalItem href={KEYRI_LINKS.Docs} target="_blank">
                  <SectionItemIconContainer>
                    <SelectOutlined />
                  </SectionItemIconContainer>
                  <SectionItemText>Documentation</SectionItemText>
                </SectionExternalItem>
              </MenuList>
            </MenuSection>
          </>
        );
      default:
        return <></>;
    }
  }, [currentService]);

  return (
    <AdminMenuWrapper>
      <HiddenMenuElement>
        <ServiceSelector />
        <TraillingLine />
      </HiddenMenuElement>
      {getMenuByServiceType()}
    </AdminMenuWrapper>
  );
};

export const AdminPanelMenu = observer(AdminPanelMenuComponent);
