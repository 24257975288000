import styled from "styled-components";
import {Radio} from "antd";
import {FONTS} from "../../assets";
import {COLORS} from "../../utils/colors";

export const RadioGroup = styled(Radio.Group)``;

export const RadioButton = styled(Radio.Button)`
  font-family: ${FONTS.RUBIK};
  font-size: 14px;
  color: ${COLORS.SCORPION};
  background-color: ${COLORS.WHITE};
  
  &:hover {
    color: ${COLORS.MINE_SHAFT} !important;
    background-color: ${COLORS.WHITE_LILAC};
  }
`;