import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { BlockTitle, MenuItemContainer, MenuItemContent, SearchBlock } from "styles";
import { StarOutlined, UploadOutlined } from "@ant-design/icons";
import { ButtonWithIconStylePreset, IListsQueryParams, OutcomeValues } from "utils/types";
import { ButtonWithIcon, LinkWithIcon } from "components";
import { ControlButtonsBlock, ListControlPanel } from "./styles";
import { ListsTableWrapper } from "./screenComponents/ListsTable";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { IpTableWrapper } from "./screenComponents/IpTable";
import { CountryTableWrapper } from "./screenComponents/CountryTable";
import { useStore } from "utils/hooks";
import { observer } from "mobx-react-lite";
import { generateQueryString, parseQuery } from "utils/helpers";
import { InternalLinks, ListType } from "./types";
import { UserIdTableWrapper } from "./screenComponents/UserIdTable";
import { DeviceIdTableWrapper } from "./screenComponents/DeviceIdTable";
import { ImeiTableWrapper } from "./screenComponents/IMEITable";
import { API_URL, generateRoute, Routes as RoutesConstants } from "utils/constants";
import { SearchTableWrapper } from "./screenComponents/SearchTable";

const FingerprintListsScreen: FC<any> = () => {
  const {
    services: { currentService },
    billing: { currentServicePlan },
    fingerprintList: { listType, page, searchField, outcome, loadDataList, loadServiceInfo, setSearchField, setOutcome, setPage, setListType },
  } = useStore();
  const location = useLocation();
  const navigate = useNavigate();

  const [localSearchInputField, setSearchInputField] = useState<string>(searchField);
  const [showUploadPopup, setShowUploadPopup] = useState<boolean>(false);

  const currentQueryParams = useMemo<IListsQueryParams>(() => {
    const params = parseQuery(location.search);
    return {
      page: params.page ? +params.page : 1,
      outcome: (params.outcome as unknown as Array<OutcomeValues>) || ([] as Array<OutcomeValues>),
      filter: !!params.filter && ["outcome", "value", "notes", "updatedBy"].includes(params.filter?.toString()) ? params.filter : "",
      search: params.search ? params.search : "",
    };
  }, [location.search]);
  const exportCSVLink = useMemo(() => {
    const token = localStorage.getItem("token");
    if (!currentService || !token) return "";

    const params: Record<string, string | Array<string>> = { token };
    if (listType !== ListType.Main) params.listType = listType;
    if (outcome) params.outcome = outcome;
    if (searchField) {
      params.filter = "value";
      params.search = searchField;
    }
    const queryParams = generateQueryString(params);
    return `${API_URL}/service-list/${currentService?.id}/export?${queryParams}`;
  }, [searchField, outcome, listType, currentService]);

  const subRoute = useMemo(() => {
    switch (true) {
      case location.pathname.includes(InternalLinks.IP):
        return " > IP";
      case location.pathname.includes(InternalLinks.Country):
        return " > Country";
      case location.pathname.includes(InternalLinks.DeviceID):
        return " > Device ID";
      case location.pathname.includes(InternalLinks.IMEI):
        return " > IMEI";
      case location.pathname.includes(InternalLinks.UserID):
        return " > User ID";
      case location.pathname.includes(InternalLinks.Search):
        return " > Search";
      default:
        return "";
    }
  }, [location]);

  const searchPlaceholder = useMemo(() => `Search${[ListType.Search, ListType.Main].includes(listType as ListType) ? " all lists" : ""}`, [listType]);

  const onChangeSearchInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchInputField(e.target.value);
  }, []);

  const handleSearchButton = useCallback(async () => {
    setSearchField(localSearchInputField);
    const searchParams = generateQueryString({ search: localSearchInputField });
    if ([ListType.Main, ListType.Search].includes(listType as ListType))
      return navigate({
        pathname: `${generateRoute(RoutesConstants.FingerprintLists, { serviceId: currentService?.id || "" })}${InternalLinks.Search}`,
        search: searchParams,
      });
    navigate({ search: searchParams });
  }, [localSearchInputField]);

  const navigateQuery = useCallback(
    (queryParams) => {
      const searchParams = generateQueryString(queryParams);
      navigate({ search: searchParams });
    },
    [navigate]
  );

  useEffect(() => {
    setSearchField(currentQueryParams.search ? currentQueryParams.search.toString() : '');
    setOutcome(currentQueryParams.outcome?.length ? currentQueryParams.outcome : ([] as Array<string>));
    setPage(currentQueryParams.page);
    return () => {
      setListType(ListType.Main);
    };
  }, []);

  useEffect(() => {
    if (!currentService) return;
    const query = {
      search: searchField,
      outcome,
      page,
    };

    navigateQuery(query);
    if (listType === ListType.Main) {
      loadServiceInfo(currentService.id);
    } else {
      loadDataList(currentService.id);
    }
  }, [currentService, page, outcome, searchField, listType]);

  useEffect(() => {
    setPage(1);
  }, [currentService, currentServicePlan]);

  useEffect(() => {
    if (listType === ListType.Search) {
      setOutcome([]);
      return;
    }
    if (subRoute === "") {
      setSearchField("");
      setSearchInputField("");
      setOutcome([]);
    }
  }, [listType, subRoute]);

  useEffect(() => {
    switch (true) {
      case location.pathname.includes(InternalLinks.IP):
        setListType(ListType.IP);
        break;
      case location.pathname.includes(InternalLinks.Country):
        setListType(ListType.Country);
        break;
      case location.pathname.includes(InternalLinks.DeviceID):
        setListType(ListType.DeviceID);
        break;
      case location.pathname.includes(InternalLinks.IMEI):
        setListType(ListType.IMEI);
        break;
      case location.pathname.includes(InternalLinks.UserID):
        setListType(ListType.UserID);
        break;
      case location.pathname.includes(InternalLinks.Search):
        setListType(ListType.Search);
        break;
      default:
        setListType(ListType.Main);
    }
  }, [location]);

  return (
    <MenuItemContainer>
      <MenuItemContent>
        <BlockTitle>
          {listType !== ListType.Main ? (
            <Link to={generateRoute(RoutesConstants.FingerprintLists, { serviceId: currentService?.id || "" })}>Lists</Link>
          ) : (
            "Lists"
          )}
          {subRoute}
        </BlockTitle>
        <ListControlPanel>
          <SearchBlock
            defaultValue={localSearchInputField}
            onChange={onChangeSearchInput}
            onSearch={handleSearchButton}
            value={localSearchInputField}
            height={32}
            placeholder={searchPlaceholder}
            style={{ maxWidth: 505 }}
          />
          <ControlButtonsBlock>
            {subRoute === "" && (
              <ButtonWithIcon
                type={"submit"}
                text="Upload CSV"
                icon={<StarOutlined />}
                maxWidth={140}
                preset={ButtonWithIconStylePreset.Small}
                onClick={() => setShowUploadPopup(!showUploadPopup)}
              />
            )}
            <LinkWithIcon text="Export" icon={<UploadOutlined />} maxWidth={100} preset={ButtonWithIconStylePreset.Small} href={exportCSVLink} />
          </ControlButtonsBlock>
        </ListControlPanel>
        <Routes>
          <Route path={InternalLinks.Lists} element={<ListsTableWrapper showUploadForm={showUploadPopup} />} />
          <Route path={InternalLinks.IP} element={<IpTableWrapper />} />
          <Route path={InternalLinks.UserID} element={<UserIdTableWrapper />} />
          <Route path={InternalLinks.Country} element={<CountryTableWrapper />} />
          <Route path={InternalLinks.DeviceID} element={<DeviceIdTableWrapper />} />
          <Route path={InternalLinks.IMEI} element={<ImeiTableWrapper />} />
          <Route path={InternalLinks.Search} element={<SearchTableWrapper />} />
        </Routes>
      </MenuItemContent>
    </MenuItemContainer>
  );
};

export const FingerprintListsScreenWrapper = observer(FingerprintListsScreen);
