import { FC, Fragment, useMemo } from "react";
import { PlanInfo } from "../PlanInfo";
import { PlanInput, PlanLabel, Plans, SubBlock, SubBlockTitle } from "./styles";
import { IBilling } from "./props";
import { useStore } from "../../utils/hooks";
import { DefaultLoader } from "../DefaultLoader";
import { observer } from "mobx-react-lite";
import { BillingInformation } from "../BillingInformation";
import { IPlan } from "../../utils/types";
import { filterPlans, sortPlansHelper } from "../../utils/helpers";
import { InfoWarnIcon, WarnAlert } from "../../styles";
import { IPlanMX } from "../../stores/BillingStore";

export enum IPlanTypes {
  AuthFraud = "auth_fraud",
  EchoGuard = "echo",
}

const BillingComponent: FC<IBilling> = ({ defaultPlanTypes = IPlanTypes.AuthFraud, onChange, handleChange, handleBlur, selectedPlan }) => {
  const {
    billing: { isPlansFetch, plans },
  } = useStore();

  const selectedTypePlans = useMemo(() => filterPlans(plans, defaultPlanTypes), [defaultPlanTypes, plans]);

  const selectedPlanObject: IPlan | undefined = useMemo(() => plans.find((plan) => plan.id === selectedPlan), [selectedPlan, plans]);

  return (
    <>
      <SubBlock>
        <SubBlockTitle style={{ paddingLeft: "12px" }}>Plans</SubBlockTitle>
        {isPlansFetch ? (
          <DefaultLoader />
        ) : (
          <Plans>
            {selectedTypePlans
              .slice()
              .sort(sortPlansHelper)
              .map((item, index) => (
                <Fragment key={item.name}>
                  <PlanInput
                    checked={item.id === selectedPlan}
                    type="radio"
                    name="plan"
                    id={item.id}
                    value={item.id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <PlanLabel data-type={item.name} htmlFor={item.id}>
                    <PlanInfo plan={item} />
                  </PlanLabel>
                </Fragment>
              ))}
          </Plans>
        )}
        {/Enterprise/.test(plans.find((planObject: IPlanMX) => planObject.id === selectedPlan)?.name ?? "") && (
          <WarnAlert
            message={"You will be contacted by the Keyri sales team within 2 business days to finalize the terms of your Enterprise Plan."}
            type="info"
            icon={<InfoWarnIcon />}
            showIcon
            maxWidth={1176}
          />
        )}
      </SubBlock>
      {selectedPlanObject?.requiredCard && (
        <SubBlock>
          <SubBlockTitle>Billing Information</SubBlockTitle>
          <BillingInformation onChange={onChange} />
        </SubBlock>
      )}
    </>
  );
};

export const Billing = observer(BillingComponent);
