import styled from "styled-components";
import { FONTS } from "assets";
import { COLORS } from "utils/colors";
import { DIMENSIONS } from "utils/constants";
import { Link } from "react-router-dom";

export const SettingsSubHeaderTitle = styled.div`
  font-family: ${FONTS.RUBIK};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.SCORPION};
`;

export const CustomizeQRBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 36px;
`;

export const QRExampleImage = styled.div`
  width: 214px;
  height: 214px;
  border-radius: 11px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.WHITE};
`;

export const QRExampleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const QRExampleBorder = styled.div`
  width: 220px;
  height: 220px;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background: conic-gradient(from 45deg at 50% 50%, rgb(118, 118, 118) 0deg, rgb(57, 210, 213) 180deg, rgb(140, 91, 220) 360deg);
`;

export const ServiceLogo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
`;

export const ImageUploadBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ImagePreviewBlock = styled(ImageUploadBlock)`
  padding: 0 0 0 56px;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: ${COLORS.GALLERY};

  @media (max-width: ${DIMENSIONS.MAX_FLEX_ROW_WIDTH}px) {
    padding: 0;
    border: none;
  }
`;

export const ImageBlockHeader = styled.div`
  font-family: ${FONTS.RUBIK};
  font-size: 18px;
  line-height: 24px;
  color: ${COLORS.SCORPION};
`;

export const Message = styled.div`
  font-family: ${FONTS.RUBIK};
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.MINE_SHAFT};
`;

export const UpgradePlanButton = styled(Link)`
  color: ${COLORS.KUMERA};
  background-color: inherit;
  border: none;
  cursor: pointer;
`;
