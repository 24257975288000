export enum InternalLinks {
  Lists = "/",
  IP = "/ip",
  Country = "/country",
  UserID = "/user-id",
  DeviceID = "/device-id",
  IMEI = "/imei",
  Search = "/search",
}

export enum ListType {
  Main = 'main',
  IP = "ip",
  Country = "country",
  UserID = "user_id",
  DeviceID = "device_id",
  IMEI = "imei",
  Search = 'search',
}