import styled from 'styled-components';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import { COLORS } from '../../utils/colors';
import { FONTS } from '../../assets';

export const InputImageName = styled(UserOutlined)`
  color: ${COLORS.MEDIUM_PURPLE};
  position: absolute;
  height: 18px;
  width: 18px;
  left: 22px;
  top: 50%;
  transform: translateY(-50%);
`;

export const InputImageEmail = styled(MailOutlined)`
  color: ${COLORS.MEDIUM_PURPLE};
  position: absolute;
  height: 18px;
  width: 18px;
  left: 22px;
  top: 50%;
  transform: translateY(-50%);
`;

export const UpdateProfileButton = styled.button`
  height: 40px;
  padding: 0 16px;
  margin-top: 24px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  user-select: none;
  
  background: ${COLORS.SELAGO};
  border: 1px solid ${COLORS.MEDIUM_PURPLE};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.5s linear;
  width: 100%;
  
  &:hover > div, &:hover {
    background: none;
  }
  
  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: 0.5;
    border-color: #D9D9D9;
    background-color: #F5F5F5;
    color: ${COLORS.SILVER};
  }
`;

export const InputWrapperElement = styled.label`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
`;

export const StyledLine = styled.hr`
  border-top: 1px solid ${COLORS.LIGHT_GRAY};
  border-right: 0;
  border-left: 0;
`;

export const InputTitle = styled.div`
  font-family: ${FONTS.RUBIK};
  font-size: 14px;
  color: ${COLORS.SCORPION};
  margin-bottom: 12px;
`;
