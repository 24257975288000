import React, { CSSProperties, FC } from "react";
import s from "./menuLink.module.css";
import { IMenuLinkProps } from "./props";
import { NavLink } from "react-router-dom";
import { SectionItemIconContainer, SectionItemText } from "../AdminPanelMenu/styles";
import { COLORS } from "../../utils/colors";

const activeLinkStyles: CSSProperties = {
  color: COLORS.MEDIUM_PURPLE,
  border: `1px solid #A37CE3`,
  backgroundColor: COLORS.WHITE_LILAC,
  boxShadow: `0px 3px 6px rgba(0, 0, 0, 0.04)`,
};

export const MenuLink: FC<IMenuLinkProps> = ({ icon, title, to }) => {
  return (
    <li>
      <NavLink className={s.menuItemStyle} to={to} style={({ isActive }) => (isActive ? activeLinkStyles : {})}>
        <SectionItemIconContainer>{icon}</SectionItemIconContainer>
        <SectionItemText>{title}</SectionItemText>
      </NavLink>
    </li>
  );
};
