import styled from "styled-components";
import { FONTS } from "assets";
import { COLORS } from "utils/colors";

export const ButtonValue = styled.div`
  font-family: ${FONTS.RUBIK};
  font-weight: 700;
  font-size: 16px;
  color: ${COLORS.GRAY};
`;

export const ShortDescription = styled.div`
  font-size: 16px;
  line-height: 20px;
  height: 90px;
  font-family: ${FONTS.RUBIK};
  padding: 28px 0 0;
  color: ${COLORS.SCORPION};
  font-family: ${FONTS.RUBIK};
`;

export const PropertyStringValue = styled.div`
  height: 51px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
