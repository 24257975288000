import styled from "styled-components";
import { COLORS } from "../../../../utils/colors";

export const CustomTables = styled.div`
  max-width: 1200px;
`;

export const ControlBlock = styled.div<{ height?: number }>`
  width: 180px;
  height: ${({ height }) => (height ? height : 65)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 0;
  gap: 8px;

  &:first-child:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${COLORS.GALLERY};
    position: absolute;
    top: 48px;
    left: 0;
  }

  &:first-child:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${COLORS.GALLERY};
    position: absolute;
    top: 0;
    left: 0;
  }
`;
