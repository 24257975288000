import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { BlockContent, BlockTitle, InfoWarnIcon, MenuItemContainer, MenuItemContent, WarnAlert } from "../../styles";
import { DashboardLineChart, DefaultLoader, DoughnutChart, KeysNCredentials, RadioButtons } from "../../components";
import { useAmberfloRequest, useStore, useTabTitle } from "../../utils/hooks";
import { periodToNumberQuery, switchLocaleTimeToUTC, switchUTCTimeToLocale } from "../../utils/time-periods";
import { COLORS } from "../../utils/colors";
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from "chart.js";
import { MeterApiNames, TimePeriodsList } from "../../utils/constants";
import { DatePicker } from "antd";
import { ArrowRightOutlined, SafetyCertificateOutlined } from "@ant-design/icons";
import moment from "moment";
import { IEchoReportScreen } from "./props";
import { ILogsQueryParams } from "../../utils/types";
import { useLocation, useNavigate } from "react-router-dom";
import { generateQueryString, parseQuery } from "../../utils/helpers";
import { amberfloRequests } from "../../utils/amberflo";
import { convertNumberToAbbreviatedString } from "../../utils/convert-number-to-abbreviated-string";
import {
  DoughnutReport,
  FlexGroup,
  Log,
  LogChart,
  LogCount,
  LogCountsBlock,
  LogInfo,
  LogInfoContainer,
  LogItem,
  LogName,
  LogNameContainer,
  LogsContainer,
  SubFlexGroup,
} from "../ActivityReportScreen/styles";
import { ILineValue } from "../../components/DashboardLineChart/props";

const { RangePicker } = DatePicker;

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement, BarElement);

interface IVerificationsData {
  result: IVerificationsResult;
  value: number;
}

interface IVerificationsResult {
  group: { groupInfo: Record<string, string> };
  groupValue: number;
  values: Array<ILineValue>;
  color?: string;
}

interface ICurrentQueryParams {
  selectedPeriod: TimePeriodsList;
  periodQuery: { from: number; to?: number };
}

export const EchoReportScreen: FC<IEchoReportScreen> = () => {
  useTabTitle("Activity Report");
  const {
    services: { currentService },
  } = useStore();
  const navigate = useNavigate();
  // Get all initializing params from query string or set default
  const location = useLocation();
  const currentQueryParams = useMemo<ICurrentQueryParams>(() => {
    const params = parseQuery(location.search);
    return {
      selectedPeriod: params.selectedPeriod ? (params.selectedPeriod as TimePeriodsList) : TimePeriodsList.Last7Days,
      periodQuery:
        params.selectedPeriod === TimePeriodsList.Custom
          ? { from: +params.from, to: +params.to }
          : periodToNumberQuery(params.selectedPeriod ? params.selectedPeriod.toString() : TimePeriodsList.Last7Days),
    };
  }, [location.search]);

  // Set initial state params
  const [validationsLineLabels, setValidationsLineLabels] = useState<Array<number>>([]);
  const [validationsAllDoughnutData, setValidationsAllDoughnutData] = useState<IVerificationsData>({
    result: { group: { groupInfo: {} }, groupValue: 0, values: [] },
    value: 0,
  });
  const [validationsSuccessDoughnutData, setValidationsSuccessDoughnutData] = useState<IVerificationsData>({
    result: { group: { groupInfo: {} }, groupValue: 0, values: [] },
    value: 0,
  });
  const [selectedPeriod, setSelectedPeriod] = useState<TimePeriodsList>(currentQueryParams.selectedPeriod);
  const [periodQuery, setPeriodQuery] = useState<{ from: number; to?: number }>(currentQueryParams.periodQuery);

  const { isLoading, tokenError, requestError } = useAmberfloRequest({
    requests: [
      {
        body: amberfloRequests.getGroupingDataRequest({
          meterApiType: MeterApiNames.PhoneVerificationsInitiated,
          periodQuery: periodQuery,
        }),
        callback: (item) => {
          setValidationsLineLabels(item.secondsSinceEpochIntervals);
          setValidationsAllDoughnutData({
            result: { group: { groupInfo: {} }, groupValue: 0, values: [], color: COLORS.MEDIUM_PURPLE, ...item.clientMeters[0] } as IVerificationsResult,
            value: item.clientMeters[0]?.groupValue || 0,
          });
        },
      },
      {
        body: amberfloRequests.getGroupingDataRequest({
          meterApiType: MeterApiNames.PhoneVerificationsSuccessful,
          periodQuery: periodQuery,
        }),
        callback: (item) => {
          setValidationsSuccessDoughnutData({
            result: { group: { groupInfo: {} }, groupValue: 0, values: [], color: COLORS.MEDIUM_PURPLE, ...item.clientMeters[0] } as IVerificationsResult,
            value: item.clientMeters[0]?.groupValue || 0,
          });
        },
      },
    ],
    currentService,
    periodQuery,
  });

  // Handler for switching period
  const handlePeriod = useCallback((field: TimePeriodsList) => {
    return (value: any) => {
      setSelectedPeriod(field);
      switch (field) {
        case TimePeriodsList.Custom: {
          setPeriodQuery({
            from: switchLocaleTimeToUTC(+value[0].format("X")),
            to: switchLocaleTimeToUTC(+value[1].format("X")),
          });
          break;
        }
        default: {
          setPeriodQuery(periodToNumberQuery(field));
        }
      }
    };
  }, []);

  // Universal function for redirect with query params
  const navigateQuery = useCallback(
    (queryParams) => {
      const searchParams = generateQueryString(queryParams);

      navigate({ search: searchParams });
    },
    [navigate]
  );

  useEffect(() => {
    if (!currentService) return;
    const params: ILogsQueryParams = {
      selectedPeriod,
      from: +periodQuery.from,
    };
    if (periodQuery.to) params.to = +periodQuery.to;

    navigateQuery(params);
  }, [currentService, periodQuery, selectedPeriod, navigateQuery]);

  return (
    <MenuItemContainer>
      <MenuItemContent>
        <BlockTitle>Dashboard</BlockTitle>
        <FlexGroup>
          <SubFlexGroup>
            <RadioButtons
              defaultValue={selectedPeriod}
              list={[
                { value: TimePeriodsList.Last7Days, title: "1W", handler: handlePeriod(TimePeriodsList.Last7Days) },
                { value: TimePeriodsList.Last30Days, title: "1M", handler: handlePeriod(TimePeriodsList.Last30Days) },
                { value: TimePeriodsList.Last365Days, title: "1Y", handler: handlePeriod(TimePeriodsList.Last365Days) },
                { value: TimePeriodsList.Custom, title: "Custom" },
              ]}
            />
            <RangePicker
              style={{ maxWidth: "260px" }}
              picker="date"
              format="MMM D, YYYY"
              value={[
                moment(switchUTCTimeToLocale(periodQuery.from) * 1000),
                moment(periodQuery.to ? switchUTCTimeToLocale(periodQuery.to) * 1000 : Date.now()),
              ]}
              separator={<ArrowRightOutlined style={{ color: COLORS.SILVER }} />}
              disabledDate={(currentData) => currentData.isAfter(moment(Date.now()))}
              onChange={handlePeriod(TimePeriodsList.Custom)}
            />
          </SubFlexGroup>
        </FlexGroup>
        <LogsContainer>
          <BlockContent type={"small"}>
            {isLoading ? (
              <DefaultLoader />
            ) : requestError ? (
              <WarnAlert message={requestError} type="info" icon={<InfoWarnIcon />} showIcon />
            ) : (
              <Log>
                <LogNameContainer>
                  <SafetyCertificateOutlined />
                  <LogName>Phone Number Verifications</LogName>
                </LogNameContainer>
                <LogInfoContainer>
                  <DoughnutReport>
                    <DoughnutChart
                      data={[validationsAllDoughnutData.value, validationsSuccessDoughnutData.value]}
                      calculatedPercentageCount={validationsSuccessDoughnutData.value}
                      labels={["all", "success"]}
                      defaultColors={[COLORS.MEDIUM_PURPLE, COLORS.CLEAR_GREEN]}
                    />
                  </DoughnutReport>
                  <LogCountsBlock>
                    <LogInfo>
                      <LogItem color={COLORS.MEDIUM_PURPLE}>All</LogItem>
                      <LogCount>{convertNumberToAbbreviatedString(validationsAllDoughnutData.value)}</LogCount>
                    </LogInfo>
                    <LogInfo>
                      <LogItem color={COLORS.CLEAR_GREEN}>Success</LogItem>
                      <LogCount>{convertNumberToAbbreviatedString(validationsSuccessDoughnutData.value)}</LogCount>
                    </LogInfo>
                  </LogCountsBlock>
                </LogInfoContainer>
                <LogChart>
                  <DashboardLineChart
                    resultSet={[validationsAllDoughnutData.result, validationsSuccessDoughnutData.result]}
                    labels={validationsLineLabels}
                    group={"riskStatus"}
                  />
                </LogChart>
              </Log>
            )}
          </BlockContent>
        </LogsContainer>
        <KeysNCredentials />
      </MenuItemContent>
    </MenuItemContainer>
  );
};
