import { IChartBlockProps } from "./props";
import { BlockContent, InfoWarnIcon, WarnAlert } from "styles";
import { DefaultLoader } from "components";
import { Log, LogName, LogNameContainer, LogsContainer } from "../../styles";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import React, { PropsWithChildren } from "react";

export const ChartBlock = ({ title, isLoading, error, children }: PropsWithChildren<IChartBlockProps>) => {
  return (
    <LogsContainer>
      <BlockContent type={"small"} style={{ paddingBottom: "24px" }}>
        {isLoading ? (
          <DefaultLoader />
        ) : error ? (
          <WarnAlert message={error} type="info" icon={<InfoWarnIcon />} showIcon />
        ) : (
          <Log>
            <LogNameContainer>
              <SafetyCertificateOutlined />
              <LogName>{title}</LogName>
            </LogNameContainer>
            {children}
          </Log>
        )}
      </BlockContent>
    </LogsContainer>
  );
};
