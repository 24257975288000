import {
  FingerprintRulesList,
  IAccountAccessPerDeviceForm,
  IAddRuleForm,
  IChangeServicePlanForm,
  ICreateServiceForm,
  IEditUserForm,
  IFingerprintListTableValues,
  ILoginForm,
  INewCustomRuleForm,
  INewEchoApplicationForm,
  IRegistrationsPerDeviceForm,
  IRiskDistanceForm,
  ISignUpForm,
  IStackRuleForm,
  IWarnSettingForm,
  OutcomeValues,
} from "./types";
import moment from "moment/moment";
import { FingerprintEventsTitles } from "./constants";
import { ListType } from "../screens/FingerprintListsScreen/types";

const isValidEmail = (email: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

export const loginFormValidator = ({ email }: ILoginForm) => {
  const errors: { email?: string; password?: string } = {};
  if (!email) errors.email = "Invalid email address";
  if (email.length < 6 || email.length > 42) errors.email = "Email should be between 6 and 42 symbols in length";
  if (!!email && !isValidEmail(email)) errors.email = "Invalid email";
  return errors;
};

export const createAuthServiceFormValidator = ({ name, domainName }: ICreateServiceForm) => {
  const errors: { name?: string; domainName?: string; subDomainName?: string } = {};
  if (!name) errors.name = "Application name is required";
  if (!domainName) errors.domainName = "Invalid original domain address";
  return errors;
};

export const createEchoServiceFormValidator = ({ name }: INewEchoApplicationForm) => {
  const errors: { name?: string } = {};
  if (!name || name.length < 2) errors.name = "Application name is required and must be longer than 2 characters";
  return errors;
};

export const createCustomRuleFormValidator = ({ ruleName, outcome, notes, conditions, operator }: INewCustomRuleForm) => {
  const errors: { ruleName?: string; notes?: string; conditions?: Record<number, Record<string, string>>; outcome?: string; operator?: string } = {};
  if (!ruleName || ruleName.length < 3) errors.ruleName = "Rule name should be longer than 3 symbols";
  if (Object.values(FingerprintRulesList).includes(ruleName as FingerprintRulesList)) errors.ruleName = "You cannot predefine default rules";
  if (!outcome) errors.outcome = "Select outcome value";
  if (conditions.length === 0) errors.conditions = { [-1]: { condition: "Add conditions to rule" } };
  conditions.forEach((condition, index) => {
    const conditionErrors: Record<string, string> = {};
    if (!condition.condition) conditionErrors.condition = "Input condition";
    if (!["$or", "$not", "$in", "$nin", "$gte", "$gt", "$lte", "$lt", "$ne", "$eq"].includes(`${condition.comparator}`))
      conditionErrors.comparator = "Select comparator from the list";
    if (!condition.value) conditionErrors.value = "Input value field";

    if (Object.keys(conditionErrors).length) errors.conditions = { ...errors.conditions, [index]: conditionErrors };
  });
  if (conditions.length > 1 && !operator) errors.operator = "Select operator value";
  return errors;
};

export const editUserFormValidator = ({ name, email }: IEditUserForm) => {
  const errors: { name?: string; email?: string } = {};
  if (!name) errors.name = "User name is required field";
  if (name.length < 2 || name.length > 32) errors.name = "User name length should be between 2 and 32 symbols";
  if (!email) errors.email = "Email is required field";
  if (email.length < 6 || email.length > 42) errors.email = "Email should be between 6 and 42 symbols in length";
  return errors;
};

export const changeServicePlanFormValidator = ({ plan }: IChangeServicePlanForm) => {
  const errors: { plan?: string } = {};
  if (!plan) errors.plan = "Application name is required";
  return errors;
};

export const signUpFormValidator = async ({ fullName, email }: ISignUpForm) => {
  const errors: { fullName?: string; email?: string; response?: string } = {};
  if (!email) errors.email = "Email is required";

  const userEmailDomain = email.split("@")[1];
  if (!userEmailDomain/* || emailDomains.includes(userEmailDomain)*/) errors.email = "Please use a real email to sign up";

  if (!isValidEmail(email)) errors.email = "Invalid email";
  if (!fullName) errors.fullName = "Full name is required";
  return errors;
};

export const riskDistanceFormValidator = ({ warnDistance, denyDistance }: IRiskDistanceForm) => {
  const errors: { warnDistance?: string; denyDistance?: string } = {};
  if (!!warnDistance && isNaN(parseInt(warnDistance))) errors.warnDistance = "Warn Distance should be a number";
  if (!!warnDistance && parseInt(warnDistance) <= 0) errors.warnDistance = "Warn Distance should be bigger than 0";
  if (warnDistance && parseInt(warnDistance) >= parseInt(denyDistance)) errors.warnDistance = "Warn Distance should be less than Deny Distance";
  if (!!denyDistance && isNaN(parseInt(denyDistance))) errors.denyDistance = "Deny Distance should be a number";
  if (!!denyDistance && parseInt(denyDistance) >= 1000) errors.denyDistance = "Deny Distance should be less than 1000";
  return errors;
};

export const registrationsPerDeviceFormValidator = ({ denyRegistrationsPerDevices }: IRegistrationsPerDeviceForm) => {
  const errors: {
    denyRegistrationsPerDevices?: string;
  } = {};

  if (!!denyRegistrationsPerDevices) {
    if (Number.isNaN(+denyRegistrationsPerDevices)) {
      errors.denyRegistrationsPerDevices = "Deny Registration pre Device should be a number";
    }
    if (denyRegistrationsPerDevices >= 1000) {
      errors.denyRegistrationsPerDevices = "Deny Registration pre Device should be less than 1000";
    }
  }

  return errors;
};

export const accAccessPerDeviceFormValidator = ({ denyAccAccess }: IAccountAccessPerDeviceForm) => {
  const errors: {
    denyRegistrationsPerDevices?: string;
  } = {};

  if (!!denyAccAccess) {
    if (Number.isNaN(+denyAccAccess)) {
      errors.denyRegistrationsPerDevices = "Deny Registration pre Device should be a number";
    }
    if (denyAccAccess >= 1000) {
      errors.denyRegistrationsPerDevices = "Deny Registration pre Device should be less than 1000";
    }
  }

  return errors;
};

export const fingerprintListTableValidator = ({ records }: IFingerprintListTableValues) => {
  const errors: Record<number, any> = {};
  records.forEach((record, index) => {
    const recordErrors: Record<string, string> = {};
    if (!Object.values(OutcomeValues).includes(record.outcome)) recordErrors.outcome = "Incorrect outcome value";
    if (record.value.length > 200 || record.value.length < 2) recordErrors.value = "Value have incorrect length";
    if (moment(record.expired_date).valueOf() < moment().startOf("day").valueOf()) recordErrors.expired_date = "Expired date is incorrect";
    if (record.notes.length > 300) recordErrors.notes = "Notes are more than 300 symbols";

    if (Object.keys(recordErrors).length) errors[index] = recordErrors;
  });

  return errors;
};

export const ipListTableValidator = ({ records }: IFingerprintListTableValues, errors: Record<number, any> = {}) => {
  records.forEach((record, index) => {
    const expression =
      /(?:^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$)|(?:^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$)/gm;
    if (!expression.test(record.value)) errors[index] = { ...errors[index], value: "Please input a valid IPv4 or IPv6 address" };
  });

  return errors;
};

export const warnValidator = ({ events, seconds }: IWarnSettingForm) => {
  const errors: {
    events?: string;
    seconds?: string;
  } = {};

  return errors;
};

export const addRuleValidator = ({ events, identifiers, eventsAmount, amountedIdentifiers, risk, ruleName }: IAddRuleForm) => {
  const errors: {
    events?: string;
    identifiers?: string;
    eventsAmount?: string;
    amountedIdentifiers?: string;
    risk?: string;
    ruleName?: string;
  } = {};

  if (!events || !Object.keys(FingerprintEventsTitles).includes(events)) errors.events = "Select events from events list";
  if (!identifiers || !Object.values(ListType).includes(identifiers as ListType)) errors.identifiers = "Select identifiers from identifiers list";
  if (typeof eventsAmount !== "number") errors.eventsAmount = "Fill events amount field";
  if (!amountedIdentifiers || !Object.values(ListType).includes(amountedIdentifiers as ListType))
    errors.amountedIdentifiers = "Select second identifier from identifiers list";
  if (!risk || !Object.values(OutcomeValues).includes(risk as OutcomeValues)) errors.risk = "Select outcome value from selector";
  if (!ruleName || ruleName.length < 2) errors.ruleName = "Input rule name";

  return errors;
};

export const stackRuleValidator = ({ firstSignal, secondSignal, risk }: IStackRuleForm) => {
  const errors: {
    firstSignal?: string;
    secondSignal?: string;
    risk?: string;
  } = {};

  if (!firstSignal) errors.firstSignal = "Select signal from signals list";
  if (!secondSignal) errors.secondSignal = "Select second signal from signals list";
  if (!risk) errors.risk = "Select risk from risks list";

  return errors;
};
