import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { FONTS } from "../../assets";
import { CloseOutlined } from "@ant-design/icons";

export const Headline = styled.div`
  margin: 0 0 42px;
  padding: 0 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${COLORS.NIAGARA};
`;

export const HeadlineInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  font-family: ${FONTS.RUBIK};
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
`;

export const HeadlineImage = styled.img`
  width: 16px;
  height: 24px;
`;

export const CrossButton = styled(CloseOutlined)`
  font-size: 20px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const ModalContent = styled.div`
  flex: 1;
  font-family: ${FONTS.RUBIK};
  min-width: 300px;
`;

export const StepListTitle = styled.div`
  margin: 0 0 42px;
  font-size: 15px;
  line-height: 23px;

  color: ${COLORS.SCORPION};
`;

export const QrContainer = styled.div`
  width: 204px;
  height: 204px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 3px;
  background: conic-gradient(
    rgba(140, 91, 220, 1),
    rgba(57, 210, 213, 1),
    rgba(140, 91, 220, 1)
  );
  border-radius: 13px;
`;

export const QrCode = styled.div<{dimension: number}>`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({dimension}) => !!dimension ? dimension : 214}px;
  height: ${({dimension}) => !!dimension ? dimension : 214}px;

  background-color: ${COLORS.WHITE};
  border-radius: 10px;
`;
