import { Response } from "@denver23/keyri-shared";

export class KeyriError extends Error {
  code: number;
  message: string;
  constructor(error: Response.IError | number, message?: string) {
    super();
    this.name = "KeyriError";
    let errorObject = typeof error === "number" ? errorList[`error${error}`] : error;
    this.code = errorObject.code;
    this.message = `${errorObject.code === 999 ? errorList.error999.message : ""}${message ?? errorObject.message}`;
  }
}

export const errorList: Record<string, Response.IError> = {
  error999: {
    message: "The next error was caught: ",
    code: 999,
  },
  error998: {
    message: "Service not found",
    code: 998,
  },
  error997: {
    message: "User not found",
    code: 997,
  },
  error996: {
    message: "Crypto module is not initialized",
    code: 996,
  },
  error995: {
    message: "Service not selected",
    code: 995,
  },
  error994: {
    message: "Rules are not valid",
    code: 994,
  },
};
