import styled from "styled-components";
import { COLORS } from "../../../../utils/colors";
import { GeneralBlockShadow } from "../../../../styles";

export const MainBlock = styled.div`
  ${GeneralBlockShadow};
  padding: 18px 23px;
  display: flex;
  flex-wrap: wrap;
`;

export const DeviceProperty = styled.div`
  display: flex;
  gap: 12px;
  margin-right: 45px;
  color: ${COLORS.MINE_SHAFT};
`;
