import React, { FC, useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { FONTS } from "../../assets";
import { COLORS } from "../../utils/colors";
import { IDoughnutChartProps } from "./props";

const formatDoughnut = (data: Array<number>, calculatedPercentageCount = 0, labels: Array<string>, defaultColors?: Array<string>) => {
  const wait = !data.length ? 1 : 0;

  const reducedCount = data.reduce((prev, current) => prev + current, 0);

  const sortColors = defaultColors ? defaultColors : [...labels, wait].map((item, index) => {
    const isLastItem = index === labels.length;
    return isLastItem ? COLORS.SILVER : item === "true" ? COLORS.MEDIUM_PURPLE : COLORS.CARNATION;
  });

  return {
    data: {
      labels: labels,
      datasets: [
        {
          label: "# of Votes",
          data: [...data, wait],
          backgroundColor: sortColors,
          borderColor: sortColors,
          borderWidth: 1,
        },
      ],
    },
    redraw: true,
    options: {
      cutout: 38,
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false,
        },
      },
    },
    plugins: [
      {
        id: "textInside",
        beforeDraw: function (chart: any) {
          const width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
          ctx.restore();
          ctx.font = 14 + `px ${FONTS.RUBIK}`;
          ctx.textBaseline = "top";
          ctx.fillStyle = COLORS.MEDIUM_PURPLE;
          const text = wait ? "NA%" : !reducedCount ? "100%" : `${Math.round((calculatedPercentageCount * 100) / reducedCount)}%`,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2 - 7;
          ctx.fillText(text, textX, textY);
          ctx.save();
        },
      },
    ],
  };
};

export const DoughnutChart: FC<IDoughnutChartProps> = ({ data, calculatedPercentageCount = 0, labels, defaultColors }) => {
  const config = useMemo(() => formatDoughnut(data, calculatedPercentageCount, labels, defaultColors), [data, calculatedPercentageCount, labels]);

  return <Doughnut data={config.data} redraw={config.redraw} options={config.options} plugins={config.plugins} />;
};
