import { IMAGES } from "../../assets";
import { ActivateIcon, ActivatePassless, ActivateText, HeaderContainer, Logo, LogoButton, Profile, ProfileIcon, UserSettings } from "./styles";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useMemo, useState } from "react";
import { IAdminPanelHeaderProps } from "./props";
import { Menu, Dropdown, Tooltip } from "antd";
import { LogoutOutlined, ProfileOutlined } from "@ant-design/icons";
import { EditUserProfile } from "..";
import { useStore } from "../../utils/hooks";
import { Routes } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { isMobileDevice } from "../../utils/device";

const AdminPanelHeaderComponent: FC<IAdminPanelHeaderProps> = ({ handleModal }) => {
  const navigate = useNavigate();
  const { clearStore, user } = useStore();
  const [isProfileModalVisible, setIsModalVisible] = useState(false);
  const isDesktop = useMemo(() => {
    return !isMobileDevice();
  }, []);

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const logoutHandler = useCallback(async () => {
    await user.logout();
    clearStore();
    navigate(Routes.Login);
  }, [clearStore, navigate, user]);

  const menu = useMemo(
    () => (
      <Menu style={{ borderRadius: "6px" }}>
        <Menu.Item key={"profile-button"} icon={<ProfileOutlined />} className={"edit-profile"} onClick={showModal}>
          Edit Profile
        </Menu.Item>
        <Menu.Item key={"log-out-button"} icon={<LogoutOutlined />} onClick={logoutHandler} danger={true}>
          Log out
        </Menu.Item>
      </Menu>
    ),
    [logoutHandler]
  );

  return (
    <HeaderContainer>
      <LogoButton to={Routes.Home}>
        <Logo src={IMAGES.NEW_LOGO} />
      </LogoButton>
      <UserSettings>
        {isDesktop && (
          <ActivatePassless data-type={"activate-passwordless"} onClick={handleModal}>
            <ActivateIcon src={IMAGES.PHONE_BOLD} />
            <ActivateText>Activate Passwordless Login</ActivateText>
          </ActivatePassless>
        )}
        <Dropdown arrow trigger={["click"]} overlay={menu} placement="bottomRight" overlayClassName={"header-profile-dropdown"}>
          <Profile data-type={"user-dropdown"}>
            <Tooltip placement="left" title={user.name}>
              <ProfileIcon />
            </Tooltip>
          </Profile>
        </Dropdown>
      </UserSettings>
      <EditUserProfile visible={isProfileModalVisible} onCancel={handleCancel} />
    </HeaderContainer>
  );
};

export const AdminPanelHeader = observer(AdminPanelHeaderComponent);
