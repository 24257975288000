import styled from "styled-components";
import { MainContentWrapper } from '../../styles';
import { DIMENSIONS } from '../../utils/constants';
import { COLORS } from '../../utils/colors';
import { FONTS, IMAGES } from '../../assets';

interface IAppItemProps {
  logoUrl?: string;
}

interface IMenuVisible {
  menuVisible?: boolean;
}

interface IGeneralWrapperProps {
  backgroundImage: boolean;
}

export const AppItem = styled.button<IAppItemProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  background: none;
  border: none;
  width: 100%;
  
  box-sizing: border-box !important;
  transition: all 0.5s;
  padding: 0;
  
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AppPropertyContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;


export const GENERAL_FONT_STYLES = `
  font-size: 16px;
  font-family: ${FONTS.ROBOTO};
`;

export const AdminDataList = `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: ${FONTS.FUTURA_MEDIUM_C};
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const GeneralWrapper = styled.div<IGeneralWrapperProps>`
  display: flex;
  flex-direction: column;
  background: url(${(({backgroundImage}) => backgroundImage ? `${IMAGES.WELCOME_QR_BG}` : 'none')});
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: ${COLORS.ALABASTER};
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
`;

export const AdminContentWrapper = styled(MainContentWrapper)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;

  padding: 0 60px;
  
  @media (min-width: ${DIMENSIONS.MAX_CONTENT + 120}px) {
    width: 100%;
    max-width: 1920px;
    box-sizing: content-box;
    margin: 0 auto;
  }

  @media (max-width: ${DIMENSIONS.WHITEPAPER_FORM}px) {
    padding: 0 30px;
  }
`;

export const ContentWrapper = styled.div<IMenuVisible>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
 
  ${({ menuVisible }) => menuVisible ? 'max-width: calc(100% - 302px);' : ''}

  @media (max-width: ${DIMENSIONS.LIST_BLOCK}px) {
    ${({ menuVisible }) => menuVisible ? 'max-width: calc(100% - 85px);' : ''}
  }
`;

export const AdminBlock = `
  display: flex;
  background-color: ${COLORS.LIGHT_GRAY};
  border: 1px solid ${COLORS.SECONDARY_PURPLE};
  padding: 25px 30px;
  flex-grow: 1;
  align-content: space-between;
  box-sizing: border-box !important;
  
  &:last-child {
    margin-right: 0;
  }
`;
