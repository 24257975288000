import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import IChangableFieldProps from "./props";
import { useChangableField } from "../../utils/hooks";
import {
  ChangePackageUrlButton,
  PackageNameIcon,
  PackageNameInput,
  PackageNameLabel,
  PropertyString,
  PropertyValue,
  SavePackageButton,
  UpdatePackageButton,
} from "./styles";

export const ChangableField: FC<IChangableFieldProps> = ({
  icon,
  initialValue,
  suffix,
  callback,
  onClick,
  button,
  placeholder,
  maxlength,
  initialMode = false,
  changeable = true,
}) => {
  const { nameInput, setNameInput, switchEditMode, editMode, onInput, setEditMode, reference } = useChangableField(initialMode);
  const inputObject = useRef<HTMLDivElement>(null);

  const initialText = useMemo(() => {
    const fullLengthValue = `${initialValue}${!!suffix ? suffix : ""}`;
    const shortcutValue = fullLengthValue.length > 30 ? fullLengthValue.slice(0, 30) + "..." : fullLengthValue;
    return initialValue ? shortcutValue : "None";
  }, [initialValue, suffix]);

  const handleSaveButton = useCallback(async () => {
    try {
      if (callback) {
        await callback(nameInput ? nameInput : "");
      }
      setEditMode(false);
    } catch (err: any) {
      setEditMode(false);
    }
  }, [nameInput, callback, setEditMode]);

  useEffect(() => {
    setNameInput(initialValue ? initialValue : "");
  }, [initialValue, setNameInput]);

  const handleClickOutside = useCallback(
    (e: Event) => {
      const eventNode = e.target as Node;
      if ((!inputObject.current || !inputObject.current.contains(eventNode)) && inputObject.current?.getAttribute("data-mode") && initialValue) {
        setEditMode(false);
      }
    },
    [inputObject, setEditMode, initialValue]
  );

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => window.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  return button ? (
    <PropertyValue>
      {icon}
      {initialText}
      <ChangePackageUrlButton onClick={onClick} />
    </PropertyValue>
  ) : (
    <div ref={inputObject} data-mode={editMode}>
      <PropertyValue>
        <PackageNameLabel hidden={!editMode}>
          <PackageNameIcon>{icon}</PackageNameIcon>
          <PackageNameInput
            maxLength={maxlength}
            placeholder={placeholder ? placeholder : ""}
            type="text"
            value={nameInput ? nameInput : ""}
            onChange={onInput}
            ref={reference}
          />
          {initialValue ? (
            <UpdatePackageButton data-type={placeholder} onClick={handleSaveButton} />
          ) : (
            <SavePackageButton data-type={placeholder} onClick={handleSaveButton} />
          )}
          {/*<CancelPackageButton onClick={handleCancelButton} />*/}
        </PackageNameLabel>
        <PropertyString hidden={editMode} data-type={`${placeholder} Value`}>
          {icon}
          {initialText}
          {changeable && <ChangePackageUrlButton data-type={placeholder} onClick={switchEditMode(initialValue ? initialValue : "")} />}
        </PropertyString>
      </PropertyValue>
    </div>
  );
};
