import LOGO from "./images/logo.png";
import ADMIN_LOGO from "./images/admin-logo.png";
import MENU_BUTTON from "./images/menu-button.png";
import SMALL_LOGO from "./images/small-logo.png";
import SMALL_ADMIN_LOGO from "./images/admin-small-logo.png";
import USER from "./images/user.png";
import REGISTRATION_QR_CODE from "./images/registration-qr-code.png";
import PLUS from "./images/plus.svg";
import INFO from "./images/info.png";
import WARNING from "./images/warning.png";
import DOWNLOAD from "./images/download.png";
import EDIT from "./images/edit.svg";
import APP_LOGO from "./images/app-logo.png";
import GOOGLE_PLAY_LOGO from "./images/google-play-brands.png";
import APP_STORE_LOGO from "./images/app-store-ios-brands.png";
import FULL_NAME from "./images/signup-name-icon.svg";
import EMAIL from "./images/signup-mail-icon.svg";
import PASSWORD from "./images/signup-password-icon.svg";
import NEW_LOGO from "./images/logo.svg";
import REGISTRATION_MAIL from "./images/signup-registration-mail.svg";
import BUTTON_ARROW from "./images/button-arrow.svg";
import CHANGE_MAIL_MARKER from "./images/change-mail-marker.svg";
import WELCOME_PHONE from "./images/welcome-phone.svg";
import WELCOME_PLUS from "./images/welcome-plus.svg";
import WELCOME_QR_BG from "./images/welcome-qr-bg.png";
import PHONE_BOLD from "./images/phone-bold.svg";
import MODAL_PHONE from "./images/modal-phone.svg";
import STATIC_USER_ICON from "./images/static-user-icon.png";
import REQUIREMENT_FAIL from "./images/requirement_fail.svg";
import REQUIREMENT_SUCCESS from "./images/requirement_success.svg";
import COUNTRY_ICON from "./images/country.svg";
import ZIP_ICON from "./images/zip.svg";

import { ReactComponent as USER_ICON } from "./images/user-icon.svg";
import { ReactComponent as USER_VERTICAL_CARD } from "./images/user-vertical-card.svg";
import { ReactComponent as COPY_ICON } from "./images/copy-icon.svg";
import { ReactComponent as KEY_ICON } from "./images/key-icon.svg";
import { ReactComponent as PLUS_ICON } from "./images/plus.svg";
import { ReactComponent as ADD_APPLICATION_ICON } from "./images/add-application.svg";
import { ReactComponent as UPGRADE_ICON } from "./images/upgrade-icon.svg";
import { ReactComponent as LINE_DISTANCE_ICON } from "./images/line-distance-icon.svg";
import { ReactComponent as COUNTRY_DIFFERENTIAL_ICON } from "./images/country-differential-icon.svg";
import { ReactComponent as TOR_FILLED_ICON } from "./images/tor-filled-icon.svg";
import { ReactComponent as KNOWN_ATTACKER_ICON } from "./images/known-attacker-icon.svg";
import { ReactComponent as ANONYMOUS_FILLED_ICON } from "./images/anonymous-filled-icon.svg";
import { ReactComponent as VERIFY_CLOUD_ICON } from "./images/verify-cloud-icon.svg";
import { ReactComponent as PLAY_ICON } from "./images/play-icon.svg";
import { ReactComponent as APP_STORE_ICON } from "./images/app-store-icon.svg";
import { ReactComponent as PLAY_MARKET_ICON } from "./images/play-market-icon.svg";
import { ReactComponent as PACKAGE_ICON } from "./images/package-icon.svg";
import { ReactComponent as HASHTAG_ICON } from "./images/hashtag-icon.svg";
import { ReactComponent as DANGER_ICON } from "./images/danger.svg";
import { ReactComponent as DESKTOP_ICON } from "./images/desktop-icon.svg";
import { ReactComponent as MASK_ICON } from "./images/mask-icon.svg";
import { ReactComponent as TAG_ICON } from "./images/tag-icon.svg";
import { ReactComponent as ALARM } from "./images/alarm.svg";
import { ReactComponent as AVATAR } from "./images/avatar.svg";
import { ReactComponent as BUG } from "./images/bug.svg";
import { ReactComponent as COMPASS } from "./images/compass.svg";
import { ReactComponent as COUNTRY2 } from "./images/country2.svg";
import { ReactComponent as DANGEROUS } from "./images/dangerous.svg";
import { ReactComponent as DEVICE } from "./images/device.svg";
import { ReactComponent as MASK2 } from "./images/mask2.svg";
import { ReactComponent as NOT_EQUAL } from "./images/not-equal.svg";
import { ReactComponent as USER_CARD } from "./images/user_card.svg";
import { ReactComponent as WARN } from "./images/warn.svg";
import { ReactComponent as BOT_DETECTED } from "./images/bot-detected-icon.svg";
import { ReactComponent as CLOUD_UPLOAD } from "./images/cloud-upload.svg";

export const IMAGES = {
  LOGO,
  ADMIN_LOGO,
  MENU_BUTTON,
  SMALL_LOGO,
  SMALL_ADMIN_LOGO,
  USER,
  REGISTRATION_QR_CODE,
  PLUS,
  WARNING,
  INFO,
  DOWNLOAD,
  EDIT,
  APP_LOGO,
  GOOGLE_PLAY_LOGO,
  APP_STORE_LOGO,
  FULL_NAME,
  EMAIL,
  PASSWORD,
  NEW_LOGO,
  REGISTRATION_MAIL,
  BUTTON_ARROW,
  CHANGE_MAIL_MARKER,
  WELCOME_PHONE,
  WELCOME_PLUS,
  WELCOME_QR_BG,
  PHONE_BOLD,
  MODAL_PHONE,
  STATIC_USER_ICON,
  REQUIREMENT_FAIL,
  REQUIREMENT_SUCCESS,
  COUNTRY_ICON,
  ZIP_ICON,
};

export const ICONS = {
  PLUS: PLUS_ICON,
  ADD_APPLICATION: ADD_APPLICATION_ICON,
  UPGRADE: UPGRADE_ICON,
  LINE_DISTANCE: LINE_DISTANCE_ICON,
  COUNTRY_DIFFERENTIAL: COUNTRY_DIFFERENTIAL_ICON,
  TOR_FILLED: TOR_FILLED_ICON,
  KNOWN_ATTACKER: KNOWN_ATTACKER_ICON,
  ANONYMOUS_FILLED: ANONYMOUS_FILLED_ICON,
  USER: USER_ICON,
  COPY: COPY_ICON,
  KEY: KEY_ICON,
  VERIFY_CLOUD: VERIFY_CLOUD_ICON,
  PLAY: PLAY_ICON,
  APP_STORE: APP_STORE_ICON,
  PLAY_MARKET: PLAY_MARKET_ICON,
  PACKAGE: PACKAGE_ICON,
  HASHTAG: HASHTAG_ICON,
  DANGER: DANGER_ICON,
  DESKTOP: DESKTOP_ICON,
  MASK: MASK_ICON,
  TAG: TAG_ICON,
  WARN,
  USER_CARD,
  NOT_EQUAL,
  MASK2,
  DEVICE,
  DANGEROUS,
  COUNTRY2,
  COMPASS,
  BUG,
  AVATAR,
  ALARM,
  USER_VERTICAL_CARD,
  BOT_DETECTED,
  CLOUD_UPLOAD,
};

export const FONTS = {
  FUTURA_DEMI_C: "FuturaDemiC",
  FUTURA_MEDIUM_C: "FuturaMediumC",
  FUTURA_LIGHT_C: "FuturaLightC",
  FUTURA_BOLD_C: "FuturaBoldC",
  FUTURA_BOOK_C: "FuturaBookC",

  RUBIK_SEMI_BOLD: "Rubik-SemiBold",
  RUBIK: "Rubik",
  RUBIK_BOLD: "Rubik-Bold",
  RUBIK_EXTRA_BOLD: "Rubik-ExtraBold",
  RUBIK_LIGHT: "Rubik-Light",
  ROBOTO: "Roboto",
  MIRIAM_LIBRE: "Miriam Libre",
};
