import styled from 'styled-components';
import { FONTS } from '../../assets';
import { COLORS } from '../../utils/colors';

export const CancelButton = styled.button`
  background: none;
  font-size: 14px;
  font-family: ${FONTS.RUBIK};
  color: ${COLORS.MEDIUM_PURPLE};
  border: none;
  cursor: pointer;
`;
