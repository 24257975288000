import { FC } from "react";
import { Navigate } from "react-router-dom";
import { Routes } from "../../utils/constants";
import { IHiddenRoute } from "./props";

const HiddenRoute: FC<IHiddenRoute> = ({ condition, children }) => {
  return condition ? <>{children}</> : <Navigate to={Routes.Home} />;
};

export default HiddenRoute;
