import React, { FC } from "react";
import { IKeyriSelectProps } from "./props";
import "./styles.css";
import { SelectSizesEnum, SelectWrapper } from "./styles";
import { Select } from "antd";

export const KeyriSelect: FC<IKeyriSelectProps> = ({ showPlaceholderInfinitely = false, size = SelectSizesEnum.Medium, maxWidth, dataName, error, width, ...props }) => {
  return (
    <SelectWrapper placeholder={props.placeholder} data-name={dataName} showPlaceholderInfinitely={showPlaceholderInfinitely} size={size} maxWidth={maxWidth} width={width} error={error}>
      <Select {...props} />
    </SelectWrapper>
  );
};
