import styled from "styled-components";


export const Background = styled.div`
  min-height: 100vh;
  overflow: hidden;
  background: radial-gradient(
          95.31% 188.48% at 4.69% 81.64%,
          rgba(140, 91, 220, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%
  ),
  radial-gradient(
          67.88% 134.24% at 94.27% 22.61%,
          rgba(7, 199, 202, 0.23) 0%,
          rgba(255, 255, 255, 0) 100%
  );
`;
